import React, { useState } from 'react';
import right_arrow from '../../../../../../assets/icons/right_arrow.svg'
import dot from '../../../../../../assets/icons/dot.svg'
import IndexList from '../IndexList';
import './IndexElement.css'
import { useAppSelector } from '../../../../../../redux';

type IndexElementProps = {
    section?: any, 
    goToSection: (section:any) =>void
};

const IndexElement:React.FC<IndexElementProps> = ({section, goToSection}) => {

    const [isItemsOpened, setIsItemsOpened] = useState(false);
    
    const openItems = () => {
        console.log("Open items")
        setIsItemsOpened(!isItemsOpened)
    }

    const _goToSection = () =>{
        goToSection(section)
        console.log("section: ", section)
    }
    
    return (
        <div className='index-item' >
            <div className={`element`}>
                
                {
                    section.items.length == 0 ? 
                    <div className='dot open-element-icon'><img src={dot} alt="" style={{marginBottom: '2px'}}/></div> :
                    <div className='arrow-icon open-element-icon keepClickoutOpen' onClick={openItems}><img className={`${isItemsOpened ? "d" : "r"} keepClickoutOpen`} src={right_arrow} alt="" /></div>
                }

                <div className='element-title' onClick={_goToSection}>{section.title}</div>

            </div>

            <div className='index-bar-element-seperator'></div>

            <div className='index-items'>
                {isItemsOpened ? 
                    <IndexList goToSection={goToSection} outline={section.items}></IndexList> : null
                }
            </div>
        </div>
    );
}
export default IndexElement;