import React from 'react';
import IndexElement from './IndexElement/IndexElement';

type IndexListProps = {
    outline?: any ,
    goToSection: (section: any) =>void,
};

const IndexList:React.FC<IndexListProps> = ({outline, goToSection}) => {
    
    return (
    
        <div className='index-list'>
            {outline.map((section:any)=>(
                <IndexElement goToSection={goToSection} section={section}></IndexElement>
            ))}
        </div>
        
    )
}

export default IndexList;