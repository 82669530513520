import React, {useState} from 'react';
import Tab from './Tab/Tab';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import plusIcon from '../../../assets/icons/color_plus.svg';
import { useAppSelector, useAppDispatch } from '../../../redux';
import { addTab, closeTab, setActiveTabIndex, reorderTabs } from '../../../pages/desk/reader/Reader/readerTabsSlice';

//Reference: https://codesandbox.io/s/mr9y10?file=/src/components/DraggableTabsList.jsx:1829-1910

import './TabsBar.css'
import './Tab/Tab.css'
import Icon from '../../icons/HoverTipIcon/Icon';
import { ReaderTabs } from '../../../pages/desk/reader/Reader/readerTabsSlice';

/**
 * This is a generic tabs component from the ui-library that can be used anywhere. 
 */

type TabsBarProps = {
   newTabHandler?: () =>void,
   clickTabHandler: (id: string) =>void,
   closeTabHandler: (indexToClose: number, newActiveId: string | null) =>void, 
   reorderTabHandler: (source: any, destination: any, draggableId: any) => void
};

const TabsBar:React.FC<TabsBarProps> = (props) => {

    const readerTabsState = useAppSelector((state) =>state.readerTabs)
    const {tabs, activeTabId} = readerTabsState

    // const tabTitles = tabs.map(tab => tab.title)

    const [numberOfTabs, setNumberOfTabs] = useState<number>(tabs.length);
    
    const newTabHandler = () =>{
        if(props.newTabHandler) {
            props.newTabHandler()
            setNumberOfTabs(numberOfTabs+1);
        }
    }

    const clickTabHandler = (e: any) => {
        if(e.target.id=="closeIcon") return
        else {
            const id = e.target.id
            console.log("Hello clickTabHandler", id)
            props.clickTabHandler(id)
        }
    }

    const closeTabHandler = (index: number) => {

        console.log("closeTabHandler me activeTabIndex: ", activeTabId, " & tabs[index].id", tabs[index].readingDocument.objectID)
        
        var newActiveId: string | null = null

        console.log("readingDoc: ", index, tabs, tabs[index])

        if(activeTabId === tabs[index].readingDocument.objectID){
            if(index == 0){
                if(tabs.length > 1) newActiveId = tabs[1].readingDocument.objectID;
                
            }else{
                newActiveId = tabs[index-1].readingDocument.objectID;
            }

        }
        
        props.closeTabHandler(index, newActiveId)

        setNumberOfTabs(numberOfTabs-1);

    }

    const handleOnDragEnd = (result:any) =>{
        //TODO: synchronously update the state here. 
        console.log("Dragging done!", result )
        const {destination, source, draggableId} = result;

        if(!destination){
            return;
        }

        if(
            destination.droppableID == source.droppableID &&
            destination.index == source.index
        ) {
            return;
        }

        props.reorderTabHandler(source, destination, draggableId)

    }

    const handleOnDragStart = (result: any) =>{
        console.log("Starting drag: ", result)
    }

    const handleOnDragUpdate = (result: any) =>{
        console.log("Updating drag: ", result)
    }
    
    return(
        <div className='TabsBar'>
            <DragDropContext onDragEnd={handleOnDragEnd} 
                // onDragStart={handleOnDragStart} onDragUpdate={handleOnDragUpdate}
            >
                <StrictModeDroppable droppableId = "tabs-container" direction='horizontal'>
                    {
                        (provided) =>(
                            <div className="tabs-container" id="tabs-container" 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            >
                                {
                                    tabs.map((tab, index) =>{
                                        return(
                                            <Tab
                                                title={tab.readingDocument.name} index={index}
                                                id={tab.readingDocument.objectID}
                                                key = {tab.readingDocument.objectID}
                                                active={activeTabId===tab.readingDocument.objectID} 
                                                closeTabHandler={closeTabHandler}
                                                clickTabHandler = {clickTabHandler}
                                                numberOfTabs = {numberOfTabs}
                                            />
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )
                    }

                </StrictModeDroppable>
            
            </DragDropContext>

            {
                props.newTabHandler && tabs.length?
                <Icon src={plusIcon} className="newTabIcon" onClick={newTabHandler} />
                :null
            }
            
        </div>
    )
}
export default TabsBar;

