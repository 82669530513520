import React from 'react';
import plus from '../../../../../assets/icons/plus.svg'
import { openOverlay } from '../../../../../layouts/Overlay';
import { useAppDispatch } from '../../../../../redux';
import NewButtonOverlay from './NewButtonOverlay/NewButtonOverlay';

import './NewButton.mobile.css'

type NewButtonProps = {
    openCreateFolderOverlay: (event: React.MouseEvent<HTMLDivElement>) => void
};

const NewButton:React.FC<NewButtonProps> = ({openCreateFolderOverlay}) => {

    const dispatch = useAppDispatch();

    const openNewButtonOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: undefined,
            component: <NewButtonOverlay/>
        }))
    }
    
    return(

        <div className='NewButton mobile' onClick={openNewButtonOverlay}>
            <img src={plus} className='NewButton-icon'></img>
        </div>
    )
}
export default NewButton;