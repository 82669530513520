import React from 'react';
import new_folder from '../../../../../../assets/icons/new_folder.svg';
import upload_file from '../../../../../../assets/icons/upload_file.svg';
import notebook_icon from '../../../../../../assets/icons/notebook.svg';
import './NewButtonOverlay.css'
import { openOverlay } from '../../../../../../layouts/Overlay';
import { useAppDispatch, useAppSelector } from '../../../../../../redux';
import CreateFolder from '../../laptop/CreateFolder/CreateFolder';
import folder from '../../../../../../assets/icons/folder.svg'
import UploadPdf from '../../laptop/UploadFile/UploadPdf';
import { Folder } from '../../../../../../types/files';
import { selectFolderPath } from '../../../../features/files/folderPathSlice';

type NewButtonOverlayProps = {
    
};

type IconButton = {
    icon: string,
    iconLabel: string,
    handler: (event: React.MouseEvent<HTMLDivElement>) => void
}

const NewButtonOverlay:React.FC<NewButtonOverlayProps> = () => {

    const dispatch = useAppDispatch();
    const folderPath = useAppSelector(selectFolderPath) 
    const currentFolder = folderPath.segments.at(-1) as Folder

    const openCreateFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                 title: "Create Folder",
                 icon: folder,
                 data: ""
            },
            component: <CreateFolder/>
        }))
    }

    const openUploadPdfOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: upload_file,
                title: "Upload File"
            },
            component: <UploadPdf currentFolder={currentFolder}/>
        }))
    }

    const IconButtons: IconButton[] = [
        {icon: upload_file, iconLabel: "Upload File", handler: openUploadPdfOverlay},
        {icon: new_folder, iconLabel: "New Folder", handler: openCreateFolderOverlay},
        {icon: notebook_icon, iconLabel: "New Notebook", handler: ()=>{}},
    ] 
    
    return (
        <div className='newButtonOverlay-container'>
            {IconButtons.map(Iconbutton => (
                <div className='IconButton' onClick={Iconbutton.handler}>
                    <div className='IconButton-icon-wrapper'><img className='IconButton-icon' src={Iconbutton.icon} alt="" /></div>
                    <div>{Iconbutton.iconLabel}</div>
                </div>
            ))}

        </div>
    )
}
export default NewButtonOverlay;