import "./polyfills";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Amplify, Auth, Storage } from "aws-amplify";
import { awsExports } from "@desklamp/common/";
import {
    createBrowserRouter,
    RouterProvider,
    useNavigate,
} from "react-router-dom";
import { routes } from "@desklamp/common/src/pages/routes";
import { EnvironmentContext, UserContext } from "@desklamp/common/src/contexts";
import { store } from "@desklamp/common/src/redux";
import { getPathFromRoute } from "@desklamp/common/src/pages/routes";
import {
    updateFolderPath,
    loadingFolderPath,
} from "@desklamp/common/src/pages/home/features/files/folderPathSlice";
import {
    setUser,
    unsetUser,
} from "@desklamp/common/src/features/account/userSlice";
Amplify.configure(awsExports);
Auth.configure(awsExports);
Storage.configure(awsExports);
// This is to initialise the path from the URL
getPathFromRoute()
    .then((newPath) => {
        store.dispatch(updateFolderPath(newPath));
    })
    .catch(() => {});

// This is to handle the user clicking the back/forward button
window.addEventListener("popstate", async () => {
    store.dispatch(loadingFolderPath());
    const newPath = await getPathFromRoute();
    store.dispatch(updateFolderPath(newPath));
});


    //@ts-ignore
window.intercomSettings = {
    app_id: "xx7gln9f",
    hide_default_launcher: false
}
//@ts-ignore
window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "xx7gln9f",
    name: "Prajwal", // Full name
    email: "prajwal.prakash2000@gmail.com", // Email address
    hide_default_launcher: true,
});
const script = document.createElement("script");
script.id = "clueso-script"
script.addEventListener("load", (e) => {
    console.log("Loading script");
    //@ts-ignore
    window.clueso.initialize("9ca802c0-d09f-45ba-82fb-cd3095c9d754", {
        createHelpButton: true,
    });
    //@ts-ignore
    window.clueso.setSupportFunction(() => {
        //@ts-ignore
        window.Intercom("show")
    });
});
script.src = "https://clueso-dist.s3.us-west-1.amazonaws.com/clueso-support.js"
script.defer = true
document.body.appendChild(script) 

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <UserContext.Provider
            value={{
                signIn: (userAttributes) => {
                    store.dispatch(
                        setUser({
                            name: userAttributes.name,
                            email: userAttributes.email,
                            userID: userAttributes.sub,
                        })
                    );
                    window.location.replace("/folders/Folder_library");
                    //TODO: mark default URL
                },
                signOut: () => {
                    console.log("SIGNING OUT");
                    Auth.signOut().then(() => {
                        window.location.replace("/login");
                        store.dispatch(unsetUser());
                    });
                },
            }}
        >
            <EnvironmentContext.Provider
                value={{
                    name: "web",
                    navigator: useNavigate,
                }}
            >
                <RouterProvider router={router}></RouterProvider>
            </EnvironmentContext.Provider>
        </UserContext.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
