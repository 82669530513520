import React, { Component, useState, useEffect } from 'react'
import { MouseEventHandler } from 'remirror/dist-types/extensions';
import {ColorAlias, ColorMap, ReverseColorMap,  basicColors} from '../../../utils/Colors'


import './ColorSelector.css'

type ColorSelectorProps = {
    activeColors: ColorAlias[] ,  //an array of all colors that are active. or "all" to activate all colors
    hiddenColors?: ColorAlias[], //colors that won't be shown in the color selector
    onClickColor: (x: ColorAlias)=>void, //function to be run when a color is clicked
    transparent?: boolean, //true if you want transparent in the color selector
};

const ColorSelector:React.FC<ColorSelectorProps> = (props) => {

    const appTheme="light"

    const onClickColor  = (e: any ) =>{
        props.onClickColor(e.target.id)
    }

    var borderColor

    useEffect(() =>{
        // if(props.activeColors==="all") {
        //     if(props.transparent) {
        //         var temp = [...colors]
        //         temp.push("transparent")
        //         props.setColorArray(temp)
        //     }
        //     else props.setColorArray(colors)
        // }
    }, [])
    

    const numColors = props.hiddenColors? basicColors.length - props.hiddenColors.length : basicColors.length
    
    return (


        <div className={`colorSelector-container`} style={{
            width: numColors==basicColors.length?"calc(120px - 15px)":"calc(120px - 40px)"
        }}>

            {
                basicColors.map(color=>{

                    if(props.hiddenColors?.includes(color) ) return

                    var isActiveColor = false
                    if(props.activeColors && !(props.activeColors.length==basicColors.length)) isActiveColor = (props.activeColors?.includes(color) || props.activeColors?.includes(ColorMap[color] as ColorAlias))
                    // || props.activeColor.substring(0,7)===ColorMap[color]
                    
                    return(
                    <>
                    <svg className="btn-color" viewBox="0 0 10 10" width="30%"
                        onClick={onClickColor} id={color} name={color}>
                        <circle id={color} cx="5" cy="5" r="3.5" stroke-width="0.5" stroke={isActiveColor?"#00247D":"transparent"} 
                        fill={isActiveColor?'#fff':"transparent"}/>
                        <circle id={color} cx="5" cy="5" r="2.5" fill={ColorMap[color]}/>
                    </svg>
                    
                    </>
                    )
                        }
                )
            }
            {
                props.transparent?
                    <svg className="btn-color" viewBox="0 0 10 10" width="30%"
                        onClick={onClickColor} id={"transparent"} name={"transparent"}>
                        <circle id={"transparent"} cx="5" cy="5" r="3.5" stroke-width="0.5" stroke={props.activeColors?.includes("transparent")?"#00247D":"transparent"} fill={props.activeColors?.includes("transparent")?'#fff':"transparent"}/>
                        <circle id={"transparent"} cx="5" cy="5" r="2.5" stroke-width="0.25" stroke={appTheme==="light" || props.activeColors?.includes("transparent")?"#00247D":"white"} fill={"transparent"}/>
                        <line x1="3.4" y1="3.4" x2="6.6" y2="6.6" style={{stroke:appTheme==="light" || props.activeColors?.includes("transparent")?"#db5461":"#ffa69e", strokeWidth:"0.3"}} />
                    </svg>:null
            }

        </div>
    )
}
export default ColorSelector;






