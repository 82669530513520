import { composeWithDevTools } from '@reduxjs/toolkit/dist/devtoolsExtension';
import React from 'react';
import {useState} from 'react'
import folder_color from '../../../../../assets/icons/folder_color.svg'
import folderIcon from '../../../../../assets/icons/folder.svg'
import right_arrow from '../../../../../assets/icons/right_arrow.svg'
import Clickout from '../../../../../layouts/Clickout/Clickout'
import {Folder} from '../../../../../types/files'

type MoveFolderProps = {
    folder: Folder,
    setDisable: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentParent: (x: Folder) => void
};

const MoveFolder:React.FC<MoveFolderProps> = ({folder, setDisable, setCurrentParent}) => {

    const [isHovering, setIsHovering] = useState(false);
    const[isSelected,setIsSelected] = useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    const onSelect = () => {
        console.log("Inside move folder for", )
        setIsSelected(!isSelected);
        setDisable(isSelected);  
    }
    
    return(
        <div className='move-file' 
            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} 
            style={{backgroundColor: isSelected ? "#4f46e5" : "", color: isSelected ? "white" : "", zIndex: isSelected ? 5 : 0}}
            onClick = {()=>{setCurrentParent(folder)}}
        >
            <Clickout closeFunction={()=>setIsSelected(false)}>
                <div className='move-file-name' >
                    <img className={`file-icon ${ isSelected ? "selected" : ""}`} src={isSelected ? folderIcon : folder_color}></img>
                    <div className='file-name'>{folder.name}</div>
                </div>
            </Clickout>
            <img  className={`move-right-arrow ${isSelected ? "selected" : ""}`} src={right_arrow} color={isSelected ? "white" : ""} style={{filter: isSelected ? "$filter_white" : ""}}></img>
        </div>
    );
}
export default MoveFolder;