import React, {useEffect, useState} from 'react';
import { Button} from '../../../../ui/buttons/';
import {useAppDispatch, useAppSelector} from '../../../../redux'
import {openOverlay} from '../../../../layouts/Overlay'
import SharingBox from '../../../../features/collaboration/SharingBox/SharingBox/SharingBox';
import folder from '../../../../assets/icons/folder.svg';
import sharing from '../../../../assets/icons/sharing.svg'
import { selectFolderPath } from '../../features/files/folderPathSlice';
import { Folder } from '../../../../types/files';
import './ShareButton.css'
import useGetCollaborators from '../../../../hooks/CollaborationHooks/useGetCollaborators';
import { Collaborator } from '../../../../types/user';
import MultiProfilePreview from '../../../../features/account/MultiProfilePreview/MultiProfilePreview';
import {User} from '../../../../types/user'
import useWindowSize from '../../../../hooks/useWindowSize';
import { Device } from '../../../../types/devices';

type ShareButtonProps = {
    
};

const ShareButton:React.FC<ShareButtonProps> = () => {

    const dispatch = useAppDispatch()

    const folderPath = useAppSelector(selectFolderPath)
    const currFolder = folderPath.segments.at(-1) as Folder

    const getCollaborators = useGetCollaborators()

    const [users, setUsers] = useState<Collaborator[]>()

    useEffect(()=>{
        // Initialise users!
        // This is done based on the props
        getCollaborators({
            fileType: "Folder",
            objectData: currFolder
        }).then(res=>{
            // console.log("ShareButton: ", res)
            if(res) setUsers(res)
        })
        .catch(err=>{
            console.error("ShareButton error: ", err)
        })
    }, [folderPath])

    const device: Device = useWindowSize();

    const openSharingOverlay = () =>{
        dispatch(openOverlay({
            heading: {
                icon: folder,
                title: "Share",
                data: currFolder.name , 
            },
            component: 
                <div className={`SharingBox-overlay ${device}`}>
                    <SharingBox file = {{fileType:'Folder', objectData:currFolder}} updateCollaboratorsInParent={setUsers}/>
                </div>
                ,
        }))
    }

    if(currFolder.objectID=="Folder_library" || currFolder.objectID=="Folder_shared") return null

    
    return(
        <>
            <Button icon={sharing} edges='sharp' fill='solid' onClick={openSharingOverlay}>Share</Button>
            {
                users && users.length > 1?
                <div onClick={openSharingOverlay} style={{cursor: "pointer"}}>
                    <MultiProfilePreview maxNumber={2} shadow={true} users={users}/>
                </div>
                :null
            }
        </>
    )
}
export default ShareButton;