import React, {useContext, useEffect, useState} from 'react';
import Reader from '../../reader/Reader/Reader';
import notebookBtn from '../../../../assets/icons/goToNotebookBtn.svg'
import Writer from '../../writer/Writer/Writer';
import './Desk.mobile.css'
import Overlay from '../../../../layouts/Overlay/Overlay';
import { EnvironmentContext } from '../../../../contexts';
import { useEffectOnce } from '../../../../hooks/useEffectOnce';
import { ContextMenuObject } from '../../../../layouts/ContextMenu/ContextMenu';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { selectSeparator } from '../../components/Separator/separatorSlice';
import { initialiseFromURL } from '../../desk-logic/cachedDataManager';
import { selectCurrentNotebook, selectInitialisedFetchedNotebooks } from '../../writer/notebooks/fetchedNotebooksSlice';
import { setNotebook, setPDF } from '../../components/Separator/Separator.MobileSlice/Separator.MobileSlice';
import MessagePopup from '../../../../layouts/MessagePopup/MessagePopup';

type DeskProps = {
    
};

const Desk:React.FC<DeskProps> = () => {

    // const [isNotebook, setIsNotebook] = useState(false);

    const dispatch = useAppDispatch();
    const {isNotebook} = useAppSelector(state=>state.SeparatorMobile)

    const openNotebooks = () => {
        console.log("Open Notebook");
        if(isNotebook){
            dispatch(setPDF())
        }else{
            dispatch(setNotebook())
        }
    }


    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    // const allReadings = useAppSelector(selectAllReadings)

    const { readerWidth, isReaderCollapsed, isWriterCollapsed } =
        useAppSelector(selectSeparator);

    const [contextMenu, setContextMenu] = useState<ContextMenuObject>({
        menuArray: [],
        show: false,
        X: 0,
        Y: 0,
    });

    const showContextMenu = (cont: ContextMenuObject | false) => {
        console.log(cont, "CONTEXT MENU");
        if (!cont) {
            setContextMenu({
                menuArray: [],
                show: false,
                X: 0,
                Y: 0,
            });
        } else {
            setContextMenu(cont);
        }
    };

    const separatorWidth: number = 10; //pixels
    // console.log("isReaderCollapsed: ", isReaderCollapsed)

    var calculatedReaderWidth: number = 0;

    if (isReaderCollapsed) calculatedReaderWidth = 0;
    else if (isWriterCollapsed) calculatedReaderWidth = 100;
    else calculatedReaderWidth = readerWidth;
    const readerTabsState = useAppSelector((state) =>state.readerTabs)
    const currentNotebook = useAppSelector(selectCurrentNotebook)
    const fetchedNotebooksInitialised = useAppSelector(selectInitialisedFetchedNotebooks)
    const env= useContext(EnvironmentContext)
    const navigate = env.navigator()
    useEffect(()=>{

        const params = new URLSearchParams();
        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);        
        if (readerTabsState.initialised){
        
            let objectIDs = readerTabsState.tabs.map(tab=>tab.readingDocument.objectID)
            console.log("",objectIDs)
            if (objectIDs.length !== 0) params.append('reading', JSON.stringify(objectIDs));
            
        }
        else{
            if (searchParams.get('reading')){
                params.append('reading',searchParams.get('reading')!)

            }
        }
        if (fetchedNotebooksInitialised){
            if (currentNotebook) params.append('writing', currentNotebook?.objectID);

        }
        else{
            if (searchParams.get('writing')){
                params.append('writing',searchParams.get('writing')!)

            }
        }
        const searchString = params.toString();
        navigate('/desk?' + searchString)

        
    },[readerTabsState, fetchedNotebooksInitialised, currentNotebook, navigate])

    useEffectOnce(() => {
        // Fetch the tabs from the URLs
        let params = new URLSearchParams(document.location.search);
        try {
            console.log("URL",params)
            let objectIDs = JSON.parse(params.get("reading") ?? "[]");
            let notebookID = params.get("writing");
            if (!notebookID && objectIDs.length === 0) {
                setInitialLoading(false);
            }
            if (notebookID) {
                initialiseFromURL(objectIDs, ()=>setInitialLoading(false), notebookID, )
            } else {
                initialiseFromURL(objectIDs, ()=>setInitialLoading(false))
            }
        } catch (e) {
            console.error(e);
        }
    });

    if (initialLoading) {
        return (
            <div
                className="lds-grid"
                style={{
                    zoom: 2,
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    WebkitTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }
    
    return (
        <div className='Desk mobile'>

            <Overlay />
            
            <MessagePopup />
            
            
            <div className="Desk-read" style={{width: "100%", display: isNotebook ? "none" : "block"}}>

                <Reader/>
            
            </div>
            
            {
                isNotebook ? 
                <div className='Desk-write'>
                    <Writer/>
                </div>
                :
                null
            }

            <img src={notebookBtn} onClick={openNotebooks} className="notebookBtn"/>

        </div>
    )
}
export default Desk;