import React, { Dispatch, SetStateAction, useState } from 'react';
import Topbar from '../../../components/DeskTopbar/DeskTopbar';
import RightIcons from '../../../components/DeskTopbar/RightIcons/RightIcons';
import horizontal_dots from '../../../../../assets/icons/horizontal_dots.svg'
import search from '../../../../../assets/icons/search.svg'
import sharing from '../../../../../assets/icons/sharing.svg'
import Icon from '../../../../../ui/icons/HoverTipIcon/Icon';
import right_arrowhead from '../../../../../assets/icons/right_arrowhead.svg'

import undoIcon from '../../../../../assets/icons/undo.svg'
import redoIcon from '../../../../../assets/icons/redo.svg'

import DocumentTitle from '../../../components/DeskTopbar/DocumentTitle/DocumentTitle';

import star from '../../../../../assets/icons/star.svg'
import notebookIcon from '../../../../../assets/icons/notebookIcon.svg'
import { DisplayMenu } from '../../../../../ui/menus/Menu/Menu';
import { WritingDocument } from '../../../../../types/files';
import downloadIcon from '../../../../../assets/icons/download.svg'
import { useAppDispatch } from '../../../../../redux';
import { openOverlay } from '../../../../../layouts/Overlay';
import SharingBox from '../../../../../features/collaboration/SharingBox/SharingBox/SharingBox';
import { triggerShortcut } from '../../../../../utils/shortcuts/shortcuts';
import useEditFile from '../../../../../hooks/FileHooks/useEditFile';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { Device } from '../../../../../types/devices';


type WriterTopbarProps = {
    page: "Notebook" 
    notebook: WritingDocument
    showNotebookMenu: ()=>void
}|{
    page: "NotebookMenu"
    showNotebookMenu: ()=>void
};

const WriterTopbar:React.FC<WriterTopbarProps> = (props) => {
    const {page, showNotebookMenu} = props

    const [notebookTitle, setNotebookTitle] = useState<string>(props.page==="Notebook"?props.notebook.name:"")

    const dispatch = useAppDispatch()

    const editNotebookFunction = useEditFile()

    const onEditTitle = (newTitle: string) =>{

        if(props.page!=="Notebook") return

        console.log("editing notbeook title: ", newTitle)

        const tempNotebook = {...props.notebook}
        tempNotebook.name = newTitle

        editNotebookFunction(tempNotebook)
        setNotebookTitle(notebookTitle)

    }

    const device: Device = useWindowSize();
    const showSharingMenu = () =>{

        if(page==="NotebookMenu") return


        dispatch(openOverlay({
            heading: {
                icon: sharing,
                title: "Share",
                data: props.notebook.name , 
            },
            component: 
                <div className={`SharingBox-overlay ${device}`}>
                    <SharingBox file = {{fileType:'Writing', objectData:props.notebook}} />
                </div>
                ,
        }))
    }

    const settingsMenu = {
        menu: [
            // {type:"header", label: "Edit"},
            // {icon: undoIcon, label: "Undo", handler: () =>{triggerShortcut({CmdOrCtrl: true, key: 'Z'})}},
            // {icon: redoIcon, label: "Redo", handler: () =>{triggerShortcut({CmdOrCtrl: true, key: 'Z', shift: true})}},

            {type:"header", label: "Sharing"},
            {icon: sharing, label: "Edit Collaborators", handler: showSharingMenu},

            // {type:"header", label: "Location"},
            // {icon: star, label: "Move To"},
            // {icon: star, label: "Add to Favourites"},
            // {icon: star, label: "Delete", type: "warning"},

            // {type:"header", label: "Export"},
            // {icon: downloadIcon, label: "Download Notebook"},


        ], 
    } as DisplayMenu

    
    
    return(
        <Topbar>
            {
                page==="Notebook"?
                <>
                    <Icon onClick={showNotebookMenu} size="s" src={right_arrowhead} style={{transform: 'rotate(180deg)'}}></Icon>
                    <DocumentTitle topbarType= {"Writing"} title={props.notebook.name} location='' onEdit={props.notebook.permission!=="viewer"?onEditTitle:undefined}  />
                    <RightIcons icons = {[
                        {icon: sharing, handler: showSharingMenu},                  
                    ]} settingsMenu={settingsMenu}></RightIcons>
                </>
                :
                <div className="Notebook-Menu-title">
                    Notebook Menu
                </div>
            }
            
            

        </Topbar>
    )
}
export default WriterTopbar;