import React, { useState } from 'react';
import './CodeInput.css'
import { Caption } from '../../text/Text';



const CodeInput = ({value, onChange, error, onSubmit}) => {
    /**
     * @props
     * value: [], (length of array must be code length)
     * onChange: () =>{}
     * onSubmit: () =>{}
     * error: {message: string}
    */

    const isDigit = (val) =>{
        return /^\d$/.test(val)
    }

    const getIndexFromId = (id) =>{
        /**
         * The id of each input box is of the format code-input-${index}
         * This function gets the index through simple string handling and returns it here
         */
        const arr = id.split('-')
        const index = parseInt(arr[arr.length - 1])
        return index
    }

    const focusNext = (index) =>{
        if(index<5) document.getElementById(`code-input-${parseInt(index) + 1}`).focus()
    }

    const focusPrev = (index) =>{
        if(index>0) document.getElementById(`code-input-${parseInt(index) - 1}`).focus()
    }

    const setDigit = (index, newVal) =>{

        var tempCode=[...value]
        tempCode[index] = newVal
        onChange(tempCode)

    }

   

    const onKeyDown = (e) =>{

        const index = getIndexFromId(e.target.id)
        
        //Handle typing a digit here.
        if(isDigit(e.key)){
            const newVal = e.key
            setDigit(index, newVal)
            focusNext(index)
        }

        //Handle backspace here. 

        else if(e.key==="Backspace"){
            if(!index){
                //We are on the first box
                setDigit(index, "")
            }
            else if(!e.target.value){
                //There is nothing in this box right now
                setDigit(index-1, "")
            }
            else{
                //There is some number in this box right now
                setDigit(index, "")
            }
            focusPrev(index)

        }

        else if(e.key==="ArrowLeft") focusPrev(index)
        else if (e.key==="ArrowRight") focusNext(index)
        else if (e.key==="Enter"){
            onSubmit()
        }

    }

    const onPaste = (e) =>{

        //Handle pasting
        const pasteText =  e.clipboardData.getData('text').trim()

        // Allow numbers only
        if (!/\d{6}/.test(pasteText)) return e.preventDefault(); // Invalid. Exit here
        document.getElementById(`code-input-5`).focus()

        const pasteCode = [...pasteText];
        onChange(pasteCode)


    }

    return (  
        <>
        <div className={`codeinput-container ${error?"error":""}`}>
            {
                value.map((i, index)=>
                    <input 
                        id={`code-input-${index}`}
                        value={i}
                        className="ap-otp-input" type="number" 
                        maxLength="1"
                        onChange={()=>{return}} 
                        onKeyDown = {onKeyDown}
                        onPaste = {onPaste}
                    />)
            }

        </div>
        {
            error?
            <Caption type="error">{error.message}</Caption>
            :null
        }
        </>


    );
}
 
export default CodeInput;