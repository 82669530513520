import React, { useState } from 'react';
import { nonChainable } from 'remirror';
import Clickout from '../../../../../../layouts/Clickout/Clickout';
import { useAppDispatch } from '../../../../../../redux';
import { showErrorMessagePopup } from '../../../../../../layouts/MessagePopup/messagePopupSlice';
import Icon from '../../../../../../ui/icons/HoverTipIcon/Icon';
import ToolTip from '../../../../../../ui/legacy/ToolTip';
import { ReaderTool } from '../ReaderToolbar';
import { inactivateReaderToolbar } from '../readerToolbarSlice';
import Tooltip from '../tooltips/Tooltip';
import './ToolbarIcon.css';

type ToolbarIconProps = {
    iconData: IconDataType,
    index: number
};

export type IconDataType = {
    src: string,
    hoverTip: string,
    shortcut?: string,
    active: boolean,
    tooltip?:React.ReactNode,
    tool?: ReaderTool,
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void,
    nonFunctional? :boolean
}

const ToolbarIcon:React.FC<ToolbarIconProps> = ({iconData, index}) => {

    const [tooltip, setTooltip] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const {active} = iconData

    return (
        <div className={`ToolbarIcon ${active ? "active":"inactive"} ${iconData.nonFunctional ? "non-functional" : ""}`}>
            <Icon src={iconData.src} hoverTip={!tooltip?iconData.hoverTip:""} onClick={(e) =>{
                console.log("CLICKING", iconData)
                if(iconData.nonFunctional){
                    dispatch(showErrorMessagePopup("This feature will be available in mobile soon"))
                }else{
                    if (!iconData.active){
                        iconData.onClick(e)
                        if(iconData.tooltip) setTooltip(true)
                    }
                    else{
                        console.log("INACTIVATING")
                        dispatch(inactivateReaderToolbar())
                    }
                }
            }} shortcut={iconData.nonFunctional ? "" : iconData.shortcut} 
                hoverProperties={ iconData.nonFunctional ? active : !active}/>
            {
                tooltip ?
                <Clickout closeFunction={() =>{setTooltip(false)}} closeEarly>
                    <Tooltip>
                        {iconData.tooltip}
                    </Tooltip>
                </Clickout>
                
                :null
            }
        </div>
    );
}
export default ToolbarIcon;