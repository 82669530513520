import HeaderLaptop from "./laptop/Header.laptop";
import HeaderMobile from "./mobile/Header.mobile";

import { Device } from "../../../../types/devices";
import React from 'react';
import useWindowSize from "../../../../hooks/useWindowSize";




export type HeaderProps = {
    folderName: string, 
    location: string, 
    description: string
};

const Header:React.FC = () => {

    const deviceType:Device = useWindowSize()

    const folderName: string = "Quantum Classification"
    const location: string="Library > Quantum Classification"
    const description: string = "Folder for our Literature Survey"

    if(deviceType==="laptop") return   <HeaderLaptop folderName={folderName} location={location} description={description}  />
    else return <HeaderMobile folderName={folderName} location={location} description={description}  />

}

export default Header