import React from 'react';
import {User} from '../../../types/user'
import './ProfilePreview.css'

type ProfilePreviewProps = {
    user: {
        name: string,
        email: string
    }
};

const ProfilePreview:React.FC<ProfilePreviewProps> = ({user}) => {
    if (!user){
        return <></>
    }
    const nameSplit = user.name.split(" ")
    const initials = nameSplit[0][0] + nameSplit[1][0]

    
    return (
        <div className='ProfilePreview'>
            <div className='ProfilePreview-dp'>
                {initials}
            </div>
            <div className='ProfilePreview-account'>
                <div className='account-details'>
                    <div className='name'>{user.name}</div>
                    <div className='email'>{user.email}</div>
                </div>

            </div>

        </div>

    )
}
export default ProfilePreview;