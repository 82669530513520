import React, { useState } from 'react';
import { IconMenu } from '../../../../../types/menus';
import Icon from '../../../../../ui/icons/HoverTipIcon/Icon';
import horizontal_dots from '../../../../../assets/icons/horizontal_dots.svg'
import './RightIcons.css'
import Menu, { DisplayMenu } from '../../../../../ui/menus/Menu/Menu';
import Clickout from '../../../../../layouts/Clickout/Clickout';
type RightIconsProps = {
    icons: IconMenu, 
    settingsMenu?: DisplayMenu,
};


const RightIcons:React.FC<RightIconsProps> = ({icons, settingsMenu}) => {

    const [showSettings, setShowSettings ] = useState<boolean>(false)

    const displaySettings = () =>{
        setShowSettings(true)
    }

    const hideSettings = () =>{
        setShowSettings(false)
    }
    
    return (
        <div className="RightIcons">
            {
                icons.map(icon=>
                    <Icon src={icon.icon} onClick={icon.handler} hoverTip={icon.hoverTip} className='RightIcons-icon'></Icon>    
                )
            }
            { settingsMenu? <Icon src={horizontal_dots} size="s" onClick={displaySettings}></Icon>:null}
            { settingsMenu && showSettings? 
                <Clickout closeFunction={hideSettings}>
                    <div className="RightIcons-menu">
                        <Menu size="s" menu ={settingsMenu.menu}/>
                    </div>

                </Clickout>
                

            :null}

        </div>
    )
}
export default React.memo(RightIcons);