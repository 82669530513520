import React from 'react';

import './TopbarTitle.css'

type TopbarTitleProps = {
    children: string
};

const TopbarTitle:React.FC<TopbarTitleProps> = ({children}) => {

    return <div className="TopbarTitle">{children}</div>

}
export default TopbarTitle;