import React, { useState } from 'react';
import plus from '../../../../../../assets/icons/plus.svg';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../types/devices';
import { Size } from '../../../../../../types/sizes';

import '../../Thumbnails/ThumbnailLamination/ThumbnailLamination.css'
import './NewDocument.css'

type NewDocumentProps = {
    size?: Size
    onClick: ()=>Promise<boolean>
};

const NewDocument:React.FC<NewDocumentProps> = ({size, onClick}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const device: Device = useWindowSize();

    return(

        <div className={`NewDocument ${size} ${device}`} onClick = {()=>{
            setLoading(true)
            onClick().then(()=>setLoading(false))
        }
        } >
            {!loading?<img src={plus} className="plusIcon" ></img>: null}
            .{loading?<div className='lds-hourglass'></div>: null}
        </div>
    
    )
}
export default NewDocument;