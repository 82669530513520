import React, { useContext } from 'react';
import folderIcon from '../../../../../../../../../assets/icons/folder_color.svg';
import pdfIcon from '../../../../../../../.././../assets/icons/pdfIcon.svg';
import notebookIcon from '../../../../../../../.././../assets/icons/notebookIcon.svg';
import star from '../../../../../../../../../assets/icons/star.svg'
import trash from '../../../../../../../../../assets/icons/trash.svg'
import './SearchResultElement.css'
import { DocumentInfoProps, Folder, ObjectUser } from '../../../../../../../../../types/files';
import { SearchResult } from '../../SearchBox';
import useFolderNavigate from '../../../../../../../../../hooks/FileHooks/useFolderNavigate';
import { EnvironmentContext } from '../../../../../../../../../contexts';
import { useAppDispatch } from '../../../../../../../../../redux';
import { closeOverlay } from '../../../../../../../../../layouts/Overlay';
import { Device } from '../../../../../../../../../types/devices';
import useWindowSize from '../../../../../../../../../hooks/useWindowSize';
import { closeFullScreenOverlay } from '../../../../../../../../../layouts/FullScreenOverlay';
import { openReading, openWriting } from '../../../../../../../../../features/files/openFile';
// import { openFiles } from '../../../../../../../../routes';

type SearchResultElementProps = {
    searchResult: SearchResult
};

const SearchResultElement:React.FC<SearchResultElementProps> = (props) => {
    
    const file = props.searchResult.objectUser
    const fileType = file.objectID.split("_")[0]
    const dispatch = useAppDispatch()

    const env = useContext(EnvironmentContext)
    const navigate = useFolderNavigate(env)

    let fileIcon: string = folderIcon;
    if(fileType == "Folder"){
        fileIcon = folderIcon
    } else if(fileType == "Reading"){
        fileIcon = pdfIcon
    } else if(fileType == "Writing"){
        fileIcon = notebookIcon
    }
    
    console.log("searchResult: ", props.searchResult)

    const onClick=() =>{
        if(fileType == "Folder"){
            console.log("navigating to: ", file)
            navigate(file)
            
        } else if(fileType == "Reading"){
            // fileIcon = pdfIcon
            openReading(props.searchResult.objectUser.objectID)

        } else if(fileType == "Writing"){
            // fileIcon = notebookIcon
            openWriting(props.searchResult.objectUser.objectID)
        }
        dispatch(closeOverlay())
        dispatch(closeFullScreenOverlay())
    }

    const device: Device = useWindowSize();

    return (
        <div className={`searchResultElement-container ${device}`} onClick={onClick}>
            <div className='file-info'>
                <div className='fileIcon'>
                    <img style={{height: "36px"}} src={fileIcon} alt="" />
                </div>

                <div className={`fileHeading ${device}`}>
                    <div className='fileName'>
                        {props.searchResult.highlightedName}
                    </div>

                    {/* {fileType == "Folder" ? <div className='fileDescription'>{(file as Folder).description}</div> : null} */}
                </div>

                <div className={`fileLocation ${device}`}>
                    {(file as Folder).description || "None"}
                </div>
            </div>

            {/* <div className='hover-icons'>
                <div className='star-icon'><img src={star} alt="" /></div>
                <div className='trash-icon'><img className='trash-icon-img' src={trash} alt="" /></div>
            </div> */}
        </div>
    );
}
export default SearchResultElement;