import { DocumentInfoProps } from "../../types/files"
import { API } from "aws-amplify"
import { Collaborator, Permission } from "../../types/user"
import { shareFile } from "../../graphql/mutations"

type AddCollaboratorInput = {
    file: DocumentInfoProps,
    email: string, 
    permission: Permission
}

const useAddCollaborator: () =>(input: AddCollaboratorInput) => Promise<Collaborator | false> =() =>{
    
    return async(input: AddCollaboratorInput) =>{

        const {file, email, permission } = input

        try{
            let resp = await API.graphql({
                query: shareFile,
                variables: {
                    objectID: file.objectData.objectID,
                    email: email,
                    permission: permission
                }
            }) as any

            const newCollaborator = resp.data.shareFile
            console.log("got the result: ", newCollaborator)
            return newCollaborator

        }
        catch(e: any){
            console.error("SHARE FAILED", e)
            const errorMessage = e.errors[0].message

            // TODO: Add error handling here
            return false
        }
    }
}

export const getUpdatedCollaborators: (currentCollaborators: Collaborator[], newCollaborator: Collaborator) => Collaborator[] = 
    /**
     * 
     * @param currentCollaborators : the current list of collaborators
     * @param newCollaborator : the new collaborator to be added
     * If the new collaborator is not in the current collaborators, then this function adds the new collaborator to the current collaborators
     * If the new collaborator is already in the current collaborators with the same permission, then this function returns current collaborators
     * If the new collaborator is in the current collaborators but with a different permission, then this function updartes the permission of the new
     *      user in current collaborators and returns the updated list. 
     * @returns updated collaborators list  
     */
    (currentCollaborators, newCollaborator) => {


        const currentCollaboratorsCopy = [...currentCollaborators]

        for (var collaborator of currentCollaboratorsCopy){

            if(collaborator.email===newCollaborator.email){

                if(collaborator.permission===newCollaborator.permission){

                    return currentCollaboratorsCopy
                }
                else{
                    collaborator.permission = newCollaborator.permission
                    return currentCollaboratorsCopy
                }
            }
        }

        return currentCollaboratorsCopy.concat([newCollaborator])

}

export default useAddCollaborator