import { EnvironmentContextValue } from "../../contexts";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Folder, ReadingDocument, WritingDocument } from '../../types/files';
import { API } from 'aws-amplify';
import { moveFile, selectExploreFolderData } from "../../pages/home/features/files/folderDataSlice";
import { showErrorMessagePopup, showLoadingMessagePopup, showMessagePopup } from "../../layouts/MessagePopup/messagePopupSlice";
import { editFile as editCloud } from "../../graphql/mutations";
import { getFileType } from "../../features/files/getFileDetails";

type MoveFileInput = {
    file: Folder | ReadingDocument | WritingDocument,
    newParentID: string
}

const useMoveFile: (env: EnvironmentContextValue) => (input: MoveFileInput)=>Promise<boolean> = (env) =>  {

    //newParentID is currentParent.objectID
    //folder is objectData
    const dispatch = useAppDispatch()

    return async(input: MoveFileInput) => {

        const {file, newParentID} = input

        if(file.objectID===newParentID){
            //trying to move a folder within the same folder itself
            console.log("trying to move a folder within the same folder itself")
            return false
        }

        console.log("file: ", file)
        console.log("newParentID: ", newParentID )

        try{
            dispatch(showLoadingMessagePopup())
            const resp = await API.graphql({
                query: editCloud, 
                variables: { 
                    input : {
                        objectID: file.objectID, 
                        parentID: newParentID
                    }
                }
            }) as any
            // TODO: Add error handling 
            dispatch(moveFile({file: file, newParent: newParentID}))
            dispatch(showMessagePopup("Moved 1 file successfully."))
            return true
            
        }
        catch(e){
            console.error("Error while moving folder: ", e)
            dispatch(showErrorMessagePopup("Error while moving folder."))
            return false
        }

    }
     

}


export default useMoveFile