export const getDomain = () =>{

    const url = new URL(window.location.href)

    if(url.origin.hostname==="localhost"){
        return ""
    }

    const arr = url.host.split('.')
    return `${arr[arr.length - 2]}.${arr[arr.length - 1]}`
}

export const getURLSearchParam = (searchParam) =>{
    let params = new URLSearchParams(document.location.search);
    return params.get(searchParam)
    
}

export const openExternalLink = async (url) => {

    try {
        window.electronAPI.openExternal(url)
    } catch (error) {
        console.log('handle this later for the web case');
        const a = document.createElement('a');
        a.href = url;
        a.target="_blank"
        a.click();
        a.remove()
}}

export const ExternalLink = (props) =>{
    /**
     * A react component to be used in place of <a> that implements openExternalLink
     */

    return(
        <a onClick={() =>{openExternalLink(props.href)}}>{props.children}</a>
    )

}