import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ObjectUser } from '../../../../API'
import { getFileType } from '../../../../features/files/getFileDetails'
import type { RootState } from '../../../../redux/store'
import {FileType, Folder, myLibrary, ReadingDocument, sharedFiles, WritingDocument} from '../../../../types/files'

type File = Folder | ReadingDocument | WritingDocument


// Define a type for the slice state
export type CurrentFolderData =  {
    currentFolder: Folder, //The folder currently open on the Files section of the home screen.
    foldersData: Folder[],
    readingData: ReadingDocument[],
    writingData: WritingDocument[],
    loading?: Boolean
}

export type FolderSection = {
    folder: Folder
    collapsed: Boolean
    children: FolderSection[]
    loaded: Boolean
}

interface FolderState {
    exploreFoldersData: FolderSection[]
    currentFolderData: CurrentFolderData
}

// Define the initial state using that type
const initialState: FolderState = {
    currentFolderData: {
        currentFolder: myLibrary,
        foldersData: [],
        readingData: [],
        writingData: [],
        loading: true
    },
    exploreFoldersData: [
        {
            folder: myLibrary,
            collapsed: true,
            children: [],
            loaded: false
        },
        {
            folder: sharedFiles,
            collapsed: true,
            children: [],
            loaded: false
        }
    ]
}


// Both web an
// Both web and electron will have to dispatch an initial update
// To load an initial path



export const folderDataSlice = createSlice({
    name: 'folderData',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCurrentFolderData: (state, action: PayloadAction<CurrentFolderData>)=>{
            state.currentFolderData.currentFolder = action.payload.currentFolder
            state.currentFolderData.foldersData = action.payload.foldersData
            state.currentFolderData.readingData = action.payload.readingData
            state.currentFolderData.writingData = action.payload.writingData
            // console.log("SETTING DATA")
            state.currentFolderData.loading = false
        },
        
        updateExploreFolderData: (state, action: PayloadAction<FolderSection>)=>{
            // Update a folder section in the explore folder data
            const folder = action.payload.folder
            // Loop through the exploreFoldersData, until this folder is found.
            const exploreNode = (currFolder :FolderSection)=>{
                if (currFolder.folder.objectID === folder.objectID){
                    currFolder = action.payload
                    return currFolder
                }
                else{
                    currFolder.children = currFolder.children.map(exploreNode)
                    return currFolder
                }
            }
            const copy = [...state.exploreFoldersData]
            state.exploreFoldersData = copy.map(exploreNode)
        },

        loadingCurrentFolderData: (state)=>{
            // console.log("SETTING LOADING")
            state.currentFolderData.foldersData = []
            state.currentFolderData.readingData = []
            state.currentFolderData.writingData = []
            state.currentFolderData.loading = true
        },

        createFile: (state, action: PayloadAction<File>)=>{

            // TODO: Don't add here if parentID does not match! 

            const file = action.payload
            const fileType = getFileType(file)

            const currentParentID = state.currentFolderData.currentFolder.objectID

            console.log("createFile!: ", file, fileType)

            if(fileType==="Folder"){

                if(file.parentID===currentParentID){
                    state.currentFolderData.foldersData.push(file)
                }
                else{
                    console.log(file.parentID, currentParentID)
                    console.warn("skipping create folder in frontend")
                }

                // Loop through the exploreFoldersData, until this folder is found.
                const exploreNode = (currFolder :FolderSection)=>{
                    if (currFolder.folder.objectID === file.parentID){
                        currFolder.children.push({folder: action.payload, children: [], collapsed: true, loaded: false})
                        return currFolder
                    }
                    else{
                        currFolder.children = currFolder.children.map(exploreNode)
                        return currFolder
                    }
                }
                const copy = [...state.exploreFoldersData]
                state.exploreFoldersData = copy.map(exploreNode)
            }
            else if (fileType==="Reading"){
                console.log("reading came!")
                state.currentFolderData.readingData.push(file as ReadingDocument)

            }
            else{
                state.currentFolderData.writingData.push(file as WritingDocument)

            }

            

            
            
        },

        editFile: (state, action: PayloadAction<File>)=>{

            const file = action.payload
            const fileType = getFileType(file)

            const editMapFunction = (fol: File)=>{
                if (fol.objectID === action.payload.objectID){
                    return action.payload
                }
                else {
                    return fol
                }
            }

            if(fileType==="Folder"){
                state.currentFolderData.foldersData = state.currentFolderData.foldersData.map(editMapFunction)

                //For the left side panel
                const exploreNode = (currFolder :FolderSection)=>{

                    if (currFolder.folder.objectID === file.objectID){
                        currFolder.folder = file
                        return currFolder
                    }
                    else{
                        currFolder.children = currFolder.children.map(exploreNode)
                        return currFolder
                    }
                }
                const copy = [...state.exploreFoldersData]
                state.exploreFoldersData = copy.map(exploreNode)

            }
            else if(fileType==="Reading"){
                // console.log("edit Reading: ", state.currentFolderData.readingData, state.currentFolderData.foldersData)
                state.currentFolderData.readingData = state.currentFolderData.readingData.map(editMapFunction) as ReadingDocument[]
            }
            else{
                state.currentFolderData.writingData = state.currentFolderData.writingData.map(editMapFunction) as WritingDocument[]
            }
            
        },
        
        moveFile: (state, action: PayloadAction<{file: File, newParent: string}>)=>{

            const file = action.payload.file
            const newParent = action.payload.newParent
            const fileType = getFileType(file)

            const moveMapFunction = (fol:File)=>{
                if (fol.objectID === file.objectID){
                    return false
                }
                else {
                    return true
                }
            }

            if(fileType==="Folder"){
                state.currentFolderData.foldersData = state.currentFolderData.foldersData.filter(moveMapFunction)
                const exploreNode = (currFolder :FolderSection)=>{
                    if (currFolder.folder.objectID === file.parentID){
                        currFolder.children = currFolder.children.filter((x)=>x.folder.objectID !== file.objectID)
                    }
                    if (currFolder.folder.objectID === newParent){
                        currFolder.children.push({folder: file, children: [], collapsed: true, loaded: false})
                    }
                    currFolder.children = currFolder.children.map(exploreNode)
                    return currFolder
                    
                }
                const copy = [...state.exploreFoldersData]
                state.exploreFoldersData = copy.map(exploreNode)
            }
            else if(fileType==="Reading"){
                state.currentFolderData.readingData = state.currentFolderData.readingData.filter(moveMapFunction) as ReadingDocument[]
            }
            else{
                state.currentFolderData.writingData = state.currentFolderData.writingData.filter(moveMapFunction) as WritingDocument[]
            }
            
        },

        deleteFile: (state, action: PayloadAction<File>)=>{

            const deletedFile = action.payload
            console.log("The deleted Folder is: ", deletedFile)

            const fileType = getFileType(deletedFile)

            const deleteMapFunction = (x: File)=>x.objectID!== deletedFile.objectID

            if(fileType==="Folder") {
                state.currentFolderData.foldersData = state.currentFolderData.foldersData.filter(deleteMapFunction)

                const exploreNode = (currFolder :FolderSection)=>{
                    if (currFolder.folder.objectID === deletedFile.parentID){
                        currFolder.children = currFolder.children.filter((x)=>x.folder.objectID !== deletedFile.objectID)
                    }
                    
                    currFolder.children = currFolder.children.map(exploreNode)
                    return currFolder
                }
                const copy = [...state.exploreFoldersData]
                state.exploreFoldersData = copy.map(exploreNode)

            }
            else if(fileType==="Reading"){
                state.currentFolderData.readingData = state.currentFolderData.readingData.filter(deleteMapFunction)
            }
            else{
                state.currentFolderData.writingData = state.currentFolderData.writingData.filter(deleteMapFunction)
            }
        }
    }
})

export const {setCurrentFolderData, loadingCurrentFolderData, createFile, deleteFile, editFile, moveFile, updateExploreFolderData} = folderDataSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectCurrentFolderData = (state: RootState) => state.folderData.currentFolderData
export const selectExploreFolderData = (state: RootState) => state.folderData.exploreFoldersData
export default folderDataSlice.reducer
