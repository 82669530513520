import { API } from "aws-amplify";
import { Folder } from "../../types/files";
import { getPath } from "../../graphql/custom";

const useGetPath: () => (objectID: string) =>Promise<[Folder, ...Folder[]] | false> = () =>{

    return async(objectID: string) =>{

        console.log("getPath objectID: ", objectID)

        try{
            const resp = await API.graphql({
                query: getPath, 
                variables: {input:{
                    objectID: objectID
                }}
            }) as any

            console.log("the getPath response is: ", resp)

            const sections = resp.data.getPath
            return sections

        }
        catch(e){
            console.error("Error getting path: ", e)
            return false
        }

    }
}

export default useGetPath
