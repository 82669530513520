
import React from 'react';
import RightIcons from './RightIcons/RightIcons';

/**
 * This component is to be used as the common parent for the
 * reading and writing windows. 
 * The last child MUST be RightIcons
 */

type DeskTopbarProps = {
    children: React.ReactNode
};

import './DeskTopbar.css'

const DeskTopbar:React.FC<DeskTopbarProps> = ({children}) => {
    
    return (
        <div className='DeskTopbar'>
            {children}
        </div>
    )
}
export default DeskTopbar;