import leftAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/leftAlign_light.png";
import centerAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/centerAlign_light.png";
import rightAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/rightAlign_light.png";
import leftAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/leftAlign_dark.png";
import centerAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/centerAlign_dark.png";
import rightAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/rightAlign_dark.png";
import image_dark from "../../../../../../assets/legacy_icons/notebook_icons/image_dark.png";
import image_light from "../../../../../../assets/legacy_icons/notebook_icons/image_light.png";
import React, { useRef, useState } from "react";
import HoverTip from "./HoverTip/HoverTip";
import arrowLight from "../../../../../../assets/legacy_icons/find_arrow.png";
import arrowDark from "../../../../../../assets/legacy_icons/find_arrow_white.png";
import latex_dark from "../../../../../../assets/legacy_icons/notebook_icons/latex_dark.png";
import latex_light from "../../../../../../assets/legacy_icons/notebook_icons/latex_light.png";
import { ColorMap, DarkNotebookColorMap, LightNotebookColorMap } from "../../../../../../utils/legacy_utils/Colors";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import '../../Notebook/Toolbar/NotebookToolbar.css'

export const BaseButton = (props) => {
    let src = "";
    let baseElement;
    const vertical = props.vertical;
    const dropdownRef = useRef();
    const [showHover, setShowHover] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    let hideRegularDropdown = (e) => {
        console.log("temp called from", e.target);
        if (
            e.target.id !== `base-button-span-${props.item.label}` &&
            e.target.id !== `base-button-image-${props.item.label}` &&
            e.target.id !== `base-button-dropdown-${props.item.label}`
        ) {
            window.removeEventListener("click", hideRegularDropdown);
            console.log("hiding from temp");
            if (dropdownRef.current){
                dropdownRef.current.style.display = "none";
            }
            setMenuActive(false);
            setShowHover(false);
        }
    };
    let toggleDropdown = (e) => {
        setShowHover(false);
        let style = dropdownRef.current.style.display;
        console.log("toggledrowpdown", style);
        if (style == "" || style == "none") {
            setMenuActive(true);
            dropdownRef.current.style.display = vertical
                ? "block "
                : "inline-flex";
            console.log("style none");
            window.addEventListener("click", hideRegularDropdown);
        } else if (style == "block" || style == "inline-flex") {
            setMenuActive(false);
            window.removeEventListener("click", hideRegularDropdown);
            console.log("style block. Hiding from toggle");
            dropdownRef.current.style.display = "none";
        }
    };
    let arrow = props.theme == "light" ? arrowLight : arrowDark;
    switch (props.item.label) {
        case "color":
            let colorMap = props.theme == "light" ? LightNotebookColorMap : DarkNotebookColorMap
            baseElement = (
                <svg
                    className={`activeColorButton ${
                        props.inline ? "inline" : ""
                    }`}
                    viewBox="0 0 10 10"
                    width="100%"
                    id={`base-button-image-${props.item.label}`}
                    
                >
                    <circle
                        className="circle-button"
                        id={`base-button-image-${props.item.label}`}
                        cx="5"
                        cy="5"
                        r="3"
                        fill={
                            props.item.active() == "default"
                                ? props.theme == "light"
                                    ? "black"
                                    : "white"
                                : colorMap[props.item.active()]
                        }
                    />
                </svg>
            );
            break;
        case "latex":
            src = props.theme == "light" ? latex_light : latex_dark;
            break;
        case "image":
            src = props.theme == "light" ? image_light : image_dark;
            break;
        case "alignment":
            if (props.theme == "light") {
                switch (props.item.active()) {
                    case "left":
                        src = leftAlign_light;
                        break;
                    case "right":
                        src = rightAlign_light;
                        break;
                    case "center":
                        src = centerAlign_light;
                        break;

                    default:
                }
            } else {
                switch (props.item.active()) {
                    case "left":
                        src = leftAlign_dark;
                        break;
                    case "right":
                        src = rightAlign_dark;
                        break;
                    case "center":
                        src = centerAlign_dark;
                        break;
                    default:
                }
            }

            break;
        default:
    }
    if (src !== "") {
        baseElement = (
            <img
                id={`base-button-image-${props.item.label}`}
                className={` notebook-toolbar-button base-button ${props.theme} inactive-nb-button`}
                src={src}
            ></img>
        );
    }

    const device = useWindowSize();

    return (
        <span className="dropdown">
            <span
                id={`base-button-span-${props.item.label}`}
                className={`base-button-span`}
                onMouseEnter={() => setShowHover(true)}
                onMouseLeave={() => setShowHover(false)}
                onClick={(e) => toggleDropdown(e)}
                alt={props.item.label}
            >
                {baseElement}
                {vertical ? (
                    <img
                        src={arrow}
                        className="dropdown-arrow-icon"
                        alt="down"
                        id={`base-button-dropdown-${props.item.label}`}
                    ></img>
                ) : (
                    <></>
                )}
                {showHover && !menuActive && props.hoverTip ? (
                    <HoverTip
                        appTheme={props.theme}
                        text={props.hoverTip.text}
                        fineTunePosition={{ top: "30px" }}
                        position="bottom"
                    ></HoverTip>
                ) : null}
            </span>

            {!vertical ? (
                <div
                    ref={dropdownRef}
                    className={`basedropdown dropdown-content ${props.theme} ${device}`}
                >
                    <div
                        className={`notes-toolbar ${props.theme} ${
                            props.inline ? "inline" : ""
                        }`}
                    >
                        {props.children}
                    </div>
                </div>
            ) : (
                <div
                    ref={dropdownRef}
                    className={`dropdown-content ${props.theme} ${device}`}
                >
                    {props.children}
                </div>
            )}
        </span>
    );
};
