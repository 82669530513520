
import React, { useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { Device } from '../../types/devices';
import ColorSelector from '../../ui/inputs/ColorSelector/ColorSelector';
import { basicColors } from '../../utils/Colors';
import star from '../../assets/icons/star.svg';

import './contextMenu.css'
import SliderInput from '../../ui/inputs/SliderInput/SliderInput';
import { useEffectOnce } from '../../hooks/useEffectOnce';

// TODO: move type declarations into context menu folder

export type ContextMenuObject = {

    menuArray: ContextMenuEntry[],
    show: boolean,
    X : number,
    Y: number,
    backward?: boolean //if the text selection is backward?

}

export type ContextMenuEntry = 
{
    label: string,
    id?:string,
    icon: string,
    handler: ()=>void, 
    disabled?:boolean,
    type?:"none" | "danger",
} | 
{
    label?: string,
    id?:string,
    handler: (color : string)=>void,
    disabled?:boolean,
    type: "colors",
    icon?: string,
} | 
{
    type: "separator"
} | 
{
    label:string,
    type: "slider",
    id?:string,
    currentValue: number,
    handler: (newValue : number)=>void,
    disabled?:boolean,
    icon?: string,
}


type ContextMenuProps = {
    visible: boolean, //whether the context menu is visible or not. 
    menuArray: ContextMenuEntry[], 
    X: number, 
    Y: number, 
    showContextMenu: (a: ContextMenuObject | false) => void, 
};

export const ContextMenuComponent:React.FC<ContextMenuProps> = (props) => {
    /**This is meant to be a highly modular component that can be used for all use cases of ContextMenus.
     * Expected props to be passed is an array of objects in the form: (! means compulsory)
     * menuArray = [{
     *      label: String !
     *      handler: function() !
     *      icon: path to img src
     *      type: "separator" for a separator, none for a button
     *      id: The html id attribute. This is useful to handle onClickOutside issues
     *      disabled: (applicable for labels only) true if the label is not clickable and hence should have no hover color
     * }]
    **/

    const device: Device = useWindowSize();
    
    const handleClick = () =>{

        if(props.visible){
            console.log("closingContextMenu!")
            props.showContextMenu(false)
        }

    }

    useEffectOnce(() =>{

        setTimeout(()=>{
            console.log("CONTEXT MENU Adding listener")
            document.addEventListener("click", handleClick)}, 300);

        return ()=>
        {   console.log("CONTEXT MENU REMOVING LISTENER")
            document.removeEventListener("click", handleClick)}

    })

    
    return (
        <div className={`contextmenu-container ${device == "mobile" ? "contextMenu-mobile-view" : ""}`} style={device == "mobile" ? {} : {left:String(props.X)+"px", top:String(props.Y)+"px"}}
        //align="left"
        >

        <div className={`${device == "mobile" ? "contextMenu-mobile":""}`} style={
            device == "laptop" ? 
            {left:String(props.X)+"px", top:String(props.Y)+"px"}:
            {}
        }>
            {props.menuArray.map((item, index)=>{

                if(item.type==="separator"){
                    if(device == "laptop"){
                        return <hr key={index} className="contextMenu-hr"></hr>
                    }else{
                        return null
                    }
                }

            else if(item.type==="colors"){
                return(
                <div key={index} className="cm-colors">
                    {item.label?
                        <ContextLabel label={item.label} icon={item.icon} disabled={item.disabled}  />    
                    :null}
                    <div className="cm-colorSelector">
                        <ColorSelector 
                                activeColors =  {basicColors} //an array of all colors that are active. or "all" to activate all colors
                                onClickColor = {item.handler} //function to be run when a color is clicked
                        />
                    </div>
                    
                </div>
                )
            }
            else if (item.type==="slider"){
                return (
                <div key={index} className="cm-colors">
                {item.label?
                    <ContextLabel label={item.label} icon={item.icon} disabled={item.disabled}  />    
                :null}
                {// @ts-expect-error
                <SliderInput value={item.currentValue} onChange = {(e)=>item.handler(e.target.value)} inState={true}/>}
                </div>)
            }
            else{
                return(
                    <ContextLabel type={item.type} label={item.label} icon={item.icon} disabled={item.disabled} handler={item.handler} />
                )
            }
            })}
        
        </div>
        </div>
    )
    
}


type ContextLabelProps = {
    label: string, 
    icon?:string, 
    disabled?:boolean , 
    handler?: () => void, 
    type?: string,
};

const ContextLabel:React.FC<ContextLabelProps> = (item) => {

    
    return (
        <div  key={item.label} className={`cm-text ${item.type} ${item.handler?"hoverable":""} ${item.disabled?"disabled":""} light `} 
            //TODO: Uncomment this
            // id={item.id}
            // onMouseDown = {item.mouseDown == true? (e)=>{e.preventDefault()} : null} 
            onClick={item.handler}
        >
        
            
            <img className={`cm-icon ${item.icon?"":"hidden"}`} src={item.icon?item.icon:star} alt="icon"></img>
            
            <span className='label'>
                {
                    item.disabled?
                        <i>{item.label}</i>
                    :<>{item.label}</>
                }
            </span>
            
            
        </div>
    )
}

export default ContextLabel;

