import React from 'react';
import { allPDFAnnotations, PDFAnnotationType } from '../../../../reader/viewers/PDF/PdfManager';

export type TypeFilterProps = {
    allAnnotationTypes: PDFAnnotationType[]
    selectedAnnotationTypes: PDFAnnotationType[] ,
    setSelectedAnnotationTypes: React.Dispatch<React.SetStateAction<PDFAnnotationType[]>>,
};

const TypeFilter:React.FC<TypeFilterProps> = (props) => {
    
    const onChange = (e:any) =>{


        console.log("onChange: ", e.target.value)
        const {selectedAnnotationTypes, setSelectedAnnotationTypes} = props

        var temp = [...selectedAnnotationTypes]

        const index = selectedAnnotationTypes.indexOf(e.target.id)
        

        if(index>-1){
            temp.splice(index, 1)
        }
        else{
            temp.push(e.target.name)
        }
        setSelectedAnnotationTypes(temp)

    }

    type CheckboxProps = {
        label: PDFAnnotationType,
        
    }

    
    const Checkbox:React.FC<CheckboxProps> = ({label}) =>{
        return(
        <div className="annotations-checkbox">
            <input className="keepClickoutOpen" type="checkbox" id={label} name={label} value={label} 
                checked={props.selectedAnnotationTypes.includes(label)} onChange = {onChange}/>
            <label className="keepClickoutOpen" > {`${label.charAt(0).toUpperCase()}${label.slice(1)}`}</label><br/>
        </div>
        )
    }

    return(
        <div>
            <p>Filter by <b>Annotations Type</b></p>
            <div className="annotations-check-boxes">
                {
                    allPDFAnnotations.map(annoType=><Checkbox label={annoType} />)
                }
            </div>


        </div>
    )
    
    
}
export default TypeFilter;