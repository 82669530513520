
import Title_Cover from '../../../assets/images/auth-graphics/AuthTitle_Full.png'
import VerificationCode_Cover from '../../../assets/images/auth-graphics/VerificationCode.png'
import ForgotPassword_Cover from '../../../assets/images/auth-graphics/ForgotPassword.png'

import './AuthCoverImage.css'

const AuthCoverImage = ({subScreen}) => {
    /**
     * @props
     * subScreen: "SignInForm"  | "ResetPassword" | "SignUpForm" | "VerificationCode"
    */

    var imgSrc= Title_Cover

    if(subScreen=="SignInForm" || subScreen=="SignUpForm"){
        return(
            <img src={Title_Cover} className="auth-cover" />
        )
    }
    else if(subScreen=="VerificationCode" || subScreen=="VerifyUnconfirmedUser"){
        imgSrc=VerificationCode_Cover
    }
    else if(subScreen=="ResetPassword" || subScreen=="ForceChangePassword" ){
        imgSrc=ForgotPassword_Cover
    }

    return (  
        <img src={imgSrc} className="auth-cover" />
    );

}

const ImageCover = () =>{
    return <img src={src} className="auth-cover" />
}
 
export default AuthCoverImage;