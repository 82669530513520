import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux';
import {WritingDocument} from '../../../../../../types/files';
// import Document from '../../../../../home/features/files/Document/Document/Document';
import NewDocument from '../../../../../home/features/files/Document/NewDocument/NewDocument';
import SimpleThumbnail from '../../../../../home/features/files/Thumbnails/SimpleThumbnail/SimpleThumbnail';
import { updateNotebookRecency, uploadNotebook } from '../../../../desk-logic/notebookSaver';
import { selectActiveTab } from '../../../../reader/Reader/readerTabsSlice';
import { createNotebook, selectFetchedNotebooks } from '../../fetchedNotebooksSlice';
import { selectNotebookManager } from '../../notebookWorkspaceManagerSlice';
import { getNotebook } from '../../../../desk-logic/notebookSaver';
import './NotebookSelector.css'
import { Device } from '../../../../../../types/devices';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import ThumbnailLamination from '../../../../../home/features/files/Thumbnails/ThumbnailLamination/ThumbnailLamination';

type ItemNotebooksProps = {
    
};

const NotebookSelector:React.FC<ItemNotebooksProps> = () => {
    
    const activeTab = useAppSelector(selectActiveTab)
    const fetchedData = useAppSelector(selectFetchedNotebooks)
    const dispatch = useAppDispatch()
    const notebookManager = useAppSelector(selectNotebookManager)
    // const device: Device = useWindowSize();

    const onOpenNotebook: (nb: WritingDocument) => Promise<boolean>  = async(nb: WritingDocument)=>{

        return new Promise((resolve, reject) =>{

            getNotebook(nb).then(resp =>{
                if(resp) notebookManager.setNotebookContent(resp, false)
                resolve(true)
            })
            .catch((err) =>{
                reject(err)
            })

        })

    }
    

    if (activeTab){
        console.log("DATA", fetchedData)
        const {itemNotebooks, globalNotebooks} = fetchedData[activeTab.readingDocument.objectID]
        return (
            <div className='NotebookSelector'>
    
                <h3>Notebooks</h3>
                <p className='subtitle'>Notebooks opened with this reading.</p>
    
                <div className='NotebooksList-container'>
                <NewDocument onClick={
                        async ()=>{
                            const counter = globalNotebooks.reduce((previousValue:number, currentValue: WritingDocument)=>{
                                if (currentValue.name.includes('Untitled')){
                                    return previousValue + 1
                                }else{
                                    return previousValue
                                }
                            }, 0)
                            let title = "Untitled"
                            if (counter !== 0){
                                title += " (" + counter+ ")"
                            }
                            const resp  = await uploadNotebook(activeTab.readingDocument.parentID, title)
                            if (resp){
                                dispatch(createNotebook({notebook: resp, sourceID: activeTab.readingDocument.objectID}))
                                updateNotebookRecency(resp, activeTab.readingDocument.objectID)
                                return true
                            }
                            else{
                                return true
                            }
                        }
                    } />
                {
                    globalNotebooks.map(nb =>{

                        

                        return (
                            // <SimpleThumbnail device={device} file={nb} onClick={onOpenNotebook}  />
                            <ThumbnailLamination zoom = {0.8} fileType='Writing' objectData={nb} onClick={()=>{
                                onOpenNotebook(nb)
                            }}></ThumbnailLamination>
                        )
                    })

                }
    
                </div>
                <br/>
                <br/>
                {/* <h3>Item Notebooks</h3>
                <p className='subtitle'>Notebooks you can access only from this reading. Ideal for summaries.</p>
                
                <div className='NotebooksList-container'>
                    <NewDocument onClick={
                        async ()=>{
                            const counter = itemNotebooks.reduce((previousValue:number, currentValue: WritingDocument)=>{
                                if (currentValue.name.includes('Untitled')){
                                    return previousValue + 1
                                }else{
                                    return previousValue
                                }
                            }, 0)
                            let title = "Untitled"
                            if (counter !== 0){
                                title += " (" + counter+ ")"
                            }
                            const resp  = await uploadNotebook(activeTab.readingDocument.objectID, title)
                            if (resp){
                                dispatch(createNotebook({notebook: resp, sourceID: activeTab.readingDocument.objectID}))
                                updateNotebookRecency(resp, activeTab.readingDocument.objectID)
                                return true
                            }
                            else{
                                return true
                            }
                        }
                    } />
                    {
                        itemNotebooks.map(itemNotebook =>{
                            return (
                            // <Document title={itemNotebook.name} fileType="Notebook"/>
                            <SimpleThumbnail file={itemNotebook} onClick={onOpenNotebook}/>
                            )
                        })
    
                    }
    
                </div> */}
                
                <br/>
                
            </div>
        )
    }
    else{
        return <></>
    }
    
}
export default NotebookSelector;