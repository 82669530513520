import React, {useContext, useEffect, useState} from 'react';
import Clickout from '../../../../layouts/Clickout/Clickout';
import Topbar from '../../components/DeskTopbar/DeskTopbar';
import Toolbar from '../../components/Toolbar/Toolbar';
import IndexBar from '../components/IndexBar/IndexBar';
import ReaderTabsBar from '../components/ReaderTabsBar/ReaderTabsBar';
import ReaderToolbar from '../components/ReaderToolbar/ReaderToolbar';
import ReaderTopbar from '../viewers/PDF/PdfReaderTopbar/PdfReaderTopbar';
import PdfViewer from '../viewers/PDF/PdfViewer/PdfViewer';

import { useAppDispatch, useAppSelector } from '../../../../redux';

import './Reader.css'
import ReaderViewer from '../viewers/ReaderViewer';
import { useEffectOnce } from '../../../../hooks/useEffectOnce';
import { getReadingTheme, setReadingTheme } from './ReadingTheme';
import { EnvironmentContext } from '../../../../contexts';
import EmptyReaderViewer from '../viewers/EmptyReaderViewer';

type ReaderProps = {
};

const Reader:React.FC<ReaderProps> = () => {

    const readerTabsState = useAppSelector((state) =>state.readerTabs)
    const {tabs, activeTabId} = readerTabsState
    const env= useContext(EnvironmentContext)

    // WTF WHY IS THIS HERE
    // const activeTab = tabs.find(x=>x.readingDocument.objectID === activeTabId)
    
    return(
        <div className='Reader'>
            
            <ReaderTabsBar />
            
            {/* PDF VIEWERS */}
            {
                tabs.length?
                tabs.map((tab) =>
                    <ReaderViewer
                        key={tab.readingDocument.objectID}
                        tab = {tab}
                        active={activeTabId===tab.readingDocument.objectID}
                    />
                )
                :
                <EmptyReaderViewer />
            }
            

        </div>
    )
}
export default Reader;