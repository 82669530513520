import React from 'react';
import { useEffect } from 'react';
import Button from '../../../../../ui/buttons/Button/Button';
import plus from '../../../../../assets/icons/plus.svg'
import upload_file from '../../../../../assets/icons/upload_file.svg'
import new_folder from '../../../../../assets/icons/new_folder.svg'
import notebook_icon from '../../../../../assets/icons/notebook.svg'
import folder from '../../../../../assets/icons/folder.svg'
import {store, useAppDispatch, useAppSelector} from '../../../../../redux'
import {openOverlay} from '../../../../../layouts/Overlay'
import CreateFolder from './CreateFolder/CreateFolder'
import { DisplayMenuItem } from '../../../../../ui/menus/Menu/Menu';

import { uploadNotebook } from '../../../../desk/desk-logic/notebookSaver';
import UploadPdf from './UploadFile/UploadPdf';
import { selectFolderPath } from '../../../features/files/folderPathSlice';
import { Folder } from '../../../../../types/files';
import { createFile } from '../../../features/files/folderDataSlice';

type NewButtonProps = {
    openCreateFolderOverlay: (event: React.MouseEvent<HTMLDivElement>) => void
};

const NewButton:React.FC<NewButtonProps> = () => {

    const dispatch = useAppDispatch()
    const folderPath = useAppSelector(selectFolderPath) 
    const currentFolder = folderPath.segments.at(-1) as Folder
    const openCreateFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) =>{
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: folder,
                title: "Create Folder",
                data: "" , 
            },
            component: <CreateFolder/>,
        }))
    }

    const openUploadPdfOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: upload_file,
                title: "Upload File",
                data: "",
            },
            component: <UploadPdf currentFolder={currentFolder} />
        }))
    }

    const newItemMenu: DisplayMenuItem[] = [
        {
            icon: upload_file,
            label: "Upload File",
            handler: openUploadPdfOverlay
        },
        {
            icon: new_folder,
            label: "New Folder",
            handler: openCreateFolderOverlay
        },
        {
            icon: notebook_icon,
            label: "New Notebook",
            handler: async() => {
                const currentFolder = store.getState().folderPath.value.segments.at(-1)
                const resp = await uploadNotebook(currentFolder?.objectID as string)
                if(resp){
                    dispatch(createFile(resp))
                }
            }
        },
    ]
    return(
        <Button icon={plus} edges='sharp' rank='secondary' fill='solid' onClick={() =>{}} dropdown={newItemMenu}>New</Button>
         
    )
}
export default NewButton;