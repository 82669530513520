import React, { useEffect, useRef , useContext, useState} from "react";
import "./NotebookToolbar.css";
import { HeadingToolbar } from "./HeadingToolbar";
import ColorSelector from "../../../../../../ui/menus/legacy/ToolTip/ColorSelector";
import { LightNotebookColorMap, DarkNotebookColorMap } from "../../../../../../utils/legacy_utils/Colors";
import { ToolbarButton } from "./ToolbarButton";
import { DropdownEntry } from "./DropdownEntry";
import {BaseButton} from "./BaseButton"
import useWindowSize from "../../../../../../hooks/useWindowSize";
export const NotebookToolbar = (props) => {
  
  // const {api} = useContext(PDFContext)
  const [showNotesMenu, setShowMenu] = useState(0)
  // const checkHideNotesMenu = () =>{
  //   console.log("PDF Reader Width", props.pdfReaderWidth, (100-props.pdfReaderWidth)*api.getWindowSize()[0]/100  )
  //   if ((100-props.pdfReaderWidth)*api.getWindowSize()[0]/100 < 600 ){
  //       console.log("State Changed to Hide")
  //       if((100-props.pdfReaderWidth)*api.getWindowSize()[0]/100 < 450){
  //         setShowMenu(2)
  //       }
  //       else{
  //         setShowMenu(1)
  //       }
  //   }
  //   else if ((100-props.pdfReaderWidth)*api.getWindowSize()[0]/100 >= 450 ){
  //     console.log("State Changed to Hide")
  //     if((100-props.pdfReaderWidth)*api.getWindowSize()[0]/100 < 600){
  //       setShowMenu(1)
  //     }
  //     else{
  //       setShowMenu(0)
  //     }
  //   }
  // }
  // useEffect(checkHideNotesMenu, [props.pdfReaderWidth])
  const device = useWindowSize();
  
  return (
    <div className = {`notes-toolbar ${props.theme} ${props.inline?'inline':''} ${device}`}>
        {props.items.map((item, index) => {
          if(item.label=="color" && showNotesMenu !== 2) {
            return (
              <BaseButton 
              theme = {props.theme}
              item = {item}
              key = {index}
              vertical = {true}
              hoverTip={
                {
                  text: "Text Color"
                }
              }
              >
                  <ColorSelector setColor={item.handler} appTheme={props.theme} activeColors={[item.active()]} colorMap = {props.theme === "light"? LightNotebookColorMap: DarkNotebookColorMap}></ColorSelector>
                </BaseButton>

            )
          }
          else if (item.label == "alignment"){
            return (
              <BaseButton 
              theme = {props.theme}
              item = {item}
              key = {index}
              vertical = {false}
              hoverTip={
                {
                  text: "Alignment"
                }
              }
              >
                <ToolbarButton key = {index + '_1'} theme = {props.theme} item = {{
                  label: 'leftAlign',
                  active: ()=> item.active()==="left",
                  handler: ()=>item.handler('left'),
                  hoverTip: {
                    text: 'Left Align'
                  }
                }}></ToolbarButton>
                
                <ToolbarButton key = {index + '_2'} theme = {props.theme} item = {{
                  label: 'centerAlign',
                  active: ()=> item.active()==="center",
                  handler: ()=>item.handler('center'),
                  hoverTip: {
                    text: 'Center Align'
                  }
                }}></ToolbarButton>

                <ToolbarButton key = {index + '_3'} theme = {props.theme} item = {{
                  label: 'rightAlign',
                  active: ()=> item.active()==="right",
                  handler: ()=>item.handler('right'),
                  hoverTip: {
                    text: 'Right Align'
                  }
                }}></ToolbarButton>
              </BaseButton>
            )
          }
          else if (item.label === "latex"){
            return(
              <BaseButton 
                theme = {props.theme}
                key = {index}
                item = {item}
                vertical = {true}
                hoverTip={
                  {
                    text: "Insert LaTeX"
                  }
                }
                >
                  <DropdownEntry key = {index + '_1'} theme = {props.theme} 
                    label =  'inline'
                    handler = {()=>item.handler('inline')}
            
                    text = 'Add Inline'
                    
                  ></DropdownEntry>
                  <DropdownEntry key = {index + '_2'} theme = {props.theme} 
                    label =  'inline'
                    handler = {()=>item.handler('block')}
            
                    text = 'Add Block'
                    
                  ></DropdownEntry>
                       
              </BaseButton>
            )
          }

          else if (item.label === "image"){
            return(
            <BaseButton 
              theme = {props.theme}
              key = {index}
              item = {item}
              vertical = {true}
              hoverTip={
                {
                  text: "Insert Image"
                }
              }
              >
                <DropdownEntry key = {index + '_1'} theme = {props.theme} 
                  label =  'clipping'
                  handler = {()=>item.handler('clipping')}
          
                  text = 'Clip From Document'
                  
                ></DropdownEntry>
                
                <label>
                <DropdownEntry key = {index + '_2'} theme = {props.theme} 
                label =  'external'
                handler = {()=>{}}
                text = 'Upload File'>
                  
                </DropdownEntry>
                    <input onChange = {(e)=>{
                    console.log(e)
                    let fileReader = new FileReader()
                    fileReader.onloadend = ()=>{
                        item.handler('external')(fileReader.result)
                    }
                    fileReader.readAsDataURL(e.target.files[0])

                }}
                type="file" accept="image/*" style = {{display:"none"}}></input>
                    </label> 
                
              </BaseButton>
            )
          }
          
          else if(item.label == "heading" && showNotesMenu !==2){
            return (
              <HeadingToolbar theme = {props.theme} item={item}></HeadingToolbar>
            )
          }
          else{
            if (showNotesMenu===0){
              return (
                <ToolbarButton key = {index} theme = {props.theme} item = {item}></ToolbarButton>
              );
            }
            else if (showNotesMenu === 1 || showNotesMenu === 2){
              if (item.label === 'bold' || item.label === 'italic' || item.label === 'underline' || item.label === 'strikethrough'){
                return <></>
              }
              else{
                if(showNotesMenu === 2 && (item.label == 'color' || item.label == 'heading')){
                    return <></>
                  }
                }
                // return <></>
                return <ToolbarButton key = {index} theme = {props.theme} item = {item}></ToolbarButton>

              }
            else {
                // return <></>
              return <ToolbarButton key = {index} theme = {props.theme} item = {item}></ToolbarButton>
            }
            
          }
          
          
        })}
    </div>
  );
};


