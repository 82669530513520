import React, { Component, useState } from 'react';
import { signIn } from '../auth-functions/SignInFunctions';
import PromoCode from './PromoCode/PromoCode';
import SignUpForm from './SignUpForm';
import VerificationCode from './VerificationCode';

const SignUp = (props) => {
    /**
     * @props
     * setSubScreen: () => {}
     * subScreen:  "SignUpForm" | "VerificationCode" | "PromoCode"
     * @state
     * user: AWSUserObject
    */

    const [user, setUser] = useState(null)

    const [formState, setFormState] = useState(null)


    const getSignUpScreen = () =>{
        switch(props.subScreen){
            case "SignUpForm":
                return <SignUpForm setSubScreen={props.setSubScreen} completeSignUpStep={completeSignUpStep} initFormState={formState} setFormState={setFormState} />
            case "VerificationCode":
                return <VerificationCode user={user} completeSignUpStep={completeSignUpStep} setSubScreen={props.setSubScreen} />
            case "PromoCode":
                return <PromoCode toggleInfoPanel={props.toggleInfoPanel} completeSignUpStep={completeSignUpStep}  />
        }
    }

    const completeSignUpStep = (data) =>{
        /**
         * This function is used to determine the flow of the sign up process. 
         * @param 
         * screen: (same as subScreen @state. The step that is completed)
         * data: (the end result of the step)
         */
        
        switch(props.subScreen){
            case "SignUpForm":
                setUser(data.payload)
                props.setSubScreen("VerificationCode")
                return
            case "VerificationCode":
                props.signUserIn(user.username, formState.password.value)
                // props.setSubScreen("PromoCode")
                return
            case "PromoCode":
                return   
            default: 
                throw new Error()
        }
    } 

    return (
        <div>
            {getSignUpScreen()}
        </div>
    );
}
 
export default SignUp;