import React from 'react';

import './DeskTabsBar.css'

/**
 * This is the parent component for ReaderTabsBar and WriterTabsBar
 */

type DeskTabsBarProps = {
    children: React.ReactNode, 
    space?:"Reader" | "Writer"
};

const DeskTabsBar:React.FC<DeskTabsBarProps> = ({children, space}) => {
    
    return (
        <div className={`DeskTabsBar ${space}-TabsBar`}>
            {children}
        </div>
    )
}
export default DeskTabsBar;