
import React from 'react';
import exclamation_icon from '../../assets/icons/exclamation.svg'
import { openOverlay } from '../../layouts/Overlay';
import { useAppDispatch } from '../../redux';

import './LegacyDesklamp.css'

type LegacyDesklampPopupProps = {
};

const LegacyDesklampPopup:React.FC<LegacyDesklampPopupProps> = (props) => {

    
    return (
        <div className='LegacyDesklampPopup-container'>
            <p>
                Accounts for the old Desklamp (v1) and the new Desklamp (v2) are separate. 
                You can access your existing account and files at <a href="https://app.desklamp.io" target={"_blank"}>app.desklamp.io</a>.
            </p>

            <p>
                You will soon be able to import all your existing files in the new version.  
                We recommend that you start all your new work from the new app.
            </p>
{/* 
            <p>
                Read about all the improvements in the new Desklamp.
            </p> */}

            <p>
                Our team is available to help if you have any questions. 
                Get in touch with us at <a href='mailto:support@desklamp.io'>support@desklamp.io</a>.
            </p>

        </div>
    )
}
export default LegacyDesklampPopup;