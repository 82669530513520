import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import {User} from '../../types/user'

// Define a type for the slice state
interface UserObject {
    user: User | null
}
// Define the initial state using that type
const initialState: UserObject = {user: null}



export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUser: (state, action:PayloadAction<User>)=>{
            state.user = action.payload
        },
        unsetUser: (state)=>{
            state.user = null
        }
    }
})

export const {setUser, unsetUser} = userSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user.user
export default userSlice.reducer
