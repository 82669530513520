
import { EnvironmentContextValue } from "../../contexts";
import { useAppDispatch } from "../../redux";
import { deleteFile as deleteFileMutation } from "../../graphql/mutations";
import { API } from "aws-amplify";
import { showLoadingMessagePopup, showMessagePopup } from "../../layouts/MessagePopup/messagePopupSlice";
import { deleteFile } from "../../pages/home/features/files/folderDataSlice";
import { Folder } from "../../types/files";
import { deleteNotebook } from "../../pages/desk/writer/notebooks/fetchedNotebooksSlice";

const useDeleteFile: (env?: EnvironmentContextValue) => (objectID: string) =>Promise<Folder | false> = (env) =>{

    const dispatch = useAppDispatch()

    return async(objectID: string) =>{


        try{

            dispatch(showLoadingMessagePopup())

            const resp = await API.graphql({
                query: deleteFileMutation, 
                variables: {
                    input: {
                        objectID: objectID
                    }
                }
            }) as any

            console.log("resp: ", resp)

            const deletedFolder = resp.data.deleteFile

            dispatch(showMessagePopup("Deleted successfully."))
            dispatch(deleteFile(deletedFolder))
            // DIRTY FIX: On reading screen, we want to delete the notebook from fetched slice as well
            dispatch(deleteNotebook(objectID))
            return deletedFolder

        }
        catch(e) {
            console.error("Error deleting folder: ", e)
            return false

        }
    }


}

export default useDeleteFile