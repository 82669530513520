import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import React from "react"
import type { RootState } from "../../../../redux/store"

type ReaderLayout = {
    toolbar: boolean,
    topbarDropper: boolean,
    findbar: boolean,
    indexbar: boolean, 
}

const initialState: ReaderLayout = {
    toolbar: true,
    topbarDropper: false,
    findbar: false,
    indexbar: false,
}

export const readerLayoutSlice = createSlice({
    name: 'readerLayout', 
    initialState, 
    reducers: {
        setToolbar: (state, action: PayloadAction<boolean>) =>{
            state.toolbar = action.payload
        },
        setTopbarDropper: (state, action: PayloadAction<boolean>) =>{
            console.log("Setting TopbarDropper to: ")
            state.topbarDropper = action.payload
        },
        setFindbar: (state, action:PayloadAction<boolean>) =>{
            state.findbar = action.payload
        }, 
        setIndexbar: (state, action: PayloadAction<boolean>) =>{
            state.indexbar = action.payload
            if(action.payload==true) state.toolbar = false
            else state.toolbar = true
        }, 

    }
})

export const {setToolbar, setIndexbar, setTopbarDropper, setFindbar} = readerLayoutSlice.actions
export const selectReaderLayout = (state: RootState) => state.readerLayout
export default readerLayoutSlice.reducer
