import React, {useState} from 'react';
import ColorSelector from '../../../../../../ui/inputs/ColorSelector/ColorSelector';
import { ColorAlias } from '../../../../../../utils/Colors';

type CommentTooltipProps = {
    commentState : {color: ColorAlias}
    setCommentState: (x:{color:ColorAlias})=>void
    onClick?: (x :{color:ColorAlias}) => void
};

const CommentTooltip:React.FC<CommentTooltipProps> = ({commentState, setCommentState, onClick}) => {

    // const [activeColor, setActiveColor] = useState<ColorAlias>("yellow")
    
    // const setColor = (color: ColorAlias) =>{
    //     setActiveColor(color)
    // }
    
    return (
        <div className="CommentTooltip">
            Comment Tool
            <hr></hr>

            <ColorSelector
                activeColors={[commentState.color]}
                onClickColor ={(color: ColorAlias)=>{
                    if (onClick) onClick({color})
                    setCommentState({color: color})
                }}  
                transparent={false} 
                hiddenColors={["brown", "black"]}
            />

        </div>
    )
}
export default CommentTooltip;