import { API, Storage, Auth } from "aws-amplify";
import {v4 as uuid4} from 'uuid'
import {createCloud, createReadingCloud} from "../../../graphql/custom"
import { store } from "../../../redux";
import { createFile } from "../../home/features/files/folderDataSlice";
import awsmobile from "../../../aws-exports";
import { ReadingDocument, WritingDocument } from "../../../types/files";
import { initialiseTabs } from "../reader/Reader/readerTabsSlice";
import { completeFileUpload, editProgress } from "../../../features/files/FileUploadProgress/fileUploadProgressSlice";

export const uploadFile = async (file : File, parentID: string, postUploadCallback: (newObject: ReadingDocument) =>void)=>{
    const fileID = uuid4()
    let result

    const newReadingProgressFile: ReadingDocument =  {name: file.name, objectID: fileID, parentID: parentID, format: "PDF", createdAt: "", permission: "owner" }

    try {
        store.dispatch(editProgress({
            file:newReadingProgressFile, 
            progress: Math.floor(100 * 0)
        }))
       
        try{
            result = await Storage.put(
                "PDFs/"+fileID, //test.txt', 
                file, 
                {
                    progressCallback(progress){
                        store.dispatch(editProgress({
                            file:newReadingProgressFile, 
                            progress: Math.floor(100 * progress.loaded/progress.total)
                        }))
                    }
                }
               );

            // File upload has succeeded! We can now create this file
            const resp = await API.graphql({
                query: createReadingCloud,
                variables: {
                    input: {
                        file: 
                            {
                                bucket: awsmobile["aws_user_files_s3_bucket"],
                                region: awsmobile["aws_user_files_s3_bucket_region"],
                                key: result.key
                            }
                        ,
                        parentID: parentID,
                        name: file.name,
                        format: 'PDF'
                    }
                }
            }) as any
            const newFile = resp.data.createReading

            store.dispatch(completeFileUpload(newReadingProgressFile))

            postUploadCallback(newFile)

            return newFile

        }
        catch(e){
            console.log("error: ", e)
            return false
        }

    }
    catch(e){
        //TODO: Error on upload
    }
}

export const fetchFile = async (doc: ReadingDocument)=>{
    if (doc.file){
        const result = await Storage.get(
            doc.file.key
           );
        return result
    }
    else if (doc.url){
        return doc.url
    }
    else{
        return ''
    }
}

