/**
 * SliderTabs.js : A component with icons as menu options and a slider bevel to indicate the selected option
 * @props 
 * Required
 *      1. icons: an array of icons in the desired order
 *      2. setMenuPage(index): a function that takes a number as a parameter
 *      3. appTheme
 * Optional
 *      3. iconSize: the size of the icon in pixels (default: 24px)
 *      4. (NOT WORKING) iconMargin: the lateral margin around each icon in pixels (default: 6px)
 *      5. (NOT WORKING) heightPadding: vertical padding around icons (default: 10px)
 *      6. styleSet: (default: "menu")
 *          "menu" (colorscheme for the left menu pane)
 *          "pagenav" (to match the colorscheme of the pagenav)
 *      7. hoverTips: {
 *              tips: [] ; an array of hovertips corresponding to icons(1) ,
 *              position: String ; position of the hover tips relative to the icons
 *          }
 */

import React, { Component } from 'react'
// import HoverTip from '../../static-components/HoverTip/HoverTip'
import './SliderTabs.css'

export class SliderTabs extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            hoverTip: null,
            currIndex: 0, 
            activeIndex: 0,
        }
        this.scaleFactor = props.scaleFactor || 1
        this.iconSize=props.iconSize || (24 * props.scaleFactor)
        this.iconMargin=props.iconMargin || (6 * props.scaleFactor)
        this.slidertrackRef = React.createRef()
        this.heightOffset = props.sliderHeightOffset||(5 * props.scaleFactor)
        this.heightPadding = props.iconPadding||(10 * props.scaleFactor)
        this.bevelWidth = this.iconSize+this.heightPadding
        this.num = props.icons.length
        this.className = props.className ?? 'waste'

        this.sliderBarWidth = this.props.icons.length*(this.iconSize+this.iconMargin*3)
        this.leftGap = (this.sliderBarWidth -this.num*this.iconSize - 2*this.num*this.iconMargin)/2
    }

    moveSlider = (e) =>{
        console.log("move slider")

        let index = parseInt(e.target.name)
        let pos = this.getPosition(index)
        this.slidertrackRef.current.style.setProperty('--bevelPos', `${pos}px`)
        this.props.setMenuPage(index+1)
        this.setState({activeIndex:index})
        
    }

    getPosition = (index) =>{
        let pos = this.leftGap+(2*index+1)*this.iconMargin + (index)*this.iconSize
        pos = pos - (this.bevelWidth - this.iconSize)/2
        return pos
    }

    programmaticSelect = (index) =>{
        //index starts from 0
        let pos = this.getPosition(index)
        this.slidertrackRef.current.style.setProperty('--bevelPos', `${pos}px`)
        this.props.setMenuPage(index+1)
    }

    componentDidMount(){
        console.log("leftGap: ", this.leftGap)
        this.slidertrackRef.current.style.setProperty('--bevelPos', `${this.getPosition(0)}px`)
        // this.slidertrackRef.current.style.setProperty('--iconSize', `${this.iconSize}px`)
        // this.slidertrackRef.current.style.setProperty('--heightPadding', `${this.heightPadding}px`)
    }

    setHoverTip = (name) =>{
       
        this.setState({
            hoverTip: name
        })
    }

    clearHoverTip = (name) =>{
        if (this.state.hoverTip !== name) return
        this.setState({
            hoverTip: null
        })
    }

    render() {
        var styleSet = "sliderstyle-"+(this.props.styleSet || "menu")
        return (
            <span className={`slider-menu-container ${this.props.appTheme}`}>
                <label className="option-switch" style={{
                    width:this.sliderBarWidth,
                    //aritrary width
                    height:this.iconSize+this.heightPadding + this.heightOffset
                }} htmlFor="checkbox">
                    {/* <div style={this.props.iconSize?{
                        height: this.props.iconSize + this.heightPadding, width: this.props.iconSize + this.heightPadding
                    }:null}/> */}
                    {
                        this.iconSize?
                        <style dangerouslySetInnerHTML={{
                            __html: [
                               `.${this.className ?? 'slider-bar'}:before {`,
                               `  width: ${this.iconSize + this.heightPadding}px;`,
                               `  height:${this.iconSize + this.heightPadding}px`,
                               '}'
                              ].join('\n')
                            }}>
                          </style>:null
                    }
                    <span ref={this.slidertrackRef} className={`slider-bar ${this.className} bevel ${styleSet} ${this.props.appTheme} `}>
                        <span className="icons-container">
                            {this.props.icons.map((src, index)=>
                                <img onClick={this.moveSlider} name={index}
                                    onMouseEnter = {this.props.hoverTips? 
                                        () =>{
                                            this.setState({
                                                currIndex: parseInt(index)
                                            })
                                            this.setHoverTip(this.props.hoverTips.tips[index])
                                        }
                                    : null} onMouseLeave={this.props.hoverTips? 
                                        () =>{this.clearHoverTip(this.props.hoverTips.tips[index])} : null} 
                                    style={{height: this.iconSize, width: this.iconSize, marginLeft: this.iconMargin, marginRight: this.iconMargin}}
                                    className={`slider-img ${this.state.activeIndex==index?"active-img":"inactive-img"}`} src={src} key={src} alt="slider"></img>
                            )}
                            {/* {
                                this.state.hoverTip?
                                <HoverTip appTheme={this.props.appTheme} position={this.props.hoverTips.position} fineTunePosition ={{...this.props.hoverTips.fineTunePosition, marginLeft: this.state.currIndex * this.iconSize + 'px'}} text={this.state.hoverTip} />
                                :null

                            } */}
                        </span>
                        
                    </span>
                </label>
            </span>
        )
    }
}

export default SliderTabs
