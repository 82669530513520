import React, {useEffect, useState} from 'react';
import Topbar from '../../../home/components/Header/laptop/Topbar/Topbar';
import { ReaderTab } from '../Reader/readerTabsSlice';
import PdfReaderTopbar from './PDF/PdfReaderTopbar/PdfReaderTopbar';
import PdfViewer from './PDF/PdfViewer/PdfViewer';
import { useAppSelector } from '../../../../redux';
import ReaderToolbar from '../components/ReaderToolbar/ReaderToolbar';

import './ReaderViewer.css'
import { Device } from '../../../../types/devices';
import useWindowSize from '../../../../hooks/useWindowSize';

type ReaderViewerProps = {
    tab: ReaderTab, 
    active: boolean
};

const ReaderViewer:React.FC<ReaderViewerProps> = (props) => {

    
    const readerLayoutState = useAppSelector((state) =>state.readerLayout)
    const {toolbar, topbarDropper, indexbar, findbar } = readerLayoutState
    const {readerWidthPixels, isReaderCollapsed} = useAppSelector(state =>state.separator)

    const device: Device = useWindowSize();

    
    return(
        <div className={`ReaderViewer ${props.active?"active":""}`}>
            
            {
                (toolbar && !topbarDropper && readerWidthPixels >= 300 && !isReaderCollapsed) || (device === "mobile") ?
                    <ReaderToolbar tab={props.tab}/>
                :null
            }

            <PdfViewer tab={props.tab} active = {props.active}/>

        </div>
        
        
    )
}
export default ReaderViewer;