import { API } from "aws-amplify";
import { annotationsByObjectID, getSourceAnnotation } from "../../../graphql/queries";
import {createSourceAnnotation} from "../../../graphql/custom"
import { ReadingDocument } from "../../../types/files";
import { PDFAnnotation } from "../reader/viewers/PDF/PdfManager";
import { SourceAnnotation } from "./metadata";
import {store} from '../../../redux'
import { addAnnotation, initialiseSourceAnnotations, updateAnnotation as updateAnnotationReducer, deleteAnnotation as deleteAnnotationReducer } from "../writer/annotations/annotationsPaneSlice";
import { deleteSourceAnnotation, updateSourceAnnotation } from "../../../graphql/mutations";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
//TODO: Use environment/document storage status to change depending on offline or online storage
// ALSO dispatch update in the store
let updateTimer: ReturnType<typeof setTimeout> | undefined = undefined
let updatingAnnotation: SourceAnnotation | undefined = undefined 
export const getSingleAnnotation = async (id: String)=>{
    try{
        const result = await API.graphql({
            query: getSourceAnnotation,
            variables: {
                id: id
            }
        }) as any
        let x = result.data.getSourceAnnotation
        x.renderInfo = JSON.parse(x.renderInfo)
        x.sourceInfo = JSON.parse(x.sourceInfo)
        return x
    }
    catch(e){
        return
    }
    
}

export const getAnnotations = async (doc: ReadingDocument)=>{
    // await sleep(500)
    // From a given ReadingDocument, fetch all annotations
    try{
        const result = await API.graphql({
            query: annotationsByObjectID,
            variables: {
                objectID: doc.objectID
            }
        }) as any
        const fullList = result.data.annotationsByObjectID.items
        const final : {[key: number] : PDFAnnotation[]} = {}
        fullList.forEach((x: SourceAnnotation)=>{
            x.renderInfo = JSON.parse(x.renderInfo)
            x.sourceInfo = JSON.parse(x.sourceInfo)
            if (doc.format === "PDF"){
                const pdfX = x as PDFAnnotation
                const page = pdfX.sourceInfo.pageIndex
                if (final[page]){
                    final[page].push(pdfX)
                }
                else{
                    final[page] = [pdfX]
                }
            }
        })
    
        //dispatch annotations to annotationsPane in redux
        store.dispatch(initialiseSourceAnnotations({
            objectID: doc.objectID, 
            annotations: {...final}
        }))
        return final


    }
    catch(e){
        // TODO: analytics on e
        return {}
    }

    

}

export const createAnnotation = async (anno: SourceAnnotation) => {
    console.log("Creating", anno)
    const annotation : any= {...anno}
    annotation.sourceInfo = JSON.stringify(anno.sourceInfo)
    annotation.renderInfo = JSON.stringify(anno.renderInfo)

    store.dispatch(addAnnotation({...anno}))

    const result = await API.graphql({
        query: createSourceAnnotation,
        variables: {
            input: {
                ...annotation
            }
        }
    })

    // Create it in the cloud. If an error, throw it.
}

export const deleteAnnotation = async (annotation: SourceAnnotation) =>{
    try{

        store.dispatch(deleteAnnotationReducer({...annotation}))

        const result = await API.graphql({
            query: deleteSourceAnnotation,
            variables: {
                input: {
                    id : annotation.id
                }
            }
        })
        return true
    }
    catch(e){
        return false
    }

}

export const updateAnnotation = async(annotation: SourceAnnotation) => {
    if (updatingAnnotation && annotation.id === updatingAnnotation.id){
        updatingAnnotation = JSON.parse(JSON.stringify(annotation))
        clearTimeout(updateTimer)
        updateTimer= setTimeout(()=>sendNetworkUpdateAnnotation(annotation), 500)
    }
    else if (updatingAnnotation && annotation.id !== updatingAnnotation.id){
        clearTimeout(updateTimer)
        sendNetworkUpdateAnnotation(updatingAnnotation)
        updatingAnnotation = annotation
        updateTimer= setTimeout(()=>sendNetworkUpdateAnnotation(annotation), 500)
    }
    else{
        updatingAnnotation = annotation
        updateTimer= setTimeout(()=>sendNetworkUpdateAnnotation(annotation), 500)
    }
    
}

const sendNetworkUpdateAnnotation = async (annotation: SourceAnnotation)=>{
    try{
        
        const withoutUpdatedAt = JSON.parse(JSON.stringify(annotation))
        withoutUpdatedAt.sourceInfo = JSON.stringify(withoutUpdatedAt.sourceInfo)
        withoutUpdatedAt.renderInfo = JSON.stringify(withoutUpdatedAt.renderInfo)
        delete withoutUpdatedAt.updatedAt

        store.dispatch(updateAnnotationReducer({...annotation}))

        const result = await API.graphql({
            query: updateSourceAnnotation,
            variables: {
                input: {
                    ...withoutUpdatedAt
                }
            }
        })
        return true
    }
    catch(e){
        return false
    }
}