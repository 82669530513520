import React, { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import './SectionScroller.css';
import Section from './Section';

type SectionScrollerProps = {
    sections: Section[]
};

type Section = {
    icon: string,
    header: string,
    contents: React.ReactNode,
}


const SectionScroller:React.FC<SectionScrollerProps> = ({sections}) => {

    return(
        <div className='sectionScroller'>
            {
            sections.map(SectionItem =>
                <Section header={SectionItem.header} icon={SectionItem.icon} contents={SectionItem.contents}/>
            )}
        </div>
    );
}
export default SectionScroller;