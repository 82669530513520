import React, {useRef, useState} from 'react';
import './SearchInput.css'
import searchIcon from '../../../../../../../../assets/icons/search.svg'
import { Device } from '../../../../../../../../types/devices';
import useWindowSize from '../../../../../../../../hooks/useWindowSize';

type SearchInputProps = {
    runSearch: (search: string) => void;
    setSearchString: React.Dispatch<React.SetStateAction<string>>

};

const SearchInput:React.FC<SearchInputProps> = ({ runSearch, setSearchString}) => {

    // let timer: ReturnType<typeof setTimeout> 
    let timer = useRef<any>(null)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        
        setSearchString(e.target.value)

        if(timer.current!==undefined){
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() =>{
            console.log("running search!: ", e.target.value)
            runSearch(e.target.value)
        }, 300)
    }

    
    const [focus, setFocus] = useState<boolean>(false)

    const onFocus = () =>{
        setFocus(true)
    }

    const onBlur = () =>{
        setFocus(false)
    }

    const device: Device = useWindowSize();
    
    return(
        <div className={`SearchInput ${focus?"active":""}`}>

            {/* // <TitledInput value="" id='search' onChange={() =>{}} icon={searchIcon} /> */}
            
            <img className={`Searchbar-icon ${device}`} src={searchIcon}></img>
            <input onBlur={onBlur} onFocus={onFocus} className={`Searchbar-input`} onChange={onChange} placeholder='What would you like to work on today?' autoFocus={true}></input>
         
        </div>
    )
}
export default SearchInput;