import React, { Component } from 'react'
import {ColorMap} from '../../../../utils/legacy_utils/Colors'

function inverse(obj){
    var retobj = {};
    for(var key in obj){
      retobj[String(obj[key])] = key;
    }
    return retobj;
  }

const ReverseColorMap = inverse(ColorMap)


export class ColorSelector extends Component {
    /**
     * 
     * @param {*} props 
     * activeColors: an array of all colors that are active. or "all" to activate all colors
     * setColor: function to be run when a color is clicked
     * setColorArray: function to set activeColors as an array
     * transparent: true if you want transparent in the color selector
     */
    constructor(props) {
        super(props)
    
        this.state = {
             
        }

        this.colors = ["red","orange", "yellow", "green", "blue", "violet", "brown", "black"]
        //NOTE: THESE MUST BE String names. They are mapped to hex codes in Colors.js !
        
    }



    componentDidMount(){
        // if(this.props.activeColors==="all") {
        //     if(this.props.transparent) {
        //         var temp = [...this.colors]
        //         temp.push("transparent")
        //         this.props.setColorArray(temp)
        //     }
        //     else this.props.setColorArray(this.colors)
        // }
        

    }
    

    setColor = (e) =>{
        this.props.setColor(e.target.id)
    }
    
    render() {
        var borderColor
        this.colorMap = this.props.colorMap ?? ColorMap

        return (
            <div className={`colorSelector-container ${this.props.appTheme}`}>

                {
                    this.colors.map(color=>{
                        var isActiveColor = false
                        if(this.props.activeColors && !(this.props.activeColors === "all")) isActiveColor = (this.props.activeColors?.includes(color) || this.props.activeColors?.includes(this.colorMap[color]))
                        // || this.props.activeColor.substring(0,7)===ColorMap[color]
                        
                        return(
                        <>
                        <svg className="btn-color" viewBox="0 0 10 10" width="30%"
                            onClick={this.setColor} id={color} name={color}>
                            <circle id={color} cx="5" cy="5" r="3.5" stroke-width="0.5" stroke={isActiveColor?"#00247D":"transparent"} 
                            fill={isActiveColor?'#fff':"transparent"}/>
                            <circle id={color} cx="5" cy="5" r="2.5" fill={this.colorMap[color]}/>
                        </svg>
                        
                        </>
                        )
                            }
                    )
                }
                {
                    this.props.transparent?
                        <svg className="btn-color" viewBox="0 0 10 10" width="30%"
                            onClick={this.setColor} id={"transparent"} name={"transparent"}>
                            <circle id={"transparent"} cx="5" cy="5" r="3.5" stroke-width="0.5" stroke={this.props.activeColors?.includes("transparent")?"#00247D":"transparent"} fill={this.props.activeColors?.includes("transparent")?'#fff':"transparent"}/>
                            <circle id={"transparent"} cx="5" cy="5" r="2.5" stroke-width="0.25" stroke={this.props.appTheme==="light" || this.props.activeColors?.includes("transparent")?"#00247D":"white"} fill={"transparent"}/>
                            <line x1="3.4" y1="3.4" x2="6.6" y2="6.6" style={{stroke:this.props.appTheme==="light" || this.props.activeColors?.includes("transparent")?"#db5461":"#ffa69e", strokeWidth:"0.3"}} />
                        </svg>:null
                }

            </div>
        )
    }
}



export default ColorSelector
