
import React from 'react';
import exclamation_icon from '../../assets/icons/exclamation.svg'
import { openOverlay } from '../../layouts/Overlay';
import { useAppDispatch } from '../../redux';

import './LegacyDesklamp.css'
import LegacyDesklampPopup from './LegacyDesklampPopup';

type LegacyDesklampProps = {
    message?: string //The warning message to show. If not passed will return a new value.
};

const LegacyDesklamp:React.FC<LegacyDesklampProps> = (props) => {

    const dispatch = useAppDispatch()

    const showLegacyOverlay = () =>{

        dispatch(openOverlay({
            heading: {
                icon: exclamation_icon, 
                title: "The old Desklamp (v1) has moved", 
            }, 
            component: <LegacyDesklampPopup />
        }))

    }
    
    return (
        <div className='LegacyDesklamp-container'>
            <div className='LegacyDesklamp' onClick={showLegacyOverlay}>
                <img src={exclamation_icon} className="warning-icon"></img>
                {props.message || "Looking for the old Desklamp?"}
            </div>
        </div>
    )
}
export default LegacyDesklamp;