import { useContext } from "react"
import { CMenuContext } from "../../contexts"
import { DocumentInfoProps, Folder, ReadingDocument, WritingDocument } from "../../types/files"
import share from '../../assets/icons/sharing.svg'
import edit from '../../assets/icons/edit.svg'
import move from '../../assets/icons/move.svg'
import trash from '../../assets/icons/trash.svg'

import { useAppDispatch } from "../../redux"
import { closeOverlay, openOverlay } from "../../layouts/Overlay"
import EditFileDetails from "./EditFileDetails/EditFileDetails"
import SharingBox from "../collaboration/SharingBox/SharingBox/SharingBox"
import { getFileType } from "./getFileDetails"
import FileExplorer from "../../pages/home/features/FileExplorer/FileExplorer"
import { useDeleteFile } from "../../hooks/FileHooks"
import useWindowSize from "../../hooks/useWindowSize"
import { Device } from "../../types/devices"

type File = ReadingDocument | WritingDocument | Folder

const useFileContextMenu:() => (file: File, X: number, Y: number) => void = () => {

    const cMenuContext = useContext(CMenuContext)
    const {showContextMenu} = cMenuContext
    const dispatch = useAppDispatch()

    const backendDeleteFolder = useDeleteFile()

    const device: Device = useWindowSize();

    return (file: File, X: number, Y: number) =>{

        const fileType = getFileType(file)

        showContextMenu({
            show: true, 
            menuArray: [
                {
                    icon: edit, 
                    label: "Edit details", 
                    handler: () =>{
                        dispatch(openOverlay({
                            heading: {
                                icon: edit, 
                                title: "Edit", 
                                data: file.name
                            }, 
                            component: <EditFileDetails file={file} />
                        }))
                    }
                }, 
                {
                    icon: share, 
                    label: "Edit collaborators", 
                    handler: () =>{
                        console.log("inside handler share")
                        dispatch(openOverlay({
                            heading: {
                                icon: share,
                                title: "Share",
                                data: file.name , 
                            },
                            component: 
                                <div className={`SharingBox-overlay ${device}`}>
                                    <SharingBox file = {{fileType: fileType, objectData: file} as DocumentInfoProps} />
                                </div>
                                
                        }))
                    }
                }, 

                {
                    icon: move, 
                    label: "Move", 
                    handler: () =>{
                        dispatch(
                            openOverlay({
                                heading: {
                                    icon: move,
                                    title: "Move",
                                    data: file.name ,
                                },
                                component: (
                                    <FileExplorer
                                        action="move"
                                        object={
                                            {objectData: file, fileType:fileType} as DocumentInfoProps
                                        }
                                        disabledItems={fileType==="Writing"?["Writing"]:["Reading", "Writing"]}
                                    />
                                ),
                            })
                        );
                    }
                }, 
                {type: "separator"}, 
                {
                    icon: trash, 
                    type: "danger",
                    label: "Delete", 
                    handler: () =>{
                        backendDeleteFolder(file.objectID)
                        dispatch(closeOverlay())
                    }
                },

            ], 
            X: X, 
            Y: Y,
        })
        


    } 

}

export default useFileContextMenu