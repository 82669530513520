import React, { useRef, useState } from "react";

import attachment_light from "../../../../../../assets/legacy_icons/notebook_icons/attachment_light.png"
import attachment_dark from "../../../../../../assets/legacy_icons/notebook_icons/attachment_dark.png"

import clipping_light from '../../../../../../assets/legacy_icons/toolbar_icons/snip_light_outline.svg'
import clipping_dark from '../../../../../../assets/legacy_icons/toolbar_icons/snip_dark_outline.svg'


export const DropdownEntry = (props)=>{
    let item = props
    let src = ""

    if (props.theme == "light") {
        switch (item.label) {
            
            case "external":
                src = attachment_light
                break
            case "clipping":
                src = clipping_light
                break
            default:
        }
    } else {
        switch (item.label) {
            
            case "external":
                src = attachment_dark
                break
            case "clipping":
                src = clipping_dark
                break
            
            default:
        }
    } 
    return (
        
        <div className = {`dropdown-entry-text ${props.theme}`} onClick={(e)=>{item.handler()}}>

            {src===""?null:<img alt = {props.label} src={src} className={"dropdown-menu-icon"}></img>}
            {props.text}
        </div>
        
    )
}