import React from "react";
import { useState } from "react";
import notebookIcon from "../../../../../assets/icons/notebookIcon.svg";
import right_arrow from "../../../../../assets/icons/right_arrow.svg";

type MoveWritingProps = {
    name: string;
    onClick: (completedCallback: () => void) => void;
    disabled?: boolean;
};

const MoveWriting: React.FC<MoveWritingProps> = ({
    name,
    onClick,
    disabled,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <div className={`move-file ${disabled || loading ? "disable" : ""}`}
        onClick={() => {
            console.log("LOADING", loading);
            if (!disabled && !loading) {
                setLoading(true);
                onClick(() => {
                    console.log("SETTING LOADING FALSE");
                    setLoading(false);
                });
            }
        }}
        >
            <div
                className="move-file-name"
                
            >
                <img className="file-icon" src={notebookIcon}></img>
                <div className="file-name">{name}</div>
            </div>
        </div>
    );
};
export default MoveWriting;
