import React from 'react';
import WriterTopbar from '../../components/WriterTopbar/WriterTopbar';
import plus from '../../../../../assets/icons/plus.svg'
import browse_files from '../../../../../assets/icons/browse_files.svg'


import './NotebookMenu.css'
import { Button } from '../../../../../ui/buttons';
import NotebookSelector from './NotebookSelector/NotebookSelector';
import { store, useAppDispatch, useAppSelector } from '../../../../../redux';
import { openOverlay } from '../../../../../layouts/Overlay';
import FileExplorer from '../../../../home/features/FileExplorer/FileExplorer';
import { WritingDocument } from '../../../../../types/files';
import { getNotebook } from '../../../desk-logic/notebookSaver';
import { selectNotebookManager } from '../notebookWorkspaceManagerSlice';

type NotebookMenuProps = {
    
};

const NotebookMenu:React.FC<NotebookMenuProps> = () => {
    const dispatch = useAppDispatch()
    const notebookManager = useAppSelector(selectNotebookManager)
    return (
        <div className='NotebookMenu'>
            <div className="NotebooksMenu-header">
                <h2>Notebooks</h2>
                <div className='NotebooksMenu-headerButtons'>
                    {/* <Button size="s" icon={plus} edges='sharp' rank='primary' fill='solid' onClick={() =>{}}>New</Button> */}
                    <Button size="s" icon={browse_files} edges="sharp" rank="primary" fill="solid" onClick={() =>{
                        dispatch(
                            openOverlay({
                                heading: {
                                    icon: '',
                                    title: "Open Notebook",
                                },
                                component: (
                                    <FileExplorer
                                        action="openWriting"
                                        disabledItems={["Reading"]}
                                        onClick={async (a: WritingDocument, completedCallback?: ()=>void) => {
                                            const notebookManager = store.getState().notebookWorkspaceManager.manager
                                            const resp = await getNotebook(a)
                                            if (resp){
                                                notebookManager.setNotebookContent(resp, true)
                                                if (completedCallback) completedCallback()
                                            }
                                        }}
                                        
                                    />
                                ),
                            })
                        );
                    }}>Browse All</Button>

                </div>

            </div>

            <NotebookSelector />
            

        </div>
    )
}
export default NotebookMenu;