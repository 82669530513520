
  import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    DelayedPromiseCreator,
    EditorView,
    ErrorConstant,
    extension,
    ExtensionTag,
    getTextSelection,
    invariant,
    isElementDomNode,
    NodeExtension,
    NodeExtensionSpec,
    NodeSpecOverride,
    NodeViewMethod,
    omitExtraAttributes,
    PrimitiveSelection,
    ProsemirrorAttributes,
    ProsemirrorNode,
    mutateTag
  } from "@remirror/core";
  import { Plugin as ProsePlugin, PluginKey, PluginSpec } from "prosemirror-state";
  import { MathView } from "./MathView";
import { defaultInlineMathParseRules , defaultBlockMathParseRules} from "./PasteRule";
import { TextSelection, NodeSelection } from '@remirror/pm/state';
mutateTag(tag=>tag.Math = 'math')

import { chainCommands, deleteSelection, selectNodeBackward, joinBackward, Command } from "prosemirror-commands";

  
  @extension({
    defaultOptions: {
      
    },
  })
  export class BlockLaTeX extends NodeExtension {
    get name() {
      return "math-display";
    }
  
    createTags() {
      return [ExtensionTag.Block, ExtensionTag.Math];
    }
  
    createNodeSpec(extra, override) {
      return {
        content: "text*",
        atom: true,
        code: true,
        toDOM: () => ["math-display", { class: "math-node" }, 0],
        parseDOM: [
            { tag: "math-display" },
            ...defaultBlockMathParseRules
        ],
		
        ...override,
        attrs: {
          ...extra.defaults()
        },
        
      };
    }
  
  
    createCommands() {
      return {
        insertBlockLatex: (attributes, selection) => {
          return ({ tr, dispatch }) => {
            const { from, to } = getTextSelection(
              selection ?? tr.selection,
              tr.doc
            );
            const node = this.type.create(attributes);

            let step1 = tr.deleteRange(from, to)
            let step2 = step1.insert(step1.mapping.map(from), node)
            let step3 = step2.insert(from+2, step2.doc.type.schema.nodes.paragraph.create())
            let step4 = step3.setSelection(NodeSelection.create(step3.doc, from+1))
            dispatch?.(
                step4
            );
  
            return true;
          };
        },
      };
    }

    createKeymap(){
      return {
        'Backspace' : ({state, dispatch})=>{
          let { $from } = state.selection;
          let nodeBefore = $from.nodeBefore;
          console.log("RESOLVING", nodeBefore)
          if(!nodeBefore){ 
            //check if two characters before works. 
            let pos = $from.pos - 1
            console.log("TRYING OTHER", pos)
            try{
              nodeBefore = state.doc.resolve(pos).nodeBefore
              if(nodeBefore?.type.name == "math-display"){
                // select math node
                let offset = nodeBefore.nodeSize
                if(dispatch) { 
                  let newTr = state.tr.setSelection(NodeSelection.create(state.doc, pos-(offset)))
                  dispatch(newTr)
                }
                return true;
              } 
              return false
            }
            catch(e){
              console.log("FAILED", e)
              return false
            }
          }
          
          else if(nodeBefore.type.name == "math-display"){
            // select math node
            console.log("IN MATH NODE")
            let index = $from.index($from.depth);
            let $beforePos = state.doc.resolve($from.posAtIndex(index-1));
            if(dispatch) { dispatch(state.tr.setSelection(new NodeSelection($beforePos))); }
            return true;
          } 

          return false
        }
      }
    }

    createPlugin(){
      return {
        state: {
          init(config, instance){
            return {
              macros: {},
              activeNodeViews: [],
              prevCursorPos: 0,
            };
          },
          apply(tr, value, oldState, newState){
            // produce updated state field for this plugin
            // console.log("Modifying plugin state", oldState.selection.from, newState.selection instanceof NodeSelection)
            return {
              // these values are left unchanged
              activeNodeViews : value.activeNodeViews,
              macros          : value.macros,
              // update with the second-most recent cursor pos
              prevCursorPos   : oldState.selection.from
            }
          },
          /** @todo (8/21/20) implement serialization for math plugin */
          // toJSON(value) { },
          // fromJSON(config, value, state){ return {}; }
        }
      }
    }

   

    // createInputRules(){
    //   console.log("RULE",makeInlineMathInputRule(REGEX_INLINE_MATH_DOLLARS, this.type))
    //   return [makeInlineMathInputRule(REGEX_INLINE_MATH_DOLLARS, this.type)]
    // }
  
    createNodeViews() {
      let that = this;
      return (node, view, getPos) => {
        /** @todo is this necessary?
        * Docs says that for any function proprs, the current plugin instance
        * will be bound to `this`.  However, the typings don't reflect this.
        */
        let pluginState = that.getPluginState();
        if(!pluginState){ throw new Error("no math plugin!"); }
        let nodeViews = pluginState.activeNodeViews;
        console.log("MATH NODE CREATED")
        // set up NodeView
        let nodeView = new MathView(
          node, view, getPos, 
          { katexOptions : { displayMode: true, macros: pluginState.macros } },
          that.pluginKey,
          ()=>{ nodeViews.splice(nodeViews.indexOf(nodeView)); },
        );
    
        nodeViews.push(nodeView);
        return nodeView;
      }
    }
  }
  