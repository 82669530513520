//If you change any shortcuts in shortcutsDict, 
//change them in kitab-electron/public/appmenu2 as well!

export var shortcutsDict:any = {
    /**
     * maps shortcuts to actions
     * format: "accelerator":"action" 
     * maps shortcuts to actions
    */
    "CommandOrControl+F":"find",
    "CommandOrControl+L":"bookmark",
    "CommandOrControl+=":"zoomIn",
    "CommandOrControl+-":"zoomOut",
    "CommandOrControl+J":"highlighter",
    "CommandOrControl+G":"clipping",
    "CommandOrControl+K":"sticky",
    "CommandOrControl+R":"shape"
    // "CommandOrControl+O":"open",
    // "CommandOrControl+Z":"undo",
    // "CommandOrControl+Shift+Z":"redo",
}

//var actionsDict = {} //maps actions to shortcuts

// for (var key in shortcutsDict){
//     actionsDict[shortcutsDict[key]] = key;
// }


export const checkKeyboardShortcut = (e:any, shortcutString:string) =>{
    /**
     * This function checks whether a combination of pressed keys matches the 
     * shortcutString parameter and returns true or false
     * @param shortcutString: a string depiction of the shortcut, split by "Or" for || and "+" for &&
     * Same format as electron accelerator
     * @returns boolean (true or false)
     * PLEASE NOTE: This function may not be complete and would need to be updated to accomodate more cases
     */
    let codes:any={
        "Command":"metaKey",
        "Control":"ctrlKey",
        "Cmd":"metaKey",
        "Ctrl":"ctrlKey"
    }
    let arr = shortcutString.split("+")
    let boolCond = true
    for (var cond of arr){
        var condArrOrs = cond.split("Or")
        let condFromOrs = false
        for (var miniCond of condArrOrs){
            let newCond
            if(codes[miniCond]) newCond = e[codes[miniCond]]
            else newCond = e["key"]===miniCond.toLowerCase()
            condFromOrs = condFromOrs || newCond
        }
        boolCond = boolCond && condFromOrs
    } 
    return boolCond
}

export const interpretKeyPress = (e:any)=>{
    /**
     * This function analyses the keys pressed and returns a shortcutString (electron accelerator)
     * PLEASE NOTE: This function may be incomplete. It would need to be updated to handle more cases. 
    */
    var shortcutString=""
    if(e["ctrlKey"] || e["metaKey"]){
        shortcutString+="CommandOrControl+"
    }
    // console.log(e["key"])
    if(e["key"]){
        if(e["key"].length===1){
            shortcutString+=e["key"].toUpperCase()
        }
        // console.log("string: ", shortcutString)

    }
    return shortcutString
}

export const addShortcutListeners = (actionFunctions:any) =>{
    /**
     * @param actionFunctions: a dictionary of the form "action":function()
     */
    document.addEventListener('keydown', (e)=>{
        
        var shortcutString = interpretKeyPress(e)
        console.log("shortcutString: ", shortcutString)

        var action = shortcutsDict[shortcutString]
        
        if(action){
            //a keyboard shortcut exists for this action
            if(actionFunctions[action]){
                e.preventDefault()
                actionFunctions[action]()
            }
        }
        
    })
}

export const triggerShortcut = (options : {CmdOrCtrl:boolean, key: string, shift?:boolean } ) =>{
    
    const {CmdOrCtrl, key, shift} = options

    var event = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        key: key.toLowerCase(),
        code: `Key${key.toUpperCase()}`,
        ctrlKey: CmdOrCtrl,
        metaKey: CmdOrCtrl, 
        shiftKey: shift,
    });
    document.dispatchEvent(event);
    
}

