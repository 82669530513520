import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { showLoadingMessagePopup } from "../../layouts/MessagePopup/messagePopupSlice";
import { Collaborator } from "../../types/user";
import { removeCollaborator } from "../../graphql/mutations";
type RemoveCollaboratorInput = {
    objectID: string, 
    email: string
}

const useRemoveCollaborator: () =>(input: RemoveCollaboratorInput) => Promise<Collaborator | false> = () =>{

    return async(input: RemoveCollaboratorInput) =>{

        const {objectID, email} = input

        try{

            let resp = await API.graphql({
                query: removeCollaborator , 
                variables: {
                    input:{
                        objectID: objectID, 
                        email: email, 
                    }
                }
            }) as any

            console.log("resp: ", resp)
            return resp.data.removeCollaborator
        }
        catch(e: any){
            console.error("SHARE FAILED", e)
            const errorMessage = e.errors[0].message

            // TODO: Add error handling here
            return false
        }

    }

}

export default useRemoveCollaborator