import React, { useState } from 'react';

type ToggleSwitchProps = {
    toggleFunctions: {"left": (() =>void) | undefined, "right":(() =>void) | undefined}, //the function to be called when the toggle switch is clicked
    initialState: boolean,  //a boolean value to indicate whether it should start in the activated (circle on right/true) or deactivated (circle on left/false) state, 
    inactiveIcon: string //the icon for the inactive state (circle on left, icon visible on right)
    activeIcon: string //the icon for the active state (circle on right, icon on left)
    //Optional
    styleSet?: "multi" | "static", 
    /**
     * "multi": to change the color between light and dark when the toggle switch is clicked
     * "static": to keep the color same depending on the appTheme only and not switch state
     */
    disabled?: boolean, //true if the toggle is to be disabled
    
};

import './ToggleSwitch.css'


const ToggleSwitch:React.FC<ToggleSwitchProps> = (props) => {

    const [currentState, setCurrentState ] = useState<boolean>(props.initialState)


    const toggleState = () =>{

        console.log('toggleState called')
        if(props.disabled) return

        if(currentState){
            if(props.toggleFunctions.left) props.toggleFunctions.left()
        }
        else if(props.toggleFunctions.right) props.toggleFunctions.right()

        setCurrentState(!currentState)
    }
    
    return(
        <div className="theme-switch-wrapper">
            <label className="theme-switch" htmlFor="checkbox">
                <span onClick={toggleState} className={`slider round ${props.styleSet || "multi"}-toggle
                        ${(currentState)?"active-toggle":"inactive-toggle"}`}>
                    <img className="toggle-img left" src={props.activeIcon}
                        style={{filter: currentState?"invert(100%)":""}}
                    ></img>
                    <img className="toggle-img right" src={props.inactiveIcon}></img>
                </span>
            </label>
        </div>
    )
}
export default ToggleSwitch;