import React, { useReducer, useState } from 'react';
import AuthButton from '../../../ui/buttons/AuthButton/AuthButton';
import TitledInput from '../../../ui/inputs/TitledInput/TitledInput';
import { Caption, Subtitle, Title } from '../../../ui/text/Text';
import './SignUp.css'

import { signUp } from '../auth-functions/SignUpFunctions';
import Form from '../../../layouts/Form/Form';
import { ExternalLink } from '../../../utils/url';
import LegacyDesklamp from '../../../utils/LegacyDesklamp/LegacyDesklamp';

const SignUpForm = (props) => {

    /**
     * @props
     * setSubScreen: () =>{}
     * completeSignUpStep: () =>{}
     * @state
     * formData: {
     *      firstName: string,
     *      lastName: string,
     *      email: string, 
     *      password: string,
     *      profession: string, 
     *      error: string (general error on the top)
     * }
     */

    const [loading, setLoading] = useState(true)

    const signUpFunction = {

        function: (formState) =>{
            var userData = {}
            for (var key of Object.keys(formState)){
                if(key!=="error") userData[key] = formState[key].value
            }
            return signUp(userData)
        }, 

        onSuccess: (user) =>{
            props.completeSignUpStep({payload:user})
        }, 

        onError: () =>{

        }
    }

    return (  
        <div>
            <Title>Create new account.</Title>
            <Subtitle>
                Already have one? &nbsp;
                <a onClick={() =>{props.setSubScreen("SignInForm")}}>Sign In</a>
            </Subtitle>

            <div className="form">

                <Form 
                    initFormState={props.initFormState}
                    setFormState={props.setFormState}
                    submit={signUpFunction.function} 
                    onSuccess={signUpFunction.onSuccess}
                >
                    <TitledInput styleSize="l" position="left half" id="firstName" label={"First Name"} placeHolder="What shall we call you?" />

                    <TitledInput styleSize="l" position="right half" id="lastName" label={"Last Name"} placeHolder="Just to know you better" />
                    <TitledInput styleSize="l" id="email" type={"email"} label={"Email"} placeHolder="We suggest your school or work email"  />
                    <TitledInput styleSize="l" id="password" label={"Password"} autoComplete="new-password" placeHolder="Something strong and memorable" type="password"  />
                    <TitledInput styleSize="l" id="profession" label={"What is your area of work or study?"} placeHolder="We'll customize Desklamp based on your choice" 
                            
                            options = {[
                                {text: 'Arts', icon: '🎭'},
                                {text: 'Business', icon: '💼'},
                                {text: 'Engineering', icon: '🛠️'},
                                {text: 'Humanities and Social Sciences', icon: '📖'},
                                {text: 'Journalism', icon: '📰'},
                                {text: 'Law', icon: '⚖️'},
                                {text: 'Medicine', icon: '🩺'},
                                {text: 'Science', icon: '⚛️'},
                                {text: 'Other', icon: '✨'},
                            ]}
                            
                    />
                    
                    <AuthButton submit>Sign Up</AuthButton>

                    <Caption size="s">
                        By signing up, you agree to the 
                        <ExternalLink href="https://desklamp.io/legal?page=terms">Desklamp Terms</ExternalLink>
                        and 
                        <ExternalLink href="https://desklamp.io/legal?page=privacy">Privacy Policy</ExternalLink>

                    </Caption>

                    <Subtitle>
                        Want a sneak peek? &nbsp;
                        <ExternalLink href="https://app.desklamp.io/read?id=46b203c6-d8df-453d-b546-95a8fa7a44b9&mode=explore">Try without an account</ExternalLink>
                    </Subtitle>

                </Form>

                {/* <hr className='Auth-hr'></hr>

                <LegacyDesklamp /> */}

            </div>
        </div>
    );
}
 
export default SignUpForm;