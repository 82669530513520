import React from "react";
import { ContextMenuObject, ContextMenuComponent } from "../layouts/ContextMenu/ContextMenu";
import { Folder } from "../types/files";

export type Navigate = (a: string)=>void

export type EnvironmentContextValue = {
    name: 'electron' | 'web' | 'fail',
    navigator: ()=>Navigate
}
type UserAttributes = any

export type UserContextValue = {
    signIn: (a:UserAttributes) => void
    signOut: ()=>void
} 

export type CMenuContextValue = {
    showContextMenu : (a: ContextMenuObject| false) => void
}

const EnvironmentContext = React.createContext<EnvironmentContextValue>({
    name: 'fail',
    navigator: ()=>{
        return (a:string)=>{console.log(a)}
    }
});

const UserContext = React.createContext<UserContextValue>(
    {
        signIn: ()=>{},
        signOut: ()=>{}
    }
)
const CMenuContext = React.createContext<CMenuContextValue>(
    {
        showContextMenu: (a) => {}
    }
)


export {EnvironmentContext, UserContext, CMenuContext}
