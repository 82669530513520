import awsmobile from "../../../../../aws-exports";

export function fetchSourceRendersFromJSON(nbObj, notebookMetadata){
    let annotationSet = {clippings: {}, extracts: {}, links: {}}

    try{
        const recursiveWalk = (node)=>{
            //AIM: Calculate global position for child and continue
            
            node.content?.forEach((childNode)=>{
                // console.log("Processing", childNode.type)
                if (childNode.type === 'image' && childNode.attrs.metadata.sourceMetadata){
                    // Clipping type
                    const sourceMetadata = childNode.attrs.metadata.sourceMetadata
                    const sourceID = sourceMetadata.sourceID
                    const elem = {
                        sourceMetadata: sourceMetadata, 
                        notebookMetadata,
                        id: childNode.attrs.metadata.id,
                        metadata: childNode.attrs.metadata

                    }
                    if (annotationSet.clippings[sourceID] != null){
                        annotationSet.clippings[sourceID].push(elem)
                    }
                    else{
                        annotationSet.clippings[sourceID] = [elem]
                    } 
                }
                else if (childNode.type === "text"){
                    // Check marks for textextract and internal link
                    childNode.marks?.forEach((mark)=>{
                        if (!mark?.attrs?.metadata) return
                        // console.log("DRAFT", mark)
                        const sourceMetadata = mark.attrs.metadata.sourceMetadata
                        const sourceID = sourceMetadata.sourceID
                        const elem = {
                            sourceMetadata: sourceMetadata, 
                            notebookMetadata,
                            id: mark.attrs.metadata.id,
                            metadata: mark.attrs.metadata

                        }
                        if (mark.type === "textextract"){
                           
                            if (annotationSet.extracts[sourceID] != null){
                                annotationSet.extracts[sourceID].push(elem)
                            }
                            else{
                                annotationSet.extracts[sourceID] = [elem]
                            }
                        }
                        else if (mark.type === "internallink"){
                            
                            if (annotationSet.links[sourceID] != null){
                                annotationSet.links[sourceID].push(elem)
                            }
                            else{
                                annotationSet.links[sourceID] = [elem]
                            }
                        }
                    })
                }
                recursiveWalk(childNode)
            })            
        }
        recursiveWalk(nbObj)
        console.log("SOURCE RENDER",annotationSet)
        return annotationSet
    }
    catch(e){
        console.error("SOURCE ERROR", e)

        return annotationSet
    }
}

export function cleanJSON(nbObj){
    try{
        const recursiveWalk = (node)=>{
            //AIM: Calculate global position for child and continue
            
            node.content?.forEach((childNode)=>{
                // console.log("Processing", childNode.type)
                if (childNode.type === 'image'){
                    // Clipping type
                    childNode.attrs.src = ""
                }
                
                recursiveWalk(childNode)
            })            
        }
        
        recursiveWalk(nbObj)
        // console.log("SOURCE RENDER")
        return nbObj
    }
    catch(e){
        console.error("SOURCE ERROR", e)

        return nbObj
    }
}

export function fetchImagesFromJSON(nbObj){
    let images = []
    try{
        
        const recursiveWalk = (node)=>{
            //AIM: Calculate global position for child and continue
            
            node.content?.forEach((childNode)=>{
                // console.log("Processing", childNode.type)
                if (childNode.type === 'image'){
                    // Clipping type
                    images.push(childNode.attrs.metadata)
                }
                
                recursiveWalk(childNode)
            })            
        }
        
        recursiveWalk(nbObj)
        // console.log("SOURCE RENDER")
        return images
    }
    catch(e){
        console.error("SOURCE ERROR", e)

        return []
    }
}

export function fetchSourceRenders(nbObj, notebookMetadata){
    try{
        let annotationSet = {clipping: {}, extract: {}, link: {}, external: {}}
        
          const recursiveWalk = (node, globalPos)=>{
              //AIM: Calculate global position for child and continue
              let start = globalPos
              node.forEach((childNode, offset, index)=>{
                  if (childNode.type.name === 'image' && childNode.attrs.metadata.sourceMetadata){
                    // Clipping type
                    const sourceMetadata = childNode.attrs.metadata.sourceMetadata
                    const sourceID = sourceMetadata.sourceID
                    const elem = {
                        sourceMetadata: sourceMetadata, 
                        notebookMetadata,
                        id: childNode.attrs.metadata.id,
                        metadata: childNode.attrs.metadata
                    }
                    if (annotationSet.clipping[sourceID] != null){
                        annotationSet.clipping[sourceID].push(elem)
                    }
                    else{
                        annotationSet.clipping[sourceID] = [elem]
                    }
                    

                }
                
                else if (childNode.type.name === "text"){
                    // Check marks for textextract and internal link
                    childNode.marks?.forEach((mark)=>{
                        if (!mark?.attrs?.metadata) return
                        // console.log("DRAFT", mark)
                        const sourceMetadata = mark.attrs.metadata.sourceMetadata
                        const sourceID = sourceMetadata.sourceID
                        const elem = {
                            sourceMetadata: sourceMetadata, 
                            notebookMetadata,
                            id: mark.attrs.metadata.id,
                            metadata: mark.attrs.metadata
                        }
                        if (mark.type.name === "textextract"){
                            
                            if (annotationSet.extract[sourceID] != null){
                                annotationSet.extract[sourceID].push(elem)
                            }
                            else{
                                annotationSet.extract[sourceID] = [elem]
                            }
                        }
                        else if (mark.type.name === "internallink"){
                            const sourceMetadata = mark.attrs.metadata.sourceMetadata
                            const sourceID = sourceMetadata.sourceID
                            if (annotationSet.link[sourceID] != null){
                                annotationSet.link[sourceID].push(elem)
                            }
                            else{
                                annotationSet.link[sourceID] = [elem]
                            }
                        }
                    })
                }
                  
                  start += recursiveWalk(childNode, start)
              })
              return node.nodeSize
              
          }
          recursiveWalk(nbObj,0)
        // console.log("SOURCE RENDER",annotationSet)
        return annotationSet
    }
    catch(e){
        console.log("PROBLEM RENDERINFO", e)
    }
}

export function getSourceInfo(sourceMetadata){
    //Update with info about source as we proceed
    // For now, return Page
    return 'Page ' + (sourceMetadata.sourceInfo.pageIndex + 1)
}

export function createNewNotebook(){
    // return {type: "doc", content : [{type: "paragraph", attrs: {
    //     nodeIndent: null,
    //     nodeLineHeight: null,
    //     nodeTextAlignment: null,
    //     style: ""

    // },
    // content: [{type: "text", text: " "}]}]}
    let contentList = []
    let numParagraphs = 35
    for(let i = 0; i < numParagraphs; i++){
        contentList.push({
            type: "paragraph", attrs: {
                nodeIndent: null,
                nodeLineHeight: null,
                nodeTextAlignment: null,
                style: ""
        
            }
        })
    }
    return {type: 'doc', content: contentList}
}

export function removeAllStubs(notebookModel, storedLocation, unrenderOnSource){
    let sourceRenders = fetchSourceRendersFromJSON(notebookModel.nbObj, {
        id: notebookModel.id,
        storedLocation: storedLocation,
    });
    const types = ["clippings", "extracts", "links"];
    types.forEach((type) => {
        for (let key in sourceRenders[type]) {
            console.log("OBserving", sourceRenders[type][key]);
            sourceRenders[type][key].forEach((asset) => {
                console.log("UNRENDERING ON SOURCE");
                unrenderOnSource(type, {
                    ...asset
                });
            });
        }
    });
}


export function addAllStubs (notebookArray, storedLocation, renderOnSource){
    notebookArray.forEach(
        (notebookModel) => {
            let sourceRenders = fetchSourceRendersFromJSON(notebookModel.nbObj, {
                id: notebookModel.id,
                storedLocation: storedLocation,
            });
            const types = ["clippings", "extracts", "links"];
            types.forEach((type) => {
                for (let key in sourceRenders[type]) {
                    console.log("OBserving", sourceRenders[type][key]);
                    sourceRenders[type][key].forEach((asset) => {
                        console.log("RENDERING ON SOURCE");
                        renderOnSource( {
                            
                            creatingNew: false,
                        });
                    });
                }
            });
            
        }       
    )
}


export function convertToOnline(nbObj, defaultSourceMetadata){

    try{
        
        const recursiveWalk = (node)=>{
            //AIM: Calculate global position for child and continue
            
            node.content?.forEach((childNode)=>{
                // console.log("Processing", childNode.type)
                if (childNode.type === 'image'){
                    // Clipping type
                    let newSourceMetadata = childNode.attrs.metadata.sourceMetadata
                    if (newSourceMetadata?.sourceID){
                        newSourceMetadata.sourceID =  defaultSourceMetadata.sourceID 
                        newSourceMetadata.storedLocation = "online"
                    }
                    
                    childNode.attrs = {
                        metadata: {
                            id: childNode.attrs.metadata.id,
                            type: "online",
                            sourceMetadata: newSourceMetadata,
                            s3Object: {
                                bucket: awsmobile.aws_user_files_s3_bucket,
                                region: awsmobile.aws_user_files_s3_bucket_region,
                                key: 'Photos/' + childNode.attrs.metadata.id
                            },
                            imageFormat: childNode.attrs.metadata.imageFormat,
                            sourceURL: childNode.attrs.metadata.sourceURL,
                        } 
                    }

                }
                else if (childNode.type === "text"){
                    // Check marks for textextract and internal link
                    childNode.marks?.forEach((mark)=>{
                        if (!mark?.attrs?.metadata) return
                        // console.log("DRAFT", mark)
                        let newSourceMetadata = mark.attrs.metadata.sourceMetadata
                        newSourceMetadata.sourceID =  defaultSourceMetadata.sourceID 
                        newSourceMetadata.storedLocation = "online"
                        
                        if (mark.type === "textextract" || mark.type === "internallink"){
                           
                            mark.attrs.metadata.sourceMetadata = newSourceMetadata
                        }
                       
                    })
                }
                recursiveWalk(childNode)
            })            
        }
        recursiveWalk(nbObj)
        
        return nbObj
    }
    catch(e){
        console.error("SOURCE ERROR", e)

        return nbObj
    }
}

