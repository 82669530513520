import { useAppDispatch, useAppSelector } from "../../../../../redux";
import { ReadingDocument } from "../../../../../types/files";
import { fetchReadingFromID } from "../../../desk-logic/cachedDataManager";
import { addTab, selectReaderTabs, setActiveTabIndex } from "../../Reader/readerTabsSlice";


const useOpenReading: () => (file: ReadingDocument) =>void = () =>{

    const dispatch = useAppDispatch()
    const readerTabs = useAppSelector(selectReaderTabs)

    return (file: ReadingDocument) =>{
        const exists = readerTabs.tabs.find(x =>x.readingDocument.objectID === file.objectID)
        if (exists){
            dispatch(setActiveTabIndex(exists.readingDocument.objectID))
        }
        else{
            // We need to dispatch a new tab, but set the last location to the sourceInfo here.
            // TODO: add fetch objectID logic
            fetchReadingFromID(file.objectID).then((readingDoc: ReadingDocument)=>{
                dispatch(addTab(
                    readingDoc
                ))
            })
        }

    }

} 

export default useOpenReading