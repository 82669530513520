import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import SharingBox from '../../features/collaboration/SharingBox/SharingBox/SharingBox'
import type { RootState } from '../../redux/store'

import folder from '../../assets/icons/folder.svg'


export type OverlayComponent = {
    component: React.ReactNode | null,
    heading: {
        icon: string, 
        title: string, //In bold
        data?: string, //Not in bold
        hideSeperator?: boolean
    } | undefined,
} | null

// Define a type for the slice state
interface OverlayState {
    value: OverlayComponent
}

// Define the initial state using that type
const initialState: OverlayState = {
    value: null,
}

export const overlaySlice = createSlice({
    name: 'overlay',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        closeOverlay: (state) => {
            state.value = null
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        openOverlay: (state, action: PayloadAction<OverlayComponent>) =>{
            state.value = action.payload
        }
    }
})

export const {closeOverlay, openOverlay} = overlaySlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectOverlay = (state: RootState) => state.overlay.value
export default overlaySlice.reducer
