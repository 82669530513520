import { createRoutesFromElements,  Route } from "react-router-dom";
import Home from "../home";
import AuthScreen from "../auth";
import Desk from "../desk" ;
import { store, useAppDispatch } from "../../redux";
import { updateFolderPath } from "../home/features/files/folderPathSlice";
import { Folder, myLibrary, sharedFiles } from "../../types/files";
import React from "react";
import { Auth } from "aws-amplify";
import { setUser } from "../../features/account/userSlice";
import useGetPath from "../../hooks/FileHooks/useGetPath";

const HardRedirect : React.FC = ()=>{
	
		window.location.replace('/folders/Folder_library')
		return (<></>)
} 
const routes = 
    createRoutesFromElements(<>
        <Route path="/folders/:objectID" element={<Home />} 
			loader={
				async ()=>{
					try{
						const user = await Auth.currentAuthenticatedUser()
						store.dispatch(setUser({
							
							email: user.attributes.email,
							userID: user.attributes.sub,
							name: user.attributes.name
						
						}))
						return null
					}
					catch(e){
						window.location.replace('/login')
						return null
					}
					
					 
				}
			}
		/>
        <Route path="/login" element={<AuthScreen />} 

		loader={
			async ()=>{
				try{
					const user = await Auth.currentAuthenticatedUser()
					store.dispatch(setUser({
							
						email: user.attributes.email,
						userID: user.attributes.sub,
						name: user.attributes.name
					
					}))
					window.location.replace('/folders/Folder_library')
					return null
				}
				catch(e){
					return null
				}
				
				 
			}
		}/>

        <Route path="/desk" element={ 
            <Desk></Desk>
			
        } 
		loader={
			async ()=>{
				try{
					const user = await Auth.currentAuthenticatedUser()
					console.log("USER ATTRIBUTES", user.attributes)
					store.dispatch(setUser({
							
						email: user.attributes.email,
						userID: user.attributes.sub,
						name: user.attributes.name
					
					}))
					return null
				}
				catch(e){
					window.location.replace('/login')
					return null
				}
				
				 
			}
		}
		/>

		<Route path = "*" element={<HardRedirect/>}/>
        
    </>)


const getPathFromRoute = async () => {
    // Here, we need to fetch the current folder and call the API to get the full path. 
	// This happens by fetching parentID objects for the folder.
	const urlSegments = window.location.pathname.split('/')
	if (!urlSegments.includes('folders')) {
		throw Error
	}
	const objectID = urlSegments.at(-1)
	let newPath : Folder[]= []

    

	if (objectID==='Folder_library'){
		newPath=  [myLibrary]
	}
	else if(objectID==='Folder_shared'){
		newPath=  [sharedFiles]
	}
	else if(objectID){
		// Here, you have to do the API call (wrapped perhaps)

        const backendGetPath = useGetPath()

        const sections = await backendGetPath(objectID)
        if(sections) {
            console.log("getPath sections: ", sections)
            newPath = sections
        }
        else{
            console.log("no sections: ")
        }

		// let currFolder = foldersData1.find((x)=>x.objectID === objectID)
		// if (!currFolder){
		// 	// Here, we have to return some error to have the rerouting happen
		// 	throw Error
		// }
		// currFolder = currFolder as Folder

		// while (currFolder){
		// 	if(newPath) newPath.unshift(currFolder)
		// 	else newPath = [currFolder]
		// 	const prevFolder = currFolder
		// 	currFolder = foldersData1.find((x)=>x.objectID === prevFolder.parentID) as Folder
		// }

		// newPath.unshift(myLibrary)

	}
	// const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
	// await sleep(500)
	return newPath as [Folder, ...Folder[]]
};



export { routes, getPathFromRoute };
export function CatchBoundary() {}
