import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

type MessagePopupType = "error"  | "loading" | "info"

interface MessagePopupState {
    isVisible: boolean,
    type: MessagePopupType,
    message?: string, 
    showTime?:number, //time to show in milliseconds
}

const initialState: MessagePopupState = {
    type: "info", 
    isVisible: false, 
    message: "Here is a message for you!", 
    showTime: 7000
}

export const  messagePopupSlice = createSlice({
    name: 'messagePopup',
    initialState, 
    reducers: {
        closeMessagePopup: (state) =>{
            state.isVisible = false
        }, 
        showMessagePopup: (state, action: PayloadAction<string>) =>{
            /**
             * if just  a string is passed, then it shows a standard info message (type set to "info")
             * if an object is passed, then you can show a loading, error, or info message
             */

            var type : MessagePopupType = "info"
            const message = action.payload

            state.isVisible = true
            state.type = type
            state.message = message
        }, 

        showErrorMessagePopup:  (state, action: PayloadAction<string>) =>{
            var type : MessagePopupType = "error"
            const message = action.payload

            state.isVisible = true
            state.type = type
            state.message = message
        },

        showLoadingMessagePopup: (state) =>{
            console.log("showLoadingMessagePopup")
            state.isVisible = true
            state.type="loading"
        }
    }

})

export const {closeMessagePopup, showMessagePopup, showLoadingMessagePopup, showErrorMessagePopup} = messagePopupSlice.actions
export const selectMessagePopup = (state: RootState) =>state.messagePopup
export default messagePopupSlice.reducer