import React, { useContext } from 'react';
import {useState} from 'react';
import './CreateFolder.css'
import TitledInput from '../../../../../../ui/inputs/TitledInput/TitledInput'
import { Button } from '../../../../../../ui/buttons';
import {closeOverlay} from '../../../../../../layouts/Overlay'
import {useAppDispatch, useAppSelector} from '../../../../../../redux'
import { API } from 'aws-amplify';
import { selectFolderPath } from '../../../../features/files/folderPathSlice';
import { Folder } from '../../../../../../types/files';
import { createFile } from '../../../../features/files/folderDataSlice';
import useCreateFolder from '../../../../../../hooks/FileHooks/useCreateFolder';
import { EnvironmentContext } from '../../../../../../contexts';
import { Device } from '../../../../../../types/devices';
import useWindowSize from '../../../../../../hooks/useWindowSize';

type CreateFolderProps = {
        
};



const CreateFolder:React.FC<CreateFolderProps> = () => {

    const [FolderName, setFolderName] = useState<string>("");
    const [Description, setDescription] = useState<string>("");
    const [creating, setCreating] = useState<boolean>(false)

    const folderPath = useAppSelector(selectFolderPath)
    const env = useContext(EnvironmentContext)
    const createFolder = useCreateFolder(env)
    const currFolder = folderPath.segments.at(-1) as Folder
    
    const onFolderNameChange = (e: any) => {
        const target = e.target as HTMLInputElement
        setFolderName(target.value)
    }

    const onDescriptionChange = (e: any) => {
        const target = e.target as HTMLInputElement
        setDescription(target.value)
    }

    const dispatch = useAppDispatch()

    const device: Device = useWindowSize();
    
    return(
        <div className='create-new-folder'>

            <div className={`input-field ${device}`}>
                <TitledInput 
                    id='' 
                    label='Name' 
                    value={FolderName} 
                    onChange={onFolderNameChange} 
                    placeHolder="Folder name ..."
                    // position="left half"
                    styleSize="xl"
                    display= {true}
                    background="white"
                    edges="sharp" 
                    autoFocus
                ></TitledInput>
            </div>
            
            <div className={`motivation ${device}`}>This folder is the start of something big. Let’s give it a memorable name.</div>
        
            <div className={`input-field ${device}`}>
                <TitledInput 
                    id='' 
                    label='Description (Optional)' 
                    value={Description} onChange={onDescriptionChange} 
                    placeHolder="What's this folder about?"
                    // position= "left half"
                    styleSize="xl"
                    display= {true}
                    background= "white"
                    edges='sharp'
                ></TitledInput></div>
            
            <div className={`motivation ${device}`}>Give a brief description to help everyone know what this folder’s about.</div>
            
            <div className={`create-folder-buttons ${device}`}>
                
                <Button edges='round' isDisabled={!FolderName.length} rank='primary' fill='solid' 
                    loading = {creating} 
                    onClick={async()=>{
                    setCreating(true)
                    const resp = await createFolder({
                        name: FolderName,
                        description: Description,
                        parentID: currFolder.objectID
                    })
                    // TODO: Handle error on true or false
                    dispatch(closeOverlay())
                    setCreating(false)
                }}>Create</Button>
                <Button edges='round' rank='warning' fill='ghost' onClick={()=>{dispatch(closeOverlay())}}>Cancel</Button>
            </div>
        </div>
    );
}
export default CreateFolder;