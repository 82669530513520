import React from 'react'
import './HoverTip.css'

/**
 * 
 * @props position: "right", "top" (the position of the hover tip relative to the button/div)
 * @props fineTunePosition: if you are not satisfied with the default position, you can fine-tune the position with
 * an object of the form {top, bottom, right, left}
 * @props shortcut: A string of the form: "CmdOrCtrl+<Letter>"
 */



function HoverTip(props) {

    const getOperatingSystem= () => {
        if (window.navigator.userAgent.indexOf('Win') !== -1) return'Windows'; 
        if (window.navigator.userAgent.indexOf('Mac') !== -1) return 'MacOS'; 
        if (window.navigator.userAgent.indexOf('Linux') !== -1) return'Linux OS';       
        if (window.navigator.userAgent.indexOf('X11') !== -1) return'UNIX OS'; 
        return null
    }

    const getShortcutString = () =>{
        
        const shortcutArray = props.shortcut.split("+")
        const shortcutStringArray = shortcutArray.map(shortcut =>{
            if(shortcut==="CmdOrCtrl" || shortcut==="CommandOrControl" ){
                if(getOperatingSystem()==="MacOS") return "⌘"
                else return "Ctrl+"
            }
            else return shortcut.charAt(0).toUpperCase()
        })

        return shortcutStringArray.join("")

    }



    return (
    <div className={`hoverTipContainer ${props.appTheme} ${props.position}`} 
            style={props.fineTunePosition?props.fineTunePosition: null}>
            {props.text}
            {props.shortcut?<span className="hoverTipShortcut">{getShortcutString()}</span>:null}
            
        </div>
    )
}

export default HoverTip
