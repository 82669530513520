import React, { useState } from "react";
import Icon from "../../../../../ui/icons/HoverTipIcon/Icon";
import TabsBar from "../../../../../ui/tabs/TabsBar/TabsBar";
import DeskTabsBar from "../../../components/DeskTabsBar/DeskTabsBar";
import homeIcon from "../../../../../assets/icons/home.svg";

import "./ReaderTabsBar.css";
import { store, useAppDispatch, useAppSelector } from "../../../../../redux";
import {
    addTab,
    setActiveTabIndex,
    closeTab,
    reorderTabs,
    ReaderTab,
    selectReaderTabs,
} from "../../Reader/readerTabsSlice";
import { openOverlay } from "../../../../../layouts/Overlay";
import move from "../../../../../assets/icons/move.svg";
import FileExplorer from "../../../../home/features/FileExplorer/FileExplorer";
import { ReadingDocument } from "../../../../../types/files";
import { fetchReadingFromID } from "../../../desk-logic/cachedDataManager";
import useOpenReading from "../../../../../hooks/DocumentHooks/useOpenReading";
import { removeSourceAnnotations } from "../../../writer/annotations/annotationsPaneSlice";
import ProfileButton from "../../../../home/components/Header/common/ProfileButton/ProfileButton";
import { Device } from "../../../../../types/devices";
import useWindowSize from "../../../../../hooks/useWindowSize";

type ReaderTabsBarProps = {};

const ReaderTabsBar: React.FC<ReaderTabsBarProps> = () => {
    const { readerWidthPixels, isReaderCollapsed } = useAppSelector(
        (state) => state.separator
    );
    const dispatch = useAppDispatch();
    const readerTabs = useAppSelector(selectReaderTabs)
    //temporary! change this logic

    const openReading = useOpenReading()

    const newTabHandler = () => {
        dispatch(
            openOverlay({
                heading: {
                    icon: move,
                    title: "Open File",
                },
                component: (
                    <FileExplorer
                        action="openReading"
                        disabledItems={["Writing"]}
                        onClick={async (a: ReadingDocument, completedCallback?: ()=>void) => {
                            const readerTabs = store.getState().readerTabs
                            const exists = readerTabs.tabs.find(x =>x.readingDocument.objectID === a.objectID)
                            if (exists){
                                dispatch(setActiveTabIndex(exists.readingDocument.objectID))
                                if (completedCallback) completedCallback()
                            }
                            else{
                                const readingDoc: ReadingDocument = await fetchReadingFromID(a.objectID)
                                if (completedCallback) completedCallback()
                                dispatch(addTab(
                                        readingDoc
                                    ))
                    
                            }
                        }}
                        // onNew={{()=>{

                        // }}
                    />
                ),
            })
        );
    };

    const clickTabHandler = (id: string) => {
        dispatch(setActiveTabIndex(id));
    };

    const closeTabHandler = (
        indexToClose: number,
        newActiveId: string | null
    ) => {
        
        if (newActiveId) dispatch(setActiveTabIndex(newActiveId));

        const tabToClose = readerTabs.tabs[indexToClose]
        dispatch(removeSourceAnnotations(tabToClose.readingDocument.objectID))
        dispatch(closeTab(indexToClose));

    };

    const reorderTabHandler = (
        source: any,
        destination: any,
        draggableId: any
    ) => {
        dispatch(
            reorderTabs({
                source: source,
                destination: destination,
                draggableId: draggableId,
            })
        );
    };

    const device: Device = useWindowSize();

    return (
        <DeskTabsBar>
            {
                !isReaderCollapsed ?
                <Icon
                    src={homeIcon}
                    className="ReaderTabs-home-icon"
                    onClick={() => window.open("/", "_self")}
                ></Icon> : null
            }
            
            <TabsBar
                newTabHandler={
                    readerWidthPixels > 90 && !isReaderCollapsed
                        ? newTabHandler
                        : undefined
                }
                clickTabHandler={clickTabHandler}
                closeTabHandler={closeTabHandler}
                reorderTabHandler={reorderTabHandler}
            />

            {
                device === "mobile" ?
                    <div className="writerTabsBar-ProfileButton">
                        <ProfileButton size="s" space="Writer" />
                    </div>
                    :null
                }

        </DeskTabsBar>
    );
};
export default ReaderTabsBar;
