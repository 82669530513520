import React, { useRef, useEffect, useState } from 'react';
import DeskTopbar from '../../../../components/DeskTopbar/DeskTopbar';
import hamburger_menu from '../../../../../../assets/icons/hamburger_menu.svg'
import arrow_left from '../../../../../../assets/icons/arrow_left.svg'
import arrow_right from '../../../../../../assets/icons/arrow_right.svg'

import './PdfReaderTopbar.css'
import DocumentTitle from '../../../../components/DeskTopbar/DocumentTitle/DocumentTitle';
import Icon from '../../../../../../ui/icons/HoverTipIcon/Icon';
import RightIcons from '../../../../components/DeskTopbar/RightIcons/RightIcons';

import search from '../../../../../../assets/icons/search.svg'
import sharing from '../../../../../../assets/icons/sharing.svg'
import sun_moon from '../../../../../../assets/icons/sun_moon.svg'
import rotate_right from '../../../../../../assets/icons/rotate_right.svg'
import rotate_left from '../../../../../../assets/icons/rotate_left.svg'
import horizontal_scroll from '../../../../../../assets/icons/horizontal_scroll.svg'
import star from '../../../../../../assets/icons/star.svg'
import trash from '../../../../../../assets/icons/trash.svg'
import folder from '../../../../../../assets/icons/folder.svg'
import PageNavigator from '../../../components/PageNavigator/PageNavigator';
import ZoomBox from '../../../components/ZoomBox/ZoomBox';
import downloadIcon from '../../../../../../assets/icons/download.svg'


import { useAppDispatch, useAppSelector } from '../../../../../../redux';
import { setIndexbar } from '../../../Reader/readerLayoutSlice';
import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { editTab, ReaderTab } from '../../../Reader/readerTabsSlice';
import { DisplayMenu } from '../../../../../../ui/menus/Menu/Menu';
import { setReadingTheme, getReadingTheme } from '../../../Reader/ReadingTheme';
import { openOverlay } from '../../../../../../layouts/Overlay';
import SharingBox from '../../../../../../features/collaboration/SharingBox/SharingBox/SharingBox';
import { triggerShortcut } from '../../../../../../utils/shortcuts/shortcuts';
import useEditFile from '../../../../../../hooks/FileHooks/useEditFile';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../types/devices';

type ReaderTopbarProps = {
    tab : ReaderTab
};

const PdfReaderTopbar:React.FC<ReaderTopbarProps> = (props) => {

    const readingDocument = props.tab.readingDocument

    const editReadingFunction = useEditFile()


    const readerWidthPixels = useAppSelector(state=>state.separator.readerWidthPixels)

    const ReaderTopbarRef = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch()

    const activeTab = useAppSelector(state => state.readerTabs.activeTab)
    const readerManager = activeTab?.manager

    const [documentTitle, setDocumentTitle] = useState<string>(readingDocument.name)

    const device: Device = useWindowSize();

    const showSharingMenu = () =>{

        if(!activeTab) return
        const currDocument = activeTab.readingDocument

        dispatch(openOverlay({
            heading: {
                icon: sharing,
                title: "Share",
                data: currDocument.name , 
            },
            component: 
                <div className={`SharingBox-overlay ${device}`}>
                    <SharingBox file = {{fileType:'Reading', objectData:currDocument}} />
                </div>
                ,
        }))
    }

    const settingsMenu = {
        menu: [

            {type:"header", label: "Style"},
            {icon: sun_moon, label: "Reading Theme", type: "toggle", 
                toggleStates: {
                    left: {icon: sun_moon, handler: () =>{setReadingTheme("light")} },
                    right: {icon: sun_moon, handler: () =>{setReadingTheme("dark")} }, 
                    active: getReadingTheme()=="dark"
                }
            },

            {type: "header", label: "Document"},
            {icon: rotate_right, label: "Rotate Right", 
                handler: () =>{
                    let rotation = readerManager.getRotation()
                    readerManager.setRotation((rotation + 90 )% 360)
                }
            },
            {icon: rotate_left, label: "Rotate Left", 
                handler: () =>{
                    let rotation = readerManager.getRotation()
                    readerManager.setRotation((rotation - 90 )% 360)
                }
            },
            {icon: search, label: "Find in PDF", 
                handler: () =>{
                    triggerShortcut({CmdOrCtrl: true, key: 'F'})
                    
                }
            },
            // {icon: search, label: "Edit PDF details", 
            //     handler: () =>{
            //         dispatch(
            //             openOverlay({
            //                 heading: {
            //                     icon: "",
            //                     title: "Edit",
            //                     data: props.objectData.name,
            //                 },
            //                 component: <DocumentInfo fileType='Reading' objectData={}  />,
            //             })
            //         )
            //     }
            // },
            // {icon: horizontal_scroll, label: "Horizontal Scrolling" },

            {type: "header", label: "Sharing"},
            {icon: sharing, label: "Edit Collaborators", handler: showSharingMenu },

            // {type: "header", label: "Location"},
            // {icon: folder, label: "Move To" },
            // {icon: star, label: "Add to Favourites" },
            // {icon: trash, label: "Delete", type:"warning" },

            // {type: "header", label: "Export"},
            // {icon: downloadIcon, label: "Download PDF" },

        ]
    } as DisplayMenu

    const showIndexBar = () =>{
        dispatch(setIndexbar(true))
    }

    const onEditTitle = (newTitle: string) =>{
        console.log("newTitle: ", newTitle)

        const tempDocument = {...readingDocument}
        tempDocument.name = newTitle

        editReadingFunction(tempDocument)
        
        setDocumentTitle(newTitle)

    }

    const docTitleThreshold = 500 //used for document and zoom
    
    return (
        <div ref={ReaderTopbarRef}>

        
            <DeskTopbar >

                    <Icon src={hamburger_menu} onClick={showIndexBar}></Icon>

                    {/* {
                    readerWidthPixels>320?
                    <div className="PdfReaderTopbar-navButtons">
                        <Icon src={arrow_left} style={{height: "12px"}} hoverTip="Go to previous location"></Icon>
                        <Icon src={arrow_right} style={{height: "12px"}} hoverTip="Go to next location"></Icon>
                    </div>
                    :null
                    } */}
                    

                    {readerWidthPixels>docTitleThreshold?
                        <DocumentTitle title={documentTitle} location='' onEdit={readingDocument.permission!=="viewer"?onEditTitle:undefined } />
                    :null}

                    
                    {
                        props.tab.manager?
                        <div className='PdfReaderTopbar-controls' style={{
                            justifyContent:`${readerWidthPixels<docTitleThreshold?"center":""}`
                        }}>
                            {
                                readerWidthPixels>200?
                                <PageNavigator viewer = {props.tab.manager.viewer} title={readingDocument.name} />
                                :null
                            }
                            
                            <span style={{display:readerWidthPixels>docTitleThreshold?"block":"none", height: "100%"}}>
                                <ZoomBox viewer = {props.tab.manager.viewer} />
                            </span>
                            
                            
                        </div>
                        :null
                    }
                    

                    <RightIcons icons = {readerWidthPixels>660?[
                        // {icon: search, handler: () =>{}}, 
                        {icon: sharing, handler: showSharingMenu, hoverTip: "Share" }, 
                    ]:[]} settingsMenu = {settingsMenu}></RightIcons>


            </DeskTopbar>
        </div>
    )
}
export default PdfReaderTopbar;