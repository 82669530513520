import React, { useEffect } from 'react';
import { closeOverlay, OverlayComponent } from '../overlaySlice';
import close from '../../../assets/icons/close.svg'
import './Overlay.mobile.css'
import { useAppDispatch } from '../../../redux';
type OverlayMobileProps = {
    overlay: OverlayComponent
};

const OverlayMobile:React.FC<OverlayMobileProps> = ({overlay}) => {

    const dispatch = useAppDispatch()

    console.log("Inside Mobile Overlay")
    
    return (
        <div className='OverlayMobile'>
            <div className='Overlay-container'>
                {
                    overlay?.heading?
                    <div className='Overlay-heading'>
                        <img src={overlay.heading?.icon} className="Overlay-heading-icon"></img>
                        <div className="Overlay-heading-title">
                            <b>{overlay.heading.title}</b> {overlay.heading.data}
                        </div>
                        <img onClick={() =>{dispatch(closeOverlay())}} className='Overlay-close-icon' src={close}></img>
                    </div>
                    :
                    null
                }
                
                
                
                <div className='Overlay-contents'>
                    {overlay?.component}
                </div>
            </div>    
        </div>
    );
}
export default OverlayMobile;