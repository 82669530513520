/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFolderData = /* GraphQL */ `
  query GetFolderData($parentID: String) {
    getFolderData(parentID: $parentID) {
      folders {
        userID
        objectID
        parentID
        permission
        owner
        name
        description
        icon
        createdAt
        updatedAt
      }
      writings {
        userID
        objectID
        parentID
        permission
        name
        description
        owner
        version
        nbObj
        createdAt
        updatedAt
        activeEditorSub
        activeEditorSession
        requested {
          sessionID
          sub
        }
      }
      readings {
        userID
        objectID
        format
        parentID
        permission
        name
        description
        owner
        file {
          bucket
          region
          key
        }
        url
        lastOpened
        thumbnail {
          bucket
          region
          key
        }
        lastLocation
        createdAt
        updatedAt
      }
    }
  }
`;
export const getCollaborators = /* GraphQL */ `
  query GetCollaborators($objectID: String) {
    getCollaborators(objectID: $objectID) {
      name
      email
      permission
    }
  }
`;
export const getPath = /* GraphQL */ `
  query GetPath($input: GetPathInput) {
    getPath(input: $input) {
      userID
      objectID
      parentID
      permission
      owner
      name
      description
      icon
      createdAt
      updatedAt
    }
  }
`;
export const getAll = /* GraphQL */ `
  query GetAll {
    getAll {
      userID
      objectID
      parentID
      permission
      name
      createdAt
      updatedAt
    }
  }
`;
export const getDesk = /* GraphQL */ `
  query GetDesk($objectIDs: [String], $notebookID: String) {
    getDesk(objectIDs: $objectIDs, notebookID: $notebookID) {
      tabs {
        source {
          userID
          objectID
          format
          parentID
          permission
          name
          description
          owner
          url
          lastOpened
          lastLocation
          createdAt
          updatedAt
        }
        globalNotebooks {
          userID
          objectID
          parentID
          permission
          name
          description
          owner
          version
          nbObj
          createdAt
          updatedAt
          activeEditorSub
          activeEditorSession
        }
        itemNotebooks {
          userID
          objectID
          parentID
          permission
          name
          description
          owner
          version
          nbObj
          createdAt
          updatedAt
          activeEditorSub
          activeEditorSession
        }
      }
      openNotebook {
        userID
        objectID
        parentID
        permission
        name
        description
        owner
        version
        nbObj
        createdAt
        updatedAt
        activeEditorSub
        activeEditorSession
        requested {
          sessionID
          sub
        }
      }
    }
  }
`;
export const getSourceAnnotation = /* GraphQL */ `
  query GetSourceAnnotation($id: String!) {
    getSourceAnnotation(id: $id) {
      id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
    }
  }
`;
export const listSourceAnnotations = /* GraphQL */ `
  query ListSourceAnnotations(
    $id: String
    $filter: ModelSourceAnnotationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSourceAnnotations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        sourceInfo
        renderInfo
        objectID
        annoType
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWriting = /* GraphQL */ `
  query GetWriting($objectID: String!, $version: String!) {
    getWriting(objectID: $objectID, version: $version) {
      objectID
      createdAt
      nbObj
      version
      schema
      activeEditorSub
      activeEditorSession
      requested {
        sessionID
        sub
      }
      owner
      updatedAt
    }
  }
`;
export const annotationsByObjectID = /* GraphQL */ `
  query AnnotationsByObjectID(
    $objectID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByObjectID(
      objectID: $objectID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        sourceInfo
        renderInfo
        objectID
        annoType
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
