import React, {useContext, useState} from 'react';
import useFilesData from '../../../../Files/useFilesData'
import {EnvironmentContext} from '../../../../../../../contexts'
import {Folder} from '../../../../../../../types/files'
import FolderSectionComponent from './FolderSection';


import './FolderAccordion.css'
import FolderComponent from '../../../../../features/files/Folder/Folder';
import { FolderSection } from '../../../../../features/files/folderDataSlice';


type FolderAccordionProps = {

    foldersData: FolderSection[],
    parentSegments: Folder[]
    
};

const FolderAccordion:React.FC<FolderAccordionProps> = ({foldersData, parentSegments}) => {

    return (
        <div className='explore-folders'>
            {foldersData.map((folder: FolderSection) => (
                <FolderSectionComponent folderSection={folder} parentSegments={parentSegments}></FolderSectionComponent>
            ))}
            {foldersData.length === 0 && 
                <div className='no-folders'>No folders here</div>
            }
        </div>
    );
}
export default FolderAccordion;