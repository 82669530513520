import React, {useState} from 'react'
import { AnnotationFilterTopic, PageNumberFilterType } from '../annotationTypes';

import './AnnotationsFilterSelector.css'
import AnnotationDocumentSelector, { DocumentFilterProps } from './filters/DocumentFilter';
import AnnotationFilterColorSelector, {ColorFilterProps} from './filters/ColorFilter';
import AnnotationTypeSelector, {TypeFilterProps} from './filters/TypeFilter';
import PageNumberSelector, {PageNumberFilterProps} from './filters/PageNumberFilter';
import DocumentFilter from './filters/DocumentFilter';
import { allPDFAnnotations } from '../../../reader/viewers/PDF/PdfManager';


type AnnotationFilterSelectorProps = {
    activeTopic: AnnotationFilterTopic,
    setActiveFilterTopic: React.Dispatch<React.SetStateAction<AnnotationFilterTopic>>, 
    
} & PageNumberFilterProps & ColorFilterProps & TypeFilterProps & DocumentFilterProps 

const AnnotationFilterSelector: React.FC<AnnotationFilterSelectorProps> = (props) =>{

    const {activeTopic} = props

    const commonProps = {
        setActiveTopic: props.setActiveFilterTopic,
        activeTopic: props.activeTopic
    }

    var activeTopicFilter

    if(activeTopic ==="Annotation Type") {
        activeTopicFilter = 
        <AnnotationTypeSelector 
            selectedAnnotationTypes={props.selectedAnnotationTypes} 
            setSelectedAnnotationTypes = {props.setSelectedAnnotationTypes} 
            allAnnotationTypes={allPDFAnnotations}
        />

    }
    else if(activeTopic ==="Page Number"){
        activeTopicFilter = 
            <PageNumberSelector 
                totPages = {props.totPages} 
                pageNumberFilter = {props.pageNumberFilter}
                setPageNumberFilter = {props.setPageNumberFilter} 
            />
        // activeTopicFilter = "Hoho there"
    }
    else if(activeTopic == "Color"){
        activeTopicFilter = 
        <AnnotationFilterColorSelector 
            setSelectedColors={props.setSelectedColors} 
            selectedColors = {props.selectedColors} 
        />
    }
    else if (activeTopic=="Documents"){
        activeTopicFilter = 
        <DocumentFilter
            documentFilter={props.documentFilter}
            setDocumentFilter={props.setDocumentFilter}
         
        />
    }


    return (
        <div className={`annotationFilterSelector-main`}>
            <div className={`filter-topics column`}>

                {/* <FilterTopic {...commonProps} text={"Page Number"} /> */}
                <FilterTopic {...commonProps} text={"Color"} />
                <FilterTopic {...commonProps} text={"Documents"} />
                <FilterTopic  {...commonProps} text={"Annotation Type"} />

            </div>
            <div className={`filter-options column`}>
                {activeTopicFilter}
            </div>
        </div>
    )

}


type FilterTopicProps = {
    text: AnnotationFilterTopic, 
    activeTopic: AnnotationFilterTopic, //AnnotationFilterTopic
    setActiveTopic: React.Dispatch<React.SetStateAction<AnnotationFilterTopic>>
}

const FilterTopic: React.FC<FilterTopicProps> = (props) =>{
    return(
        <div className={`filterTopic light ${props.activeTopic===props.text?"activeTopic":""}`} onMouseEnter={()=>{
            props.setActiveTopic(props.text)
        }}>
            {props.text}
        </div>
    )
}






export default AnnotationFilterSelector
