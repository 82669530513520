export type ReadingTheme = "light" | "dark"

export const setReadingTheme = (theme: ReadingTheme) =>{

    // console.log("gonna set reading theme: ", theme)
    let root = document.documentElement;
    if (theme === "dark") {
        var filter =
            "invert(0.85) brightness(.9) contrast(0.9) grayscale(1)";
        var scol = "contrast(1.9)";
    // } else if (theme === "sepia") {
    //     filter = "sepia(0.60)";
    //     // scol = '#7fcafb'
    } else {
        theme = "light";
        filter ="none"
        scol = ""
    }

    root.style.setProperty("--reading-theme", filter);
    root.style.setProperty("--reading-stheme", scol);

    localStorage.setItem("ReadingTheme", theme);
}

export const getReadingTheme: () => ReadingTheme = () =>{
    const currentReadingTheme = localStorage.getItem("ReadingTheme")
    return currentReadingTheme as ReadingTheme
}