import React from 'react';
import Topbar from './Topbar/Topbar';

import './Header.laptop.css'

import {HeaderProps} from '../index'
import PageTitle from '../common/PageTitle/PageTitle';



const Header:React.FC<HeaderProps> = () => {
    return(
        <div className="Header laptop">
            <div className="Header-Topbar">
                <Topbar />

            </div>
            <div className='Header-PageTitle'>
                <PageTitle  />

                <hr className='grey-underline'></hr>

            </div>
            
            
        </div>
    )
}
export default Header;