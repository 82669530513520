/*
This extension sets up a plugin to update all image nodes with the presigned url being 
fetched from S3. This is done via decorations.
*/

import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  Helper,
  helper,
  isElementDomNode,
  isString,
  joinStyles,
  Mark,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  omitExtraAttributes,
} from "@remirror/core";
import { Decoration, DecorationSet } from "@remirror/pm/view";
export const TEXT_COLOR_ATTRIBUTE = 'data-text-color-mark';
import "./NotebookTextColor.css"


@extension({
    defaultOptions: {
        getTheme : ()=>"light"
    },
    defaultPriority: ExtensionPriority.Low,
})
export class UpdatedTextColorExtension extends MarkExtension {

    get name() {
        return 'textColor'
      }
    
      createTags() {
        return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
      }
    
      createMarkSpec(extra, override) {
        return {
          ...override,
          attrs: {
            ...extra.defaults(),
            color: { default: this.options.defaultColor },
          },
          parseDOM: [
            {
              tag: `span[${TEXT_COLOR_ATTRIBUTE}]`,
              getAttrs: (dom) => {
                if (!isElementDomNode(dom)) {
                  return null;
                }
    
                const color = dom.getAttribute(TEXT_COLOR_ATTRIBUTE);
    
                if (!color) {
                  return null;
                }
    
                return { ...extra.parse(dom), color };
              },
            },
            {
              // Get the color from the css style property. This is useful for pasted content.
              style: 'color',
              priority: ExtensionPriority.Low,
              getAttrs: (color) => {
                if (!isString(color)) {
                  return null;
                }
    
                return { color };
              },
            },
            ...(override.parseDOM ?? []),
          ],
          toDOM: (mark) => {
            const { color, ...other } = omitExtraAttributes(mark.attrs, extra);
            const extraAttrs = extra.dom(mark);
            let style = extraAttrs.style;
            const finalColor = `var(--notebook-text-${color}, ${color})`
            if (finalColor) {
              style = joinStyles({ color: finalColor }, style);
            }
    
            return ['span', { ...other, ...extraAttrs, style, class:'notebook-text-color', [TEXT_COLOR_ATTRIBUTE]: color }, 0];
          },
        };
      }
    
      
      createCommands(){
        return{
          setTextColor: (color, options)=> {
            return this.store.commands.applyMark.original(this.type, { color }, options?.selection);
          },
          removeTextColor : (options) => {
            return this.store.commands.removeMark.original({ type: this.type, ...options, expand: true });
          }
        }
        
      }
     
    
     
    

    // createPlugin() {
    //     return {
    //         state: {
    //             init(_, { doc }) {
    //                 // this should have decorations for all color marks with the color being set 
    //                 // to the dark mode value
    //                 let decorationSet = [];
    //                 const recursiveWalk = (node, globalPos) => {
    //                     //AIM: Calculate global position for child and continue
    //                     let start = globalPos;
    //                     node.forEach((childNode, offset, index) => {
    //                         console.log("Processing", childNode.type, start);
    //                         if (childNode.type.name === "image") {
    //                             // We must update source.
    //                             // The net index should be - global
    //                             let newDec = Decoration.node(
    //                                 start + 1,
    //                                 start + 2,
    //                                 {
    //                                     style: "background: yellow",
    //                                     src: "https://dummyimage.com/600x400/000/fff",
    //                                 }
    //                             );
    //                             console.log("Created", newDec);
    //                             decorationSet.push(newDec);
    //                         }
    //                         start += recursiveWalk(childNode, start);
    //                     });
    //                     return node.nodeSize;
    //                 };
    //                 recursiveWalk(doc, 0);
    //                 // const recursiveWalk = (node)=>{
    //                 //     node.descendants((childNode, pos, parent, index)=>{
    //                 //         if (childNode.type.name === 'image'){
    //                 //             // We must update source.
    //                 //             console.log("IMAGE", node, pos, index, childNode.resolve(0))
    //                 //             let newDec = Decoration.node(index, index+1, {src: "https://dummyimage.com/600x400/000/fff"})
    //                 //             decorationSet.push(newDec)
    //                 //         }
    //                 //     })

    //                 // }
    //                 // recursiveWalk(doc)
    //                 console.log("Decoration", decorationSet);
    //                 return {
    //                     decorationSet: DecorationSet.create(doc, decorationSet),
    //                 };
    //             },
    //             apply(tr, oldState) {
    //                 return {
    //                     decorationSet: oldState.decorationSet.map(
    //                         tr.mapping,
    //                         tr.doc
    //                     ),
    //                 };
    //             },
    //         },
    //         props: {
    //             decorations(state) {
    //                 return this.getState(state).decorationSet;
    //             },
    //         },
    //     };
    // }
}
