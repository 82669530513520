 export const ColorMap = {
    "red": "#f08080",
    "orange":"#FFA500",
    "yellow":"#ffcc00",
    "green": "#00ff7f",
    "blue": "#87cefa" ,
    "violet": "#EE82EE",
    "brown": "#3f000f" ,
    "black": "#1a1b1f",
    "transparent":"#ffcc00"
}
export const ExtractColorMap = {
    "default" : "#d8d5fa",
    "red": "#fdb5b5",
    "orange":"#fdc868",
    "yellow":"#f8dd75",
    "green": "#72f8b4",
    "blue": "#b7e3fe" ,
    "violet": "#f8b1f8",
    "brown": "#795d63" ,
    "black": "#6f7383",
    "transparent":"#f8dd75"
}

export const LightNotebookColorMap = {
    "red": "#FF0000",
    "orange":"#D9730D",
    "yellow":"#CB912F",
    "green": "#008000",
    "blue": "#0000FF" ,
    "violet": "#F600F6",
    "brown": "#A15000" ,
    "black": "#000000",
}

export const DarkNotebookColorMap = {
    "red": "#FF4C48",
    "orange":"#FFA500",
    "yellow":"#FFFF00",
    "green": "#00FF00",
    "blue": "#00FFFF" ,
    "violet": "#FF80FF",
    "brown": "#FF9E3D" ,
    "black": "#FFFFFF",
}