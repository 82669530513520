import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import { ReadingDocument } from "../../types/files"
import type { RootState } from "../store"

type AllReadingsState = {
    value: {[objectID: string]:ReadingDocument}
}

const initialState:  AllReadingsState = {
    value: {}
}

export const allReadingsSlice = createSlice({
    name: "allReadings",
    initialState,
    reducers: {
        setAllReadings: (state, action: PayloadAction<{[objectID: string]:ReadingDocument}>) =>{
            state.value=action.payload
        },
        addReading: (state, action: PayloadAction<ReadingDocument>) =>{
            state.value[action.payload.objectID] = (action.payload)
        }, 
        deleteReading: (state, action: PayloadAction<ReadingDocument>) =>{
            delete state.value[action.payload.objectID]
        }
    }
})

export const {setAllReadings, addReading} = allReadingsSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectAllReadings = (state: RootState) => state.allReadings.value
export default allReadingsSlice.reducer
