import React, {useState} from 'react';
import Button  from '../../../../ui/buttons/Button/Button';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import NewButton from '../../components/NewButton';
import ShareButton from '../../components/ShareButton/ShareButton';
import Overlay from '../../../../layouts/Overlay/Overlay';
import { ContextMenuComponent, ContextMenuObject } from '../../../../layouts/ContextMenu/ContextMenu';

import './Home.laptop.css'
import Files from '../../components/Files/Files';
import InfoMessage from '../../../../layouts/MessagePopup/MessagePopup';
import MultiProfilePreview from '../../../../features/account/MultiProfilePreview/MultiProfilePreview';
import { CMenuContext } from '../../../../contexts';
import FileUploadProgress from '../../../../features/files/FileUploadProgress/FileUploadProgress';

type HomeProps = {
};

const Home:React.FC<HomeProps> = () => {

    return (
        <div className='Home laptop row'>
                <Overlay />
                <InfoMessage/>
                <FileUploadProgress />

                <div className='Navbar-container column'>
                    <Navbar />
                </div>
                <div className='HomeContents-container column'>
                    <div className='row'>
                        <Header />
                    </div>
                    <div className='Home-ActionButtons row'>
                        <NewButton />
                        <ShareButton />
                        
                    </div>
                    <div className="Home-Files vertical-scroll show-scroll">
                        <Files />
                    </div>

                </div>            
        </div>
    )
}
export default Home;

