import React, { useState } from 'react';
import './Topbar.css'
import ProfileButton from '../../common/ProfileButton/ProfileButton';
import Searchbar from '../Searchbar/Searchbar';
import TopbarButtons from '../../common/TopbarButtons/TopbarButtons';

type TopbarProps = {
    
};

const Topbar:React.FC<TopbarProps> = () => {

    const [showFrill, setShowFrill] = useState<boolean>(false)
    
    return (
        <div className='Topbar laptop'>

            {   
                showFrill?
                <div className={`frill-feedback-dropdown ${showFrill?'active': ''}`} >
                    Hello there
                    {/* <iframe frameBorder="0" title = "Feedback" height="100%" width="100%" src = {`https://feedback.desklamp.io/embed/widget/57cdd1fa-8500-4990-9e59-3489cf9708e6/?ssoToken=${this.state.frillUserToken}`} ></iframe> */}
                </div>
                :null
            }

            <div className="SearchBar-container Topbar-item">
                <Searchbar />
            </div>
            <div className="sidebuttons-container">
                <div className="TopbarButtons-container Topbar-item">
                    <TopbarButtons />
                </div>
                <div className="ProfileButton-container Topbar-item">
                    <ProfileButton />
                </div>
            </div>
            
        </div>
    )
}


export default Topbar;