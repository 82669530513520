import { API, Auth } from "aws-amplify";
// import { getUser } from "../../graphql/queries";
// import { updateUser } from "../../graphql/mutations";

const environment = process.env.REACT_APP_GA_ELECTRON?"electron":"web"


const errorTransforms = {

    "UserNotFoundException":{ field: "email", message: "Uh oh! We couldn't find an account with that email."},
    "Incorrect username or password.": {field: "password", message: "Wrong password. Try again or click Forgot Password." } ,
    "User password cannot be reset in the current state.": { message: "Cannot reset password for admin-created account. Contact support@desklamp.io"},
    "UsernameExistsException": {field: "email", message: "An account with this email already exists. Please sign in."},
    "UserNotConfirmedException": {field: "email", message: "This email has not been verified."},
    "Username cannot be empty": {field: "email", message:"Email cannot be empty"},
    "Confirmation code cannot be empty": {field: "code", message: "Verification code cannot be empty"},
    "CodeMismatchException": {field: "code" , message: "Looks like that's not the right code. "},
    "Password did not conform with policy: Password not long enough": {field: "password", message:"Password must be at least 8 characters long"},
    "Password does not conform to policy: Password not long enough":{field: "newPassword", message: "Password must be at least 8 characters long"},
    "Invalid email address format.": {field: "email", message: "Invalid email address format."},
    "Network error": {message: "You're not connected to the internet."},
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": {
        field: "password",
        message: "Password must be at least 8 characters long",
    },
    "Custom auth lambda trigger is not configured for the user pool.": {
        field: "password",
        message: "Please enter a password."
    },
    "social":"Social sign-in is coming soon",
    "Cannot reset password for the user as there is no registered/verified email or phone_number": {
        field: "email",
        message: "Uh oh! We couldn't find an account with that email."
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatEmail = (email) =>{
    try{
        let e = email.trim().toLowerCase()
        return e
    }
    catch(e) {
        console.error("Error with email: ", e)
        return ""

    }
}

export const formatName = (name) =>{
    try{
        let a =  capitalizeFirstLetter(name).trim()
        return a
    }
    catch(e){
        console.error("Error with name: ", e)
        return ""
    }
}

export const getErrorMessage = (err) =>{
    /**
     * @params
     * error: AWSAuthError
     * @returns 
     * errors: [{
     *    field: undefined | "email" | "password" | etc.
     *    message: string
     * }]
    */
    console.log("getErrorMessage: ", err)
    var transformedError = errorTransforms[err.code] || errorTransforms[err.message]    
    console.log("transformedError: ", transformedError, err.message)
    if(transformedError) return [transformedError]
    else{
        return [{
            message: err.message
        }]
    }
}

export const updateUserEnvironment = async(email) =>{

    return

    var userDetails = {}
    
    console.log("getting user")
    
    const userObject = await API.graphql({ query: getUser, variables: { id: email }});

    console.log("got User: ", userObject)

    if(environment==="electron"){
        userDetails = {
            id: email,
            desktop: "true",
            _version: userObject._version
        }
    }

    else{
        userDetails = {
            id: this.state.email,
            web: "true",
            _version: userObject._version
        }
    }

    console.log("new update: ", userDetails)
    let resp = await API.graphql({query:updateUser, variables: {input: userDetails} }  
    )

}

export const updateIdentityIdInDynamo = async(email) =>{

    return

    try{
        console.log("updateIdentityIdInDynamo: ", email)
        console.log

        const resp = await API.graphql({ query: getUser, variables: { id: email }});
        const userObject = resp.data.getUser
        console.log("got userObject: ",userObject , userObject._version)

        var userDetails = {
            id: email,
            _version: userObject._version || 1
        }
        if(environment==="electron"){
            userDetails = {
                ...userDetails, 
                desktop: "true"
            }
        }
        else{
            userDetails = {
                ...userDetails,
                web: "true"
            }
        }

        if(
            userObject.web===userDetails.web &&
            userObject.desktop===userDetails.desktop &&
            userObject.identityID===userDetails.identityID ){
                //There's nothing to update here. 
                return
        }

        Auth.currentCredentials().then(creds => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    const {identityId} = creds
                    console.log("identityID: ", identityId)
                    // const {sub, email, family_name, given_name} = user.attributes
                    userDetails = {
                        ...userDetails,
                        identityID: identityId,
                    };
                    console.log("going to update user with : ", userDetails)
                    API.graphql({query:updateUser, variables: {input: userDetails} }  
                    ).then(data=>{
                        console.log("identityID: ", identityId)
                        console.log("User updated successfully in dynamodb: ", data)
                    }).catch(err=>{
                        console.log("ERROR updating user in dynamo: ", err)
                    });                    
                })
                .catch(err => console.log(err));
        }).catch(err=>{
            console.log("ERROR!")
        });

    }
    catch(e){
        console.error("Failed to update user in dynamo: ", e)
    }

    
}