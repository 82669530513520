
import React from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import { openOverlay } from '../../../../layouts/Overlay';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { Device } from '../../../../types/devices';
import CreateFolder from './laptop/CreateFolder/CreateFolder';
import NewButtonLaptop from './laptop/NewButton.laptop'
import NewButtonMobile from './mobile/NewButton.mobile'
import folder from '../../../../assets/icons/folder.svg'


type NewButtonProps = {
    
};

const NewButton:React.FC<NewButtonProps> = () => {

    const dispatch = useAppDispatch();

    const openCreateFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: folder,
                title: "Create Folder",
                data: "" , 
            },
            component: <CreateFolder/>
        }))
    }

    const device:Device = useWindowSize()

    const currentFolder = useAppSelector(state => state.folderData.currentFolderData.currentFolder)

    if(currentFolder.objectID==="Folder_shared") return null
    
    if(device==="laptop") return <NewButtonLaptop openCreateFolderOverlay={openCreateFolderOverlay}/>
    else return <NewButtonMobile openCreateFolderOverlay={openCreateFolderOverlay}/>
}
export default NewButton;