
/* eslint import/no-webpack-loader-syntax: 0 */
import React, {useRef, useEffect, useState, useContext} from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist';
import './PdfViewer.css'
import "./pdf_overload.css"


import PdfReaderTopbar from '../PdfReaderTopbar/PdfReaderTopbar';
import { ReaderTab, updateTab } from '../../../Reader/readerTabsSlice';
import IndexBar from '../../../components/IndexBar/IndexBar';
import { useAppDispatch, useAppSelector } from '../../../../../../redux';
import { useEffectOnce } from '../../../../../../hooks/useEffectOnce';
import { PdfManager } from '../PdfManager';
import { CMenuContext } from '../../../../../../contexts';
import { fetchFile } from '../../../../desk-logic/fileSaver';
import CircularProgressBar from '../../../../../../ui/loaders/CircularProgressBar/CircularProgressBar';

const pdfjsLib = require("pdfjs-dist/webpack") //See if this is okay: pdfjs-dist/build/pdf.js

// import * as pdfjsLib from 'pdfjs-dist/webpack.js';
// import PDFWorker from "worker-loader!pdfjs-dist/build/pdf.worker.js";

// remove the https:// in front of the the url and pass that 
// GlobalWorkerOptions.workerPort = PDFWorker()
// GlobalWorkerOptions.workerSrc='../../../../../../node_modules/pdfjs-dist/build/pdf.worker.entry.js'

type PdfViewerProps = {
    tab: ReaderTab, 
    active: boolean
}

const PdfViewer: React.FC<PdfViewerProps> = (props) => {
    /**
     * @props
     * setOutline: React.Dispatch<React.SetStateAction<any>>
     * active: boolean
     * tab: ReaderTab (see readerTabsSlice)
     */



    const dispatch = useAppDispatch()
    const containerRef = useRef(null)
    const internalRef = useRef(null)
    const cMenuContext = useContext(CMenuContext)
    const readerLayoutState = useAppSelector((state) =>state.readerLayout)
    const {toolbar, topbarDropper, indexbar, findbar } = readerLayoutState

    // const [outline, setOutline] = useState<{
    //     loading: boolean, 
    //     outline: any
    // }>({loading: false, outline: null})

    /** STATES */

    const [outline, setOutline] = useState(null)
    const [loadingProgress, setLoadingProgress] = useState<number>(0)
    // const [viewer, setViewer] = useState<PDFViewer | undefined>(undefined)

    useEffectOnce(() =>{
        // TODO: Wrap this call in another function that will fetch the URL from the reading document
        // By going S3/local/ even using URL directly
        fetchFile(props.tab.readingDocument).then((url)=>loadPDF(url))
    })

    const loadPDF = (url: string) =>{
        var loadingTask = pdfjsLib.getDocument(url)
        console.log("loadingTask: ", url)

        loadingTask.onProgress = (progress: any) =>{
            const progressNumber = progress.loaded*100/progress.total
            setLoadingProgress(Math.ceil(progressNumber))
        }

        loadingTask.promise.then((pdfjsDocumentProxy: PDFDocumentProxy) =>{
            console.log("The proxy is : ", pdfjsDocumentProxy )
            pdfjsDocumentProxy.getOutline()
            .then((pdfjsOutline: any) =>{
                console.log("got the outline: ", pdfjsOutline)
                setOutline(pdfjsOutline)
            })
            .catch((err)=>{
                console.log("Couldn't get Outline: ", err)
            })
            .finally(() =>{
                console.log("Entered Finally: ", pdfjsDocumentProxy)
                init(pdfjsDocumentProxy)
            })
        })
        .catch((err: any) =>{
            console.log("Error loading PDF: ", err)
        })
    }

    const init = (pdfjsDocumentProxy:PDFDocumentProxy ) =>{

        if(containerRef.current){
            const manager = new PdfManager(
                containerRef.current, pdfjsDocumentProxy, props.tab.readingDocument, props.active, cMenuContext,
            )
            
            dispatch(updateTab({
                readingDocument: props.tab.readingDocument,
                loading: false,
                manager: manager
            }))
        }
    }

    useEffect(()=>{
        if (props.tab.manager && props.active){
            props.tab.manager.setActive()
        }
    }, [props.tab.manager, props.active])

    useEffect(() =>{

        let pinchZoomEnabled = false;
        
        console.log("setting the pinch")

    }, [])

   
    return (
        <>
        {/* <ReaderContext.Provider value={}> */}

            <div className={`PdfViewer`}>

                <PdfReaderTopbar 
                    tab = {props.tab}
                />
                
                {
                    indexbar ?
                    <IndexBar outline={outline} />
                    :null
                }
                {
                    loadingProgress<100 ?

                    <div className='PdfViewer-loading-container'>
                        <CircularProgressBar appTheme="light" percentage={loadingProgress} />
                    </div>


                    :

                    <div ref={containerRef} id="viewerContainer" className={`PdfReader show-scroll`} >
                        {// @ts-expect-error
                        <div ref={internalRef} id="viewer" className={`PageViewer pdfViewer`} data={`${props.tab.readingDocument.objectID}`}></div>
                        }
                        
                    </div>


                } 
                
            </div>

        {/* </ReaderContext.Provider> */}

        </>
        
    )
}
export default (PdfViewer);