import {Analytics} from 'aws-amplify'
// import awsmobile from '../../aws-exports'
//This module has one function: trackEvent
//We are using google Analytics measurement protocol 
//see this for more https://developers.google.com/analytics/devguides/collection/protocol/v1
// THIS PAGE IS SUPER IMPORTANT : https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
/**
 * 
 * @param {string} sub the user sub
 * @param {object} event contains three fields : category, action and label, optionally, it may have a "value" which must be an INTEGER
 */
//By convention event MUST contain {category, event, label}

// import mixpanel from 'mixpanel-browser'

function trackEvent(user, event, addToFirehose = true){
    console.info('ANALYTICS', event)
    const cid = user? user.sub : "unknown"
    const prof = user?.profession ? user.profession : "unknown"
    const tid = process.env.REACT_APP_GA_WEB ? process.env.REACT_APP_GA_WEB : process.env.REACT_APP_GA_ELECTRON
    const value = event.value ? parseInt(event.value) : 1
    const currTime = new Date().getTime().toString()
    const payload = new URLSearchParams({
        v: 1,
        cid,
        tid,
        t: "event",
        ec: event.category,
        ea: event.action,
        el : event.label,
        ev : value,
        cd1: currTime
    }).toString();
    //Navigator.sendBeacon doesn't work on IE.
    //Fuck IE.
    navigator.sendBeacon('https://google-analytics.com/collect', payload)

    const environment =  process.env.REACT_APP_GA_WEB ? "web" : "electron"
    console.log("environment: ", environment, process.env.REACT_APP_GA_WEB, process.env.REACT_APP_GA_ELECTRON)


    console.log("Tracking: ", event )
    
    mixpanel.track(event.category, {
        'environment':environment,
    })

    if(addToFirehose){
        console.log('Adding to firehose', Analytics)
        const stampedData = {...event,
        timeStamp : currTime,
        environment : environment,
        profession : prof
        }
        console.log('Stamped data: ', stampedData)
        Analytics.record({
            data: stampedData,
            streamName: 'desklamp-analytics',
            immediate: true
        }, 'AWSKinesisFirehose')

        
    }


}

//data need not have time. We stamp it here.
function trackFirehose(data){
    const currTime = new Date().getTime().toString()
    const stampedData = {...data, timeStamp: currTime}
    Analytics.record({
        data: stampedData,
        streamName: 'desklamp-analytics'
    }, 'AWSKinesisFirehose')
}

/**
 * 
 * @param {string} sub the user sub
 * @param {*} event contains docID and title
 */
// dh = app.desklamp.io
// dp = read/<docID>
// dt = title 
function trackPdfOpened(sub, event){
    const cid = sub
    const tid = process.env.REACT_APP_GA_WEB ? process.env.REACT_APP_GA_WEB : process.env.REACT_APP_GA_ELECTRON
    const page = "/read?id=".concat(event.docID)
    const currTime = new Date().getTime().toString()
    const payload = new URLSearchParams({
        v: 1,
        cid,
        tid,
        t: "pageview",
        dh: "app.desklamp.io",
        dp: page,
        dt: event.title,
        cd1: currTime
    }).toString();
    //Navigator.sendBeacon doesn't work on IE.
    //Fuck IE.
    navigator.sendBeacon('https://google-analytics.com/collect', payload)
}

export {trackEvent, trackPdfOpened, trackFirehose}