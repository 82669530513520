import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/store'
import { WritingDocument } from '../../../../types/files'
import { SourceMetadata } from '../../desk-logic/metadata'
import { navigateToNotebook } from '../../desk-logic/sourceFunctions'
// import { ReadingDocument } from '../../../../types/files'

export type NotebookWorkspaceManager = {
    screenshotCallback: (url: string, sourceMetadata: SourceMetadata) => void
    addTextBlock: (text: string, sourceMetadata: SourceMetadata)=>void
    setNotebookContent: (nbObj: WritingDocument, reset:boolean, callback?: ()=>void) => void
    navigateToNotebook: (notebook: WritingDocument, nodeDetails: {annoType: "extract" | "clipping" | "link", id: string}) => void
}   

export type NotebookWorkspaceManagerState = {
    manager: NotebookWorkspaceManager
}





const initialState: NotebookWorkspaceManagerState = {
    manager: {
        screenshotCallback: (url, sourceMetadata) =>{},
        addTextBlock: (text, sourceMetadata)=>{},
        setNotebookContent: (nbObj)=>{},
        navigateToNotebook: (notebook, assetDetails) => {}
    }
}


export const notebookWorkspaceManager = createSlice({
    name: 'notebookWorkspaceManager',
    initialState, 
    reducers: {
        updateNotebookManager: (state, action: PayloadAction<NotebookWorkspaceManager>) =>{

            state.manager = action.payload

        }
    }

})

export const {updateNotebookManager} = notebookWorkspaceManager.actions
// Other code such as selectors can use the imported `RootState` type
export const selectNotebookManager = (state: RootState) => state.notebookWorkspaceManager.manager
export default notebookWorkspaceManager.reducer

