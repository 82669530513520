import home from 'assets/icons/home.svg'
import folder from 'assets/icons/folder.svg'
import library from 'assets/icons/library.svg'
import star from 'assets/icons/star.svg'
import trash from 'assets/icons/trash.svg'
import sharing from '../../../../assets/icons/sharing.svg'
import email from '../../../../assets/icons/email.svg'
import help from '../../../../assets/icons/help.svg'
// import download from 'assets/icons/download.svg'


import { LabeledMenu } from '../../../../types/menus'

export const navbarMenuArray: LabeledMenu = [
    // {
    //     label: 'Home', icon: home, 
    //     handler: () =>{}
    // },
    
    {
        label: 'My Library', icon: library, 
        handler: () =>{}
    },
    // {
    //     label: 'Folders', icon: folder,
    //     exclusiveTo: 'mobile', 
    //     handler: () =>{}
    // },
    {
        label: 'Shared Files', icon: sharing, 
        handler: () =>{}
    },
    // {
    //     label: 'Trash', icon: trash, 
    //     handler: () =>{}
    // }
]

export const navbarFooterButtons: LabeledMenu = [
    // {
    //     label: 'Download Desktop App', icon: star,
        // handler: () =>{}
    // },
    {
        label: "Help & Getting Started",
        icon: help,
        handler: () =>{
            //@ts-expect-error
            // window.clueso.startTour({id: 'a5e2641f-5201-4379-b264-c15cb06b4134'})
            window.clueso.display()
        }

    },

]








