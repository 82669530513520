import React from 'react';
import './AuthButton.css'
// import '../ui-types/ui-types.css'
import loading_sec from '../../../assets/icons/gifs/loading_secondary.gif'
import loading_prim from '../../../assets/icons/gifs/loading_primary.gif'

type ButtonProps = {
    text?: string,
    fill?: 'solid' | 'ghost' //default solid
    shape?: 'round' | 'square' //default round
    type?: 'secondary' | 'primary' | 'warning' //default secondary
    icon?:string,
    secondaryIcon?:string,
    position?: 'full' | 'left half' | 'right half' | 'fit', 
    onClick: (e: React.MouseEvent<HTMLElement>) => void,
    loading?: boolean,
    children: string
};

const Button:React.FC<ButtonProps> = ({
        text, fill='solid', shape='round', type="secondary", icon, secondaryIcon, position="full", onClick, loading, children
    }) => {
    
    return (  
        <div className={`${type+'-button '} ${position}`} onClick={onClick}>
            <div className={`AuthButton ${loading?"loading":""}`}>
                
                {icon?<img className={`button-image`} src={icon}/>:null}
                <span className='button-text'>{children}</span>
                <img className={`loading-image img-${position}`} src={type=="primary"?loading_prim: loading_sec} />
            </div>
        </div>
        
    );    
    
}
 
export default React.memo(Button);