import { max, min } from "lodash"

function almostEqual(a, b){
    return b-1 <= a && a <= b + 1
}
export function fillGap (selectionRects) {
    selectionRects.sort(
        (a,b)=>{
            //Sorting left to right, and top to bottom
            if(a.left < b.left) return -1
            else if (a.left>b.left) return 1
            if (a.left == b.left) {
                if(a.top < b.top) return -1
                else return 1
            }

    })
    let coords = []
    let prev = selectionRects[0]
    // ELIMINATE OVERLAPPING RECTANGLES - this is an artifact of the way selection rects are generated.
    // Keep the first rectangle. For each remaining rectangle check if the preceding rectangle 
    // overlaps significantly
    // console.log("INITIAL", selectionRects)

    for(let i = 1; i < selectionRects.length; i++){
        
        let curr = selectionRects[i]
            // we need to check if there is an overlap
        let condition =  false && (prev.right > curr.left)  && (
        (curr.bottom >= prev.top && curr.bottom <= prev.bottom) ||
        (prev.bottom <=curr.bottom && prev.bottom >= curr.top) )
        //Combine the two- problem
        if(condition){
            prev = {
                left: prev.left,
                right: max([prev.right, curr.right]),
                top:min([prev.top, curr.top]),
                bottom: max([prev.bottom, curr.bottom])
            }
        }
            
        else{
            coords.push({
                left: prev.left,
                right: prev.right,
                top: prev.top,
                bottom: prev.bottom
            
            })
            prev = curr
        }
    }
    coords.push({
        left: prev.left,
        right: prev.right,
        top: prev.top,
        bottom: prev.bottom
    
    })
    
    coords.sort(
        (a,b) => {
            // Sort from top to bottom, left to right (lines)
            if(a.top < b.top){
                return -1
            }
            else if (a.top>b.top) {
                return 1
            }
            if (a.top == b.top) {
                if(a.left < b.left) return -1
                else return 1
            }
            return 0
        }
    )
    // console.log("INTERMEDIATE", coords)
    //return coords
    let final = []
    prev = coords[0]
    for(let i = 1; i < coords.length; i++){
        let curr = coords[i]
        
        if(almostEqual(prev.top,curr.top) || almostEqual(prev.bottom, curr.bottom)){
            // extend the previous if needed!
            if (prev.left < curr.left){
                prev.right = max([curr.right,prev.right])
            } 
            else if (prev.left > curr.left){
                // superscript type - extend the current to the previous
                final.push(prev)
                curr.right = max([curr.right, prev.left])
                prev = curr
            }
        }
        else{
            final.push(prev)
            prev = curr
        }
    }
    final.push(prev)
    final.map((element)=>{return{
        left: Math.trunc(element.left),
        right: Math.trunc(element.right),
        top: Math.trunc(element.top),
        bottom: Math.trunc(element.bottom)
    }
        
    })
    // console.log("Final Rects are", final)
    return final
}