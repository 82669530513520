import React from 'react';
import { Size } from '../../../types/sizes';
import { Collaborator, User } from '../../../types/user';
import './MultiProfilePreview.css'

type MultiProfilePreviewProps = {
    users: (User | Collaborator)[]
    maxNumber: number,
    shadow?: boolean,
    size?: Size 
};

const MultiProfilePreview:React.FC<MultiProfilePreviewProps> = ({maxNumber, shadow, users, size}) => {

    const getInitials = (name:string) => {

        var names = name.split(' '),
        initials = names[0].substring(0,1).toUpperCase();

        if(names.length > 1) {
            initials += names[names.length - 1].substring(0,1).toUpperCase();
        }

        return initials;
    }
    
    return (
        <div className='MultiProfilePreview'>

            {maxNumber >= 1 ? <div className={`Profile first ${shadow ? "shadow" : ""} ${size}`}>{getInitials(users[0].name)}</div> : null}
            {(maxNumber >= 2 && users.length >= 2) ? <div className={`Profile second ${shadow ? "shadow" : ""} ${size}`}>{getInitials(users[1].name)}</div> : null}
            {(maxNumber >= 3 && users.length >= 3) ? <div className={`Profile third ${shadow ? "shadow" : ""} ${size}`}>{getInitials(users[2].name)}</div> : null}

            {users.length - maxNumber <= 0 ? null : <div className={`Profile plus ${shadow ? "shadow" : ""} ${size}`}>{`+${users.length-maxNumber}`}</div>}

        </div>
    );
}
export default MultiProfilePreview;