import React, { useEffect, useRef } from 'react';

type ClickoutProps = {
    children: React.ReactNode, 
    closeFunction: (a?:any) => void,
    closeEarly?:boolean, //true if to close on mousedown instead of on mouse click. 
};

const Clickout:React.FC<ClickoutProps> = ({children, closeFunction, closeEarly}) => {

    const clickoutRef = useRef<HTMLDivElement>(null)

    const clickOutsidePopup = (e: MouseEvent) =>{

        if(clickoutRef.current?.contains(e.target as Node)){
            console.log("Inside component", e.target)
        }
        else if((e.target as Element).classList.contains("keepClickoutOpen")) {
            //hardcoded keep open
            return

        }
        else{
            console.log("Outside component", e.target, clickoutRef.current)
            closeFunction()
        }
    }

    useEffect(() =>{
        // console.log("mounted Clickout")

        const triggerAction = closeEarly?"mousedown":"click"
        
        setTimeout(() =>{
            
            document.addEventListener(triggerAction, clickOutsidePopup)
        }, 300)

        return () =>{
            // console.log("unmounting Clickout")
            document.removeEventListener(triggerAction, clickOutsidePopup)
        }

    }, [])
    
    return(
        <div className='Clickout' ref={clickoutRef}>
            {children}
        </div>
    
    )
}
export default React.memo(Clickout);