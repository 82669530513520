import React, { useContext, useEffect, useRef, useState } from "react";
import Topbar from "../../../home/components/Header/laptop/Topbar/Topbar";
import WriterTopbar from "../components/WriterTopbar/WriterTopbar";
import NotebookEditor from "./Notebook/NotebookEditor";
import NotebookMenu from "./NotebookMenu/NotebookMenu";
import { OnlineImageStore } from "../../../../utils/legacy_utils/saving/OnlineImageStore";
import { store, useAppDispatch, useAppSelector } from "../../../../redux";
import {
    selectActiveTab,
    selectReaderTabs,
    setActiveTabIndex,
} from "../../reader/Reader/readerTabsSlice";
import ReaderContext from "../../reader/ReaderContext";
import {
    NotebookWorkspaceManager,
    selectNotebookManager,
    updateNotebookManager,
} from "./notebookWorkspaceManagerSlice";
import { useEffectOnce } from "../../../../hooks/useEffectOnce";
import { SourceMetadata } from "../../desk-logic/metadata";
import {
    renderOnSource,
    unrenderOnSource,
} from "../../desk-logic/sourceFunctions";
import { WritingDocument } from "../../../../types/files";
import {
    acceptNotebookRequest,
    sendNotebookRequest,
    updateNotebookContents,
    updateNotebookRecency,
} from "../../desk-logic/notebookSaver";

import "./NotebookWorkspace.css";
import { EnvironmentContext } from "../../../../contexts";
import {
    selectCurrentNotebook,
    selectFetchedNotebooks,
    selectInitialisedFetchedNotebooks,
    selectLoadingNewNotebook,
    setCurrentNotebook,
    setLoadingNewNotebook,
} from "./fetchedNotebooksSlice";
import { createNewNotebook } from "./Notebook/NotebookUtils";
import { selectSeparator } from "../../components/Separator/separatorSlice";
import { API } from "aws-amplify";
import { onUpdateWriting } from "../../../../graphql/subscriptions";
import { selectUser } from "../../../../features/account/userSlice";
import { Permission } from "../../../../types/user";
import { Button } from "../../../../ui/buttons";

type NotebookProps = {};

const getEditorStatus = (requestPhase?: boolean) => {
    const currentNotebook = store.getState().fetchedNotebooks.currentNotebook;
    const user = store.getState().user.user?.userID;
    const subscriptionStatus = user === currentNotebook?.activeEditorSub;
    // console.log("EDITOR IS",store.getState().user.user,  user, currentNotebook, subscriptionStatus )
    if (requestPhase) {
        return false;
    }
    if (!subscriptionStatus) {
        return false;
    }
    if (currentNotebook?.permission === "viewer") {
        return false;
    }
    return true;
};

const imageStore = new OnlineImageStore();
//
const NotebookWorkspace: React.FC<NotebookProps> = () => {
    // const [page, setPage] = useState<"Notebook" | "NotebookMenu">("NotebookMenu");
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const currentNotebook = useAppSelector(selectCurrentNotebook);
    const loadingNewNotebook = useAppSelector(selectLoadingNewNotebook);
    const [requested, setRequested] = useState<boolean>(false);
    const [requestReceived, setRequestReceived] = useState<boolean>(false);
    const page = currentNotebook ? "Notebook" : "NotebookMenu";
    const setNotebook = (doc: WritingDocument) => {
        console.log("NEW CUURRENT NOTEBOOK", doc);
        setRequested(false);
        setRequestReceived(false);
        dispatch(setCurrentNotebook(doc));
    };
    const showNotebookMenu = () => {
        dispatch(setCurrentNotebook(null));
    };

    const updateTimer = useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined
    );
    const updateCounter = useRef<number>(0);
    const contentToStart = currentNotebook?.nbObj ?? createNewNotebook();

    const editorRef = useRef(null);
    const editorProps = {
        ref: editorRef,
        editable: getEditorStatus(requested || requestReceived),
        theme: "light",
        notebookMetadata: {
            objectID: currentNotebook?.objectID,
            storedLocation: "online",
        },
        initialContent: contentToStart,
        setNotebookFocus: () => {},
        imageStore: imageStore,
        renderOnSource: renderOnSource,
        unrenderOnSource: unrenderOnSource,
        updateNotebook: (nbObj: any) => {
            if (!getEditorStatus()) {
                clearTimeout(updateTimer.current);
                return;
            }
            clearTimeout(updateTimer.current);
            updateCounter.current = updateCounter.current + 1;
            const json = nbObj;
            // const json = nbObj
            if (updateCounter.current === 25) {
                updateNotebookContents({
                    ...(currentNotebook as WritingDocument),
                    nbObj: json,
                });
                updateCounter.current = 0;
            } else {
                updateTimer.current = setTimeout(() => {
                    updateNotebookContents({
                        ...(currentNotebook as WritingDocument),
                        nbObj: json,
                    });
                }, 750);
            }
        },
    };
    // const activeTab = useAppSelector(selectActiveTab)

    const initNotebookManager = {
        screenshotCallback: (url: string, sourceMetadata: SourceMetadata) => {
            if (!getEditorStatus()) return
            imageStore.saveAndInsertImage(
                { url, sourceMetadata },
                (attributes, selection) => {
                    const nb = editorRef.current as any;
                    nb.chain?.insertImage(attributes, selection).focus().run();
                }
            );
        },
        //ispagenotebook
        addTextBlock: (text: string, sourceMetadata: SourceMetadata) => {
            if (!getEditorStatus()) return
            const nb = editorRef.current as any;
            nb.chain
                .insertExtract(text, {
                    metadata: { sourceMetadata: sourceMetadata },
                })
                .focus()
                .run();
        },
        setNotebookContent: (
            notebook: WritingDocument,
            resetNotebook: boolean,
            callback?: () => void
        ) => {
            if (resetNotebook) dispatch(setLoadingNewNotebook(true));
            setNotebook(notebook);
            if (resetNotebook)
                setTimeout(() => dispatch(setLoadingNewNotebook(false)), 200);
            if (callback) callback();
            setTimeout(() => {
                const nb = editorRef.current as any;
                nb.chain.setStartSelection().focus().run();
            }, 200);
        },
        navigateToNotebook: (
            notebook: WritingDocument,
            assetDetails: {
                annoType: "extract" | "clipping" | "link";
                id: string;
            }
        ) => {
            if (!currentNotebook) {
                setNotebook(notebook);
                setTimeout(() => {
                    dispatch(setLoadingNewNotebook(false));
                    const nb = editorRef.current as any;
                    nb.chain
                        .navigateToNotebook(
                            assetDetails.annoType,
                            assetDetails.id
                        )
                        .focus()
                        .run();
                }, 100);
            } else {
                showNotebookMenu();
                setTimeout(() => {
                    setNotebook(notebook);
                    setTimeout(() => {
                        dispatch(setLoadingNewNotebook(false));
                        const nb = editorRef.current as any;
                        nb.chain
                            .navigateToNotebook(
                                assetDetails.annoType,
                                assetDetails.id
                            )
                            .focus()
                            .run();
                    }, 100);
                }, 0);
            }
        },
    };
    // This dispatch needs to happen as and when something is changed
    // (new notebook picked, navigated to menu, etc.)
    useEffectOnce(() => {
        dispatch(updateNotebookManager(initNotebookManager));
    });
    const activeTab = useAppSelector(selectActiveTab);
    useEffect(() => {
        if (page === "Notebook" && activeTab) {
            updateNotebookRecency(
                currentNotebook!,
                activeTab.readingDocument.objectID
            );
        }
    }, [activeTab, page, currentNotebook]);
    const activeSubscription = useRef<any>(null);
    useEffect(() => {
        if (activeSubscription.current) {
            activeSubscription.current.unsubscribe();
            activeSubscription.current = null;
        }
        if (currentNotebook) {
            activeSubscription.current = (
                API.graphql({
                    query: onUpdateWriting,
                    variables: { objectID: currentNotebook.objectID },
                }) as any
            ).subscribe({
                next: (x: { provider: any; value: any }) => {
                    const newWriting = x.value.data.onUpdateWriting;
                    console.log("NEW WRITING", newWriting);
                    let newNotebook = JSON.parse(
                        JSON.stringify(currentNotebook)
                    );
                    for (let key of Object.keys(newWriting)) {
                        newNotebook[key] = newWriting[key];
                    }
                    newNotebook.nbObj = JSON.parse(newWriting.nbObj);
                    const newStatus = newNotebook.requested;
                    if (requested) {
                        console.log(
                            "We made a request. Have received a response"
                        );

                        if (newStatus == null) {
                            console.log("Request approved or denied");
                            setRequested(false);
                            dispatch(setCurrentNotebook(newNotebook));
                            return;
                        }
                    }
                    if (newStatus) {
                        console.log("Requested field changed");
                        if (newNotebook.activeEditorSub === user?.userID) {
                            setRequestReceived(true);
                            setTimeout(async () => {
                                const reUpdate = await acceptNotebookRequest(
                                    newNotebook
                                );
                                setNotebook(reUpdate);
                            }, 5000);
                            return;
                        }
                    }

                    if (newNotebook.activeEditorSub !== user?.userID) {
                        // Update received

                        const nb = editorRef.current as any;
                        nb.manager.view.updateState(
                            nb.manager.createState({
                                content: newNotebook.nbObj,
                            })
                        );
                        dispatch(setCurrentNotebook(newNotebook));
                    }
                },
                error: (error: Error) => console.error(error),
            });
        }
    }, [currentNotebook, user, requested, requestReceived]);

    const isWriterCollapsed = useAppSelector(selectSeparator).isWriterCollapsed;

    if (loadingNewNotebook) {
        console.log("SHOWING", isWriterCollapsed);
        return (
            <>
                <WriterTopbar
                    page="NotebookMenu"
                    showNotebookMenu={showNotebookMenu}
                />
                <div className="NotebookMenu-container">
                    <div
                        className="lds-hourglass"
                        style={{
                            zoom: 2,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            WebkitTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                            display: isWriterCollapsed ? "none" : "block",
                        }}
                    ></div>
                </div>
            </>
        );
    }

    if (page === "Notebook") {
        return (
            <>
                <WriterTopbar
                    page="Notebook"
                    showNotebookMenu={showNotebookMenu}
                    notebook={currentNotebook as WritingDocument}
                />
                {requested ? (
                    <div className="request-bar light">
                        Taking over ...
                    </div>
                ) : null}
                {requestReceived ? (
                    <div className="request-bar light">Editing paused</div>
                ) : null}
                {currentNotebook?.permission == "viewer" ? (
                    <div className="request-bar light">
                        You cannot edit this notebook. Please request the owner
                        to give you edit access.
                    </div>
                ) : null}
                {user?.userID !== currentNotebook?.activeEditorSub ? (
                    <div className="request-bar light">
                        <b>Someone else is editing this notebook.</b><br></br>
                        <Button
                            isDisabled={requested}
                            onClick={() => {
                                setRequested(true)
                                // sendNotebookRequest(currentNotebook!)
                                updateNotebookContents({
                                    ...(currentNotebook as WritingDocument),
                                   
                                });                                
                            }}
                        >
                            Take Control
                        </Button>
                    </div>
                ) : null}

                <div className="Notebook">
                    <NotebookEditor {...editorProps}></NotebookEditor>
                </div>
            </>
        );
    } else {
        //if (page==="NotebookMenu")
        return (
            <>
                <WriterTopbar
                    page="NotebookMenu"
                    showNotebookMenu={showNotebookMenu}
                />
                <div className="NotebookMenu-container">
                    <NotebookMenu />
                </div>
            </>
        );
    }
};
export default NotebookWorkspace;
{
    /* <div>
                            <div
                                className={`request-bar ${this.context.getAppTheme()}`}
                            >
                                {this.state.requested === true ? (
                                    <>Awaiting response</>
                                ) : null}
                                {this.state.requestReceived === true ? (
                                    <>Editing Paused</>
                                ) : null}
                                {this.state.requestReceived === false &&
                                this.state.requested === false ? (
                                    <>
                                        {this.state.activeEditor.sub ===
                                        this.context.saver.getNotebookUser().sub
                                            ? this.props.permission === "viewer"
                                                ? "You cannot edit this notebook. Please request the owner to give you edit access."
                                                : "You are editing somewhere else."
                                            : this.context.getPeople(
                                                  this.state.activeEditor.sub
                                              )?.name +
                                              " is editing this notebook"}
                                        {this.props.permission ===
                                        "viewer" ? null : (
                                            <button
                                                onClick={this.requestAccess}
                                                className={`editor-request ${this.context.getAppTheme()}`}
                                            >
                                                {this.state.activeEditor.sub ===
                                                this.context.saver.getNotebookUser()
                                                    .sub
                                                    ? "Start editing here"
                                                    : "Request access"}
                                            </button>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div> */
}
