import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../types/devices';
import { IconMenu } from '../../.././../../../types/menus';
import vertical_dots from '../../../../../../assets/icons/vertical_dots.svg'
import thunderbolt from '../../../../../../assets/icons/thunderbolt.svg'
import search from '../../../../../../assets/icons/search.svg'
import sharing from '../../../../../../assets/icons/sharing.svg'
import edit from '../../../../../../assets/icons/edit.svg'

import './TopbarButtons.css'
import { useAppDispatch, useAppSelector } from '../../../../../../redux';
import { openOverlay } from '../../../../../../layouts/Overlay';
import { openFullScreenOverlay } from '../../../../../../layouts/FullScreenOverlay';
import MarkAsRead from '../../../../../../assets/icons/mark_as_read.svg'
import SearchInput from '../../laptop/Searchbar/SearchBox/SearchInput/SearchInput';
import SearchResults from '../../laptop/Searchbar/SearchBox/SearchResults/SearchResults';
import SearchBox, { SearchResult } from '../../laptop/Searchbar/SearchBox/SearchBox';
import { API } from 'aws-amplify';
import fuzzysort from 'fuzzysort';
import { Folder, ObjectUser } from '../../../../../../types/files';
import EditFileDetails from '../../../../../../features/files/EditFileDetails/EditFileDetails';
import { selectFolderPath, editFolderInPath } from '../../../../features/files/folderPathSlice';
import Icon from '../../../../../../ui/icons/HoverTipIcon/Icon';
import FrillWidget from '../../../../../../utils/FrillWidget/FrillWidget';

type TopbarButtonsProps = {
    
};

const getAllQuery = /* GraphQL */ `
query MyQuery {
    getAll{
      parentID
      permission
      name
      objectID
    }
}
`;

const TopbarButtons:React.FC<TopbarButtonsProps> = () => {

    const dispatch = useAppDispatch();

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const filesToSearch = useRef()

    const folderPath = useAppSelector(selectFolderPath)
    const currFolder = folderPath.segments.at(-1) as Folder
    const previousFolder = folderPath.segments.at(-2) as Folder

    const runSearch = (searchString: string) => {
        console.log("filesToSearch: ", filesToSearch)

        if(!filesToSearch.current) return

        const fuzzyResults = fuzzysort.go(searchString, filesToSearch.current, {key:'name'})

        const searchResults = fuzzyResults.map(res=>{
            return{
                highlightedName: fuzzysort.highlight(res, (m,i) =><span className="bolden" key={i}>{m}</span>),
                // highlightedName: fuzzysort.highlight(res) as string,
                objectUser: res.obj as ObjectUser
            }
        })

        console.log("searchResults: ", searchResults)

        // const fileResults: ObjectUser[] = results.map(res => res.obj as ObjectUser)

        // const test = results.map(result =>fuzzysort.highlight(result))
        // console.log("highlighted: ", test)

        // console.log("results: ", results)
        setSearchResults(searchResults)
        // return fileResults;
    }



    useEffect(() =>{
            
        (API.graphql({query: getAllQuery}) as any)
        .then((resp:any) =>{
            console.log("got all response: ", resp)
            filesToSearch.current = resp.data.getAll
        })
        .catch((err:any)=>{
            console.error("couldn't getall: ", err)
        })
       

    }, [])

    const editDetailsCallback = (file: Folder) =>{
        console.log("editDetailsCallback   !", file)
        const {name, description} = file
        const fileCopy= {...file}
        fileCopy.name = name
        fileCopy.description = description
        // setNameState(name)
        // setDescriptionState(name)

        dispatch(editFolderInPath(fileCopy))
    }

    const openEditFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) =>{
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: edit,
                title: "Edit",
                data: "" ,
            },
            component: <EditFileDetails file={currFolder} editDetailsCallback={editDetailsCallback}/>
        }))
    }

    const openSearchFullScreenOverlay = (event: React.MouseEvent<HTMLDivElement>) =>{
        event?.preventDefault();
        console.log("Need to open overlay")
        console.log("Search Results in openSearchFullScreenOverlay: ", searchResults)
        dispatch(openFullScreenOverlay({
            heading: {
                title: "Search",
            },
            component: <SearchBox/>,
        }))
    }

    const deviceType: Device =useWindowSize()

    const topbarButtons:IconMenu = [
        {
            icon: vertical_dots,
            exclusiveTo: "mobile",
            handler: openEditFolderOverlay,
        },
        {
            icon: sharing,
            exclusiveTo: "mobile",
            handler: () => {}
        },
        {
            icon: search,
            exclusiveTo: "mobile",
            handler: openSearchFullScreenOverlay
        },
        // {
        //     icon: notifications,
        //     handler: () =>{}
        // }
    ]

    if(previousFolder == undefined){
        topbarButtons.splice(0,2)
    }

    
    return(
        <div className={`TopbarButtons ${deviceType}`}>
            {
                deviceType==="laptop"?
                <FrillWidget>
                </FrillWidget>
                :null
            }
            {
                topbarButtons.map(item =>{
                    if(item.exclusiveTo && item.exclusiveTo!==deviceType) return null
                    else{
                        return(
                            <Icon className='TopbarButtons-icon' hoverTip={item.hoverTip} src={item.icon} onClick={(e)=>item.handler(e)} />
                        )
                    }
                })
            }


        </div>
    )

}

export default TopbarButtons;