import {configureStore} from '@reduxjs/toolkit'
import fullScreenOverlayReducer from '../layouts/FullScreenOverlay/FullScreenOverlaySlice'

import  overlayReducer from '../layouts/Overlay/overlaySlice'
import messagePopupReducer from '../layouts/MessagePopup/messagePopupSlice'
import folderPathReducer from '../pages/home/features/files/folderPathSlice'
import folderDataReducer from '../pages/home/features/files/folderDataSlice'
import userReducer from '../features/account/userSlice'
import readerTabsReducer from '../pages/desk/reader/Reader/readerTabsSlice'
import readerLayoutReducer from '../pages/desk/reader/Reader/readerLayoutSlice'
import separatorReducer from '../pages/desk/components/Separator/separatorSlice'
import SeparatorMobileReducer from '../pages/desk/components/Separator/Separator.MobileSlice/Separator.MobileSlice'
import notebookWorkspaceManagerReducer from '../pages/desk/writer/notebooks/notebookWorkspaceManagerSlice'
import readerToolbarReducer from '../pages/desk/reader/components/ReaderToolbar/readerToolbarSlice'
// import PdfFinderReducer from '../layouts/PdfFinder/PdfFinderSlice'
import fetchedNotebooksReducer from '../pages/desk/writer/notebooks/fetchedNotebooksSlice'
import annotationsPaneReducer from '../pages/desk/writer/annotations/annotationsPaneSlice'
import fileUploadProgressReducer from '../features/files/FileUploadProgress/fileUploadProgressSlice'
import allReadingsReducer from './slices/allReadingsSlice'

const store = configureStore({
    middleware: (getDefaultMiddleware)=> {
        return getDefaultMiddleware({
            serializableCheck: false
        })
    },
    reducer: {
        
        //GENERAL
        fullscreenOverlay: fullScreenOverlayReducer,
        overlay:overlayReducer,
        messagePopup: messagePopupReducer,
        user: userReducer,
        allReadings: allReadingsReducer, 

        //HOME
        folderPath: folderPathReducer,
        folderData: folderDataReducer,
        fileUploadProgress: fileUploadProgressReducer,
        
        //DESK
        separator: separatorReducer,
        SeparatorMobile: SeparatorMobileReducer,
        readerTabs: readerTabsReducer,
        readerLayout: readerLayoutReducer,
        annotationsPane: annotationsPaneReducer, 
        notebookWorkspaceManager: notebookWorkspaceManagerReducer,
        readerToolbar: readerToolbarReducer,
        fetchedNotebooks: fetchedNotebooksReducer

    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


export default store
