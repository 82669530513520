
import React, {useState} from 'react';
import {User, Permission, Collaborator} from '../../../../types/user';
import ProfilePreview from '../../../account/ProfilePreview/ProfilePreview';

import './Collaborator.css'

type CollaboratorItemProps = {
    collaborator: Collaborator,
    updateCollaborators: (email: string, permission: Permission) => void
}

const CollaboratorItem:React.FC<CollaboratorItemProps> = ({collaborator, updateCollaborators}) =>{

    const [permission, setPermission] = useState<Permission>(collaborator.permission)

    const onChange= (e:React.ChangeEvent<HTMLSelectElement>) =>{

        const newPermission = e.target.value as Permission

        setPermission(newPermission)
        updateCollaborators(collaborator.email, newPermission)
    }

    return(
        <div className='Collaborator'>
            <div className="Collaborator-ProfilePreview">
                <ProfilePreview user={collaborator} />
            </div>
            <div className="Collaborator-dropdown">
            
                <select className='select-permission' 
                    onChange ={onChange}
                    value={permission} 
                    disabled= {permission==="owner"}
                >
                    <option disabled= {true} value="owner">Owner</option>
                    <option value="editor">Can edit</option>
                    <option value="viewer">Can view</option>
                    <option value="none">Remove</option>
                </select>



            </div>

        </div>
    )
    

    

}

export default CollaboratorItem;