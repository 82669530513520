function getNextNode(node)
{
    if (node.firstChild)
        return node.firstChild;
    while (node)
    {
        if (node.nextSibling)
            return node.nextSibling;
        node = node.parentNode;
    }
}

function getNodesInRange(range)
{
    var start = range.startContainer;
    var end = range.endContainer;
    var commonAncestor = range.commonAncestorContainer;
    var nodes = [];
    var node;

    // walk parent nodes from start to common ancestor
    for (node = start.parentNode; node; node = node.parentNode)
    {
        nodes.push(node);
        if (node == commonAncestor)
            break;
    }
    nodes.reverse();

    // walk children and siblings from start until end is found
    for (node = start; node; node = getNextNode(node))
    {
        nodes.push(node);
        if (node === end)
            break;
    }

    return nodes;
}


export function getText () {

    let sel = window.getSelection()
    let range = sel.getRangeAt(0)
    let startNodeText = range.startContainer
    let endNodeText = range.endContainer
    // This is a TEXT element. We can go to the parent for span tag. 
    let text = ""
    // We will add spaces to text for every br tag.

    let startParentElement = startNodeText.parentElement
    let endParentElement = endNodeText.nodeName == "#text" ? endNodeText.parentElement : endNodeText
    // account for case of both being same node

    if (startNodeText === endNodeText ){
        return window.getSelection().toString().replace(/ +(?= )/g,'')
    }
    console.log("START", startParentElement)
    console.log("END", endParentElement)
    let nodes = getNodesInRange(range)
    console.log(nodes)
    for (const index in nodes){
        const node = nodes[index]
        console.log("CONSIDERING", node)
        if (node === startNodeText) {
            text += startNodeText.nodeValue?.slice(range.startOffset)
        }
        else if (node === endNodeText){
            if (node.nodeName === "#text"){
                text += node.nodeValue.slice(0, range.endOffset)
            }
        }
        else if (node.nodeName === "BR"){
            if(text[text.length-1] === '-' || text[text.length-1] === " "){
                // no need to add space
            }
            else{
                text += " "
            }
            
        }
        else if (node.nodeName === "#text"){
            text += node.nodeValue
        }
    }

    text = text.replace(/ +(?= )/g,'');
    console.log("GETTING TEXT", text)
    // return window.getSelection().toString()
    return text
}