import React, { useContext, useEffect } from 'react';
import './auth-styles/AuthScreen.css';
import SignUp from './SignUp/SignUp';
import SignIn from './SignIn/SignIn';
import { useState } from 'react';
import { getURLSearchParam } from '../../utils/url';
// import information from '../icons/information.svg'
import store from '../../redux/store'

import DesklampLogo from '../../assets/logo/Wordmark.svg'
import Desklamp2 from '../../assets/logo/Desklamp2.svg'

import '../../ui/ui-styles/ui-styles.css'
import '../../styles/animations/shake.css'
// import auth_title from '../icons/auth-graphics/AuthTitle.png'
// import auth_title from '../icons/auth-graphics/VerificationCode.png'
import auth_title from '../../assets/images/auth-graphics/ForgotPassword.png'

import PromoCodeInfoPanel from './SignUp/PromoCode/PromoCodeInfoPanel';
import { signIn } from './auth-functions/SignInFunctions';
import AuthCoverImage from './AuthCoverImage/AuthCoverImage';
import { UserContext } from '../../contexts';
import Overlay from '../../layouts/Overlay/Overlay';
import { Provider } from 'react-redux';

const AuthScreen = (props) => {
    /**
     * @state
     * showInfoPanel: boolean
     * authScreen: "signin" | "signup"
     * formState: {} (common formState across all pages)
     * @props
     * setUser: () =>{}
     */

    let screen = getURLSearchParam('screen')
    // let subscreen=getURLSearchParam('subscreen')
    const userContext = useContext(UserContext)
    const [showInfoPanel, setShowInfoPanel] = useState(false)
    const [subScreen, setSubScreen] = useState(screen=="signup"?"SignUpForm":"SignInForm")

    const signInScreens = ["SignInForm", "ResetPassword", "VerifyUnconfirmedUser", "ForceChangePassword"]
    const signUpScreens = ["SignUpForm", "VerificationCode", "PromoCode"]
    
    const toggleInfoPanel = () =>{
        console.log("hoho")
        setShowInfoPanel(!showInfoPanel)
    }

    useEffect(() =>{
        try {
            document.getElementsByClassName("cet-container")[0].style.overflow =
                "hidden";
        } catch (e) {}
    }, [])
    
    const signUserIn = (email, password) =>{

        return new Promise((resolve, reject) =>{
            signIn(email, password)
            .then((user) =>{
                completeSignIn(user)
                resolve(true)
            })
            .catch((err) =>{
                reject(err)
            })
        })
    }

    const completeSignIn = (user) =>{
        /**
         * @param user: AWSUserObject
         */
        console.log("completeSignIn: ", user)
        userContext.signIn(user.attributes)

    }

    return (  
        <>
        <Provider store={store}>
            <Overlay />
            {
                props.environment=="electron"?
                <div className='draggable-handle-auth'>
                    .
                    
                </div>
                :null
            }
            <div className='auth-screen row'>
                
                <div className='auth-form-column column'>

                    <img className='auth-logo auth-big-logo' src={Desklamp2}/>


                    <div className='authform-container'>


                        {
                            signInScreens.includes(subScreen)?
                                <SignIn 
                                    completeSignIn = {completeSignIn}
                                    signUserIn = {signUserIn}
                                    subScreen={subScreen}
                                    setSubScreen={setSubScreen}
                                /> 
                                :
                                <SignUp 
                                    toggleInfoPanel={toggleInfoPanel}
                                    signUserIn = {signUserIn}
                                    subScreen={subScreen}
                                    setSubScreen={setSubScreen}

                                />
                        }
                    </div>
                
                </div>

                <div className='auth-graphic-column column'>
                    <AuthCoverImage subScreen={subScreen} />
                </div>

            </div>
            {/* <div className={`info-panel ${showInfoPanel?'on-screen':'off-screen'}`}>
                <div className='info-panel-container'>
                    <img src={information} className='md info-icon'></img>
                    <PromoCodeInfoPanel />
                </div>
            </div> */}
            </Provider>
        </>
        

    );
}
 
export default AuthScreen ;