import React from 'react';
import './Text.css';

const Text = ({size="n", type="", weight="", children}) =>{
    /**
     * This component is not to be exposed outside this file.
     * The text component is used to make size-specification modular across all Text styles.
     * @props 
     * size: "xs"|"s"|"n"|"l"|"xl"
     * weight: "medium" | "normal" | ""
     * type: "success" | "error" | ""
     */

    return (
        <div className={`text ${size} ${type} ${weight}`}>
            {children}
        </div>
    )
}

export const Title = (props) => {
    /**
     * @props
     * children: string
     */

    const punctuation = ['?', '.', '.']
    var hasPunctuation = false

    const title = props.children.trim()

    const header = title.slice(0, title.length - 1)
    const lastChar = title.slice(title.length - 1)

    if(punctuation.includes(lastChar)) hasPunctuation = true 
    
    return (  
        <div className={`title`}>
        <Text {...props}>
            {
                hasPunctuation?
                <>
                    <span className='title-text'>{header}</span>
                    <span className='title-punctuation'>{lastChar}</span>
                </>
                :
                <span className='title-text'>{title}</span>
            }
            </Text>
        </div>
    );
}

export const Subtitle = (props) => {
    /**
     * @props
     * children: [
     *      string,
     *      <a href=""></a>
     * ] (an array of strings and <a> tags, or just a string)
     */


    return (
        <div className={`subtitle`}>
            <Text {...props}>
                {props.children}
            </Text>
        </div>
    )
}

export const Caption = (props) =>{

    return(
        <div className={`caption`}>
            <Text {...props}>
                {props.children}
            </Text>
        </div>
    )
}


