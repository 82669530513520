import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/store'
import {Folder, myLibrary} from '../../../../types/files'


// Define a type for the slice state
interface FolderPathState {
    value: {
        segments: [Folder, ...Folder[]]
        loading: Boolean
    }
}

// Define the initial state using that type
const initialState: FolderPathState = {
    value: {
        segments: [myLibrary],
        loading: true
    },
}
// Both web and electron will have to dispatch an initial update
// To load an initial path


export const folderPathSlice = createSlice({
    name: 'folderPath',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateFolderPath: (state, action: PayloadAction<[Folder, ...Folder[]]>)=>{
            state.value.segments = action.payload
            state.value.loading = false
        },
        loadingFolderPath: (state)=>{
            state.value.loading = true
        }, 
        editFolderInPath: (state, action:PayloadAction<Folder>) =>{
            //called when folder is edited from the topbar in the home screen
            const editedFolder = action.payload

            state.value.segments = state.value.segments.map(folder=>{
                if(folder.objectID==editedFolder.objectID) return editedFolder
                else return folder
            }) as [Folder, ...Folder[]]
        }
    }
})

export const {updateFolderPath, loadingFolderPath, editFolderInPath} = folderPathSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectFolderPath = (state: RootState) => state.folderPath.value
export default folderPathSlice.reducer
