import React from 'react';
import DoublerangeSlider from '../../../../../../ui/inputs/DoublerangeSlider/DoublerangeSlider';
import { PageNumberFilterType } from '../../annotationTypes';

export type PageNumberFilterProps = {
    totPages: number, 
    pageNumberFilter: PageNumberFilterType,
    setPageNumberFilter: React.Dispatch<React.SetStateAction<PageNumberFilterType>>,
};

const PageNumberFilter:React.FC<PageNumberFilterProps> = (props) => {
    
    return(
        <div>
            <p>Filter by <b>Page Number</b></p>
            <div className="pagenumberfilter-container">
                <p>Select a page range:</p>
                <div className='master-wrapper'>
                    
                    <DoublerangeSlider min={1} max={props.totPages} 
                        minValue={props.pageNumberFilter.minPageNumber}
                        maxValue={props.pageNumberFilter.maxPageNumber} 
                        setPageRange = {props.setPageNumberFilter} />
                    <br/>
                    
                </div>
                


            </div>

        </div>
    )
}
export default PageNumberFilter;