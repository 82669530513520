import TitledInput from "../../../ui/inputs/TitledInput/TitledInput";
import { Subtitle, Title } from "../../../ui/text/Text";
import { useState } from "react";
import AuthButton from '../../../ui/buttons/AuthButton/AuthButton'
import { signIn } from "../auth-functions/SignInFunctions";
import Form from "../../../layouts/Form/Form";
import LegacyDesklamp from "../../../utils/LegacyDesklamp/LegacyDesklamp";

const SignInForm = (props) => {
    /**
     * @props
     * setSubScreen: () =>{}
     * setFormState: () =>{}
     */

    const signInFunction = {

        function: (formState) =>{
            console.log("Hello!")
            const email = formState.email.value
            const password = formState.password.value
            
            return signIn(email, password)
        },

        onSuccess: (user) =>{
            console.log("USER: ", user)
            if(user.challengeName=='NEW_PASSWORD_REQUIRED'){
                // user signed in with temp credentials
                // show new password screen
                props.completeSignInStep({payload: user, status: "NEW_PASSWORD_REQUIRED" })
            }
            else{
                // Proceed to app
                props.completeSignInStep({payload: user, status: "SUCCESS"})
            }
        }, 

        onError: (errors) =>{
            console.log("received errors: ", errors)
            if(errors[0].message=="This email has not been verified."){
                //The user signed up earlier without confirming their email.
                props.completeSignInStep({payload: null, status: "UserNotConfirmedException" })

            }
        }
    }

    return (  
        <div>
            <Title>Welcome back.</Title>
            <Subtitle>
                New to Desklamp? &nbsp; 
                <a onClick={() =>{props.setSubScreen("SignUpForm")}}>Create an account</a>
            </Subtitle>

            <br/>
            
            <Form
                submit={signInFunction.function} 
                onSuccess={signInFunction.onSuccess}
                onError={signInFunction.onError}
                setFormState={props.setFormState}
                initFormState={props.initFormState}
            >

                <TitledInput styleSize="l" id="email" label={"Email"} type={"email"} placeHolder="Enter your email address"/>
                <TitledInput styleSize="l" id="password" type="password" autoComplete="current-password" label={"Password"} placeHolder= {`Maybe try "Open Sesame"?`} />
                
                <Subtitle size="s"><a onClick={() =>{props.setSubScreen("ResetPassword")}}>Forgot password?</a></Subtitle>
                <br/>

                <AuthButton submit> Sign In</AuthButton>

            </Form>

            <hr className="Auth-hr" />

            <LegacyDesklamp />

            

        </div>
    );
}
 
export default SignInForm;