import React, { useState } from 'react';
import arrow_left_short from '../../../../assets/icons/arrow_left_short.svg'
import arrow_right_short from '../../../../assets/icons/arrow_right_short.svg'
import './Separator.css'

import { useAppDispatch, useAppSelector } from '../../../../redux';
import { setReaderWidth, collapseReader, uncollapseReader, collapseWriter, uncollapseWriter } from './separatorSlice';

type SeparatorProps = {


};


const Separator:React.FC<SeparatorProps> = () => {

    const dispatch = useAppDispatch()
    const {readerWidth, isReaderCollapsed, isWriterCollapsed} = useAppSelector(state=>state.separator)
    

    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [isDragging, setIsDragging] = useState<boolean>(false)

    const minWidth: number = 20;
    var startX: number = 0;
    var finishX: number = 0;

    let timer: ReturnType<typeof setTimeout>   

    const startWidthChange = (e: React.MouseEvent) => {
        
        setIsDragging(true)
        startX = e.clientX;
        //console.log(this.c);
        e.preventDefault();
        document.addEventListener("pointermove", debouncedChangeWidth)
        document.addEventListener("pointerup", endWidthChange) 
    }

    const endWidthChange = (e: MouseEvent) => {
        setIsDragging(false)
        document.removeEventListener("pointermove", debouncedChangeWidth)
        document.removeEventListener("pointerup", endWidthChange)
        // this.props.checkScroll()
    }

    const debouncedChangeWidth = (e:any) =>{
        if(timer!==undefined){
            clearTimeout(timer)
        }
        timer = setTimeout(() =>{
            changeWidth(e)
        }, 5)
    }

    const changeWidth = (e: any) => {
        // console.log("Released")

        finishX = e.clientX;
        // console.log("changeWidth: ", finishX, startX)

        var percent = ((finishX - startX)/window.innerWidth)*100;
        // console.log("percent: ", percent)
        // startX = finishX

        dispatch(setReaderWidth(readerWidth + percent))

        // setReaderWidth(readerWidth + percent)

        // setReaderWidth(Math.min(Math.max(0, readerWidth + percent), 70))
    }

    const startHovering = () =>{
        // console.log("starthovering")
        setIsHovered(true)
    }

    const stopHovering = () =>{
        
        if(isDragging) return 
        else {
            console.log("stophovering")

            setIsHovered(false)
        }
    }

    const onCollapseReader = () =>{
        dispatch(collapseReader())
    }

    const onUncollapseReader = () =>{
        dispatch(uncollapseReader())
    }

    const onCollapseWriter = () =>{
        dispatch(collapseWriter())
    }

    const onUncollapseWriter = () =>{
        dispatch(uncollapseWriter())
    }


   
    if(readerWidth && !isReaderCollapsed && !isWriterCollapsed){

        //Normal State

        return(
            <div className={`Separator ${isHovered?"hovered":""}`} onPointerDown = {startWidthChange}
                onMouseEnter = {startHovering}
                onMouseLeave = {stopHovering}
            >
            
                <div className={`Separator-handle `}>

                    <div className='separator-arrow-container close-pdf' >
                        <img src={arrow_left_short} className="separator-arrow" 
                            onClick={onCollapseReader}
                        ></img>
                        <div className="HoverTip separator">
                            Hide source
                        </div>
                    </div>

                    .

                    <div className='separator-arrow-container regular close-notebook' >
                        <img src={arrow_right_short} className="separator-arrow"
                            onClick={onCollapseWriter}
                        ></img>
                        <div className="HoverTip separator">
                            Hide notes
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    else if (readerWidth==0 || isReaderCollapsed){
        //collapsed reader
        return(
            <div className='Separator'>
                <div className='open-source single-arrow-container'>
                    <img src={arrow_right_short} className="separator-arrow single"
                        onClick={onUncollapseReader}
                    ></img>
                <div className="HoverTip show-source" >
                    Show source
                </div>
                </div>
            </div>
        )
    }

    else{
        //collapsed writer
        return(
            <div className='Separator'>
                <div className='open-notes single-arrow-container'>
                    <img src={arrow_left_short} className="separator-arrow single"
                        onClick={onUncollapseWriter}
                    ></img>
                <div className="HoverTip show-notes" >
                    Show Notes
                </div>
                </div>
            </div>
        )
    }
}
export default React.memo(Separator);