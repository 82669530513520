import React, {useState} from 'react';
import searchIcon from '../../../../../../assets/icons/search.svg'
import TitledInput from '../../../../../../ui/inputs/TitledInput/TitledInput';
import { openOverlay } from '../../../../../../layouts/Overlay';
import { useAppDispatch } from '../../../../../../redux';

import './Searchbar.css'
import SearchBox from './SearchBox/SearchBox';

type SearchbarProps = {
    
};

const Searchbar:React.FC<SearchbarProps> = () => {
    
    const [searchString, setSearchString] = useState<string>("")

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setSearchString(e.target.value)
    }

    const [focus, setFocus] = useState<boolean>(false)

    const onFocus = () =>{
        setFocus(true)
    }

    const onBlur = () =>{
        setFocus(false)
    }

    const dispatch = useAppDispatch()

    const openSearchBoxOverlay = (event: React.MouseEvent<HTMLDivElement>) =>{
        event?.preventDefault();
        console.log("Need to open overlay")
        dispatch(openOverlay({
            heading: {
                icon: "",
                title: "Search",
                data: "" ,
                hideSeperator: true,
            },
            component: <SearchBox/>,
        }))
    }

    return(
        <div className={`Searchbar laptop ${focus?"active":""}`} onClick={openSearchBoxOverlay}>

            {/* // <TitledInput value="" id='search' onChange={() =>{}} icon={searchIcon} /> */}
            
            <img className='Searchbar-icon' src={searchIcon}></img>
            <input onBlur={onBlur} onFocus={onFocus} className='Searchbar-input' onChange={onChange} placeholder='What would you like to work on today?'></input>
            <div className='Searchbar-shortcut'> {false?"⌘ + P":null}</div> 
        </div>
    )

}
export default Searchbar;