import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/store'
import { ReadingDocument, WritingDocument } from '../../../../types/files'
// import { ReadingDocument } from '../../../../types/files'

export type FetchedNotebookData = {
        
        [key:string] :{
            itemNotebooks: WritingDocument[]
            globalNotebooks: WritingDocument[]
        }
}

type FetchedNotebookState = {data: FetchedNotebookData, initialised: boolean, currentNotebook: WritingDocument | null, loadingNewNotebook: boolean}



const initialState: FetchedNotebookState = {
    initialised: false,
    data: {},
    currentNotebook: null,
    loadingNewNotebook: false
}


export const fetchedNotebooks = createSlice({
    name: 'fetchedNotebooks',
    initialState, 
    reducers: {
        initialiseNotebookMenuData: (state, action: PayloadAction<FetchedNotebookData>) =>{
            console.log("SETTING", action.payload)
            state.data = action.payload
            // state.initialised = true
        },
        initialisedNotebooks: (state)=>{
            state.initialised = true
        },
        setCurrentNotebook: (state, action: PayloadAction<WritingDocument| null>)=>{
            state.currentNotebook = action.payload
        },
        setLoadingNewNotebook : (state, action: PayloadAction<boolean>)=>{
            state.loadingNewNotebook = action.payload
        },
        createNotebook: (state, action: PayloadAction<{
            sourceID: string,
            notebook: WritingDocument
        }>) => {
            const {notebook, sourceID} = action.payload
            if (notebook.parentID.includes('Reading')){
                //item
                state.data[sourceID].itemNotebooks.push(notebook)
            }
            else{
                state.data[sourceID].globalNotebooks.push(notebook)
            }
        },
        deleteNotebook: (state, action: PayloadAction<string>)=>{
            const toDelete = action.payload
            const newData : FetchedNotebookData= {}
            Object.entries(state.data).forEach(([key,value])=>{
                newData[key] = {
                    itemNotebooks: value.itemNotebooks.filter(x=>x.objectID!==toDelete),
                    globalNotebooks: value.globalNotebooks.filter(x=>x.objectID!==toDelete)
                }
            })
            state.data = newData
        },
        fetchedReading: (state, action: PayloadAction<{
            source: ReadingDocument,
            itemNotebooks: WritingDocument[],
            globalNotebooks: WritingDocument[]
        }>) => {
            const {source, itemNotebooks, globalNotebooks} = action.payload
            state.data[source.objectID] = {itemNotebooks: itemNotebooks, globalNotebooks: globalNotebooks}
        },
        updateRecentNotebooks: (state, action: PayloadAction<{
            sourceID: string,
            notebook: WritingDocument,
            geography: 'Item' | 'Global'
        }>) => {
            const {sourceID, notebook, geography} = action.payload
            if (geography === "Item"){
                state.data[sourceID].itemNotebooks = state.data[sourceID].itemNotebooks.filter(x=>x.objectID!==notebook.objectID)
                state.data[sourceID].itemNotebooks.unshift(notebook)
            }
            else{
                state.data[sourceID].globalNotebooks = state.data[sourceID].globalNotebooks.filter(x=>x.objectID!==notebook.objectID)
                state.data[sourceID].globalNotebooks.unshift(notebook)
            }
        }, 
        editNotebook: (state, action: PayloadAction<WritingDocument>) =>{
            
            const updatedNotebook = action.payload

            const getUpdatedNotebook = (x:WritingDocument) =>{

                if(x.objectID!==updatedNotebook.objectID){
                    return x
                }
                else{
                    return {...updatedNotebook};
                }
            }

            for(const sourceID in state.data){
                state.data[sourceID].itemNotebooks = state.data[sourceID].itemNotebooks.map(getUpdatedNotebook)
                state.data[sourceID].globalNotebooks = state.data[sourceID].globalNotebooks.map(getUpdatedNotebook)
            }

        }
    }

})

export const {initialiseNotebookMenuData, createNotebook, fetchedReading, updateRecentNotebooks, editNotebook, initialisedNotebooks, setCurrentNotebook, setLoadingNewNotebook, deleteNotebook} = fetchedNotebooks.actions
// Other code such as selectors can use the imported `RootState` type
export const selectFetchedNotebooks = (state: RootState) => state.fetchedNotebooks.data
export const selectInitialisedFetchedNotebooks = (state: RootState) => state.fetchedNotebooks.initialised
export const selectCurrentNotebook = (state: RootState) => state.fetchedNotebooks.currentNotebook
export const selectLoadingNewNotebook = (state: RootState) => state.fetchedNotebooks.loadingNewNotebook
export default fetchedNotebooks.reducer

