import React from 'react';
import { DocumentFilterType, DocumentPrimFilter } from '../../annotationTypes';

export type DocumentFilterProps = {
    documentFilter: DocumentFilterType,
    setDocumentFilter: React.Dispatch<React.SetStateAction<DocumentFilterType>>
};

export const documentFilterOptions:{value: DocumentPrimFilter, label: string}[] = [
    // {
    //     label: "All readings", 
    //     value: "all"
    // }, 
    {
        label: "Opened tabs", 
        value: "tabs",
    },
    {
        label: "Only the active tab",
        value: "single"
    }
]

const DocumentFilter:React.FC<DocumentFilterProps> = (props) => {

    

    const onChange = (e:any) =>{

        props.setDocumentFilter({
            primFilter: e.target.value
        })

    }

    
    return(
        <div>
            <p>Filter by <b>Readings</b></p>
            <div className="documentfilter-checkboxes">

                {
                    documentFilterOptions.map((option) => (
                        <div className="documentFilter-option" key={option.value}>
                          <input
                            className='radio'
                            type="radio"
                            value={option.value}
                            checked={props.documentFilter.primFilter === option.value}
                            onChange={onChange}
                          />
                          <label>{option.label}</label>
                        </div>
                      ))
                }
                

            </div>
            
        </div>    
    )
}
export default DocumentFilter;