import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type { RootState } from '../../../../../redux/store';

interface SeparatorMobileState {
    isNotebook: boolean
}

const initialState: SeparatorMobileState = {
    isNotebook: false
}

export const SeparatorMobileSlice = createSlice({
    name: "SeparatorMobile",
    initialState,
    reducers: {
        setNotebook: (state) => {
            state.isNotebook = true
        },
        setPDF: (state) => {
            state.isNotebook = false
        }
    }
})

export const {setNotebook, setPDF} = SeparatorMobileSlice.actions
export const selectSeparatorMobile = (state: RootState) => state.SeparatorMobile
export default SeparatorMobileSlice.reducer