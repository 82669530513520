import { ReactNode, useRef } from "react";
import { ColorMap } from "../../../../../../../utils/Colors";
import { PDFRectangleAnnotation } from "../../PdfManager";
import {
    PdfMovableAnnotationComponent,
    PdfMovableAnnotationComponentProps,
    PdfMovableAnnotationProps,
} from "./PdfMovableAnnotation";

export const PdfRectangle = (props: PdfMovableAnnotationProps) => {
    let annotation = props.annotation as PDFRectangleAnnotation
    const clicked = useRef(1)
    const finalProps: PdfMovableAnnotationComponentProps = {
        ...props,
        rndStyle: {boxShadow: "none"},
        children: (
            size: { width: number; height: number },
            topLeft: { x: number; y: number }
        ) => {
            return (
                <>
                    <div
                        className="flexContainer"
                        style={{
                            width: size.width + "px",
                            height: size.height + "px",
                        }}
                    >
                        <div
                            className="rectangle selectionHandle"
                            id={props.annotation.id}
                              onClick= {(event)=>{
                                if (clicked.current % 2 === 0){
                                    clicked.current +=1
                                    return
                                }
                                clicked.current +=1
                                  event.preventDefault()
                                  event.stopPropagation()
                                  props.showContextMenu!(event)
                                  }}
                            onContextMenu = {(event)=>{
                                event.preventDefault()
                                event.stopPropagation()
                                props.showContextMenu!(event)

                            }}
                            style={{
                                width: "100%",
                                height: `calc(100%)`,
                                backgroundColor: `${
                                    ColorMap[
                                        annotation.renderInfo.drawingStyle
                                            .activeColor
                                    ]
                                }${parseInt(
                                    (
                                        annotation.renderInfo.drawingStyle
                                            .opacity *
                                        (255 / 100)
                                    ).toString()
                                )
                                    .toString(16)
                                    .substring(0, 7)}`,
                            }}
                        ></div>
                    </div>
                </>
            );
        },
    };

    return (
        <>
            <PdfMovableAnnotationComponent
                {...finalProps}
            ></PdfMovableAnnotationComponent>
        </>
    );
};
