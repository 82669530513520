import { useState } from 'react';
import AuthButton from '../../../ui/buttons/AuthButton/AuthButton';
import CodeInput from '../../../ui/inputs/CodeInput/CodeInput';
import { Caption, Subtitle, Title } from '../../../ui/text/Text';
import { getErrorMessage } from '../auth-functions/AuthUtils';
import { confirmVerificationCode, resendSignUp } from '../auth-functions/SignUpFunctions';


const VerificationCode = (props) => {
    /**
     * @props
     * user?: AWSUserObject
     * email?: string 
     * completeSignUpStep: () =>{}
     * @state
     * caption: {message: string, type: "error" | "success", field: "code" | undefined}
     */

    const codeLength = 6

    const [code, setCode] = useState([...Array(codeLength)].map(e=>""))
    const [caption, setCaption] = useState({
        message: "", 
        type: ""
    })
    const [secondaryLoading, setSecondaryLoading] = useState(false)
    const [primaryLoading, setPrimaryLoading] = useState(false)

    
    var email
    if(props.user?.username){
        //Probably came from sign up page
        email = props.user.username
    }
    else if(props.email){
        //Probably came from Sign In page (unverified user)
        email = props.email
    }
    else{
        //Dev purposes
        email = "dev@dev.dev"
    }

    const onChange = (e) =>{
        setCode(e)
        if(caption?.type==="error") setCaption(null)
    }

    const confirmSignUp = () =>{
        setPrimaryLoading(true)
        console.log("confirming email: ", email, code)
        confirmVerificationCode(email, code.join(""))
        .then(result =>{
            console.log("going to complete step", result)
            props.completeSignUpStep(result)
        })
        .catch(err=>{
            console.log("THE ERROR: ", err)
            setCaption({message: "Incorrect verification code.", type: "error", field: "code"})
        })
        .finally(err=>{
            setPrimaryLoading(false)
        })
    }

    const onResendSignUp = () =>{
        setSecondaryLoading(true)
        resendSignUp(email)
        .then((data) =>{
            setCaption({message:"Code resent successfully!", type: "success"})
        })
        .catch((err) =>{

            const errors = getErrorMessage(err[0])

            setCaption({message: 
                 errors[0].message, 
            type: "error"})
        })
        .finally(() =>{
            setSecondaryLoading(false)
        })
    }

    return (  
        <div>
            <Title>Verify your email.</Title>
            <Subtitle size="s" >
                We've sent a code to <b>{email}</b>
            </Subtitle>
            <br/><br/>
            <CodeInput error={caption?.field=="code" && caption?.type=="error" ? caption:null} codeLength={6} value={code} onChange={onChange} onSubmit={confirmSignUp}  />
            
            {
                caption && !caption.field?
                <Caption type={caption.type}>{caption.message}</Caption>
                :null
            }
            <br/>
            <AuthButton loading={secondaryLoading} onClick={onResendSignUp} position="left half" type="secondary" text={"Resend Code"}  >Resend Code</AuthButton>
            <AuthButton loading={primaryLoading} onClick={confirmSignUp} position="right half" type="primary" text={"Verify Email"}  >Verify Email</AuthButton>

            <Caption>Please check your spam if you cannot find the code.</Caption>

            <Subtitle>
                Wrong email? &nbsp;
                <a onClick={() =>{props.email?props.setSubScreen("SignInForm"):props.setSubScreen("SignUpForm")}}>Go back.</a>
            </Subtitle>

        </div>

    );
}
 
export default VerificationCode;