

import { ColorMap } from '../../../../../utils/Colors'
import { RectangleState } from '../../components/ReaderToolbar/tooltips/RectangleTooltip'
import './Shapes.css'
export class Rectangle {
    drawingInProgress : boolean
    x1: number
    x2: number
    x3: number
    x4: number
    y1: number
    y2: number
    y3: number
    y4: number
    bounds:{
        maxX: number
        maxY: number
        minX: number
        minY: number
    }
    snipRect: {
        x: number
        y: number
        width: number
        height: number
    }
    selBox?: HTMLDivElement
    options: {
        drawingStyle?: RectangleState
        checkDragStart: (evt:MouseEvent)=>boolean
        getBounds: () => {
            maxX: number
            maxY: number
            minX: number
            minY: number
        }
        finishDrawing: (finalRect: {
            x: number,
            y:number,
            width: number,
            height: number
        }) => void
    }
    constructor(options: {
        drawingStyle?: RectangleState
        checkDragStart: (evt:MouseEvent)=>boolean
        getBounds: () => {
            maxX: number
            maxY: number
            minX: number
            minY: number
        }
        finishDrawing: (finalRect: {
            x: number,
            y:number,
            width: number,
            height: number
        }) => void
    }) {
    
        this.options = options
        this.drawingInProgress = false


        this.x1=0
        this.x2=0
        this.y1=0
        this.y2=0

        this.x3=0
        this.x4=0
        this.y3=0
        this.y4=0
        
        this.bounds = {
            maxX : 0,
            minX : 0,
            minY : 0,
            maxY : 0
        }

        this.snipRect = {
            x: 0,
            y: 0,
            width: 0,
            height : 0
        }
        setTimeout(()=>document.addEventListener("pointerdown", this.startDrawing), 0)
    }
    

    //#region RECTANGLE DRAWING SUPPORT

    startDrawing = (evt: MouseEvent) =>{
        // This is triggered when someone clicks anywhere in the document. 
        // We have to draw only when there is a check that is satisfied. 
        // This check varies for each source. 
        this.drawingInProgress = true
        const isValidStart = this.options.checkDragStart(evt)
        
        if (isValidStart){
            // Add rectangle to the page
            this.selBox = document.createElement('div')
            this.selBox.classList.add('drawing-shape', 'selection-box')
            if (this.options.drawingStyle){
                
                const {activeColor, opacity} = this.options.drawingStyle
                const fill = `${ColorMap[activeColor]}${parseInt((opacity*(255/100)).toString()).toString(16).substring(0,7)}`
                console.log('FILLL', fill, opacity)
                this.selBox.style.backgroundColor = fill

            }
            const body = document.getElementsByTagName("BODY")[0] as HTMLElement
            body.appendChild(this.selBox)
            body.style.userSelect = 'none'

            // body.style.touchAction = ''
            body.style.webkitUserSelect = 'none'
            document.addEventListener('pointermove', this.drawingMouseMove)
            document.addEventListener('pointerup', this.finishDrawing)

        }
        else{
            // We need to abort the rectangle
            document.removeEventListener("pointerdown", this.startDrawing)
            return
        }
        // this.selectionBoxRef.current.hidden = 0;
        this.x1 = evt.clientX
        this.y1 = evt.clientY
        
        this.bounds = this.options.getBounds()
        

    }

    drawingMouseMove = (evt: MouseEvent) =>{
        console.log("MOVING MOUSE")
        this.x2 = evt.clientX
        this.y2 = evt.clientY
        this.recalc()   
    }

    recalc = () =>{
        /**
         * resets the image of the selection box on the screen during snipping
         */
       
        const {x1,y1,x2,y2, } = this
        const {minX, maxX, minY, maxY} = this.bounds

        this.x3 = Math.min(x1,x2); //Smaller X
        this.x4 = Math.max(x1,x2); //Larger X
        this.y3 = Math.min(y1,y2); //Smaller Y
        this.y4 = Math.max(y1,y2); //Larger Y
        
        this.x3 = Math.max(Math.min(maxX, this.x3), minX) 
        this.x4 = Math.max(Math.min(maxX, this.x4), minX)
        this.y3 = Math.max(Math.min(maxY, this.y3), minY)
        this.y4 = Math.max(Math.min(maxY, this.y4), minY)
        if (this.selBox){
            this.selBox.style.left = this.x3 + 'px';
            this.selBox.style.top = this.y3 + 'px';
            // console.log(selBox.style)
            this.selBox.style.width = this.x4 - this.x3 + 'px';
            this.selBox.style.height = this.y4 - this.y3 + 'px';
        }   
        
        // console.log("Rectangle: ", x3,y3,x4,y4)
    }

    finishDrawing = () => {
        
        const finalRect={
            x:this.x3,
            y:this.y3,
            width:this.x4 - this.x3,
            height:this.y4 - this.y3
        }
        
        this.x1=0
        this.x2=0
        this.y1=0
        this.y2=0

        this.x3=0
        this.x4=0
        this.y3=0
        this.y4=0
        
        this.bounds = {
            maxX : 0,
            minX : 0,
            minY : 0,
            maxY : 0
        }

        this.snipRect = {
            x: 0,
            y: 0,
            width: 0,
            height : 0
        }
        this.selBox?.remove()

        document.removeEventListener('pointermove', this.drawingMouseMove)
        document.removeEventListener('pointerup', this.finishDrawing)
        document.removeEventListener("pointerdown", this.startDrawing)

        const body = document.getElementsByTagName("BODY")[0] as HTMLElement
        body.style.userSelect = 'text'
        body.style.webkitUserSelect = 'text'
        this.options.finishDrawing(finalRect)
    }

}

export default Rectangle
