import {Auth, API} from 'aws-amplify'
import {formatEmail, formatName, getErrorMessage} from './AuthUtils.js'

const trackEvent = () =>{}

export const signUp = async(userData, callback) =>{
    /**
     * @params
     * userData: {
     *      firstName: string,
     *      lastName: string,
     *      password: string,
     *      profession: string,
     *      email: string
     * },
     * callback: () =>{}
     * 
     * @returns
     * errors: [
     * {
     *      field: "firstName" | "lastName" | "password" | "profession" | "email",
     *      message?: string | undefined
     *  }
     * ]
     */
    const { password , profession} = userData

    // this.setState({loading: true, email: email})

    var errors = []

    const email = formatEmail(userData.email)
    const firstName = formatName(userData.firstName)
    const lastName = formatName(userData.lastName)

    if(!firstName) errors.push({field: "firstName"})
    if(!lastName) errors.push({field: "lastName"})
    
    if(!password) errors.push({field: "password"})
    else if (password.length<8) errors.push({field: "password", message: "Password must be at least 8 characters long."})

    if(!email) errors.push({field: "email"})
    if(!profession) errors.push({field: "profession"})


    return new Promise (async(resolve, reject) => {

        if(errors.length) {
            reject(errors)
            return
        }

        try {
            const { user } = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email,  
                    name: firstName + " " + lastName,
                    "custom:profession": profession,       
                }
            });
            console.log(user);
            // this.setScreen(null ,"confirmSignUp")
            const name = firstName + " " + lastName
            trackEvent("unknown",{
                category:"SignUp",
                action: profession,
                label: name,
            })
            console.log("User: ", user)
            resolve(user)
            
        } catch (error) {
            reject(getErrorMessage(error))
            // this.setErrorMessage(error)
        }
        
    })
}

export const confirmVerificationCode = async(email, verificationCode) =>{

    return new Promise (async(resolve, reject) =>{

        try{
            console.log("confirmSignUp: ", email, verificationCode)
            const res = await Auth.confirmSignUp(email, verificationCode)
            resolve(res)
        }
        catch(error){
            reject(getErrorMessage(error))
        }
    })
}

export const resendSignUp = (email) =>{

    return new Promise(async(resolve, reject) =>{
        try {
            await Auth.resendSignUp(email);
            resolve(true)
        } catch (err) {
            console.log("ERROR: ", err)
            console.log("ERROR msg: ", err.message)
            console.log("ERROR code: ", err.code)

            reject([{
                message: err.message
            }])
        }
    })

}