import React, { useContext } from 'react';

import {navbarMenuArray} from '../../data'
import { LabeledMenu } from '../../../../../../types/menus';
import './NavbarMenu.css'
import useFolderNavigate from '../../../../../../hooks/FileHooks/useFolderNavigate';
import { EnvironmentContext } from '../../../../../../contexts';
import { myLibrary, sharedFiles } from '../../../../../../types/files';


type NavbarMenuProps = {
    
};

const NavbarMenu:React.FC<NavbarMenuProps> = () => {

    const menu: LabeledMenu = navbarMenuArray
    const env = useContext(EnvironmentContext)
    const navigate = useFolderNavigate(env)
    return (
        
        <div className='NavbarMenu laptop'>
            {
                menu.map((menuItem)=>{
                    if(menuItem.exclusiveTo!=="mobile") return(
                        <div className='NavbarMenuItem laptop' onClick={
                            ()=>{
                                navigate(menuItem.label==="My Library" ? myLibrary: sharedFiles)
                            }
                        }>

                            <img src={menuItem.icon} className='NavbarMenuItem-icon'></img>
                            <span className='NavbarMenuItem-icon-label'>{menuItem.label}</span>
                        
                        </div>
                    )
                })

            }
        </div>
        
    )
}
export default NavbarMenu;