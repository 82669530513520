import React from 'react';
import useWindowSize from '../../../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../../../types/devices';
import { DocumentInfoProps, ObjectUser } from '../../../../../../../../types/files';
import { SearchResult } from '../SearchBox';
import SearchResultElement from './SearchResultElement/SearchResultElement';
import './SearchResults.css';


type SearchResultsProps = {
    searchResults: SearchResult[], 
    searchInProgress: boolean, 
    initLoading: boolean,
};


const SearchResults:React.FC<SearchResultsProps> = ({searchResults, searchInProgress, initLoading}) => {
    const device: Device = useWindowSize();

    return (
        <div className={`searchResults-container ${device}`}>
            <div className={`searchResults-topbar ${device}`}>
                {searchResults.length ?
                    <div className='filled-topbar'>
                        <div className='type'>Type</div>
                        <div className='name'>Name</div>
                        <div className='location'>Description</div>
                    </div>
                    : null}
            </div>

            <div className='searchResults'>
                
                {searchResults.length ?  
                    
                    <div className='searchResults-box'>
                        <div className='searchResults-content'>
                            {
                                searchResults.map((searchResult: SearchResult) => (
                                    <SearchResultElement searchResult={searchResult}></SearchResultElement>
                                ))
                            }
                        </div>

                        {/* <div className='no-more-results-text'>That's all we could find!</div> */}
                    </div>

                    : 
                    
                    <div className='emptySearchResults'>
                        {
                            searchInProgress && initLoading?
                            "Loading ..." :
                            "Type something to begin a search ..."
                        }
                        
                        </div>
                }
            </div>
        </div>
    )
}
export default SearchResults;