
import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import React, {useState, useEffect, useRef} from 'react';
import { Form, parsePath } from 'react-router-dom';
import { LabeledMenuItem } from '../../../../../types/menus';
import TitledInput from '../../../../../ui/inputs/TitledInput/TitledInput';
import Dropper from '../../../components/DeskTopbar/TopbarDropper/TopbarDropper';

import './ZoomController.css'

type ZoomControllerProps = {
    viewer: PDFViewer
};

const ZoomBox:React.FC<ZoomControllerProps> = (props) => {


    const [zoomState, setZoomState] = useState<number>(100) 
    const [zoomInput, setZoomInput] = useState<string>("100")

    const {viewer} = props

    const zoomIn = () =>{
        console.log("zoomIn")
        incrementScale(0.1)
    }

    const zoomOut = () =>{
        console.log("zoomOut")
       incrementScale(-0.1)
    }

    const setZoom = (value: number) =>{
        // number is a percentage between 0-100
        props.viewer.currentScale = value/100
        
    }

    const fitToWidth = ()=>{
        props.viewer.currentScaleValue = 'page-width'
        setZoomState(Math.floor(props.viewer.currentScale * 100))
        setZoomInput(Math.floor(props.viewer.currentScale * 100).toString())

    }

    const incrementScale = (incrementVal: number) =>{
        console.log("incrementScale: ", props.viewer.currentScale)
        var temp = props.viewer.currentScale + incrementVal;
        // var x = this.viewer

        const viewerScaleChange = (temp:number) =>{
            try{
                props.viewer.currentScale = Math.min(Math.max(temp, 0.25), 10.0)
            }
            catch(e){
                console.log(e);
            }
        }
        
        setZoomState(Math.floor(temp*100))
        setZoomInput(Math.floor(temp*100).toString())
        console.log("temp: ", temp)
        viewerScaleChange(temp)
    }


    const handleWheel = (e: WheelEvent) =>{
        if(e.ctrlKey === true 
            // && this.props.isActiveTab
        ){
            e.preventDefault()
            var increment

            if(e.deltaY) increment = -0.05*(e.deltaY/15)
            else increment = -0.03*Math.floor(e.deltaY/30)
            console.log("increment: ", increment, e.deltaY)
            incrementScale(increment)
        }   

    }

useEffect(() =>{
        document.addEventListener('wheel', handleWheel, {passive: false});

    }, [])
    
    return (
        <div className='ZoomBox' >

            <Dropper dropdown={
                <ZoomDropdown
                    zoomState={(zoomState).toString()} zoomIn={zoomIn} zoomOut = {zoomOut} setZoom={setZoom} setZoomState={setZoomState} fitToWidth= {fitToWidth}
                    zoomInput={zoomInput} setZoomInput={setZoomInput}
                />
            }>
                {zoomState} %
            </Dropper>    

        </div>
    )
}

type ZoomDropdownProps = {
    zoomInput: string,
    setZoomInput: React.Dispatch<React.SetStateAction<string>>,
    zoomState: string,
    zoomIn: () => void,
    zoomOut: () => void,
    setZoom: (value: number) => void,
    setZoomState: React.Dispatch<React.SetStateAction<number>>,
    fitToWidth: ()=>void,
}


const ZoomDropdown:React.FC<ZoomDropdownProps> = (props) => {

    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        props.setZoomInput(e.target.value)
    }

    const onKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) =>{
        if(e.key!=="Enter") return
        else{
            props.setZoomState(parseInt(props.zoomInput))
            props.setZoom(parseInt(props.zoomInput))
        } 
    }



    return(
        <div className='ZoomDropdown'>

            <input className="ZoomDropdown-input" onChange={onChange} onKeyDown={onKeyDown} value={props.zoomInput}></input>

            <hr></hr>

            <ZoomOption 
            label="Zoom in" shortcut={"⌘+"} handler = {props.zoomIn}/>
            <ZoomOption label="Zoom out" shortcut={"⌘-"} handler = {props.zoomOut}/>
            <ZoomOption label="Fit to width" handler = {() =>{
                props.fitToWidth()
            }}/>
            <div></div>
            
        </div>
    )
}


const ZoomOption: React.FC<{label: string, shortcut?: string, handler: () =>void}> = ({label, shortcut, handler}) =>{
    
    return(
        <div className={`zoom-option`} onClick={handler}>
            <span className={`zoom-option-label`}>{label}</span> 
            <span className={`zoom-option-shortcut`}>{shortcut}</span> 
            <br/>
        </div>
    )
}


export default React.memo(ZoomBox);