import React, { useContext, useState } from 'react';
import FolderAccordion from './FolderAccordion/FolderAccordion'
import { EnvironmentContext } from '../../../../../../contexts';
import useFilesData from '../../../Files/useFilesData';
import { Folder, myLibrary, sharedFiles } from '../../../../../../types/files';
import { useAppSelector } from '../../../../../../redux';
import { selectExploreFolderData } from '../../../../features/files/folderDataSlice';
type NavbarExploreFoldersProps = {
    
};




const NavbarExploreFolders:React.FC<NavbarExploreFoldersProps> = () => {

    const env = useContext(EnvironmentContext);
    // console.log("Folderdata: ", {foldersData})
    const exploreFoldersData = useAppSelector(selectExploreFolderData)
    
    return(
        <div className='navbar-explore-folder-container vertical-scroll'>
            <FolderAccordion foldersData={exploreFoldersData} parentSegments={[]}></FolderAccordion>
        </div>
    );
}
export default NavbarExploreFolders;