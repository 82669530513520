/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFolder = /* GraphQL */ `
  mutation CreateFolder($input: CreateFileInput) {
    createFolder(input: $input) {
      userID
      objectID
      parentID
      permission
      owner
      name
      description
      icon
      createdAt
      updatedAt
    }
  }
`;
export const createReading = /* GraphQL */ `
  mutation CreateReading($input: CreateFileInput) {
    createReading(input: $input) {
      userID
      objectID
      format
      parentID
      permission
      name
      description
      owner
      file {
        bucket
        region
        key
      }
      url
      lastOpened
      thumbnail {
        bucket
        region
        key
      }
      lastLocation
      createdAt
      updatedAt
    }
  }
`;
export const createWriting = /* GraphQL */ `
  mutation CreateWriting($input: CreateFileInput) {
    createWriting(input: $input) {
      userID
      objectID
      parentID
      permission
      name
      description
      owner
      version
      nbObj
      createdAt
      updatedAt
      activeEditorSub
      activeEditorSession
      requested {
        sessionID
        sub
      }
    }
  }
`;
export const editFile = /* GraphQL */ `
  mutation EditFile($input: EditFileInput) {
    editFile(input: $input) {
      objectID
      name
      description
      parentID
      icon
      permission
    }
  }
`;
export const shareFile = /* GraphQL */ `
  mutation ShareFile($objectID: String, $email: String, $permission: String) {
    shareFile(objectID: $objectID, email: $email, permission: $permission) {
      name
      email
      permission
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput) {
    deleteFile(input: $input) {
      objectID
      name
      description
      parentID
      icon
      permission
    }
  }
`;
export const removeCollaborator = /* GraphQL */ `
  mutation RemoveCollaborator($input: RemoveCollaboratorInput) {
    removeCollaborator(input: $input) {
      name
      email
      permission
    }
  }
`;
export const updateRecent = /* GraphQL */ `
  mutation UpdateRecent(
    $notebookID: String
    $sourceID: String
    $geography: String
  ) {
    updateRecent(
      notebookID: $notebookID
      sourceID: $sourceID
      geography: $geography
    )
  }
`;
export const updateSourceDocument = /* GraphQL */ `
  mutation UpdateSourceDocument(
    $input: UpdateSourceDocumentInput!
    $condition: ModelSourceDocumentConditionInput
  ) {
    updateSourceDocument(input: $input, condition: $condition) {
      objectID
      createdAt
      file {
        bucket
        region
        key
      }
      url
      lastLocation
      customSetup
      updatedAt
    }
  }
`;
export const createSourceAnnotation = /* GraphQL */ `
  mutation CreateSourceAnnotation(
    $input: CreateSourceAnnotationInput!
    $condition: ModelSourceAnnotationConditionInput
  ) {
    createSourceAnnotation(input: $input, condition: $condition) {
      id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
    }
  }
`;
export const updateSourceAnnotation = /* GraphQL */ `
  mutation UpdateSourceAnnotation(
    $input: UpdateSourceAnnotationInput!
    $condition: ModelSourceAnnotationConditionInput
  ) {
    updateSourceAnnotation(input: $input, condition: $condition) {
      id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
    }
  }
`;
export const deleteSourceAnnotation = /* GraphQL */ `
  mutation DeleteSourceAnnotation(
    $input: DeleteSourceAnnotationInput!
    $condition: ModelSourceAnnotationConditionInput
  ) {
    deleteSourceAnnotation(input: $input, condition: $condition) {
      id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
    }
  }
`;
export const updateWriting = /* GraphQL */ `
  mutation UpdateWriting(
    $input: UpdateWritingInput!
    $condition: ModelWritingConditionInput
  ) {
    updateWriting(input: $input, condition: $condition) {
      objectID
      createdAt
      nbObj
      version
      schema
      activeEditorSub
      activeEditorSession
      requested {
        sessionID
        sub
      }
      owner
      updatedAt
    }
  }
`;
