import React, { useContext } from 'react';

import {navbarMenuArray} from '../data'
import './Navbar.mobile.css'
import {LabeledMenu,  LabeledMenuItem} from '../../../../../types/menus'
import library from '../../../../../assets/icons/library.svg'
import star from '../../../../../assets/icons/star.svg'
import { myLibrary, sharedFiles } from '../../../../../types/files';
import { EnvironmentContext } from '../../../../../contexts';
import useFolderNavigate from '../../../../../hooks/FileHooks/useFolderNavigate';

type NavbarProps = {
    
};

const Navbar:React.FC<NavbarProps> = () => {

    const env = useContext(EnvironmentContext)
    const navigate = useFolderNavigate(env)

    const navigateToMyLibrary = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log("function running")
        navigate(myLibrary)
    }

    const navigateToSharedFiles = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log("function run")
        navigate(sharedFiles)
    }

    const navbarMenu: LabeledMenu = [
        // {
        //     label: 'Home', icon: home, 
        //     handler: () =>{}
        // },
        
        {
            label: 'My Library', icon: library, 
            handler: navigateToMyLibrary
        },
        // {
        //     label: 'Folders', icon: folder,
        //     exclusiveTo: 'mobile', 
        //     handler: () =>{}
        // },
        {
            label: 'Shared Files', icon: star, 
            handler: navigateToSharedFiles
        },
        // {
        //     label: 'Trash', icon: trash, 
        //     handler: () =>{}
        // }
    ]
    
    return (
        <div className='Navbar mobile'>
            <div className='Navbar-container'>
            {
                navbarMenu.map(item=>{
                    if(item.exclusiveTo!=="laptop") return(
                        <NavbarMenuItem icon={item.icon} label={item.label} handler={item.handler} />
                    )
                })
            }
            </div>
            

        </div>
    )
}


const NavbarMenuItem:React.FC<LabeledMenuItem> = ({icon, label, handler}) =>{

    return (
        <div className='NavbarMenuItem' onClick={handler}>
            <img src={icon} className='NavbarMenuItem-icon'></img>
            <div className='NavbarMenuItem-label' >{label}</div>

        </div>
    )

}

export default Navbar;