import { Provider } from 'react-redux';
import store from '../../redux/store'

import React from 'react';
import HomeLayout from './main/HomeLayout';

import '../../styles/index.css'

type HomeProps = {
    
};

const Home:React.FC<HomeProps> = () => {


    return(
        <Provider store={store} >
            <HomeLayout />
        </Provider>
    )

}


export default Home