import React, {useContext} from 'react';
import './Navbar.laptop.css'
import Logo from 'assets/logo/Wordmark.svg' //../../../../../
import { Subtitle } from '../../../../../ui/text/Text';
import NavbarSection from './NavbarSection/NavbarSection';
import NavbarMenu from './NavbarMenu/NavbarMenu';
import NavbarExploreFolders from './NavbarExploreFolders/NavbarExploreFolders';
import NavbarFooter from './NavbarFooter/NavbarFooter';

const Navbar:React.FC = () => {


    return (
        <div className='Navbar laptop'>

            <img src={Logo} className='Navbar-logo'></img>

            <NavbarSection header="Menu">
                <NavbarMenu/>
            </NavbarSection>

            <NavbarSection header="Explore Folders">
                <NavbarExploreFolders></NavbarExploreFolders>    
            </NavbarSection>

            <NavbarFooter />
                        
        </div>
    )

}
export default Navbar;