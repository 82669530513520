import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isElementDomNode,
  isString,
  joinStyles,
  Mark,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  omitExtraAttributes,
  getTextSelection,
  PrimitiveSelection,
  Static,
} from '@remirror/core';
import { ExtensionTextHighlightMessages as Messages } from '@remirror/messages';
import { TextSelection } from '@remirror/pm/state';
import {v4 as uuid} from 'uuid'


const setTextHighlightOptions = {
  icon: 'markPenLine',
  description: ({ t }) => t(Messages.DESCRIPTION),
  label: ({ t }) => t(Messages.LABEL),
};

const TEXT_EXTRACT_ATTRIBUTE = 'data-text-extract-mark';

/**
 * Add a highlight color to the selected text (or text within a specified
 * range).
 */
  @extension({
  defaultOptions: {
   sourceMetadata: null,    
  },
  staticKeys: ['defaultHighlight'],
})
 export class ExtractMarkExtension extends MarkExtension {
  get name() {
    return 'textextract' ;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        metadata: {
          id: {default: ''},
          sourceMetadata: { 
            renderInfo: {
              text: '',
              color: 'default',
              owner: '',
              coords: []
            },
            
          },
        }
      },
      parseDOM: [
        {
          tag: `span[${TEXT_EXTRACT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null;
            }

            const metadata = JSON.parse(dom.getAttribute(TEXT_EXTRACT_ATTRIBUTE));

            if (!metadata) {
              return null;
            }

            return { ...extra.parse(dom), metadata };
          },
        },
        {
          tag: `mark[${TEXT_EXTRACT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null;
            }

            const metadata = JSON.parse(dom.getAttribute(TEXT_EXTRACT_ATTRIBUTE));

            if (!metadata) {
              return null;
            }

            return { ...extra.parse(dom), metadata };
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const { metadata, ...other } = omitExtraAttributes(
          mark.attrs,
          extra,
        );
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;
        
        // if (metadata) {
        //   style = joinStyles({ backgroundColor: '#000000' }, style);
        // }

        return [
          'span',
          { ...other, ...extraAttrs, class: "text-extract", style, [TEXT_EXTRACT_ATTRIBUTE]: JSON.stringify(metadata) },
          0,
        ];
      },
    };
  }


  /**
     * Set the text highlight color value for the selected text.
     */
   createCommands(){
    return {

        insertExtractAtStart: (text,markData)=>{
          return ({tr, state, dispatch}) => {
              const from = 1
              const to = 1
              // TODO: check if the extract already exists at this location and go to end to add. 
              markData.metadata.id = uuid()
              const mark = this.type.create(markData);
              let step1 = tr.insertText(text, from, to)
              let newStart = step1.mapping.map(from, -1)
              let newEnd = step1.mapping.map(to, 1)
              let step2 = step1.addMark(newStart, newEnd, mark)
              step2 = step2.insertText(" ", newEnd)
              let step3 = step2.insert(newEnd+1, step2.doc.type.schema.nodes.paragraph.create())
              let step4 = step3.setSelection(TextSelection.create(step3.doc, newEnd+3, newEnd+3))
              dispatch?.(step4);
        }
        },
        insertExtract: (text, markData) => {

            return ({tr, state, dispatch}) => {
                const { from, to } = getTextSelection(
                    tr.selection,
                    tr.doc
                  );
                  // TODO: check if the extract already exists at this location and go to end to add. 
                  markData.metadata.id = uuid()
                  const mark = this.type.create(markData);
                  let step1 = tr.insertText(text, from, to)
                  let newStart = step1.mapping.map(from, -1)
                  let newEnd = step1.mapping.map(to, 1)
                  let step2 = step1.addMark(newStart, newEnd, mark)
                  step2 = step2.insertText(" ", newEnd)
                  let step3 = step2.insert(newEnd+1, step2.doc.type.schema.nodes.paragraph.create())
                  let step4 = step3.setSelection(TextSelection.create(step3.doc, newEnd+3, newEnd+3))
                  dispatch?.(step4);
            }
          
        }
    }


}
  
}
