import React from 'react';
import {navbarFooterButtons} from '../../data'
import { LabeledMenu } from '../../../../../../types/menus';
import './NavbarFooter.css'

type NavbarFooterProps = {
    
};

const NavbarFooter:React.FC<NavbarFooterProps> = () => {

    const menu: LabeledMenu = navbarFooterButtons

    
    return (
        <div className='NavbarFooter laptop'>
            {
                menu.map(menuItem=><NavbarFooterButton label={menuItem.label} icon={menuItem.icon} handler={menuItem.handler as ()=>void}/>)
            }
        </div>

    )

}

type NavbarFooterButtonProps = {
    icon: string,
    label: string,
    handler: ()=>void
}

const NavbarFooterButton: React.FC<NavbarFooterButtonProps> = ({icon, label, handler}) =>{

    return(

        <div className='NavbarFooterButton' onClick = {handler}>
            <img className='NavbarFooterButton-icon' src={icon}></img>
            <div className='NavbarFooterButton-label'>{label}</div>
        </div>

    )

    

}

export default NavbarFooter;