import React, {MouseEventHandler, useContext, useState} from 'react';
import folder_color from '../../../../../../../assets/icons/folder_color.svg'
import right_arrow from '../../../../../../../assets/icons/right_arrow.svg'
import { EnvironmentContext } from '../../../../../../../contexts';
import { getFileType } from '../../../../../../../features/files/getFileDetails';
import useFolderNavigate from '../../../../../../../hooks/FileHooks/useFolderNavigate';
import useMoveFile from '../../../../../../../hooks/FileHooks/useMoveFile';
import { showErrorMessagePopup } from '../../../../../../../layouts/MessagePopup/messagePopupSlice';
import { useAppDispatch,useAppSelector } from '../../../../../../../redux';
import { Folder, myLibrary, sharedFiles } from '../../../../../../../types/files';
import { FolderSection, updateExploreFolderData } from '../../../../../features/files/folderDataSlice';
import { selectFolderPath } from '../../../../../features/files/folderPathSlice';
import { getSubFolders } from '../getSubfolders';
import FolderAccordion from './FolderAccordion'
import './FolderAccordion.css'

type FolderSectionProps = {
    folderSection: FolderSection
    parentSegments: Folder[]
};

const FolderSectionComponent:React.FC<FolderSectionProps> = ({folderSection, parentSegments}) => {

    const [isFolderOpened, setIsFolderOpened] = useState(false);
    const [isOnDrop, setIsOnDrop] = useState(false);
    const env = useContext(EnvironmentContext)
    const navigate = useFolderNavigate(env)
    const folderPath = useAppSelector(selectFolderPath)
    const currFolder = folderPath.segments.at(-1)

    const backendMoveFolder = useMoveFile(env)

    const dispatch = useAppDispatch()
    const objectPath = parentSegments.concat([folderSection.folder]) as [Folder, ...Folder[]]
    // Guaranteed to have at least one element
    const onFolderNameClick: MouseEventHandler = (e ) => {
        navigate(folderSection.folder, objectPath)
        console.log("currFolder",currFolder,"objectPath", objectPath,"folderSection.name", folderSection.folder)
    }
    
    const onExploreIconClicked: MouseEventHandler = async(e)=>{
        // Update data once its loaded
        const collapsed = folderSection.collapsed
        if (collapsed){
            
            const loading = {...folderSection}
            loading.collapsed = false
            dispatch(updateExploreFolderData(loading))
            if (folderSection.loaded){
                return
            }
            // Some hook to fetch data
            const data = await getSubFolders(env, folderSection)



            const final = {...folderSection}
            final.loaded = true
            final.collapsed = false
            final.children = data
            dispatch(updateExploreFolderData(final))
        }
        else{
            const collapse = {...folderSection}
            collapse.collapsed = true
            dispatch(updateExploreFolderData(collapse))
        }
    }

    const onDropFolder = (e: React.DragEvent<HTMLDivElement>) => {
        
        var data = e.dataTransfer.getData("text");

        const fileBeingDropped = JSON.parse(data)
        console.log("data in drop", data)
        setIsOnDrop(false);

        console.log("folderSection: ", folderSection)

        //We need to check whether a folder is being moved into its descendent
        if(getFileType(fileBeingDropped)=="Folder"){
            console.log("parent segments: ", parentSegments)
            for (var folder of parentSegments){
                console.log("parent: ", folder)
                if(folder.objectID==fileBeingDropped.objectID){
                    console.warn("illegal operation!")
                    dispatch(showErrorMessagePopup("Can't move folder into its child."))
                    
                    return
                }
            }

        }
        
        //Here, we move the folder
        backendMoveFolder({
            file: fileBeingDropped, 
            newParentID: folderSection.folder.objectID 
        })


    } 

    const onDragFolder = (e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer?.setData("text", JSON.stringify(folderSection.folder))
    }

    return(
        <div className='folder-component'>
            <div className={`folder ${isOnDrop ? "isDropActive" : ""}`} draggable={true} onDragStart={onDragFolder} onDragOverCapture={(e) => {setIsOnDrop(true)}} onDragLeave={(e) => {setIsOnDrop(false)}} onDragOver={(e) => {e.preventDefault()}} onDrop={(e) => {onDropFolder(e)}}>
                <div className={`explore-folder-icon ${folderSection.collapsed ? "not-explored" : "explored"}`} onClick={onExploreIconClicked}><img src={right_arrow} alt="" style={{height: "10px"}}></img></div>
                <div className='folder-icon-name' onClick={onFolderNameClick}>
                    {folderSection.folder !== myLibrary && folderSection.folder!== sharedFiles && <div className='folder-icon'><img src={folder_color} alt="" style={{height: "18px"}}></img></div>}
                    <div className={`folder-name ${currFolder?.objectID === folderSection.folder.objectID ? "opened" : ""}`}>{folderSection.folder.name}</div>
                </div>
            </div>
            {!folderSection.collapsed && folderSection.loaded? 
                <div className='explored-folder-list'><FolderAccordion foldersData={folderSection.children} parentSegments= {objectPath}></FolderAccordion></div> : null
            }
    </div>
    );
}
export default FolderSectionComponent;