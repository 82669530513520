
import React from 'react';
import useWindowSize from '../../../../../hooks/useWindowSize';
import Clickout from '../../../../../layouts/Clickout/Clickout';
import { useAppDispatch, useAppSelector } from '../../../../../redux';
import { Device } from '../../../../../types/devices';
import { setIndexbar } from '../../Reader/readerLayoutSlice';

import './IndexBar.css'
import IndexElement from './IndexElement/IndexElement';
import IndexList from './IndexList';

type IndexBarProps = {
    outline?: any ,
};

const IndexBar:React.FC<IndexBarProps> = ({outline}) => {
    
    console.log("IndexBar got the Outline: ", outline)

    const dispatch = useAppDispatch()
    const readerManager = useAppSelector(state => state.readerTabs.activeTab?.manager)


    //WRITE CODE HERE TO RENDER THE OUTLINE NOW. Refer to IndexBar.js in kitab repo.

    const closeIndexbar = () =>{
        console.log("time to close index bar")
        dispatch(setIndexbar(false))
    }

    const goToSection = (section: any) =>{
        readerManager.goToSection(section)
        console.log("time to close")
        closeIndexbar()
    }

    const device: Device = useWindowSize();

    return(
        <Clickout closeFunction={closeIndexbar}>
            <div className={`IndexBar keepClickoutOpen ${device}`}>
                
                    {
                        outline?
                        <>
                            <div className='heading'>TABLE OF CONTENTS</div>
                            <IndexList goToSection={goToSection} outline={outline}></IndexList>
                            
                        </>
                        :
                        "This document didn't come with a table of contents."
                    }
                
            </div>
        </Clickout>
    )
}
export default React.memo(IndexBar);