import React, { useState } from 'react';
import SliderInput from '../../../../../../ui/inputs/SliderInput/SliderInput';
import ColorSelector from '../../../../../../ui/inputs/ColorSelector/ColorSelector';
import { ColorAlias } from '../../../../../../utils/Colors';

type RectangleTooltipProps = {
    rectangleState: RectangleState
    setRectangleState: (x :RectangleState) => void 
    onClick?: (x :RectangleState) => void

};

export type RectangleState = {
    opacity: number,
    activeColor: ColorAlias
}

export const RectangleTooltip:React.FC<RectangleTooltipProps> = ({rectangleState, setRectangleState, onClick}) => {

    const {opacity, activeColor} = rectangleState
    const setOpacity = (op: number)=>{
        setRectangleState({
            opacity: op,
            activeColor
        })
    }
    const setActiveColor = (col: ColorAlias)=>{
        setRectangleState({
            opacity,
            activeColor: col
        })
    }
    

    const onChangeOpacity = (e:any) =>{
        setOpacity(e.target.value)
    }

    const setColor = (e: ColorAlias) =>{
        if (onClick)onClick({
            activeColor: e,
            opacity: opacity
        })
        setActiveColor(e)
    }
    
    return(
        <div className="RectangleTooltip">
            Rectangle Tool
            <hr></hr>

            <ColorSelector
                activeColors={[activeColor]}
                onClickColor ={(e)=>{
                    setColor(e)
                }}  
                hiddenColors = {["black", "brown"]}
                transparent={false} 
            />

            <div className='tooltip-section'>
                Opacity: {opacity} %
            </div>

            <SliderInput value={opacity} onChange = {onChangeOpacity} />

        </div>
    )
}
