import React from 'react';
import { SourceAnnotation, SourceMetadata } from '../../../../desk-logic/metadata';
import './AnnotationBoxItem.css'
import { navigateToSource } from '../../../../desk-logic/sourceFunctions';
import { assertType } from 'graphql';
import { ReadingDocument } from '../../../../../../types/files';
import { ColorAlias, ColorMap } from '../../../../../../utils/Colors';
import { PDFAnnotation, PDFAnnotationType } from '../../../../reader/viewers/PDF/PdfManager';
import { useAppDispatch } from '../../../../../../redux';
import { setPDF } from '../../../../components/Separator/Separator.MobileSlice/Separator.MobileSlice';

type AnnotationBoxItemProps = {
    annotation: SourceAnnotation; 
    getReadingDocument?: (objectID: string) => (ReadingDocument | undefined); 
    getAnnotationIcon: (annotationType: PDFAnnotationType) => string;
    highlightedText?:(string | JSX.Element)[]

};

const AnnotationBoxItem:React.FC<AnnotationBoxItemProps> = ({annotation, ...props}) => {

    const dispatch = useAppDispatch();

    const text = annotation.renderInfo.text
    const color = annotation.renderInfo.color as ColorAlias

    const sourceMetadata:SourceMetadata = {
        format: "PDF", 
        sourceInfo: annotation.sourceInfo,
        renderInfo: annotation.renderInfo,
        storedLocation :"online",
        objectID: annotation.objectID
    }

    const navigate = () =>{

        dispatch(setPDF())
        console.log("annotation: ", annotation)
        navigateToSource(sourceMetadata, {
            name: "hoh", 
            key: "bob" ,
        })
    }
    const document = props.getReadingDocument ? props.getReadingDocument(annotation.objectID) : null

    console.log("highlighted text: ", props.highlightedText)

    const computeText = () =>{
        if(props.highlightedText) return props.highlightedText
        else if (text) return text
        else{
            if(annotation.annoType==="sticky") return <i>Empty sticky note</i>
            else if(annotation.annoType==="link") return <i>{`Link on page ${annotation.sourceInfo.pageIndex +1} `}</i>
            else if(annotation.annoType==="rectangle") return <i>{`Rectangle on page ${annotation.sourceInfo.pageIndex +1} `}</i>
            else if (annotation.annoType==="clipping") return <i>{`Clipping on page ${annotation.sourceInfo.pageIndex +1} `}</i>
        }
         
    }

    return(
        <div className="AnnotationBox" style={{borderLeft:`10px solid ${color?ColorMap[color]:"#e0e7ff"} `}} onClick={navigate}>

            {/* <div className='reading'>
                <span className='reading-title'> 
                    {document?
                        document.name:
                        null
                    }
                </span>
                <span className='reading-page'>
                    Page {annotation.sourceInfo.pageIndex  + 1}
                </span>

            </div> */}
            <div className='text'>
                <img className="annotation-icon" src={props.getAnnotationIcon(annotation.annoType as PDFAnnotationType)}>
                </img>
                <div className={`textContent ${props.highlightedText?"grey-out":""}`}>
                    {computeText()}
                </div>
                
            </div>

        </div>
    )
}
export default AnnotationBoxItem;