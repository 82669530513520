import React, {useReducer, useState} from 'react';
import Button from '../../ui/buttons/Button/Button';
import AuthButton from '../../ui/buttons/AuthButton/AuthButton'
/**
 * We could use render props method or cloneElement method. Going ahead with renderProps method
*/
import TitledInput from '../../ui/inputs/TitledInput/TitledInput';
import './Form.css'

const Form = ({setFormState, submit, onSuccess, onError, children, initFormState, ...props}) => {
    /**
     * @props 
     * children: The components to be rendered
     * submit: () =>{} (The function to be run on submitting the form)
     * setFormState: ()=>{} (The useState function in the parent component of the Form)
     * initFormState?: formState (The initial state of the form)
     */

    const [loading, setLoading] = useState(false)

    let elements = React.Children.toArray(children)

    var fields = []

    for (var element of elements){
        if(element.type==TitledInput) fields.push(element.props.id)
    }


    const init = {
        value: "",
        error: undefined,
    }

    var initialFormState = {}
    for (var field of fields){
        if(initFormState && initFormState[field]) initialFormState[field] = {...initFormState[field]}
        else initialFormState[field] = {...init}
    }

    if(initFormState?.success) initialFormState.success = initFormState.success
    if(initFormState?.error) initialFormState.error = initFormState.error


    const formStateReducer = (formState, action) =>{

        var formStateCopy = {...formState}

        if(action.type==="error" && !action.field){
            // console.log("old form: ", formStateCopy, action)

            formStateCopy.error = action.payload
            // console.log("new form: ", formStateCopy)
            return formStateCopy

        }


        formStateCopy[action.field][action.type] = action.payload

        setFormState(formStateCopy)

        return formStateCopy

    }

    const [formState, dispatchFormChange] = useReducer(formStateReducer, initialFormState)

    const onChange = (e) =>{

        dispatchFormChange({
            type: "value",
            field: e.target.id,
            payload: e.target.value
        })

        dispatchFormChange({
            type: "error",
            field: e.target.id,
            payload: undefined
        })

        dispatchFormChange({
            type: "error",
            payload: undefined
        })

    }

    const onSubmit = () =>{

        // console.log("onSubmit: ", submit)
        setLoading(true)

        submit(formState)
        .then((res) =>{
            onSuccess(res)
        })
        .catch((errors) =>{
            console.log("ERRORS: ", errors)

            errors.map(error=>{
                dispatchFormChange({
                    type: "error",
                    field: error.field,
                    payload: error.message || true
                })
            })

            if(onError) onError(errors)

        })
        .finally(() =>{
            setLoading(false)
        })
        
    }


    var transformedChildren = elements.map(element=>{
        //Too many renders here 
        //TO OPTIMIZE

        if(element.type==TitledInput){
            //Transform Inputs
            // console.log("Here is a titled input: ", element)

            return React.cloneElement(element, {
                onChange: onChange,
                value: formState[element.props.id].value,
                error: formState[element.props.id].error,
                onEnter: onSubmit
            })
        }
        else if (element.type==Button || element.type==AuthButton){
            //Transform Buttons
            // console.log("Here is a button: ", element)
            if(element.props.submit){
                return React.cloneElement(element, {
                    onClick: onSubmit,
                    loading: loading
                })
            }
            else return element
        }
        else{
            // console.log("unknown element: ", element, element.type, AuthButton)

            return element
        }
        
    })

    return (
        <form autoComplete='on'>
            <p className='gen-form-caption gen-form-error'>{formState.error}</p>
            <p className='gen-form-caption gen-form-success'>{formState.success}</p>
            {transformedChildren}
        </form>
        
    );
}
 
export default Form;