/* eslint-disable react-hooks/rules-of-hooks */
import "remirror/styles/all.css";
import "./NotebookEditor.css";
import React, {
    useCallback,
    forwardRef,
    useImperativeHandle,
    useContext,
    useState,
    useRef,
} from "react";
import {
    BoldExtension,
    BulletListExtension,
    ItalicExtension,
    HardBreakExtension,
    TextColorExtension,
    HeadingExtension,
    LinkExtension,
    OrderedListExtension,
    TaskListExtension,
    SearchExtension,
    StrikeExtension,
    UnderlineExtension,
    MarkdownExtension,
    NodeFormattingExtension,
    ImageExtension,
    DropCursorExtension,
} from "remirror/extensions";
// import { ImageExtension } from './Extensions/imageExtension';
import {
    EditorComponent,
    Remirror,
    useActive,
    useChainedCommands,
    useRemirror,
    useCommands,
    ThemeProvider,
    useHelpers,
    useKeymap,
    useEditorEvent,
    FloatingWrapper,
    ReactComponentExtension,
} from "@remirror/react";
import { UpdatedImageExtension } from "./Extensions/Image/UpdatedImageExtension";
import { ExtractMarkExtension } from "./Extensions/ExtractMarkExtension";
import { InternalLinkExtension } from "./Extensions/InternalLink";
import { InlineLaTeX } from "./Extensions/LaTeX/InlineLaTeXExtension";
import { UpdatedTextColorExtension } from "./Extensions/UpdatedTextColorExtension";
import { BlockLaTeX } from "./Extensions/LaTeX/BlockLaTeXExtension";
import {
    fetchSourceRenders,
    fetchSourceRendersFromJSON,
    cleanJSON,
} from "./NotebookUtils";
import { SourceRenderExtension } from "./Extensions/SourceRenderExtension";
import { NotebookToolbar } from "./Toolbar/NotebookToolbar";
import { getSourceInfo } from "./NotebookUtils";
import navigate_light from "../../../../../assets/legacy_icons/menu_icons/navigate_light.svg";
import navigate_dark from "../../../../../assets/legacy_icons/menu_icons/navigate_dark.svg";
// import { compressStateJSON, uncompressStateJSON } from "prosemirror-compress";
import { isEqual } from "lodash";
import { ColorMap } from "../../../../../utils/legacy_utils/Colors";
import { trackEvent } from "../../../../../utils/legacy_utils/analytics";
import { CMenuContext } from "../../../../../contexts";
import { navigateToSource, initiateScreenshot , getLocationFromClick} from "../../../desk-logic/sourceFunctions";
import { useAppDispatch, useAppSelector } from "../../../../../redux";
import { inactivateReaderToolbar, setActiveTool } from "../../../reader/components/ReaderToolbar/readerToolbarSlice";
const getOperatingSystem= () => {
    if (window.navigator.userAgent.indexOf('Win') !== -1) return'Windows'; 
    if (window.navigator.userAgent.indexOf('Mac') !== -1) return 'MacOS'; 
    if (window.navigator.userAgent.indexOf('Linux') !== -1) return'Linux OS';       
    if (window.navigator.userAgent.indexOf('X11') !== -1) return'UNIX OS'; 
    return null
}

const contextMenuCreator = (cMenuContext, lastClicked) => {
    return (event, props) => {
        const { view } = props;
        const { pos, inside } = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
        });
        const doc = view.state.doc;
        const nodeAt = doc.nodeAt(pos);
        console.log(nodeAt)
        if (nodeAt) {
            if (nodeAt.marks.find((x)=>
                x.type.name === "link"
            )){
                let mark = nodeAt.marks.find((x)=>
                x.type.name === "link"
                )
                const link = mark.attrs.href
                setTimeout(
                    () =>
                        cMenuContext.showContextMenu({
                            show: true,
                            menuArray: [
                                {
                                    icon:
                                        // sourceContext.getAppTheme() === "light"
                                        //     ? navigate_light
                                        //     : navigate_dark,
                                        navigate_light,
                                    label:
                                        "Navigate to Link",
                                    handler: () =>
                                        {
                                            // if (sourceContext.environment === "electron"){
                                            //     window.electronAPI.openExternal(link)
                                            // }
                                            // else{
                                            //     window.open(link, '_blank')
                                            // }
                                            window.open(link, '_blank')
                                        }
                                        
                                },
                            ],
                            X: event.clientX,
                            Y: event.clientY
                        }
                            
                        ),
                    0
                );
                event.preventDefault();
                
            }
            else if (
                nodeAt.marks.find(
                    (x) =>
                        x.type.name === "textextract" ||
                        x.type.name === "internallink"
                )
            ) {
                let mark = nodeAt.marks.find(
                    (x) =>
                        x.type.name === "textextract" ||
                        x.type.name === "internallink"
                );
                // console.log("EXTRACT CONTEXT", mark);
                if (mark.attrs.metadata.id === lastClicked.current) {
                    lastClicked.current = null;
                    return;
                }
                lastClicked.current = mark.attrs.metadata.id;
                setTimeout(
                    () =>
                        cMenuContext.showContextMenu(
                            {
                                show: true,
                            menuArray: [
                                {
                                    icon:
                                        // sourceContext.getAppTheme() === "light"
                                        //     ? navigate_light
                                        //     : navigate_dark,
                                        navigate_light,
                                    label:
                                        "Navigate to " +
                                        getSourceInfo(
                                            mark.attrs.metadata.sourceMetadata
                                        ),
                                    handler: () =>
                                        navigateToSource(
                                            mark.attrs.metadata.sourceMetadata,
                                            {
                                                name:
                                                    mark.type.name ===
                                                    "internallink"
                                                        ? "links"
                                                        : mark.type.name,
                                                key: mark.attrs.metadata.id,
                                            }
                                        ),
                                },
                            ],
                            X: event.clientX,
                            Y: event.clientY
                        }
                        ),
                    0
                );
                event.preventDefault();
            }
        }
    }
}   



const ToolbarWrapper = (props) => {
    const dispatch = useAppDispatch()

    const commands = useChainedCommands();
    const active = useActive();
    const helpers = useHelpers();
    const list = props.items;
    const nonChain = useCommands();

    let items = {
        internallink: {
            label: "internallink",
            active: () => active.internallink(),
            handler: () =>{
                if (helpers.isSelectionEmpty()){
                    // Alternate: Link the last word. 
                    // sourceContext.setPopupWarning("Please select text in the notebook to make a link!")
                }
                else{
                    dispatch(setActiveTool({tool: "link"}))
                    getLocationFromClick((data) => {
                        dispatch(inactivateReaderToolbar())
                        commands
                            .createInternalLink({
                                metadata: {
                                    sourceMetadata: {
                                        ...data,
                                    },
                                },
                            })
                            .focus()
                            .run();
                    })
                    // trackEvent(this.context.user ?? null, {
                    //     category:"NotebookLinkAdded",
                    //     action: this.context.user?.sub ?? null,
                    //     label: this.state.notebookMetadata.id,
                    //     docID: this.context.initInfo.value,
                    // })
                }
            },
            hoverTip: {
                text: "Internal Link",
            },
        },
        bold: {
            label: "bold",
            active: () => active.bold(),
            handler: () => commands.toggleBold().focus().run(),
            hoverTip: {
                text: "Bold",
                shortcut: "CmdOrCtrl+B",
            },
        },
        italic: {
            label: "italic",
            active: () => active.italic(),
            handler: () => commands.toggleItalic().focus().run(),
            hoverTip: {
                text: "Italic",
                shortcut: "CmdOrCtrl+I",
            },
        },
        underline: {
            label: "underline",
            active: () => active.underline(),
            handler: () => commands.toggleUnderline().focus().run(),
            hoverTip: {
                text: "Underline",
                shortcut: "CmdOrCtrl+U",
            },
        },
        strikethrough: {
            label: "strikethrough",
            active: () => active.strike(),
            handler: () => commands.toggleStrike().focus().run(),
            hoverTip: {
                text: "Strikethrough",
                shortcut: "CmdOrCtrl+D"
            },
        },

        color: {
            label: "color",
            active: () => {
                for (let color in ColorMap) {
                    if (active.textColor({ color: color })) {
                        return color;
                    }
                }
                return "default";
            },
            handler: (color) => {
                let activeColor = "default";
                for (let color in ColorMap) {
                    if (active.textColor({ color: color })) {
                        activeColor = color;
                    }
                }
                if (color == activeColor) {
                    commands.setTextColor(null).focus().run();
                } else {
                    commands.setTextColor(color).focus().run();
                }
            },
            hoverTip: {
                text: "Change Text Color",
            },
        },
        heading: {
            label: "heading",
            active: () => {
                let activeHeading = "default";
                [1, 2, 3, 4, 5].forEach((level) => {
                    if (active.heading({ level: level })) {
                        activeHeading = level;
                    }
                });
                return activeHeading;
            },
            handler: (level) => {
                if (level !== "default") {
                    commands.toggleHeading({ level }).focus().run();
                } else {
                    let activeHeading = "default";
                    [1, 2, 3, 4, 5].forEach((level) => {
                        if (active.heading({ level: level })) {
                            activeHeading = level;
                        }
                    });
                    if (activeHeading == "default") return;
                    commands
                        .toggleHeading({ level: activeHeading })
                        .focus()
                        .run();
                }
            },
        },
        alignment: {
            label: "alignment",
            active: () => {
                let activeHeading = "left";

                if (nonChain.centerAlign.active?.()) {
                    activeHeading = "center";
                } else if (nonChain.rightAlign.active?.()) {
                    activeHeading = "right";
                }

                return activeHeading;
            },
            handler: (align) => {
                commands.setTextAlignment(align).focus().run();
            },
        },
        list: {
            label: "list",
            active: () => false,
            handler: (type) => {},
        },
        latex: {
            label: "latex",
            active: () => false,
            handler: (type) => {
                if (type == "block") {
                    commands.insertBlockLatex().run();
                    trackEvent(this.context.user ?? null, {
                        category:"BlockLatexAdded",
                        action: this.context.user?.sub ?? null,
                        label: this.state.notebookMetadata.id,
                        docID: this.context.initInfo.value,
                    })
                } else {
                    commands.insertInlineLatex().run();
                    trackEvent(this.context.user ?? null, {
                        category:"InlineLatexAdded",
                        action: this.context.user?.sub ?? null,
                        label: this.state.notebookMetadata.id,
                        docID: this.context.initInfo.value,
                    })
                }
            },
        },
        image: {
            label: "image",
            active: () => false,
            handler: (type) => {
                if (type == "clipping") {
                    initiateScreenshot();
                } else {
                    return (url) => {
                        props.imageStore.saveAndInsertImage(
                            { url: url, sourceMetadata: null },
                            (attributes, selection) =>
                                commands
                                    .insertImage(attributes, selection)
                                    .focus()
                                    .run()
                        );
                    };
                }
            },
        },
        orderedList: {
            label: "orderedList",
            active: () => false,
            handler: () => {
                commands.toggleOrderedList().focus().run();
            },
            hoverTip: {
                text: "Numbered List",
            },
        },
        bulletList: {
            label: "bulletList",
            active: () => false,
            handler: () => {
                commands.toggleBulletList().focus().run();
            },
            hoverTip: {
                text: "Bullet List",
            },
        },
        taskList: {
            label: "taskList",
            active: () => false,
            handler: () => {
                commands.toggleTaskList().focus().run();
            },
            hoverTip: {
                text: "Task List",
            },
        }
    };

    return (
        <NotebookToolbar
            theme={props.theme}
            inline={props.inline}
            items={list.map((entry) => items[entry])}
            pdfReaderWidth={props.pdfReaderWidth}
        ></NotebookToolbar>
    );
};

const NotebookEditor = forwardRef((props, ref) => {
    const cMenuContext = useContext(CMenuContext)
    const { manager, state, setState, getContext } = useRemirror({
        extensions: () => [
            new BoldExtension(),
            new ItalicExtension(),
            new UnderlineExtension(),
            new StrikeExtension(),
            new InlineLaTeX(),
            new BlockLaTeX(),
            new BulletListExtension({ enableSpine: true }),
            new OrderedListExtension({ enableSpine: true }),
            new TaskListExtension({ enableSpine: true }),
            new HeadingExtension(),
            new LinkExtension(),
            new MarkdownExtension(),
            new UpdatedImageExtension({
                imageStore: props.imageStore,
                showContextMenu: cMenuContext.showContextMenu,
            }),
            new DropCursorExtension({color: `var(--dropcursor-color, black)`}),
            new NodeFormattingExtension(),
            new ExtractMarkExtension(),
            new InternalLinkExtension(),
            new UpdatedTextColorExtension({
                // getTheme: sourceContext.getAppTheme,
            }),
            new SourceRenderExtension({
                renderOnSource: props.renderOnSource,
                unrenderOnSource: props.unrenderOnSource,
                notebookMetadata: props.notebookMetadata,
                imageStore: props.imageStore,
            }),
        ],
        // selection: "begin",
        content: (props.initialContent),
        stringHandler: "html",
        onError: useCallback(
            (params) => {
                
                const { json, invalidContent, transformers } = params
            // Automatically remove all invalid nodes and marks.
            console.log(
                "REMIRROR CONTENT ERROR",
                params
            );
            let attempt = transformers.remove(json, invalidContent);
            if (isEqual(attempt, json)) {
                return {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            attrs: {
                                nodeIndent: null,
                                nodeLineHeight: null,
                                nodeTextAlignment: null,
                                style: "",
                            },
                            content: [
                                {
                                    type: "text",
                                    text: "There seems to be some issue with this notebook - please contact us at developers.desklamp.io to have this problem rectified.",
                                },
                            ],
                        },
                    ],
                };
            }
        }, []),
        // builtin: { persistentSelectionClass: "selection" },
    });
    // console.log("SCHEMA SPEC",JSON.stringify(manager.schema.spec))
    // const { getJSON } = useHelpers();
    const [showInlineMenu, setShowInlineMenu] = useState(false);
    const [inlineTop, setInlineTop] = useState(0);
    const [inlineLeft, setInlineLeft] = useState(0);
    const inlineMenuRef = useRef();
    const selected = useRef(false);
    const lastClicked = useRef(null);
    const hooks = [
        () => {
            const { getJSON } = useHelpers();

            const handleSaveShortcut = useCallback(
                ({ state }) => {
                    let json = cleanJSON(getJSON(state));

                    console.log(`${JSON.stringify(getJSON(state))}`);

                    fetchSourceRendersFromJSON(getJSON(state));

                    return true; // Prevents any further key handlers from being run.
                },
                [getJSON]
            );

            // "Mod" means platform agnostic modifier key - i.e. Ctrl on Windows, or Cmd on MacOS
            useKeymap("Mod-;", handleSaveShortcut);
        },
        () => {
            useEditorEvent("focus", () => {
                props.setNotebookFocus(true);
            });
        },
        () => {
            useEditorEvent("blur", () => {
                props.setNotebookFocus(false);
            });
        },
        () => {
            useEditorEvent(
                "click",
                contextMenuCreator(cMenuContext, lastClicked)
                // ()=>{}
            );
        },
        () => {
            useEditorEvent("mouseup", (event) => {
                console.log(event.target)
                if(event.target.className.includes('remirror-collapsible-list-item-button')){
                    return
                }
                console.log("Triggered mouse up", selected.current);
                if (selected.current) {
                    if (event.clientX + 330 > window.innerWidth) {
                        setInlineLeft(window.innerWidth - 270);
                    } else {
                        setInlineLeft(event.clientX + 5);
                    }

                    setInlineTop(event.clientY + 10);
                    setShowInlineMenu(true);
                    selected.current = false;
                }
            });
        },
        () => {
            useEditorEvent("mousedown", (event) => {
                setShowInlineMenu(false);
            });
        },
        ()=>{
            useEditorEvent("keydown", (event)=>{
                if (event.key === " "){
                    // trackEvent(sourceContext.user ?? null, {
                    //     category:"NotebookWordTyped",
                    //     action: sourceContext.user?.sub ?? null,
                    //     label: props.notebookMetadata.id,
                    //     docID: sourceContext.initInfo.value,
                    // })
                }
                
            } )
        }
    ];
    useImperativeHandle(ref, () => getContext(), [getContext]);

    return (
        <div
            style={{
                boxSizing: "border-box",
                position: "relative",
                textAlign: "left",
                height: "100%",
                maxWidth: "1000px",
                width: "100%",
                margin: "0 auto",
            }}
            className={props.theme}
        >
            {/* the className is used to define css variables necessary for the editor */}
            <ThemeProvider>
                <Remirror
                    manager={manager}
                    initialContent={state}
                    hooks={hooks}
                    editable={props.editable}
                    onChange={(parameter) => {
                        if (
                            parameter.tr?.docChanged ||
                            parameter.tr?.selectionSet
                        ) {
                            cMenuContext.showContextMenu(false);
                        }
                        if(parameter.tr?.selectionSet){
                            console.log("Selection changed!",parameter.tr?.selection?.empty)
                            if (parameter.tr?.selection?.empty || parameter.tr?.selection.node?.type.name == "math-inline" || parameter.tr?.selection.node?.type.name == "math-display" ) {
                                selected.current = false;
                                setShowInlineMenu(false)
                            } else {
                                selected.current = parameter.tr?.selection;
                                console.log("Displaying menu", selected.current);
                            }
                        }
                        if (parameter.tr?.docChanged) {
                            props.updateNotebook(
                                
                                    cleanJSON(
                                        JSON.parse(
                                            JSON.stringify(
                                                parameter.tr.doc.toJSON()
                                            )
                                        )
                                    )
                                
                            );
                            // THE JSON UPDATES AFFECTED THE ACTUAL STATE!!!! Stringify and parse are extremely important.
                        }
                        
                        setState(parameter.state);
                    }}
                >
                    {props.editable ? (
                        <div className="nb-main-toolbar">
                            <ToolbarWrapper
                                theme={props.theme}
                                // inline = {true}
                                imageStore={props.imageStore}
                                items={[
                                    "heading",
                                    "color",
                                    // "bold",
                                    // "italic",
                                    // "underline",
                                    // "strikethrough",
                                    "internallink",
                                    "image",
                                    "latex",
                                    "alignment",
                                    "bulletList",
                                    "orderedList",
                                    "taskList"
                                ]}
                                pdfReaderWidth={props.pdfReaderWidth}
                            ></ToolbarWrapper>
                            {/* <TempToolbar
                            imageStore={props.imageStore}
                            ></TempToolbar> */}
                        </div>
                    ) : null}

                    <div
                        className={
                            "notebook-container light show-scroll" 
                            // + sourceContext.getAppTheme()
                        }
                        // style = {{height: `calc(100vh - ${((sourceContext.environment === "electron" ? (getOperatingSystem() === "Windows"?200:170): 140) + (sourceContext.user?0:30) )}px)`}}
                        // style = {{height: `90%`}}
                        spellCheck = "false"
                        onScroll={
                            ()=>{
                                if(showInlineMenu) setShowInlineMenu(false)
                                cMenuContext.showContextMenu(false)
                            }
                        }
                    >
                        <EditorComponent></EditorComponent>
                    </div>
                    {props.editable && showInlineMenu ? (
                        <div
                            ref={inlineMenuRef}
                            style={{
                                position: "fixed",
                                top: inlineTop,
                                left: inlineLeft,
                            }}
                        >
                            <ToolbarWrapper
                                theme={props.theme}
                                inline={true}
                                items={[
                                    "internallink",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "color",
                                    "heading",
                                ]}
                            ></ToolbarWrapper>
                        </div>
                    ) : null}
                </Remirror>
            </ThemeProvider>
        </div>
    );
});
export default NotebookEditor;
