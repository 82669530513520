import thunder from '../../assets/icons/thunderbolt.svg';

import './FrillWidget.css';

import React, { PureComponent } from 'react'

let frillClicked = new CustomEvent("frillClicked")


export class FrillWidget extends PureComponent {

    constructor(props) {
        super(props)
        
        this.state = {
            widgetLoaded: false,
            ssoToken: ''
        }

        this.checkIfIframeLoadedCounter = 0
        

    }

    
    // We need to keep a reference to the widget instance so we can cleanup
    // when the component unmounts
    // widget = null;

    // config = {
    //     key: '57cdd1fa-8500-4990-9e59-3489cf9708e6', // <-- Add Widget key here
    //     ssoToken: "",
    //     callbacks: {
    //         // This will be called once the widget is ready. We have to use this incase the
    //         // Frill api is not available on the window yet
    //         onReady: this.onWidgetReady,
    //     },
    // };

    checkIfIframeLoaded = () =>{
        /**
         * This code is not working. 
         */

        this.checkIfIframeLoadedCounter+=1

        var whiteLabel = document.getElementsByClassName("text-frill")[0]     
        console.log("checking: ", whiteLabel)   
        
        if(whiteLabel){
            whiteLabel.parentElement.innerHTML
                = `Email us at <a href="mailto:support@desklamp.io" className="text-frill">support@desklamp.io</a>`
        }
        else{
            if(this.checkIfIframeLoadedCounter <=100) window.setTimeout(this.checkIfIframeLoaded, 500);
            else return
        }
    }


    async componentDidMount(){
        // Let's check if the Frill script has already loaded
        console.log("frill Widget componentDidMount" )

        // window.setTimeout(this.checkIfIframeLoaded, 3000);

        const frillUserToken = await this.props.getFrillUserToken()
        if(!frillUserToken) return
        
        this.setState({
            widgetLoaded: true,
            ssoToken: frillUserToken,
            frillClicked: false
        }
        )
    }

    // When the component unmounts we need to do some cleanup
    componentWillUnmount() {
        // Check if there is an active widget
        // if (this.widget) {
        //     // If there is a widget, destroy it, this will remove all event listeners and nodes added
        //     // to the DOM by the widget
        //     this.widget.destroy();
        //     this.widget = null;
        // }
        // // We also need to remove our config from the list so it doesn't get initialised.
        // // This would only happen if the had component mounted/unmounted before the Frill api
        // // had a chance to load.
        // if (window.Frill_Config) {
        //     window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
        // }
    }

    
    
    render() {
        var icon = null
        

        return (
            <>
            {
                this.state.widgetLoaded?
                (!this.props.frillClicked? 
                <img className={` Frill feedback-icon feedback-widget ${this.state.widgetLoaded?"loaded":"loading"}`} alt="feedback" 
                    //keep the style here in inline css and not in external file!
                    style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: this.props.appTheme==="light"?"#D8D5FA":"#4e4e58",
                        display: "block"
                    }}
                    src={this.state.widgetLoaded?icon:null}
                    // data-frill-widget={this.config.key} data-frill-sso={this.config.ssoToken}
                    onClick={(e)=>{
                        // let frillEvent = new CustomEvent("frillClicked", {ssoToken: this.state.ssoToken})
                        this.props.setFrillClicked(true)
                    }}
                >
                </img>:
                <img className={` Frill feedback-icon feedback-widget ${this.state.widgetLoaded?"loaded":"loading"}`} alt="feedback" 
                //keep the style here in inline css and not in external file!
                style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: this.props.appTheme==="light"?"#D8D5FA":"#4e4e58",
                    display: "block"
                }}
                src={this.state.widgetLoaded?thunder:null}
                // data-frill-widget={this.config.key} data-frill-sso={this.config.ssoToken}
                onClick={(e)=>{
                    // let frillEvent = new CustomEvent("frillClicked", {ssoToken: this.state.ssoToken})
                    this.props.setFrillClicked(false)
                }}
                >
                </img>
                )
                : null
            }
            
            </>
        )
    }
}

export default FrillWidget