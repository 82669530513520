import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { closeOverlay } from '../../../../../../layouts/Overlay';
import { store, useAppDispatch } from '../../../../../../redux';
import { Device } from '../../../../../../types/devices';
import { Folder, ReadingDocument } from '../../../../../../types/files';
import { Button } from '../../../../../../ui/buttons';
import TitledInput from '../../../../../../ui/inputs/TitledInput/TitledInput';
import { uploadFile } from '../../../../../desk/desk-logic/fileSaver';
import { createFile } from '../../../../features/files/folderDataSlice';
import { addTab } from '../../../../../desk/reader/Reader/readerTabsSlice';
import './UploadPdf.css'
import useOpenReading from '../../../../../desk/reader/components/ReaderTabsBar/useOpenReading';

type UploadPdfProps = {
    currentFolder: Folder, 
};

const UploadPdf:React.FC<UploadPdfProps> = ({currentFolder}) => {
    
    const [pdfUrl, setPdfUrl] = useState("");

    const drop = React.useRef<any>(null);
    const dispatch = useAppDispatch()
    const openReading = useOpenReading()

    const postUploadPDF = (newPDF: ReadingDocument) =>{
        const page = location.pathname.split('/')[1];
        console.log("postUploadPDF: ", page)
        
        if(page==="folders"){
            console.log("upload home")
            dispatch(createFile(newPDF))
        }
        else if (page==="desk"){
            console.log("dispatching addTab!")
            openReading(newPDF)
        }

    }

    React.useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        // return () => {
        //   drop.current.removeEventListener('dragover', handleDragOver);
        //   drop.current.removeEventListener('drop', handleDrop);
        // };
      }, []);
      
      const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
      };
      
      const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const {files} = e.dataTransfer;

        console.log("Files uploaded", files)

        if (files) {
            // const currentFolder = store.getState().folderPath.value.segments.at(-1)
            uploadFile(files[0], currentFolder?.objectID as string, postUploadPDF)
        }
      };

    const onBrowse = (file: File) => {
        // const currentFolder = store.getState().folderPath.value.segments.at(-1)
        store.dispatch(closeOverlay())
        uploadFile(file, currentFolder?.objectID as string, postUploadPDF)
    }

    const device: Device = useWindowSize();

    return (
        <div className='upload-files-container'>
            
            {/* <div className='link-input-button-container'>
                <div className='link-input-box'>
                    <input className='link-input' type="text" placeholder='Enter the URL of a YouTube video or a PDF'/>
                </div>
                
                <div className='upload-button'>
                    <Button onClick={()=>{}} fill="solid" edges="round">Upload</Button>
                </div>
            </div> */}

            <div className='browse-container' ref={drop}>
                <div className='browse-button'>
                    <label>
                        <div className='browse-button'>
                            <div className='browse-button-text'>Browse</div>
                            
                            <input onChange = {(e)=>{
                                console.log(e)
                                onBrowse(e.target.files![0])
                                }}
                            type="file" accept="application/pdf" style = {{display:"none"}}>

                            </input>
                        </div>
                    </label>
                </div>

                <div className='browse-text'>Drag a file here to upload or click on the browse button to open file explorer</div>
            </div>
        </div>
    );
}
export default UploadPdf;