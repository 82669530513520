import React from 'react';
import {useState} from 'react';
import right_arrow from '../../assets/icons/right_arrow.svg';

type SectionProps = {
    header: string,
    icon: string,
    contents: React.ReactNode
};

const Section:React.FC<SectionProps> = ({header,icon,contents}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);

    if(!contents) return null

    return(
        <div className='section'>
            
            {/*Section Title*/}
            <div className='titlePart'>
                {/* <img className='sectionIcon' src={icon}/> */}
                <img className='sectionIcon' src={icon}/>
                <div className='header'>{header}</div>
                {
                    false?
                    <img className={isCollapsed ? "rightArrow" : "downArrow"} src={right_arrow} onClick = {() => {setIsCollapsed(!isCollapsed)}}></img>
                    :null
                }
            </div>
            
            {/*Section Content */}
            <div className='sectionContent' style={{display: isCollapsed ? 'none' : 'flex'}}>
                {contents}
            </div>
        </div>
    );
}
export default Section;