import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import React, {useState, useEffect, ReactEventHandler} from 'react';
import { useEffectOnce } from '../../../../../hooks/useEffectOnce';
import { useAppSelector } from '../../../../../redux';
import TopbarDropper from '../../../components/DeskTopbar/TopbarDropper/TopbarDropper';

import './PageNavigator.css'

type PageNavigatorProps = {
    viewer: PDFViewer,
    title?:string
};

const PageNavigator:React.FC<PageNavigatorProps> = (props) => {

    const activeTab = useAppSelector(state=>state.readerTabs.activeTab)
    
    const readerManager = activeTab?.manager


    const initPageOffset = activeTab?.readingDocument.customSetup?.pageOffset || 0

    const [pdfPage, _setPdfPage] = useState<number>(props.viewer._currentPageNumber)
    const [pageOffset, _setPageOffset] = useState<number>(initPageOffset)
    const [bookPage, _setBookPage] = useState<number | null>(null) //used only for typing

    const [typingBookPage, setTypingBookPage] = useState<number>(0)
    const [bookPageInputValue, setBookPageInputValue] = useState<number>(props.viewer._currentPageNumber - initPageOffset)


    const {viewer, title} = props

    const totPages = viewer.pdfDocument?._pdfInfo.numPages


    useEffectOnce(() =>{

        console.log("PageNavigator viewer: ", )
        
        viewer.eventBus.on("pagechanging", (data:any) =>{
            console.log("pageChanging!")
            setPageNumber(data.pageNumber)
        })
    })

    const setBookPage = (visiblePageNumber: number) =>{

        const offset = pdfPage - visiblePageNumber
        console.log("offset: ", offset)
        _setPageOffset(offset)

        //TODO: Backend set page offset here. 
    }

    const setPageNumber = (newPageNumber:number) =>{
        console.log("newPageNumber: ", newPageNumber, newPageNumber - pageOffset)
        console.log("pageOffset: ", pageOffset)

        _setPdfPage(newPageNumber)
        _setBookPage(null)

    }

    const navigateToPage = (pageNum: number) =>{
        readerManager.setReaderConfiguration({page:pageNum})
    }

    const reset = () =>{
        console.log("reset: ", viewer.currentPageNumber)
        setPageNumber(viewer.currentPageNumber)
        _setBookPage(null)

    }


    //------PDF PAGE --------------

    const onChangePdfPage = (e: React.ChangeEvent<HTMLInputElement>) =>{

        _setPdfPage(parseInt(e.target.value))

    }

    const onChangeBookPage = (e: React.ChangeEvent<HTMLInputElement>) =>{

        _setBookPage(parseInt(e.target.value))

    }



    const onEnterPdfPage = (event: React.KeyboardEvent<HTMLInputElement>) =>{
        console.log("Key: ", event.key)
        if(event.key === "Enter"){
            
            if(pdfPage<=totPages && pdfPage>0){
                readerManager.setReaderConfiguration({page: pdfPage});
            }
            
        else{
            reset()
        }

        }
    
    }

    const onEnterBookPage = (e: React.KeyboardEvent<HTMLInputElement>) =>{

        if(!bookPage) return
        const calcPdfPage = bookPage + pageOffset

        if(e.key === "Enter"){  
            
            if(calcPdfPage<=totPages && calcPdfPage>0){
                readerManager.setReaderConfiguration({page: calcPdfPage});
            }
            
        else{
            reset()
        }
        _setBookPage(null)

        }
    
    }


    //------BOOK PAGE --------------

    
    
    return (
        <div className="PageNavigator">

            <div className='PageNavigator-inputs'>
            {
                pageOffset?
                    <span className="bookpage">
                        <input onChange={onChangeBookPage} onKeyDown={onEnterBookPage} className="PageNavigator-input" value={bookPage?bookPage:pdfPage - pageOffset} ></input>
                    </span>
                :null
            }

            <input className="PageNavigator-input" type="number" value={pdfPage} onChange={onChangePdfPage} onKeyDown={onEnterPdfPage}></input>


            </div>

            <TopbarDropper dropdown = {<PageNavigatorDropdown  setBookPage={setBookPage} bookPage={Math.max(pdfPage - pageOffset, 0)} />}>
                of {totPages}
            </TopbarDropper>

        </div>
    
    )
}

type PageNavigatorDropdownProps = {
    bookPage: number, 
    setBookPage: (bookPage: number) => void
}

const PageNavigatorDropdown: React.FC<PageNavigatorDropdownProps> = (props) =>{

    const [bookPageInputValue, setBookPageInputValue] = useState<number | undefined>()

    const onSubmit = (bookPage: number) => {
        props.setBookPage(bookPage)
    }
    
    const onChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
        
        setBookPageInputValue(parseInt(e.target.value))
    }

    const onKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) =>{
        if(e.key!=="Enter") return
        else if(bookPageInputValue){
            onSubmit(bookPageInputValue)
        }

    }

    return(
        <div className="PageNavigatorDropdown">
             <b>Match Page Numbers</b>

             <div className='PageNavigator-txt'>
                Which page occupies most of the viewer right now?
             </div>

             <input type="number" className="ZoomDropdown-input" onChange={onChange} value={bookPageInputValue} onKeyDown={onKeyDown}/>

        </div>
    )

}

export default React.memo(PageNavigator);