import './Menu.css'

import React, {useState} from 'react';
import { DisplayMenuItem } from './Menu';
import ToggleSwitch from '../../buttons/ToggleSwitch/ToggleSwitch';
import sun from '../../../assets/icons/sun.svg'
import moon from '../../../assets/icons/moon.svg'

type MenuElementProps = {

    MenuItem: DisplayMenuItem,
    closeDisplayMenu: (() => void) | undefined,
    
};

const MenuElement:React.FC<MenuElementProps> = ({MenuItem, closeDisplayMenu}) => {

    const [toggleActive, setToggleActive] = useState<boolean>();

    const toggleState = () => {
        //reason for try catch is that checkDisable is an optional prop
        console.log('toggleState called')
        // if(this.props.disable) return
        setToggleActive(!toggleActive)
        MenuItem.toggleStates?.left.handler();
    }

    const onLeftIconClick = () => {
        MenuItem.toggleStates?.left.handler();
    }

    const onRightIconClick = () => {
        MenuItem.toggleStates?.right.handler();
    }
    
    return(
        <div className={`dropdownElement ${MenuItem.type}`} onClick={(e)=>{
            if(MenuItem.handler && MenuItem.type !== "file") MenuItem.handler(e)
            if (closeDisplayMenu) closeDisplayMenu()
        }}>

            {/* label and icon */}
            <div className='clickable' >
                <img src={MenuItem.icon} className="Menu-icon"/>
                <div className='label'>{MenuItem.label}</div>
            </div>
            
            {
                //Toggle Element
                (MenuItem.type=="toggle") ?
                <span className='MenuElement-ToggleSwitch'>
                    <ToggleSwitch 
                        toggleFunctions={{"left": MenuItem.toggleStates?.left.handler, "right":MenuItem.toggleStates?.right.handler }}
                        initialState={MenuItem.toggleStates?.active || false}
                        activeIcon = {sun}
                        inactiveIcon = {moon}
                    />
                </span>
                    

                :null
            }
        </div>
    )
}
export default MenuElement;