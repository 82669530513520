import React from 'react';

import './Tooltip.css'

type TooltipProps = {
    children: React.ReactNode
};

const Tooltip:React.FC<TooltipProps> = ({children}) => {
    
    return (
        <div className='Tooltip'>
            <div className='triangle'></div>
            {children}
        </div>
    )
}
export default Tooltip;