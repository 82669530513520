import { ReactNode, useRef, useState } from "react";
import { ColorMap } from "../../../../../../../utils/Colors";
import { PDFStickyNoteAnnotation } from "../../PdfManager";
import './PdfLink.css'
import dots from "../../../../../../../assets/legacy_icons/three_dots.png"
import {
    PdfMovableAnnotationComponent,
    PdfMovableAnnotationComponentProps,
    PdfMovableAnnotationProps,
} from "./PdfMovableAnnotation";

export const PdfLink = (props: (PdfMovableAnnotationProps & {glow:boolean})) => {
    const clicked = useRef(0)
    const annotation = props.annotation as PDFStickyNoteAnnotation
    const [actualText, setActualText] = useState(annotation.renderInfo.text)
    const finalProps: PdfMovableAnnotationComponentProps = {
        ...props,
        rndStyle: {boxShadow: "none"},
        disableResizing: true,
        children: (
            size: { width: number; height: number },
            topLeft: { x: number; y: number }
        ) => {
            let initDim = 15 *  props.scale
            return(
                // Key idea: ignore size!!

                props.glow?<div style = {{left: 0, top:0, height: initDim, width: initDim, borderRadius: initDim * 0.5}} className='pulsating'  ></div>:

                <div style = {
                    {left: 0, 
                    top:0,
                }} 
                className="note-stub selectionHandle">
                     <div style = {{width: initDim, height :initDim, borderRadius: initDim * 0.5, cursor : 'pointer'}} className='circle' onClick = {
                        (e) => {
                            setTimeout(()=>props.showContextMenu(e), 0)
                        }} ></div>
                </div>
            )
        },
    };

    return (
        <>
            <PdfMovableAnnotationComponent
                {...finalProps}
            ></PdfMovableAnnotationComponent>
        </>
    );
};
