import { API } from "aws-amplify";
import { EnvironmentContextValue } from "../../../../../../contexts";
import { FolderSection } from "../../../../features/files/folderDataSlice";
import { Folder } from "../../../../../../types/files";
const getSubFolderData = /* GraphQL */ `
    query MyQuery($parentID: String) {
        getFolderData(parentID: $parentID) {
            folders {
                name
                objectID
                parentID
                description
            }
        }
    }
`;

export const getSubFolders = async (
    env: EnvironmentContextValue,
    folderSection: FolderSection
): Promise<FolderSection[]> => {
    // TODO: Any offline stuff
    const resp = (await API.graphql({
        query: getSubFolderData,
        variables: { parentID: folderSection.folder.objectID },
    })) as any;
    const data = resp.data.getFolderData.folders;
	console.log("FOLDERS",data)
    return data.map((x: Folder) => {
        return {
            folder: x,
            collapsed: true,
			children: [],
			loaded: false
        };
    }) as FolderSection[];
};
