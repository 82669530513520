import NavbarLaptop from "./laptop/Navbar.laptop";
import NavbarMobile from "./mobile/Navbar.mobile";

import useWindowSize from "../../../../hooks/useWindowSize";

import React from 'react';

type NavbarProps = {
    
};

const Navbar:React.FC<NavbarProps>= () => {

    const deviceType = useWindowSize()
  
    if(deviceType==="mobile") return <NavbarMobile />
    else return <NavbarLaptop />
}

export default Navbar;