
import { API, Storage, Auth } from "aws-amplify";
import { getDesk } from "../../../graphql/custom";
import {createCloud, createReadingCloud} from "../../../graphql/custom"
import { store } from "../../../redux";
import { createFile } from "../../home/features/files/folderDataSlice";
import awsmobile from "../../../aws-exports";
import { ReadingDocument, WritingDocument } from "../../../types/files";
import { addTab, initialiseTabs } from "../reader/Reader/readerTabsSlice";
import { uploadNotebook } from "./notebookSaver";
import { FetchedNotebookData, fetchedReading, initialisedNotebooks, initialiseNotebookMenuData, setCurrentNotebook } from "../writer/notebooks/fetchedNotebooksSlice";
import { collapseReader } from "../components/Separator/separatorSlice";

export const fetchNotebookFromID = async (notebookData: {objectID: string, storedLocation:string})=>{
    try{
        
        const result = await API.graphql({
            query: getDesk,
            variables:{
                objectIDs: [],
                notebookID: notebookData.objectID
            }
        }) as any
        const data = result.data.getDesk as any
        // console.log("READER",data)
        const notebookManager = store.getState().notebookWorkspaceManager.manager
        if (data.openNotebook){
            data.openNotebook.nbObj = JSON.parse(data.openNotebook.nbObj)
            return data.openNotebook
        }
        else{
            return false
        }
    }
    catch(e){
        console.error("READER", e)
        return false
    }
}
export const fetchReadingFromID = async (objectID: string)=>{
    try{
        
        const result = await API.graphql({
            query: getDesk,
            variables:{
                objectIDs: [objectID],
            }
        }) as any
        const data = result.data.getDesk as any
        // console.log("READER",data)
        if (data.tabs){
            store.dispatch(fetchedReading(data.tabs[0]))
            return data.tabs[0].source
        }
    }
    catch(e){
        console.error("READER", e)
    }
}


export const initialiseFromURL = async (objectIDs: string[], callback: ()=>void, notebookID?: string, )=>{
    if (objectIDs.length === 0 && !notebookID){
        store.dispatch(initialisedNotebooks())
        store.dispatch(initialiseNotebookMenuData({}))
        store.dispatch(initialiseTabs([]))
        return
    }
    if (objectIDs.length === 0){
        store.dispatch(collapseReader())
    }
    try{
        const result = await API.graphql({
            query: getDesk,
            variables: notebookID? {
                objectIDs: objectIDs,
                notebookID: notebookID
            } : {
                objectIDs: objectIDs
            }
        }) as any
        callback()
        const data = result.data.getDesk as any
        // console.log("READER",data)
        const fetchedNotebooks : FetchedNotebookData = {}
        data.tabs.forEach((element : {
            source: ReadingDocument
            itemNotebooks: WritingDocument[]
            globalNotebooks: WritingDocument[]
        }) => {
            fetchedNotebooks[element.source.objectID] = {...element}
        });
        
        if (data.openNotebook){
            data.openNotebook.nbObj = JSON.parse(data.openNotebook.nbObj)
            store.dispatch(setCurrentNotebook(data.openNotebook))
        }
        store.dispatch(initialisedNotebooks())
        store.dispatch(initialiseNotebookMenuData(fetchedNotebooks))
        store.dispatch(initialiseTabs(data.tabs.map((x:any)=>x.source)))

    }
    catch(e){
        console.error("READER", e)
    }
}

    // fetchNotebookMenuData = (objectID: string)=>{
    //     // Underlying assumption: anytime there is a tab created, the dict is updated
    //     // Anytime a notebook is opened, the dict is updated

    //     return this.fetchedNotebooks[objectID]
    // }

    // createNotebook = async (parentID: string)=>{
    //     const resp  = await uploadNotebook(parentID)
    //     if (resp){
    //         if (parentID.includes('Reading')){
    //             // This is an item notebook

    //         }
    //     }
    //     else{
    //         return false
    //     }
    // }
    




// export const fetcher = new CachedDataManager()