import React, { useContext } from "react";
import FolderComponent from "../../features/files/Folder/Folder";
import "./Files.css";
import folder from "../../../../assets/icons/folder_color.svg";
import pages from "../../../../assets/icons/papers_color.svg";
import notebooks from "../../../../assets/icons/notebook_color.svg";

// import {foldersData, readingData, writingData} from './data'
import SectionScroller from "../../../../layouts/SectionScroller/SectionScroller";
import { EnvironmentContext } from "../../../../contexts";
import useFilesData from "./useFilesData";
import { Folder, ReadingDocument, WritingDocument } from "../../../../types/files";
import { Device } from "../../../../types/devices";
import useWindowSize from "../../../../hooks/useWindowSize";
import SimpleThumbnail from "../../features/files/Thumbnails/SimpleThumbnail/SimpleThumbnail";
import FoldableThumbnail from "../../features/files/Thumbnails/FoldableThumbnail/FoldableThumbnail";
import ThumbnailLamination from "../../features/files/Thumbnails/ThumbnailLamination/ThumbnailLamination";
import { openReading, openWriting } from "../../../../features/files/openFile";
import empty_folder from '../../../../assets/images/empty-folder.png'
import empty_shared from '../../../../assets/images/empty_shared.png'

import { useAppSelector } from "../../../../redux";

type FilesProps = {};

const Files: React.FC<FilesProps> = () => {
    const env = useContext(EnvironmentContext);
    const { foldersData, readingData, writingData, loading } = useFilesData(env);

    const folderSection = foldersData.length? (
        <div className="Files-Folder">
            {foldersData.map((folder: Folder) => (
                <FolderComponent {...folder} />
            ))}
        </div>
    ):null;

    const currentFolder = useAppSelector(state => state.folderData.currentFolderData.currentFolder)


    const readingSection = readingData.length?(
        <div className="Files-Documents">
            {/* <FoldableThumbnail /> */}
            {readingData.map((rDoc: ReadingDocument) => (
                <ThumbnailLamination fileType={"Reading"} screen="home" objectData={rDoc} onClick={
                    ()=>{
                        openReading(rDoc.objectID)
                    }
                }/>
                // <ThumbnailLamination file={rDoc} />
                // <FoldableThumbnail file={rDoc} />
                
            ))}
        </div>
    ):null;

    const writingSection = writingData.length?(
        <div className="Files-Documents">
            {writingData.map((wDoc: WritingDocument) => (
                <ThumbnailLamination fileType={"Writing"} screen="home" objectData={wDoc} onClick = {()=>{
                    openWriting(wDoc.objectID)
                }}/>
                // <SimpleThumbnail file={wDoc} onClick={() =>{}} size="n" />
            ))}
        </div>
    ):null;

    const device: Device = useWindowSize()

    const isEmpty = !(folderSection ||readingSection ||writingSection )

    return (
        <div className={`Files ${device} ${isEmpty?"empty":""} `}>
            {loading && "Loading ..."}
            {!loading && (

                <SectionScroller
                    sections={[
                        {
                            icon: folder,
                            header: "Folders",
                            contents: folderSection,
                        },
                        {
                            icon: pages,
                            header: "PDFs",
                            contents: readingSection,
                        },
                        {
                            icon: notebooks,
                            header: "Notebooks",
                            contents: writingSection,
                        },
                    ]}
                />

            )}
            {
                !loading && isEmpty?
                    (
                        <img src={currentFolder.objectID==="Folder_shared" ? empty_shared :empty_folder} className='empty-folder'></img>
                    )
                :null
            }
        </div>
    );
};

export default Files;
