/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateWriting = /* GraphQL */ `
  subscription OnUpdateWriting($objectID: String!) {
    onUpdateWriting(objectID: $objectID) {
      objectID
      createdAt
      nbObj
      version
      schema
      activeEditorSub
      activeEditorSession
      requested {
        sessionID
        sub
      }
      owner
      updatedAt
    }
  }
`;
