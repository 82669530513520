import React from 'react';
import { PageNumberFilterProps } from '../AnnotationsFilterSelector/filters/PageNumberFilter';
import { ColorFilterProps } from '../AnnotationsFilterSelector/filters/ColorFilter';
import { TypeFilterProps } from '../AnnotationsFilterSelector/filters/TypeFilter';
import { basicColors, ColorMap } from '../../../../../utils/Colors';

import './AnnotationsFilterPreview.css'
import { documentFilterOptions, DocumentFilterProps } from '../AnnotationsFilterSelector/filters/DocumentFilter';
import { PDFAnnotation, PDFAnnotationType } from '../../../reader/viewers/PDF/PdfManager';

type AnnotationsFilterPreviewProps = {
    totPages: number, 
    allAnnotationTypes: PDFAnnotationType[], 
    getAnnotationIcon: (annotation: PDFAnnotationType) =>string,
    setShowFilterSelector: React.Dispatch<React.SetStateAction<boolean>>,
    
} & PageNumberFilterProps & ColorFilterProps & TypeFilterProps & DocumentFilterProps ;

function inverse(obj:any){
    var retobj:any = {};
    for(var key in obj){
        const a = obj[key]
        retobj[a] = key;
    }
    return retobj;
  }

const ReverseColorMap = inverse(ColorMap)


const AnnotationsFilterPreview:React.FC<AnnotationsFilterPreviewProps> = (props) => {

    const computeFilters = () =>{
        /**
         * Computes the filters applied and returns them so that the filterBoxes can be rendered.
        */
        var appliedFilters:{filterElement: React.ReactNode, topic: string}[] = []
        const class_name="appliedFilter-item"
        var newFilter

        const {minPageNumber, maxPageNumber} = props.pageNumberFilter

        if(minPageNumber>1 || maxPageNumber<props.totPages){
            //A filter is to be applied on the page numbers
            if(maxPageNumber===props.totPages){
                newFilter = ( 
                <div >
                    <b>Page</b> is at least <b>{minPageNumber}</b>
                </div>
                )
            }
            else if(minPageNumber===1){
                newFilter = ( 
                <div>
                    <b>Page</b> is at most <b>{maxPageNumber}</b>
                </div>
                )
            }
            else{
                newFilter = (
                <div>
                    <b>Page</b> is between <b>{minPageNumber}</b> and <b>{maxPageNumber}</b>
                </div>
                )
            }

            appliedFilters.push({
                filterElement: newFilter, 
                topic: "Page Number"
            })

        }
        if(Array.isArray(props.selectedColors)){
            if(props.selectedColors.length<basicColors.length){ //replace with Color Map length

                newFilter = (
                    <>
                        <b>Color</b> &nbsp;is in &nbsp; {
                            props.selectedColors.map((color, index)=>{
                                if(index>=3) return null
                                console.log("color: ",color, ColorMap[color] )

                                return(
                                    <div className="filterBox-color" style={{backgroundColor:ColorMap[color] || color, color:ColorMap[color] || color, 
                                        border:color==="transparent"?`1px solid ${true?"black":"white"}` :"1px solid transparent"
                                    }}>
                                    </div>
                                )

                            })

                        }
                        {props.selectedColors.length>3?"...":""}
                    </>
                )

                appliedFilters.push({
                    filterElement: newFilter, 
                    topic: "Colour"
                })


            }
            
        }

        console.log("annotationfilters: ", props.selectedAnnotationTypes)

        if(props.selectedAnnotationTypes.length<props.allAnnotationTypes.length && props.selectedAnnotationTypes.length ){
            //There are annotationType filters applied
            if(props.selectedAnnotationTypes.length<=4){
                //applying annotation type isIn filter
                newFilter = (
                <div className={class_name}>
                    <span><b>Annotation</b> type is in &nbsp; </span> 
                    <span className="annotation-icon-container">
                    {
                        props.selectedAnnotationTypes.map((annoType:PDFAnnotationType, index:number) =>{
                            return <img className={`annoType-filter-icon`} key={index} src={props.getAnnotationIcon(annoType)} alt="icon"></img>
                        })
                    }
                    </span>
                    
                </div>
                )
            }
            else{
                //applying annotation type isNotIn filter
                newFilter = (
                <div className={class_name}>
                    <span><b>Annotation</b> type is not in </span>
                    <span className="annotation-icon-container">
                    {
                        props.allAnnotationTypes.map((annoType, index) =>{
                            console.log("mapping")
                            if(props.selectedAnnotationTypes.includes(annoType)) return null
                            else return (<img className={`annoType-filter-icon`}
                                key={index} src={props.getAnnotationIcon(annoType)} alt="icon"></img>)

                        })
                    }
                    </span>
                </div>
                )
            }
            appliedFilters.push({
                filterElement: newFilter, 
                topic: "Annotation Type"
            })
        }

        //DocumentFilter: 
        appliedFilters.push({
            topic: "Document", 
            filterElement: 
            <div className={class_name}>
                <span style={{marginRight: "3px"}}><b>Annotations</b> for  </span>
                {documentFilterOptions.map(option=>
                    option.value===props.documentFilter.primFilter?option.label.toLowerCase()
                    :null
                )}
                
            </div>

        })


        return appliedFilters
    }
    
    return (
        <div className='AnnotationsFilterPreview selected-filters'> 

            {computeFilters().map(({filterElement, topic}) =>
                <span className={`filterBox`} onClick={() =>{props.setShowFilterSelector(true)}}>
                    {filterElement}
                </span>
            )}
        </div>
    
    )
}
export default AnnotationsFilterPreview;