

export const editCloud = /* GraphQL */ `
mutation EditFile($input: EditFileInput) {
    editFile(input: $input) {
        parentID
        permission
        name
        description
        icon
        createdAt
        updatedAt
        objectID
    }
}
`;

export const createCloud = /* GraphQL */ `
mutation MyMutation (
    $input: CreateFileInput
) {
    createFolder(input: $input) {
        parentID
        permission
        name
        description
        icon
        createdAt
        updatedAt
        objectID
    }
  }
`

export const createReadingCloud = /* GraphQL */ `
mutation MyMutation (
    $input: CreateFileInput
) {
    createReading(input: $input) {
        parentID
        name
        objectID
    }
  }
`



export const getPath = /* GraphQL */ `
query MyQuery($input: GetPathInput) {
    getPath(input: $input){
      parentID
      permission
      name
      description
      icon
      createdAt
      updatedAt
      objectID
    }
}
`;

export const getDesk = /* GraphQL */ `
  query GetDesk($objectIDs: [String], $notebookID: String) {
    getDesk(objectIDs: $objectIDs, notebookID: $notebookID) {
      tabs {
        source {
          userID
          objectID
          format
          parentID
          permission
          name
          description
          owner
          url
          lastOpened
          lastLocation
          createdAt
          updatedAt
          file {
            bucket
            key
            region
          }
        }
        globalNotebooks {
          userID
          objectID
          parentID
          permission
          name
          description
          owner
          version
          nbObj
          createdAt
          updatedAt
        }
        itemNotebooks {
          userID
          objectID
          parentID
          permission
          name
          description
          owner
          version
          nbObj
          createdAt
          updatedAt
        }
      }
      openNotebook {
        userID
        objectID
        parentID
        permission
        name
        description
        owner
        version
        nbObj
        createdAt
        updatedAt
        activeEditorSub
        activeEditorSession
        requested {
          sessionID
          sub
        }
      }
    }
  }
`;

export const createSourceAnnotation = /* GraphQL */ `
mutation CreateAnnotation($input: CreateSourceAnnotationInput!) {
    createSourceAnnotation(input: $input){
      id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
    }
}
`;

export const annotationsByObjectID = /* GraphQL */ `
query annotationsByObjectID($objectID: String!) {
    annotationsByObjectID(objectID: $objectID){
      items {
        id
      createdAt
      sourceInfo
      renderInfo
      objectID
      annoType
      owner
      updatedAt
      }
    }
}
`;