
import React, { useState } from 'react';
import HomeLaptop from './laptop/Home.laptop'
import HomeMobile from './mobile/Home.mobile'
import useWindowSize from '../../../hooks/useWindowSize'
import {Device} from '../../../types/devices'
import { useAppSelector } from '../../../redux';
import Overlay from '../../../layouts/Overlay/Overlay';
import {ContextMenuObject, ContextMenuComponent} from '../../../layouts/ContextMenu/ContextMenu';
import { CMenuContext } from '../../../contexts';

import './HomeLayout.css'

type Props = {
    
};

const HomeLayout:React.FC<Props> = () => {
    const device: Device = useWindowSize()

    const [contextMenu, setContextMenu] = useState<ContextMenuObject>({
        menuArray: [],
        show: false, 
        X: 0, 
        Y: 0,
    })

    const showContextMenu  = (cont: ContextMenuObject | false) =>{

        if (!cont){
            setContextMenu({
                menuArray: [],
                show: false,
                X : 0,
                Y: 0
            })
        }
        else{
            setContextMenu(cont)
        }

    }

    return(
        <div className="HomeLayout">
            <CMenuContext.Provider value={{showContextMenu: showContextMenu}}>
            {
                device==="laptop"?
                <HomeLaptop />
                :
                <HomeMobile />
            }

            
            {
                    contextMenu.show?
                    <ContextMenuComponent
                        visible = {contextMenu.show}
                        X = {contextMenu.X}
                        Y = {contextMenu.Y}
                        menuArray = {contextMenu.menuArray}
                        showContextMenu = {showContextMenu}
                    />:null
                }
            </CMenuContext.Provider>

            
        </div>
    )


}
export default HomeLayout;