import React, {useState} from 'react';
import { getErrorMessage } from '../auth-functions/AuthUtils';
import VerificationCode from '../SignUp/VerificationCode';
import ForceChangePassword from './ForceChangePassword';
import ResetPassword from './ResetPassword';
import SignInForm from './SignInForm';

const SignIn = (props) => {
    /**
     * @state
     * signInScreen: "SignInForm" | "ResetPassword"
     * @props
     * subScreen: "SignInForm" | "ResetPassword" | "VerifyUnconfirmedUser" | "NewPasswordRequired"
     * setSubScreen: () =>{}
     */

    
    const [formState, setFormState] = useState(null)
    const [tempPassword, setTempPassword] = useState("")
    const [tempUser, setTempUser] = useState(null)


    const getSignInScreen=() =>{
        /**
         * NOTE: Make sure all the cases here are present in the AuthScreen.signInScreens array.
         */

        switch(props.subScreen){
            case "SignInForm":
                return( 
                    <SignInForm completeSignInStep={completeSignInStep} 
                            setSubScreen={props.setSubScreen} setFormState={setFormState} initFormState={formState}
                            />
                )
            case "ResetPassword":
                return <ResetPassword completeSignInStep={completeSignInStep} setSubScreen={props.setSubScreen} initFormState={formState} setFormState={setFormState}/>
            case "VerifyUnconfirmedUser":
                return <VerificationCode completeSignUpStep={completeSignInStep} email={formState.email.value} setSubScreen={props.setSubScreen} />
            case "ForceChangePassword":
                return <ForceChangePassword setFormState={setFormState} initFormState={formState} tempPassword={tempPassword} tempUser={tempUser} completeSignInStep={completeSignInStep} setSubScreen={props.setSubScreen} />
            default: 
                throw new Error("Invalid Subscreen!")
                
        }
    }

    const completeSignInStep = (data) =>{
        /**
         * This function is used to determine the flow of the sign in process. 
         * @param 
         * screen: (same as signInScreen @state. The step that is completed)
         * data: {status: string, payload} (the end result of the step)
         */

        console.log("completeSignInStep", data)

        switch (props.subScreen){
            case "SignInForm":
                const {status} = data
                console.log("status: ", status)
                //status: "SUCCESS" | "UserNotConfirmedException" | "NEW_PASSWORD_REQUIRED"
                if(status==="SUCCESS") {
                    //Successful sign in attempt
                    console.log("goto complete")
                    let user = data.payload
                    props.completeSignIn(user)
                }
                else if(status==="UserNotConfirmedException") {
                    //User not confirmed. They signed up without verification code last time.
                    props.setSubScreen("VerifyUnconfirmedUser")
                }
                else if (status==="NEW_PASSWORD_REQUIRED"){
                    setTempPassword(formState.password.value)
                    setTempUser(data.payload)
                    props.setSubScreen("ForceChangePassword")
                }
                else throw new Error("Unknown status")
                return

            case "VerifyUnconfirmedUser":
                props.signUserIn(formState.email.value, formState.password.value)
                .then((res) =>{

                })
                .catch(err =>{
                    setFormState({ 
                        ...formState,
                        success: "Your email was verified successfully, but password is incorrect.",
                        password: {
                            value: formState.password.value,
                            error: "Wrong password. Try again or click forgot password."
                        }, 
                        email: {
                            value: formState.email.value, 
                            error: undefined
                        }
                    })
                    props.setSubScreen("SignInForm")
                })
                return

            case "ResetPassword":
                props.completeSignIn(data.payload)
                return
            
            case "ForceChangePassword":
                props.completeSignIn(data.payload)
                return

        }
    }

    return getSignInScreen();

}
 
export default SignIn;