import React, {useState} from 'react';
import { Workspace } from '../../../../types/workspace';
import Topbar from '../../components/DeskTopbar/DeskTopbar';
import Toolbar from '../../components/Toolbar/Toolbar';
import AnnotationsWorkspace from '../annotations/AnnotationsWorkspace';
import WriterTabsBar from '../components/WriterTabsBar/WriterTabsBar';
import WriterTopbar from '../components/WriterTopbar/WriterTopbar';
import NotebookWorkspace from '../notebooks/NotebookWorkspace';
import { store, useAppSelector } from '../../../../redux';
import { selectNotebookManager } from '../notebooks/notebookWorkspaceManagerSlice';
import ReaderContext from '../../reader/ReaderContext';
import './Writer.css'
import { Device } from '../../../../types/devices';
import useWindowSize from '../../../../hooks/useWindowSize';

type WriterProps = {
    
};

const Writer:React.FC<WriterProps> = () => {

    const [workspace, setWorkspace] = useState<Workspace>("Notebooks")
    // const manager = useAppSelector(selectNotebookManager)
    // const activeTab= useAppSelector(selectActiveTab)
    // const readerTabs = useAppSelector(selectReaderTabs)
    var workspaceComponent

    if(workspace=="Notebooks"){
        workspaceComponent = <NotebookWorkspace />
    }
    else if (workspace=="Annotations"){
        workspaceComponent = <AnnotationsWorkspace totPages={10} />
    }
    else if (workspace =="Clipboard"){
        workspaceComponent="Clipboard"
    }

    const device: Device = useWindowSize();
    
    return(
        <div className={`Writer ${device}`}>
           
            <WriterTabsBar setWorkspace={setWorkspace} />
        
            {workspaceComponent}

        </div>
    )
}
export default Writer;