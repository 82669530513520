import { Storage } from "aws-amplify";
import {v4 as uuid} from 'uuid'
import awsmobile from "../../../aws-exports";

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);

    let mimeType = dataURI.split(',')[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
    if (mimeType==="image/svg+xml"){
        return dataURI
    }
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    console.log("TYPE" , mimeType)
    return new Blob([new Uint8Array(array)], {type: mimeType});
}

function getURLFromBlob(blob){
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
}

export class OnlineImageStore{
    constructor(){
        this.sourceMap = {}
    }

    deleteImageFromStore = (obj)=>{
        console.log("METADATA", obj.metadata)
        if(obj.id in this.sourceMap) this.sourceMap[obj.id].deleted = true
        Storage.remove(obj.metadata.s3Object.key)
    }



    saveFromPromise = (promiseGenerator, sourceMetadata, noSave=false)=>{
        let id = uuid()
        let attributes = {
            metadata: {
                id: id,
                type: "online",
                imageFormat: "image/png",
                sourceMetadata: sourceMetadata,
                s3Object: {
                    bucket: awsmobile.aws_user_files_s3_bucket,
                    region: awsmobile.aws_user_files_s3_bucket_region,
                    key: 'Photos/' + id
                }
            } 
        }
        promiseGenerator().then(url=>{
            console.log("PROMISE RESOLVED", url)
            if(!noSave){
                try{
                
                
                    var blobData = dataURItoBlob(url);
                    Storage.put('Photos/' + id, blobData, {
                        contentType: "image/png"
                    })
                    this.sourceMap[id] = {url: url, blob: blobData, deleted: false}
          
                
                
                }
                catch(e){
                    // Should not happen, as we get url only
                    console.log("URL", e)
                    
                }
            }
            else{
                this.sourceMap[id] = {url: url, blob: null, deleted: false}
            }
            
        })
        console.log("DRAFT", attributes)
        return attributes


    }

    saveAndInsertImage = ({url, sourceMetadata}, callback=(attributes, selection)=>{}, oldID=null)=>{
        let id = oldID ?? uuid()
        // This image should be saved on the cloud and added with metadata
        // However, it should be rendered in the meanwhile. The cloud upload can happen in the background.
        // If cloud upload fails or is incomplete:
        // When we fetch the image again, it might not work. If it has source info, we can trigger a lambda to fetch image from source and 
        // update the image as well as image in notebook. 
        // If it is external image, we are screwed. 
        this.sourceMap[id] = {url, deleted: false}
        let attributes = {
            src: url,
            metadata: {
                id: id,
                type: "online",
                sourceMetadata: sourceMetadata,
                s3Object: {
                    bucket: awsmobile.aws_user_files_s3_bucket,
                    region: awsmobile.aws_user_files_s3_bucket_region,
                    key: 'Photos/' + id
                }

            } 
        }
        try{
            console.log(url, url.slice(0,4) )
            if(url.slice(0,4) === "data"){
                var blobData = dataURItoBlob(url);
                if (blobData.slice(0,4) === "data"){
                    attributes.metadata.id = id +'.svg'
                    attributes.metadata.s3Object.key += '.svg'
                    attributes.metadata.imageFormat = "image/svg+xml"
                    Storage.put('Photos/' + id + '.svg', blobData)
                }
                else{
                    attributes.metadata.imageFormat = blobData.type
                    Storage.put('Photos/' + id, blobData, {
                        contentType: blobData.type
                    })
                }
                console.log("ADDING", blobData, attributes.metadata.id)
                this.sourceMap[attributes.metadata.id] = {blob:blobData, url:url, deleted: false}

                
            }
            else{
                fetch(url).then(resp=>{
                    resp.blob().then(
                        (blobData)=>{
                            var blobData = dataURItoBlob(url);
                            console.log("SUCCESSFULLY FETCHED",blobData)
                
                        }
                    )
                })
                attributes.metadata.imageFormat= "external-url"
                attributes.metadata.sourceURL = url
            }
            
            
        }
        catch(e){
            // Not a data URI! We can fetch and upload the image to an S3.
            // TODO: If I copy paste an image from the main bucket, it should not reupload the file. 
            // For now, it is ok.
            console.log("URL", e)
            
        }
        callback(attributes)

        // this.sourceMap[attributes.metadata.id] = url

        return attributes
        
    }

    fetchImageFromMetadata = async (attributes) => {
        console.log("METADATA", attributes, this.sourceMap)

        if (!attributes.metadata){
            // TODO: Image pasted from somewhere else has to be saved on 
            // the cloud and then rerendered. From here, it has to return the
            // normal src only. 
            return attributes.src
        }
        try{
            console.log("METADATA", attributes, this.sourceMap)
            let type = attributes.metadata.type
            if (attributes.metadata.imageFormat=="external-url"){
                return attributes.metadata.sourceURL
            }
            if(type !== "online"){
                // it is an image that has been pasted from some offline doc
                // Same function as above needs to handle it. For now, just return the
                // normal src.
                return attributes.src
            }
            else{
                let id = attributes.metadata.id
                let key= attributes.metadata.s3Object.key
                let blob;
                // Check if fetched
                let isSVG = key.slice(key.length-4, key.length)===".svg"
                if (id in this.sourceMap && this.sourceMap[id]){
                    console.log("FETCHING FROM CACHE", this.sourceMap[id])
                    blob = this.sourceMap[id].blob
                }
                else{   
                    // Fetch from S3
                    console.log("ABOUT TO FETCH" , attributes.metadata.imageFormat)
                    blob = await Storage.get( key, {download:true, contentType: isSVG ? "text": attributes.metadata.imageFormat??"text"})
                    this.sourceMap[id] = {blob:blob.Body, url: await getURLFromBlob(blob.Body), deleted:false}
                }

                
                    
                let text = this.sourceMap[id].url
                if (isSVG){
                    text = await this.sourceMap[id].blob.text()
                }
                return text

                    
                
                
            }
        }
        catch(e){
            console.log("IMAGE ERROR",e)
            return attributes.src
        }
    }
}