import React, { useState } from 'react';
import useEditFile from '../../../hooks/FileHooks/useEditFile';
import { DocumentInfoProps, Folder, ReadingDocument, WritingDocument } from '../../../types/files';
import TitledInput, { TitledInputProps } from '../../../ui/inputs/TitledInput/TitledInput';
import { getFileType } from '../getFileDetails';
import trash from '../../../assets/icons/trash.svg'
import open from '../../../assets/icons/open.svg'
import save from '../../../assets/icons/tick.svg'
import move from '../../../assets/icons/move.svg'


import './EditFileDetails.css'
import { Button } from '../../../ui/buttons';
import { useAppDispatch } from '../../../redux';
import { closeOverlay, openOverlay } from '../../../layouts/Overlay';
import FileExplorer from '../../../pages/home/features/FileExplorer/FileExplorer';
import { useDeleteFile } from '../../../hooks/FileHooks';
import useWindowSize from '../../../hooks/useWindowSize';
import { Device } from '../../../types/devices';


type File = ReadingDocument | WritingDocument | Folder

type EditFileDetailsProps = {
    file: File, 
    editDetailsCallback?: (file: File) => void
};

const EditFileDetails:React.FC<EditFileDetailsProps> = ({file, editDetailsCallback}) => {

    const backendEditFile = useEditFile()
    const backendDeleteFolder = useDeleteFile()
    const dispatch = useAppDispatch()

    const titledInputProps: any & TitledInputProps = {
        styleSize:"xl", 
        edges: "sharp"
    }

    const [fileName, setFileName] = useState<string>(file.name)
    
    const [description, setDescription] = useState<string>(file.description?file.description:"")

    const fileType = getFileType(file)

    const onFileNameChange = (e: any) => {
        setFileName(e.target.value)
    }

    const onDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    const submitChanges = () =>{

        const fileCopy = {...file}
        fileCopy.name=fileName

        if(fileType==="Folder"){
            fileCopy.description = description
        }

        backendEditFile(fileCopy)
        if(editDetailsCallback){
            editDetailsCallback(fileCopy)
        }
        dispatch(closeOverlay())

        
        
    }
    

    const device: Device = useWindowSize();

    
    return (
        <div className={`EditFileDetails ${device}`}>

        <div className="folder-details-edit ">

                <TitledInput
                    {...titledInputProps}
                    label="Name"
                    background="white"
                    id="edit-folder-name"
                    value={fileName}
                    onChange={onFileNameChange}
                ></TitledInput>

                {
                fileType=="Folder"?
                        <TitledInput
                            {...titledInputProps}
                            label="Description (Optional)"
                            background="white"
                            placeHolder='Something memorable here'
                            id="edit-folder-description"
                            value={description}
                            onChange={onDescriptionChange}
                        ></TitledInput>
                :null
            }
        </div>
           

            <div className='buttons-row'>
                    <Button
                        icon={save}
                        rank="primary"
                        fill="solid"
                        edges="round"
                        onClick={submitChanges}
                        isDisabled={file.permission==="viewer"}
                    >
                        Save
                    </Button>
                

            </div>



            



        
        </div>
    )
}
export default EditFileDetails;