import { Action } from "@remix-run/router";


import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import {ReadingDocument, sampleReading, WritingDocument } from "../../../types/files";


type fileUploadProgressState = {
    uploadsInProgress: {[id:string]: {file: ReadingDocument, progress: number}}, 
    //a dictionary of the form {title (String): progress (Int)}
    //NOTE: progress value of 101 means completed

    download: boolean,

};

const sampleInitialState: fileUploadProgressState = {
    uploadsInProgress: {
        "id1":{
            file:sampleReading ,
            progress: 101
        }, 
        "id2":{
            file: sampleReading ,
            progress: 101
        }, 
        "id3":{
            file: sampleReading ,
            progress: 101
        }
    }, 
    download: false,
}

const initialState: fileUploadProgressState = {
    uploadsInProgress: {}, 
    download: false,
}

export const fileUploadProgressSlice = createSlice({
    name: 'fileUploadProgress', 
    initialState: initialState, 
    reducers: {
        editProgress: (state, action: PayloadAction<{file:ReadingDocument, progress:number}>) =>{
            const {file, progress} = action.payload
            state.uploadsInProgress[file.objectID] = {file: file, progress: progress}
        }, 
        clearAllProgress: (state) =>{
            //used to close the box once all have hit 101
            state.uploadsInProgress = {}
        }, 
        completeFileUpload: (state, action: PayloadAction<ReadingDocument>) =>{
            const file = action.payload
            state.uploadsInProgress[file.objectID] = {file: file, progress: 101}
        }
    }
})

export const {editProgress, clearAllProgress, completeFileUpload} = fileUploadProgressSlice.actions
export const selectFileUploadProgress = (state: RootState) =>state.fileUploadProgress
export default fileUploadProgressSlice.reducer
