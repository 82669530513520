import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/store'

interface SeparatorState {
    readerWidth: number,
    readerWidthPixels: number,
    isReaderCollapsed: boolean,
    isWriterCollapsed: boolean,
}

const initialState: SeparatorState = {
    readerWidth: 60, 
    readerWidthPixels: 60 * window.innerWidth/100,
    isReaderCollapsed: false,
    isWriterCollapsed: false
}

export const separatorSlice = createSlice({
    name: 'separator', 
    initialState, 
    reducers: {
        setReaderWidth: (state, action: PayloadAction<number>) =>{
            state.readerWidth = action.payload
            state.readerWidthPixels = action.payload * window.innerWidth/100
        }, 
        resizeWindow: (state) =>{
            state.readerWidthPixels = state.readerWidth * window.innerWidth/100
        },
        collapseReader: (state) =>{
            state.isReaderCollapsed = true
        }, 
        uncollapseReader: (state) =>{
            state.isReaderCollapsed = false
        }, 
        collapseWriter: (state) =>{
            state.isWriterCollapsed = true
        }, 
        uncollapseWriter: (state) =>{
            console.log("uncollapse writer")
            state.isWriterCollapsed = false
        }
    }
})

export const {setReaderWidth, collapseReader, uncollapseReader, collapseWriter, uncollapseWriter, resizeWindow} = separatorSlice.actions
export const selectSeparator = (state: RootState) => state.separator
export default separatorSlice.reducer



