

export type ColorAlias = "red" | "orange" | "yellow" | "green" | "blue" | "violet" | "brown" | "black" | "transparent" | "purple" | "teal"

export const basicColors: ColorAlias[] = ["red","orange", "yellow", "green", "blue", "violet", "brown", "black", "purple", "teal"]

type ColorMapType = { [key in ColorAlias]? :string}
type StrictColorMapType = { [key in ColorAlias] :string}

function inverse(obj:any){
    var retobj:any = {};
    for(var key in obj){
      retobj[obj[key]] = key;
    }
    return retobj;
}

export const ColorMap: StrictColorMapType = {
    "red": "#f08080",
    "orange":"#FFA500",
    "yellow":"#ffcc00",
    "green": "#00ff7f",
    "blue": "#87cefa" ,
    "violet": "#EE82EE",
    "brown": "#3f000f" ,
    "black": "#1a1b1f",
    "transparent":"#ffcc00",
    "purple" :  "#ac94f4",
    "teal" : "#81cdc6"
}

export const ReverseColorMap = inverse(ColorMap)

export const ExtractColorMap:{ [key in ColorAlias | "default"]? :string} = {
    "default" : "#d8d5fa",
    "red": "#fdb5b5",
    "orange":"#fdc868",
    "yellow":"#f8dd75",
    "green": "#72f8b4",
    "blue": "#b7e3fe" ,
    "violet": "#f8b1f8",
    "brown": "#795d63" ,
    "black": "#6f7383",
    "transparent":"#f8dd75"
}

export const LightNotebookColorMap:ColorMapType = {
    "red": "#FF0000",
    "orange":"#D9730D",
    "yellow":"#CB912F",
    "green": "#008000",
    "blue": "#0000FF" ,
    "violet": "#F600F6",
    "brown": "#A15000" ,
    "black": "#000000",
}

export const DarkNotebookColorMap:ColorMapType = {
    "red": "#FF4C48",
    "orange":"#FFA500",
    "yellow":"#FFFF00",
    "green": "#00FF00",
    "blue": "#00FFFF" ,
    "violet": "#FF80FF",
    "brown": "#FF9E3D" ,
    "black": "#FFFFFF",
}