import React, { MouseEventHandler } from "react";
import { PDFSourceInfo } from "../PdfManager";
import './PdfHighlight.css'
type PdfHighlightProps = {
    id: string,
    rectangles: {
        left: number,
        top: number,
        width: number,
        height: number
    }[]
    stroke?: string,
    color: string,
    sourceInfo: PDFSourceInfo,
    borderBottom ?: boolean
    glow?: boolean
    showContextMenu: MouseEventHandler
    
}

export const PdfHighlight = (highlight: PdfHighlightProps) =>{
    const topRef = React.createRef<HTMLElement>()
    const generateList = ()=>{
        var i;
        var elems = []
        for(i= 0; i < highlight.rectangles.length; i++){
            var left = highlight.rectangles[i].left
            var top = highlight.rectangles[i].top
            var width = highlight.rectangles[i].width
            var height = highlight.rectangles[i].height
            const bottom = top + height
            let hlStroke = highlight.stroke ?? 'h'

            let topY, botY
            if (hlStroke==="h"){
                topY = top
                botY = bottom
            }

            var style = {
                left: left + 'px',
                top:topY + 'px',
                width:width + 'px',
                height:botY as number - (topY as number) + 'px',
                backgroundColor: highlight.color,
                pointerEvents: "inherit" as React.CSSProperties['pointerEvents'],
                borderBottom:  highlight.borderBottom ? '1px dotted black' : 'none',
                animation: 'none'
            }
            if (highlight.glow){
                style = {
                    left: left + 'px',
                    top:topY + 'px',
                    width:width + 'px',
                    height:botY as number - (topY as number) + 'px',
                    pointerEvents: "inherit" as React.CSSProperties['pointerEvents'],
                    borderBottom:  highlight.borderBottom ? '1px dotted black' : 'none',
                    animation: 'color-change 1.25s ease-in-out infinite',
                    backgroundColor: highlight.color,
                }
            }
            var elem = 
            <div key = {i} className = {`customHighlight`} style = {style} >
            </div>
            elems.push(elem)
        }
        return elems
    }
    
    return (
        <span className={`custom-highlight-container ${highlight.glow ? "glow": ''}`} ref = {topRef} id = {highlight.id} 
        onClick = {highlight.showContextMenu} onContextMenu = {highlight.showContextMenu}>
        
            {generateList()}
        </span>
    )
}