import pdfIcon from  '../../assets/icons/pdfIcon.svg'
import notebookIcon from '../../assets/icons/notebookIcon.svg'
import { Folder, ObjectUser, ReadingDocument, WritingDocument } from '../../types/files'

type File = Folder | ReadingDocument | WritingDocument

export const getFileType: (file: File) => "Folder" | "Reading" | "Writing" = (file: File) =>{
    if(file.objectID.includes("Folder")) return "Folder"
    else if (file.objectID.includes("Reading")) return "Reading"
    else return "Writing"

}

export const getFileIcon: (file: ReadingDocument | WritingDocument) =>{format: string, icon: string} 
    = (file: ReadingDocument | WritingDocument) => {

    const fileCopy:any = {...file}
    const documentType = fileCopy.objectID.includes("Reading")?"Reading":"Writing"

    if(documentType==="Reading"){
        //this is a readingDocument

        switch(fileCopy.format){
            case "PDF":
                return {format: "PDF", icon: pdfIcon}
            
            case "ePub":
                return {format: "PDF", icon: pdfIcon}

            default: 
                return {format: "PDF", icon: pdfIcon}
        }
    }
    else{
        //this is a writing document
        if(file.parentID.includes("Reading_")) return {format: "Notebook", icon: notebookIcon}
        else return {format: "Notebook", icon: notebookIcon}

    }

}

