import { useState, useEffect } from 'react';
import {useAppSelector, useAppDispatch} from '../../redux'
import {selectFolderPath, updateFolderPath} from '../../pages/home/features/files/folderPathSlice'
import { EnvironmentContextValue } from '../../contexts';
import { Folder } from '../../types/files';

import { myLibrary, sharedFiles } from '../../types/files';
import useGetPath from './useGetPath';
const useFolderNavigate: (env: EnvironmentContextValue) => (folder: Folder, newPath?: [Folder, ...Folder[]] | null)=>void = (env) => {
    // This hook returns a function that allows the consumer to navigate to a given folder
    // given a folder object.
    // In web, this means updating the path in the app state and also changing the route

    
    const navigate = env.navigator()
    // Env.navigator is a hook (useNavigate in web)
    // Navigate is a function that will actually be able to navigate

    const folderPath = useAppSelector(selectFolderPath)
    const dispatch = useAppDispatch()
    // We must update folderPath based on ID given in the core function

    return async(folder: Folder, newPath?: [Folder, ...Folder[]] | null)=>{
        // We first create updated folderPath state

        console.log("going to navigate: ", folder)

        let newSegments : [Folder, ...Folder[]] = folderPath.segments
        if (newPath){
            dispatch(updateFolderPath( newPath))
            navigate('/folders/'+folder.objectID)
            return
        }
        const folderExists = newSegments.findIndex((x)=>x.objectID === folder.objectID)
        if (folder.objectID === 'Folder_library'){
            newSegments = [myLibrary]
        }
        else if (folder.objectID === 'Folder_shared'){
            newSegments = [sharedFiles]
        }
        else if (folder.parentID === 'Folder_library'){
            // This is a root folder: it is either in the shared files folder or the root
            // For now, I put it in my library
            newSegments = [myLibrary, folder]
        }
        else if (folder.parentID == 'Folder_shared'){
            newSegments = [sharedFiles, folder]
        }
        else if (folderExists >= 0) {
            newSegments = newSegments.slice(0, folderExists+1) as [Folder, ...Folder[]]
        }
        else{
            const parentExists = newSegments.findIndex((x)=>x.objectID === folder.parentID)
            if (parentExists !== -1){
                newSegments = newSegments.slice(0, parentExists + 1).concat([folder]) as [Folder, ...Folder[]]
            }
            else{
                //There is no path provided
                const backendGetPath = useGetPath()
                const sections = await backendGetPath(folder.objectID)
                if(sections){
                    newSegments = sections
                }
                else{
                    newSegments = [myLibrary]
                }
                console.log("newSegments errored: ", myLibrary)
            }
        }
        // console.error('FOLDER PATH', folder, newSegments)
        dispatch(updateFolderPath( newSegments))
        navigate('/folders/'+folder.objectID)

    }
}

export default useFolderNavigate