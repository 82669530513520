import React from 'react'

import './CircularProgressBar.css'
//import pulp from '/home/hampiholi/Downloads/internet-pulp-fiction.gif'
//@gaurav import yourgif here

function CircularProgressBar(props) {
    
    const strokeWidth= props.strokeWidth || "10"
    const sqSize=props.sqSize || "200"
    const showText = props.showText !== false
    const internetDown = props.internetDown
    // showText determines whether percentage is shown
    // Size of the enclosing square
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * props.percentage / 100;
    return (
    <div>
    <svg
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}>
        <circle
            className={`circle-background ${props.appTheme}`}
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`} />
        <circle
            className={`circle-progress ${props.appTheme}`}
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
            style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
            }} />
        
        {showText? <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            {`${props.percentage}%`}
        </text> : null}
        {/* <image
            x="50%"
            y="100%"
            height = "100px"
            width = "100px"
            href="../../../icons/logo/logo_light.png"/> */}
    </svg>
    {/* {internetDown ? <img src = {pulp} className="loading-bar-gif" alt = "Check your internet connection"></img>: null} */}
    </div>
    );
    
  }
  

  export default CircularProgressBar
  

