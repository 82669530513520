import { API, Storage, Auth } from "aws-amplify";
import {v4 as uuid4} from 'uuid'
import { createWriting, updateRecent, updateWriting } from "../../../graphql/mutations";
import { getWriting } from "../../../graphql/queries";
import { store } from "../../../redux";

import { ReadingDocument, WritingDocument } from "../../../types/files";
import { updateRecentNotebooks } from "../writer/notebooks/fetchedNotebooksSlice";

export const uploadNotebook = async (parentID: String, title?: String, )=>{
    const fileID = uuid4()
    let result
    const user = store.getState().user.user?.userID

    try{
        
        const resp = await API.graphql({
            query: createWriting,
            variables: {
                input: {
                    parentID: parentID,
                    name: title ?? "Untitled",
                    // owner: user
                }
            }
        }) as any
        const newWriting = resp.data.createWriting

        // postUploadCallback(newWriting)

        return newWriting

    }
    catch(e){
        console.log("error: ", e)
        return false
    }
}

export const updateNotebookContents = async (notebook: WritingDocument)=>{
    try{
        console.log("UPDATING", notebook)
        const user = store.getState().user.user?.userID
        const resp = await API.graphql({
            query: updateWriting,
            variables: {
                input: {
                    // owner: notebook.owner,
                    objectID: notebook.objectID,
                    nbObj: JSON.stringify(notebook.nbObj),
                    version: "latest",
                    activeEditorSub: user,
                    activeEditorSession: notebook.activeEditorSesion,
                    schema: '2022-08-15',
                    requested: null
                }
            }
        }) as any
        const newWriting = resp.data.updateWriting
        newWriting.nbObj = JSON.parse(newWriting.nbObj)
        return {...notebook, ...newWriting}

    }
    catch(e){
        console.log("error: ", e)
        return false
    }
}

export const updateNotebookRecency = async(notebook: WritingDocument, sourceID: string)=>{
    console.log(notebook, sourceID)
        API.graphql({
            query: updateRecent,
            variables: {
                notebookID: notebook.objectID,
                sourceID: sourceID,
                geography: notebook.parentID.includes('Reading') ? 'Item' : 'Global'
            }
        })
        store.dispatch(updateRecentNotebooks({
            sourceID: sourceID,
            notebook: notebook,
            geography: notebook.parentID.includes('Reading') ? 'Item' : 'Global'
        }))
    
}

export const getNotebook = async (notebook: WritingDocument)=>{
    try{
        const user = store.getState().user.user?.userID
        console.log("GETTING",notebook)
        const resp = await API.graphql({
            query: getWriting,
            variables: {
                
                objectID: notebook.objectID,
                version: "latest"
                
            }
        }) as any
        const newWriting = resp.data.getWriting
        const final = {...notebook}
        console.log("Setting",newWriting)
        for (let key of Object.keys(newWriting)){
            // @ts-expect-error
            final[key as string]  = newWriting[key]
        }
        final['nbObj'] = JSON.parse(newWriting.nbObj)
        const currentID = store.getState().readerTabs.activeTab?.readingDocument.objectID
        if (currentID){
            updateNotebookRecency(notebook, currentID )
        }
        return final
    }
    catch(e){
        console.log("error: ", e)
        return false
    }
}

export const acceptNotebookRequest = async (notebook: WritingDocument)=>{
    try{
        console.log("UPDATING", notebook)
        const resp = await API.graphql({
            query: updateWriting,
            variables: {
                input: {             
                    // owner: notebook.owner,       
                    objectID: notebook.objectID,
                    nbObj: JSON.stringify(notebook.nbObj),
                    version: "latest",
                    activeEditorSub: notebook.requested?.sub,
                    activeEditorSession: notebook.requested?.sessionID,
                    schema: '2022-08-15',
                    requested: null
                }
            }
        }) as any
        const newWriting = resp.data.updateWriting
        newWriting.nbObj = JSON.parse(newWriting.nbObj)
        return {...notebook, ...newWriting}

    }
    catch(e){
        console.log("error: ", e)
        return false
    }
}

export const sendNotebookRequest = async (notebook: WritingDocument)=>{
    try{
        console.log("UPDATING", notebook)
        const user = store.getState().user.user?.userID

        const resp = await API.graphql({
            query: updateWriting,
            variables: {
                input: {              
                    // owner: notebook.owner,      
                    objectID: notebook.objectID,
                    nbObj: JSON.stringify(notebook.nbObj),
                    version: "latest",
                    activeEditorSub: notebook.activeEditorSub,
                    activeEditorSession: notebook.activeEditorSesion,
                    schema: '2022-08-15',
                    requested: {
                        sub: user,
                        sessionID: ''
                    }
                }
            }
        }) as any
        const newWriting = resp.data.updateWriting
        newWriting.nbObj = JSON.parse(newWriting.nbObj)
        return {...notebook, ...newWriting}

    }
    catch(e){
        console.log("error: ", e)
        return false
    }
}

