import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'


export type FullScreenOverlayComponent = {
    component: React.ReactNode | null,
    heading: {
        title: string, //In bold, React.ReactNode for the search textInput
        icon?: string, //Mark as read button
        iconText?: string,
    } | undefined,
    noHeader?: boolean
} | null

// Define a type for the slice state
interface FullScreenOverlayState {
    value: FullScreenOverlayComponent
}

// Define the initial state using that type
const initialState: FullScreenOverlayState = {
    value: null,
}



export const FullScreenOverlaySlice = createSlice({
    name: 'FullScreenOverlay',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        closeFullScreenOverlay: (state) => {
            state.value = null
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        openFullScreenOverlay: (state, action: PayloadAction<FullScreenOverlayComponent>) =>{
            state.value = action.payload
        }
    }
})

export const {closeFullScreenOverlay, openFullScreenOverlay} = FullScreenOverlaySlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectFullScreenOverlay = (state: RootState) => state.fullscreenOverlay.value
export default FullScreenOverlaySlice.reducer
