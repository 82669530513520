import { useState, useEffect } from 'react';
import {useAppSelector, useAppDispatch} from '../../redux'
import { EnvironmentContextValue } from '../../contexts';
import { Folder } from '../../types/files';

import { myLibrary, sharedFiles } from '../../types/files';
import { createFile } from '../../pages/home/features/files/folderDataSlice';
import { API } from 'aws-amplify';


import { createCloud } from '../../graphql/custom';

type CreateFolderInput = {
    name : string,
    description?: string,
    parentID: string
}

const useCreateFolder: (env: EnvironmentContextValue) => (folder: CreateFolderInput)=>Promise<Folder | false> = (env) => {
    
    // This hook returns a function that allows the consumer to create a given folder
    // given a folder object.
    // In web, this means updating the path in the app state and also changing the route
    
    // Env.navigator is a hook (useNavigate in web)
    // Navigate is a function that will actually be able to navigate

    const dispatch = useAppDispatch()
    // We must update folderPath based on ID given in the core function

    return async (folder: CreateFolderInput)=>{
        // We first create updated folderPath state
        try{
            console.log("creating folder: ", folder)

            const resp = await API.graphql({
                query: createCloud,
                variables: {
                    input: {
                        name: folder.name,
                        description: folder.description ?? null,
                        parentID: folder.parentID
                    }
                }
            }) as any
            const newFolder = resp.data.createFolder
            console.log("newFolder: ", newFolder)
            dispatch(createFile(newFolder))
            return newFolder

        }
        catch(e){
            console.log("error: ", e)
            return false
        }

    }
}

export default useCreateFolder