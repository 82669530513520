import AuthButton from "../../../ui/buttons/AuthButton/AuthButton";
import TitledInput from "../../../ui/inputs/TitledInput/TitledInput";
import { Subtitle, Title } from "../../../ui/text/Text";
import { useEffect, useReducer, useState } from "react";
import { sendResetPasswordCode, forgotPasswordSubmit, signIn } from "../auth-functions/SignInFunctions";
import Form from "../../../layouts/Form/Form";

/**
 * 
 * @references
 * Animated List: https://codepen.io/LFCProductions/pen/VwWwvyg
 */

const ResetPassword = (props) => {

    /**
     * @state
     * screen: "email" | "password"
     * loading: boolean
     */

    const [screen, setScreen] = useState("email")
    // screen: "email" (to send verification code) | "password" (to confirm the verification code)

    const sendResetCodeFunction = {
        function: (formState) =>{
            const email = formState.email.value
            return sendResetPasswordCode(email)
        }, 

        onSuccess: () =>{
            setScreen("password")
        }, 

        onError: ()=>{}
    }

    const onForgotPasswordSubmitFunction = {

        function: (formState) =>{
            return forgotPasswordSubmit({
                email: formState.email.value, 
                code: formState.code.value,  
                password: formState.newPassword.value, 
                confirmPassword: formState.confirmPassword.value
            })
        },

        onSuccess: (user) =>{
            props.completeSignInStep({payload: user})
        }, 

        onError: () =>{}
    }

    var onClickHandler
    if(screen=="email"){
        onClickHandler = sendResetCodeFunction
    }
    else{
        onClickHandler = onForgotPasswordSubmitFunction
    }


    return (  
        <div className="dynamic-form">
            <Title>Reset your password.</Title>
            <Subtitle>This should only take a few seconds.</Subtitle>

            <br/>

            <Form
                initFormState={props.initFormState}
                setFormState={props.setFormState}
                submit={onClickHandler.function} 
                onSuccess={onClickHandler.onSuccess}
            >
                <TitledInput styleSize="l"  id="email" label={"Email"} placeHolder="Enter your email address" disabled={screen=="password"} />
                
                <TitledInput 
                styleSize="l"
                    display={screen=="password"} 
                    id="code" label={"Verification Code"} placeHolder="We've sent a 6-digit code to the email above" 
                />
                <TitledInput styleSize="l" display={screen=="password"}  type="password" id="newPassword" label={"New Password"} placeHolder="Something strong and memorable" />
                <TitledInput styleSize="l" display={screen=="password"}  type="password" id="confirmPassword" label={"Confirm New Password"} placeHolder="Let's make sure we got that right"  />
            
                <AuthButton 
                    submit
                    style={screen=="email"?"secondary":"primary"}
                > {screen=="email"?"Send Reset Code": "Reset Password"} </AuthButton>
            </Form>

            <br/>
        
            <Subtitle>Remember your password? &nbsp;
                <a onClick={() =>{props.setSubScreen("SignInForm")}}>Sign In</a>
            </Subtitle>
            
        </div>
    );
}
 
export default ResetPassword;