import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReaderTool } from './ReaderToolbar'
import type { RootState } from '../../../../../redux/store'

// type ToolbarMessageComponent = {
//     message: string | undefined,
//     isError?: boolean
// } | null

// Define a type for the slice state

type ReaderToolbarState = {
    tool: ReaderTool | null, 
    message?: string | null, 
    isError?:boolean, 
} 

// Define the initial state using that type
const initialState: ReaderToolbarState = {
    tool: null ,
    message: null,
}

const defaultMessages: {[key in ReaderTool]: string } = {
    "clipping":"Drag across the PDF", 
    "comment":"Click anywhere on the PDF to add comment", 
    "link" : "Click anywhere on the PDF to create a link",
    "highlighter":"",
    "rectangle":"Drag across the PDF", 
    "find":""
}

export const readerToolbarSlice = createSlice({
    name: 'readerToolbar',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        inactivateReaderToolbar: (state) => {
            state.tool = null
            state.message = null
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        setActiveTool: (state, action: PayloadAction<ReaderToolbarState & {tool: ReaderTool}>) =>{

            //ReaderTool is compulsory here. 

            const {tool, message, isError} = action.payload
            
            state.tool = tool
            if(action.payload.message){
                state.message = message
            }
            else{
                state.message = defaultMessages[tool]
            }
            if(action.payload.isError) state.isError = isError
        }
    }
})

export const {setActiveTool, inactivateReaderToolbar} = readerToolbarSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectReaderToolbar = (state: RootState) => state.readerToolbar
export default readerToolbarSlice.reducer
