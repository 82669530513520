
import { API } from "aws-amplify";
import { showLoadingMessagePopup, showMessagePopup, showErrorMessagePopup } from "../../layouts/MessagePopup/messagePopupSlice";
import { editFile } from "../../pages/home/features/files/folderDataSlice";
import { useAppDispatch } from "../../redux";
import { Folder, ReadingDocument, WritingDocument } from "../../types/files";
import { editFile as editCloud } from "../../graphql/mutations";
import { editTab } from "../../pages/desk/reader/Reader/readerTabsSlice";
import { editNotebook } from "../../pages/desk/writer/notebooks/fetchedNotebooksSlice";




const useEditFile: () => (object: ReadingDocument | WritingDocument | Folder ) =>Promise<boolean> = () =>{
    
    const dispatch = useAppDispatch()

    return async(file: ReadingDocument | WritingDocument | Folder) =>{

        //folder has all new details only. Whether details have changed or not are to be checked elsewhere. 

        const {name, description, objectID, parentID} = file

        try{
            dispatch(showLoadingMessagePopup())

            const resp = await API.graphql({
                query: editCloud, 
                variables: {
                    input: {
                        objectID: objectID, 
                        name: name, 
                        description: description, 
                        parentID: parentID
                    }
                }
            }) as any

            dispatch(editFile(file))

            if(objectID.includes("Reading_")){
                //this is a reading document. We may need to update the tabs data also
                dispatch(editTab(file as ReadingDocument))
            }
            else if(objectID.includes("Writing_")){
                //this is a writing document. We may need to update the notebook in Notbeook Menu
                dispatch(editNotebook(file as WritingDocument))
            }


            dispatch(showMessagePopup("Edited 1 item successfully."))
            return true
        }
        catch(e) {
            console.error("Error while editing item: ", e)
            dispatch(showErrorMessagePopup("Error while editing item."))
            return false
        }
        

    }

}

export default useEditFile