import { useState } from "react"
import navigate_light from '../../../../../../assets/legacy_icons/menu_icons/navigate_light.svg'
import hideLight from '../../../../../../assets/legacy_icons/menu_icons/hide_light_outline.svg'
import image from '../../../../../../assets/legacy_icons/notebook_icons/image_dark.png'
import { ContextMenuObject } from "../../../../../../layouts/ContextMenu/ContextMenu"
import { ExtractColorMap } from "../../../../../../utils/Colors"
// import image from '../'
const  PdfClip = (props: {
    topLeft: [number, number]
    botRight: [number, number]
    scale: number
    hidden?: boolean
    setHidden: (x:boolean)=>void
    showContextMenu: (x: ContextMenuObject) => void
    navigateToNotebook: any // TODO: TYPE
    glow?: boolean
})=>{
    let topLeft = [Math.min(props.topLeft[0], props.botRight[0]),Math.min(props.topLeft[1], props.botRight[1]) ]
    let botRight = [Math.max(props.topLeft[0], props.botRight[0]),Math.max(props.topLeft[1], props.botRight[1]) ]

    return (props.hidden? <div style = {{position: 'absolute', left: topLeft[0] + 'px', 
        cursor: 'pointer',
        top: topLeft[1] + 'px',
        background: 'black', 
        width: 15 *props.scale + 'px', height: 15 * props.scale + 'px',
        mixBlendMode: 'normal'
    }} onClick={()=>{
            props.setHidden(false)
        }}>
            <img alt = "hidden" style = {{position: 'absolute', left:  '0px', 
        cursor: 'pointer',
        top: '0px',
        //background: '#665AEF33', 
        width: 15 *props.scale + 'px', height: 15 * props.scale + 'px'
    }} src = {image}></img>
        </div> :
        
        <div onClick = {(e)=>{
            e.stopPropagation()
            e.preventDefault()
            props.showContextMenu({show: true, menuArray:  [{
                label: "Navigate To Clip",
                handler: ()=>{
                    props.navigateToNotebook()
                },
                // icon: props.getAppTheme() == "dark" ? navigate_dark : navigate_light
                icon: navigate_light
            }, {
                label: "Hide",
                handler: ()=>{
                    props.setHidden(true)
                },
                // icon: props.getAppTheme() == "dark" ? hideDark : hideLight
                icon: hideLight
            }], X: e.pageX, Y: e.pageY})
        }} style = {{position: 'absolute', left: topLeft[0] + 'px', 
        cursor: 'pointer',
        top: topLeft[1] + 'px', width: botRight[0]-topLeft[0] + 'px', height: botRight[1] - topLeft[1] + 'px', background: ExtractColorMap["default"],
        animation: props.glow ? 'color-change 1.25s ease-in-out infinite' : 'none'
    }}></div>)
    }

export default PdfClip