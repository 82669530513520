import React, { useEffect, useState } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { Device } from '../../../types/devices';

import DeskLaptop from './laptop/Desk.laptop'
import DeskMobile from './mobile/Desk.mobile'

import './DeskLayout.css'
import { ContextMenuComponent, ContextMenuObject } from '../../../layouts/ContextMenu/ContextMenu';
import { CMenuContext } from '../../../contexts';

type DeskLayout = {
    
};

const DeskLayout:React.FC<DeskLayout> = () => {

    const device: Device = useWindowSize()

    const [contextMenu, setContextMenu] = useState<ContextMenuObject>({
        menuArray: [],
        show: false,
        X: 0,
        Y: 0,
    });

    const showContextMenu = (cont: ContextMenuObject | false) => {
        console.log(cont, "CONTEXT MENU");
        if (!cont) {
            setContextMenu({
                menuArray: [],
                show: false,
                X: 0,
                Y: 0,
            });
        } else {
            setContextMenu(cont);
        }
    };

    useEffect(() =>{
        document.addEventListener("contextmenu", (event) =>{
            event.preventDefault()
        })
    }, [])
    
    return(
        <div className="DeskLayout">

{contextMenu.show ? (
                <ContextMenuComponent
                    visible={contextMenu.show}
                    X={contextMenu.X}
                    Y={contextMenu.Y}
                    menuArray={contextMenu.menuArray}
                    showContextMenu={showContextMenu}
                ></ContextMenuComponent>
            ) : null}

            <CMenuContext.Provider value={{
                    showContextMenu: showContextMenu,
                }}>
            {
                device==="laptop"?
                <DeskLaptop />
                :
                <DeskMobile />
            }
            </CMenuContext.Provider>
        </div>
    )
}

export default DeskLayout;