import { DocumentInfoProps } from "../../types/files"
import { API } from 'aws-amplify';
import { Collaborator } from "../../types/user";

const getCollaborators = /* GraphQL */ `
query MyQuery (
    $objectID: String
){
    getCollaborators(objectID: $objectID) {
        name
        email
        permission
    }
  }
`


const useGetCollaborators: () => (file: DocumentInfoProps) =>Promise<Collaborator[] | false> = () =>{

    return async (file: DocumentInfoProps ) =>{
        
        try{
            const resp = await API.graphql({
                query: getCollaborators,
                variables: {
                    objectID: file.objectData.objectID
                }
            }) as any
            console.log("collaborators repsonse: ", resp)
            const newUsers = resp.data.getCollaborators
            return newUsers
        }

        catch(e){
            return false
        }
            
        

    }

} 

export default useGetCollaborators