import React, { useState } from 'react';
import { useAppSelector } from '../../../../../../redux';
import { ReadingDocument, WritingDocument } from '../../../../../../types/files';
import { getNotebook } from '../../../../../desk/desk-logic/notebookSaver';
import { selectNotebookManager } from '../../../../../desk/writer/notebooks/notebookWorkspaceManagerSlice';
import pdfIcon from '../../../../../../assets/icons/pdfIcon.svg'
import notebookIcon from '../../../../../../assets/icons/notebookIcon.svg'

import './SimpleThumbnail.css'
import { getFileIcon } from '../../../../../../features/files/getFileDetails';
import { Size } from '../../../../../../types/sizes';
import { Device } from '../../../../../../types/devices';

type File = ReadingDocument | WritingDocument

type SimpleThumbnailProps =  {
    file: File,
    size?:Size,
    onClick: (file: File) => (void | Promise<boolean>) ,
    device: Device
}

const SimpleThumbnail:React.FC<SimpleThumbnailProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const notebookManager = useAppSelector(selectNotebookManager)


    const onClickHandler = () =>{

        setLoading(true)
        const res = props.onClick(props.file)
        if(res instanceof Promise){
            
            //the function is an asyn fnuction
            console.log("returns promise")
            res.then(resp =>{
                setLoading(false)
            })
        }
        else{
            //the function is a sync fnuction
            setLoading(false)
        }
    }

    const {icon, format} = getFileIcon(props.file)

    return(
        <div className={`SimpleThumbnail ${props.size} ${props.device}`} onClick={onClickHandler}>

            {/* <img src={icon} className={`thumbnail-icon ${props.device}`}></img> */}

            <div className='contents'>
                <div className={`format ${props.device}`}>
                    {format}
                </div>

                <div className={ `title ${props.device}`} >
                    {loading? <div className='lds-hourglass'/>:props.file.name}
                </div>

                <div className={`format ${props.device}`}>
                    .
                </div>

            </div>

            

        </div>
    )
}
export default SimpleThumbnail;