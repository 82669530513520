import React, { useContext, useState } from 'react';
import {Folder, rootFolder} from '../../../../../types/files'
import useFolderNavigate from '../../../../../hooks/FileHooks/useFolderNavigate';

import './Breadcrumbs.css'
import BreadcrumbSection from './BreadcrumbSection';

export type BreadcrumbsProps = {
    rootHandler?:() =>void, //defined if root is to be appended to the beginning.
    segments: [Folder, ...Folder[]],
    navigationHandler?: (segment: Folder) => void,
    droppable?: boolean, //whether files can be dropped on the sections to move them there. 
};

const  Breadcrumbs:React.FC<BreadcrumbsProps> = (props) => {

    const {segments} = props

    // Map each segment 
    const finalList : any = []

    if(props.rootHandler && segments[0].objectID!=="Folder_root"){
        finalList.push(
            <BreadcrumbSection segment={rootFolder} navigationHandler={props.rootHandler} />
        )
        finalList.push(<span>{' > '}</span>)
    }


    const navigationHandler = (segment: Folder) =>{
        if(props.navigationHandler) return props.navigationHandler(segment)
        else return null
    }
    
    segments.forEach((segment, index)=>{
        finalList.push(
           <BreadcrumbSection segment={segment} navigationHandler = {navigationHandler} />
        )
        if (index < segments.length -1){
            finalList.push(<span>{' > '}</span>)
        }
    })
    return(
        <div className="Breadcrumbs">
            {finalList}
        </div>
    )

}
export default Breadcrumbs;