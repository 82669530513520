import React from 'react';
import useOpenReading from '../../../../hooks/DocumentHooks/useOpenReading';
import FileExplorer from '../../../home/features/FileExplorer/FileExplorer';
import DeskTopbar from '../../components/DeskTopbar/DeskTopbar';
import move from '../../../../assets/icons/move.svg'; 
import { openOverlay } from '../../../../layouts/Overlay';


import './ReaderViewer.css'
import { useAppDispatch } from '../../../../redux';

type EmptyReaderViewerProps = {
    
};

const EmptyReaderViewer:React.FC<EmptyReaderViewerProps> = () => {

    const openReading = useOpenReading()
    const dispatch = useAppDispatch()

    const newTabHandler = () => {
        dispatch(
            openOverlay({
                heading: {
                    icon: move,
                    title: "Open File",
                },
                component: (
                    <FileExplorer
                        action="openReading"
                        disabledItems={["Writing"]}
                        onClick={openReading}
                    />
                ),
            })
        );
    };
    
    return (
        <div className="EmptyReaderViewer">

            <DeskTopbar>Choose a Source</DeskTopbar>

            {/* Click here */}

            {/* <div className='EmptyReaderViewer-message-container' onClick={newTabHandler}>
                <div className="EmptyReaderViewer-text">
                    Click to open a source
                </div>
            </div> */}
            
            <div className="EmptyViewer">
                <div className='EmptyReaderViewer-FileExplorer'>
                    <FileExplorer
                        action="openReading"
                        disabledItems={["Writing"]}
                        onClick={openReading}
                    />
                </div>

            </div>

            

        </div>
    )
}
export default EmptyReaderViewer;