import React, {useState, useEffect} from 'react';
import Icon from '../../../../../ui/icons/HoverTipIcon/Icon';

import clippingIcon from '../../../../../assets/icons/reader_toolbar/clipping.svg'
import highlightIcon from '../../../../../assets/icons/reader_toolbar/highlighter.svg'
import commentIcon from '../../../../../assets/icons/reader_toolbar/comment.svg'
import stickerIcon from '../../../../../assets/icons/reader_toolbar/sticker.svg'
import emojiIcon from '../../../../../assets/icons/reader_toolbar/emoji.svg'
import rectangleIcon from '../../../../../assets/icons/reader_toolbar/rectangle.svg'
import search from '../../../../../assets/icons/search.svg'


import Toolbar from '../../../components/Toolbar/Toolbar';
import { ReaderTab } from '../../Reader/readerTabsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux';
import { selectNotebookManager } from '../../../writer/notebooks/notebookWorkspaceManagerSlice';
import { IconProps } from '@remirror/react';
import ToolbarIcon, { IconDataType } from './ToolbarIcon/ToolbarIcon';
import { addShortcutListeners } from '../../../../../utils/shortcuts/shortcuts';

import './ReaderToolbar.css'
// import { selectReaderToolbar } from './readerToolbarSlice';
import ReaderFindbar from '../ReaderFindbar/ReaderFindbar';
import {RectangleTooltip, RectangleState} from './tooltips/RectangleTooltip';
import CommentTooltip from './tooltips/CommentTooltip';
import HighlightTooltip from './tooltips/HighlightTooltip';
import { ColorAlias } from '../../../../../utils/Colors';
import { createPortal } from 'react-dom';
import { inactivateReaderToolbar, setActiveTool } from './readerToolbarSlice'
import { Device } from '../../../../../types/devices';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { getOperatingSystem } from '../../../../../utils/os';

type ReaderToolbarProps = {
    tab: ReaderTab
};

export type ReaderTool = "clipping" | "highlighter" | "comment" | "rectangle" | "find" | "link"





const ReaderToolbar:React.FC<ReaderToolbarProps> = (props) => {

    const manager = useAppSelector(selectNotebookManager)
    const dispatch = useAppDispatch();
    const ReaderToolbarState = useAppSelector((state) => state.readerToolbar)

    const device: Device = useWindowSize();

    

    const [rectangleState, setRectangleState] = useState<RectangleState>({
        opacity: 30,
        activeColor: "red"
    })

    const [commentState, setCommentState] = useState<{color: ColorAlias}>({
        color: "yellow"
    })

    const [findbarVisible, setFindbarVisible] = useState<boolean>(false)

    const initiateRectangle = (e?:any)=>{
        // defaultClick("rectangle")

        props.tab.manager.initiateRectangle(rectangleState)
        
    }

    const initiateComment = (e?:any)=>{
        // defaultClick("comment")
        console.log("props: ", props)
        props.tab.manager.getLocationFromClick((l:any)=>props.tab.manager.createComment(l, commentState.color), "comment")
    }

    const initiateClipping =  (e?: any)=>{
        // defaultClick("clipping")
        console.log("TAB", props.tab)
        props.tab.manager.initiateScreenshot(manager.screenshotCallback)
    }

    const defaultClick = (tool: ReaderTool)=>{
        console.log("SETTING", tool)
        if (ReaderToolbarState.tool === tool){
            dispatch(inactivateReaderToolbar)
        }
        // if (tool !== "find"){
        //     setFindbarVisible(false)
        // }
        else{
            // We need to set the tooltip
            setTimeout(()=>{
                console.log("ACTUALLY SETTING", tool)

                setActiveTool({tool: tool})
            }, 10)
        }
    
    }

    const CmdOrCtrl = (getOperatingSystem() ==="MacOS")?"⌘":"Ctrl"

    const ToolbarIcons: (IconDataType)[] = [
        {
            src: clippingIcon , hoverTip: device === "mobile" ? "" : "Clipping Tool", shortcut: `${CmdOrCtrl} + G`, tool: "clipping" , 
            active: ReaderToolbarState.tool ==="clipping",
            onClick: initiateClipping,
            nonFunctional: device === "mobile",
        },
        // {
        //     src: highlightIcon , hoverTip: "Highlighter", shortcut: 'Ctrl + J', tool: "highlighter" ,
        //     active: ReaderToolbarState.tool ==="highlighter",
        //     tooltip: <HighlightTooltip />,
        //     onClick: (e)=>{
        //         defaultClick("highlighter")
        //     }
        // },
        {
            src: commentIcon , hoverTip: "Comment", tool: "comment" , shortcut: `${CmdOrCtrl} + K`, active: ReaderToolbarState.tool ==="comment",
            tooltip: <CommentTooltip  commentState={commentState} setCommentState={({color})=>setCommentState({color: color})} onClick = {(newCommentState : {color: string})=>{
                // setTimeout(()=>setActiveTool({tool:"comment"}), 0)
                props.tab.manager.getLocationFromClick((l:any)=>props.tab.manager.createComment(l, newCommentState.color), "comment")
            }} />,
            onClick: initiateComment
        },
        {
            src: rectangleIcon , hoverTip: device === "mobile" ? "" : "Rectangle Tool", tool: "rectangle" , shortcut: `${CmdOrCtrl} + R`, 
            active: ReaderToolbarState.tool ==="rectangle",
            tooltip: <RectangleTooltip rectangleState={rectangleState} setRectangleState={setRectangleState} onClick = {(rectState)=>{
                // setTimeout(()=>setActiveTool({tool:"rectangle"}), 0)
                props.tab.manager.initiateRectangle(rectState)
            }}/>,
            onClick:  initiateRectangle,
            nonFunctional: device === "mobile",

        },
        {
            src: search , hoverTip: "Find in PDF", tool: "find" , shortcut: `${CmdOrCtrl} + F`, onClick: ()=>{setFindbarVisible(true)},
            active: ReaderToolbarState.tool ==="find",
        }
    ]

    

    useEffect(() =>{

        if(props.tab.manager){
            addShortcutListeners({
                "find": () =>{
                    //TODO: Some isActiveTab here?
                    setFindbarVisible(true)
                }, 
                "clipping":initiateClipping, 
                "sticky":initiateComment, 
                "shape":initiateRectangle, 
    
            })
        }
        
    }, [props.tab.manager])

    const reader  = document.getElementsByClassName("ReaderViewer active")[0]
    
    return(
        <div className='ReaderToolbar'>
            
            {
                !findbarVisible || device === "laptop" ?
                <Toolbar>
                    {
                        ToolbarIcons.map((iconData, index) => (
                            <ToolbarIcon iconData={iconData} index={index}/>
                        ))
                    }
                </Toolbar> : null
            }

            
            <ReaderFindbar tab = {props.tab} findbarVisible={findbarVisible} setFindbarVisible={setFindbarVisible}></ReaderFindbar>
            
                
            
            {
                ReaderToolbarState.message?
                createPortal(
                <div className={`toolbarMessage ${ReaderToolbarState.isError ? "error" : ""}
                `}>
                    {/*                      ${(readerWidthPixels > 500 && readerWidthPixels < 762) ? "positioning" : ""} */}
                    {ReaderToolbarState.message}
                </div>, reader)
                :null
            }
        </div>

    )
}
export default ReaderToolbar;