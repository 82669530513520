import React from 'react';
import SliderTabs from '../../../../../ui/tabs/SliderTabs/SliderTabs';
import DeskTabsBar from '../../../components/DeskTabsBar/DeskTabsBar';

import notebook_workspace from '../../../../../assets/icons/notebook_workspace.svg'
import annotation_workspace from '../../../../../assets/icons/annotation_workspace.svg'
import homeIcon from '../../../../../assets/icons/home.svg'


import './WriterTabsBar.css'
import ProfileButton from '../../../../home/components/Header/common/ProfileButton/ProfileButton';
import { useAppSelector } from '../../../../../redux';
import { Size } from '../../../../../types/sizes';
import { Workspace } from '../../../../../types/workspace';
import { Device } from '../../../../../types/devices';
import useWindowSize from '../../../../../hooks/useWindowSize';
import Icon from '../../../../../ui/icons/HoverTipIcon/Icon';

type WriterTabsBarProps = {
    setWorkspace: React.Dispatch<React.SetStateAction<Workspace>>
};

const WriterTabsBar:React.FC<WriterTabsBarProps> = (props) => {

    const {readerWidthPixels, isWriterCollapsed, isReaderCollapsed} = useAppSelector(state=>state.separator)

    const writerWidthPixels = window.innerWidth - readerWidthPixels
    // console.log("writerWidthPixels: ", writerWidthPixels)

    var size:Size = "n"

    if (writerWidthPixels<200 || isWriterCollapsed ){
        size="xs"
    }
    else if (writerWidthPixels<350  ){
        size="s"
    }

    const device: Device = useWindowSize();

    return (
        <DeskTabsBar space="Writer">

            {
                (device === "mobile" || isReaderCollapsed) ?
                <Icon
                    src={homeIcon}
                    className="WriterTabs-home-icon"
                    onClick={() => window.open("/", "_self")}
                ></Icon>    
                :null
            }

            <div className={`WriterTabsBar ${size} ${device}`}>

                <span className='Workspace-text'>Workspace: </span>

                <div className="sliderTabs-container">
                <SliderTabs
                    icons={[notebook_workspace, annotation_workspace]}
                    scaleFactor={0.7}
                    appTheme="light"
                    setMenuPage = {(index:number)=>{
                        console.log(index)
                        if(index===1){
                            props.setWorkspace("Notebooks")
                        }
                        else if(index===2){
                            props.setWorkspace("Annotations")
                        }
                        else{
                            props.setWorkspace("Clipboard")
                        }
                    }}
                />
                </div>
                <></>
                <div className="writerTabsBar-ProfileButton">
                    <ProfileButton size="s" space="Writer" />
                </div>

            </div>
        </DeskTabsBar>    
    )
}

export default WriterTabsBar;