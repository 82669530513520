import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import arrowIcon from '../../../../../assets/icons/right_arrowhead.svg'
import Clickout from '../../../../../layouts/Clickout/Clickout';
import { useAppDispatch, useAppSelector } from '../../../../../redux';
import { setToolbar, setTopbarDropper } from '../../../reader/Reader/readerLayoutSlice';


import './TopbarDropper.css'

type TopbarDropperProps = {
    children: React.ReactNode, 
    dropdown?: React.ReactNode,
};

const TopbarDropper:React.FC<TopbarDropperProps> = ({children,dropdown, ...props}) => {

    // const state = useAppSelector(state => state.readerLayout.is)

    const [dropperIsVisible, setDropperIsVisible] = useState<boolean>(false)
    //local state is used to determine if the dropper of this component is to be made visible

    const dispatch = useAppDispatch()

    const topbarDropper = useAppSelector(state =>state.readerLayout.topbarDropper)
    //global redux state is to be used to determine whether toolbar is hidden


    const openDropdown = () =>{

        dispatch(setTopbarDropper(true))
        console.log("gonna open dropdown: ", dropdown)
        setDropperIsVisible(true)
    }

    const closeDropdown = () =>{

        dispatch(setTopbarDropper(false))
        console.log("gonna close dropdown: ", dropdown)
        setDropperIsVisible(false)
    }
    
    
    return (
        <div className="TopbarDropper" onClick={openDropdown}>
            {children}

            <img src={arrowIcon} className="Topbar-arrowIcon" alt="arrow"/>

            {
                dropperIsVisible?
                <Clickout closeFunction={closeDropdown}>
                    <div className="topmenu-dropdown">
                        {dropdown}
                    </div>
                </Clickout>
                
                :
                null
            }
            

        </div>
    )
}
export default TopbarDropper;