import React, { useState } from 'react';
import { Size } from '../../../types/sizes';
import './Menu.css';
import MenuElement from './MenuElement';


export type DisplayMenu = {
    menu: DisplayMenuItem[], 
    spacing?: Size, 
    size?:Size,
    closeDisplayMenu?: ()=>void,
};

export type DisplayMenuItem = {
    label? : string,
    icon? : string,
    handler?: (event: React.MouseEvent<HTMLDivElement>) => void,
    type?: "warning" | "toggle" | "separator" | "header" | null,
    toggleStates?: {
        left: ToggleOption
        right: ToggleOption
        active?: boolean //true if right
    },
    directComponent?: React.ReactNode
} | {
    label? : string,
    icon? : string,
    type: "file",
    handler?: (file: File) => void
    directComponent?: React.ReactNode
    toggleStates?: {
        left: ToggleOption
        right: ToggleOption
        active?: boolean //true if right
    }
} 

type ToggleOption = {
    icon: string,
    handler: () => void
}


const Menu:React.FC<DisplayMenu> = ({menu, spacing="n", size="n", closeDisplayMenu}) => {
    
    return (
        <div className={`Menu ${size}`}>
            {/* <div className='triangleElement'>&#9650;</div> */}
            <ul className='list'>
                {menu.map(MenuItem => 

                    (MenuItem.directComponent!=null) ? 

                    <div>{MenuItem.directComponent}</div> :

                    <div className={`li ${spacing}`}>
                        {
                            //Menu Element
                        (MenuItem.type == "warning" || MenuItem.type=="toggle" || MenuItem.type==null) ?
                            <MenuElement MenuItem={MenuItem} closeDisplayMenu={closeDisplayMenu}></MenuElement> : null
                        }

                        {
                        //separator line
                        (MenuItem.type=="separator") ? <hr className='separator'/> : null
                        }

                        {
                        //Header
                        (MenuItem.type=="header") ? <div className='header'>{MenuItem.label?.toUpperCase()}</div> : null
                        }

                        {
                            MenuItem.type === "file" ? 
                            <label>

                            <div className='dropdownElement' >
                                <div className='clickable'>

                                <img src={MenuItem.icon} className="Menu-icon"/>
                                <div className='label'>{MenuItem.label}</div>
                                <input onChange = {(e)=>{
                                console.log(e)

                                MenuItem.handler!(e.target.files![0])
                                
                
                                }}
                                type="file" accept="application/pdf" style = {{display:"none"}}>

                                </input>
                                </div>

                            </div>
                            </label>

                             : 
                            null
                        }

                    </div> 
                )}
            </ul>
        </div>
    );
}

export default Menu;