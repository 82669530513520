import React, { useRef } from "react";
import "./HeadingToolbar.css";
import arrowLight from '../../../../../../assets/legacy_icons/find_arrow.png'
import arrowDark from '../../../../../../assets/legacy_icons/find_arrow_white.png'
import useWindowSize from "../../../../../../hooks/useWindowSize";

export const HeadingToolbar = (props) => {
    let item = props.item
  const dropdownRef = useRef()
  let hideRegularDropdown = (e)=>{
    console.log("temp called from", e.target.id)
    if (e.target.id !== "dropdownBtn_span" && e.target.id !== "dropdownBtn_img") {
        console.log("hiding from temp")
        dropdownRef.current.style.display = "none"
        window.removeEventListener("click", hideRegularDropdown)
    }
  }
  let toggleDropdown = (e)=>{
    
    let style = dropdownRef.current.style.display
    console.log("toggledrowpdown", style)
    if (style == "" || style == "none"){
        dropdownRef.current.style.display = "block"
        console.log("style none")
        window.addEventListener("click", hideRegularDropdown)
    }
    else if (style == "block"){
        console.log("style block. Hiding from toggle")
        dropdownRef.current.style.display = "none"
        window.removeEventListener("click", hideRegularDropdown)
    }
    
    
  }
  let arrow = props.theme == "light" ? arrowLight : arrowDark

  const device = useWindowSize();
  return (<span className = "dropdown">
                    <span id = "dropdownBtn_span" className={`dropbtn ${props.theme}`} onMouseDown ={(e)=>{e.preventDefault()}} onClick = {(e)=>toggleDropdown(e, "heading")}>{item.active()==="default"?'Regular':'H'+item.active()}
                        <img src={arrow} className="dropbtn-icon" alt="down" id = "dropdownBtn_img"></img>
                    </span>
                    <div ref = {dropdownRef} className = {`dropdown-content ${props.theme} ${device}`}>
                        <div className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler('default')}}>Regular</div>
                        <h1 className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler(1)}}>H1</h1>
                        <h2 className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler(2)}}>H2</h2>
                        <h3 className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler(3)}}>H3</h3>
                        <h4 className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler(4)}}>H4</h4>
                        <h5 className = {`editor-tool ${props.theme}`}  onMouseDown={(e)=>{e.preventDefault()}} onClick={(e)=>{e.preventDefault();item.handler(5)}}>H5</h5>

                    </div> 
            </span>)
}