import { selectReaderTabs, setActiveTabIndex } from "../../pages/desk/reader/Reader/readerTabsSlice"
import { useAppDispatch, useAppSelector } from "../../redux"
import { ReadingDocument } from "../../types/files"
import { fetchReadingFromID } from "../../pages/desk/desk-logic/cachedDataManager"
import { addTab } from "../../pages/desk/reader/Reader/readerTabsSlice"

const useOpenReading: () =>(reading: ReadingDocument) =>void = () =>{

    const dispatch = useAppDispatch()
    const readerTabs = useAppSelector(selectReaderTabs)

    return (reading: ReadingDocument) =>{

        const exists = readerTabs.tabs.find(x =>x.readingDocument.objectID === reading.objectID)
        if (exists){
            dispatch(setActiveTabIndex(exists.readingDocument.objectID))
        }
        else{
            // We need to dispatch a new tab, but set the last location to the sourceInfo here.
            // TODO: add fetch objectID logic
            fetchReadingFromID(reading.objectID).then((readingDoc: ReadingDocument)=>{
                dispatch(addTab(
                    readingDoc
                ))
            })
        }       
    }
}

export default useOpenReading