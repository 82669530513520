import { formatEmail, getErrorMessage, updateUserEnvironment } from "./AuthUtils";
import {Auth, API} from 'aws-amplify'
import { update } from "lodash";


export const signIn = async(email, password, options = {updateUserEnvironment : true}) => {
    /**
     * @param updateUserEnvironment true to update electron/web field for User in dynamo
     */


    return new Promise (async(resolve, reject) =>{

        var errors = []

        if(!email) errors.push({field: "email"})
        if(!password) errors.push({field: "password"})

        if(errors.length) reject(errors)


        try {
            let user

            try{   
                user  = await Auth.signIn(formatEmail(email), password);
                resolve(user)
            }
            catch(e){
                if(e.code=="UserNotFoundException"){
                    //Here we have to try signing again because of case sensitivity
                    user  = await Auth.signIn(email.trim(), password);
                    resolve(user)
                }
                else{
                    reject(getErrorMessage(e))
                }
            }
    
        } catch (error) {
            console.log("error: ", error)
            reject(getErrorMessage(error))
        }
        
    })
}

export const sendResetPasswordCode = (email) =>{

    return new Promise((resolve, reject) =>{

        Auth.forgotPassword(email)
            .then(data => {
                resolve(data)
            })
            .catch(err => {
                console.error("Auth.forgotPassword: ", err)
                reject(getErrorMessage(err))
            });

    })
}

export const forgotPasswordSubmit = (userData ) =>{
    /**
     * @params
     * This is for Forgot Password flow
     */

    const {email, code, password, confirmPassword} = userData

    return new Promise((resolve, reject) =>{

        console.log("password: ", password, confirmPassword)

        var errors = []

        if(password!==confirmPassword){
            errors.push({
                field: "confirmPassword",
                message: "Passwords don't match"
            })  
        } 
        if(password.length<8){
            errors.push({
                field: "newPassword",
                message: "Password must be 8 characters long."
            })
        }

        if(errors.length) reject(errors)

        Auth.forgotPasswordSubmit(email, code.trim(), password)
            .then(data =>{
                signIn(email, password)
                .then((user) => {
                    console.log("signed in after forgotPasswordSubmit: ", user)
                    resolve(user)
                })
                .catch(e=>reject(e))
            })
            .catch(err=>{
                console.log("ERROR: ", err.message, )
                console.log("CODE: ", err.code)
                reject(getErrorMessage(err))
            })
    })
}

export const completeNewPassword = (tempUser, email, newPassword, confirmNewPassword) =>{
    /**
     * This is for FORCE_CHANGE_PASSWORD users
     */

    return new Promise(async (resolve, reject) =>{

        if(newPassword!==confirmNewPassword){
            reject ([{
                field: "confirmNewPassword",
                message: "Passwords don't match"
            }])  
            return
        }

        console.log("tempUser: ", tempUser, newPassword)

        try{

            await Auth.completeNewPassword(tempUser, newPassword)

            signIn(email, newPassword)
            .then(user=>{
                console.log("completeNewPassword Success: ", user)
                resolve(user)
            })
            .catch(e=>{
                console.log("completeNewPassword Error: ", e)
                reject(e)
            })
        }
        catch(e){
            console.log("Could not complete new pass: ", e)
            reject([{
                message: "An unkown error occurred. Please restart the app. "
            }])
        }
        
    })
    
}