import React from 'react';
import ColorSelector from '../../../../../../ui/inputs/ColorSelector/ColorSelector';
import { basicColors, ColorAlias } from '../../../../../../utils/Colors';

export type ColorFilterProps = {
    selectedColors: ColorAlias[],
    setSelectedColors: (selectedColors: ColorAlias[]) =>void,
};

const ColorFilter:React.FC<ColorFilterProps> = (props) => {
    
    const setActiveColors = (color:any) =>
    {
        const activeColors = props.selectedColors
        if(activeColors.length==basicColors.length) {
            //all Colors are selected
            props.setSelectedColors([color])
            return
        }
        
        const index = activeColors.indexOf(color)
        var temp = [...activeColors]
        if(index > -1) {
            temp.splice(index, 1)
        }
        else{
            temp.push(color)
        }
        props.setSelectedColors(temp)
    }

    return(
        <div>
            <p>Filter by <b>Colour</b></p>
            <div className="colorpicker-annotationsFilter">
                <ColorSelector 
                    activeColors = {props.selectedColors} 
                    onClickColor = {setActiveColors}
                    transparent={false}
                />
            </div>
 
        </div>
    )
}
export default ColorFilter;

