import React from 'react';
import close from '../../../assets/icons/close.svg'
import tick from '../../../assets/icons/tick.svg'
import { useAppDispatch, useAppSelector } from '../../../redux';

import './FileUploadProgress.css'

import fileUploadProgressSlice, { clearAllProgress } from './fileUploadProgressSlice';

const FileUploadProgress:React.FC = () => {

    let numCompleted = 0
    let numOngoing = 0

    

    const {download, uploadsInProgress} = useAppSelector(state =>state.fileUploadProgress)
    const dispatch = useAppDispatch()

    const clearUploadStatus = () =>{
        dispatch(clearAllProgress())
    }

    // console.log("progress: ", uploadsInProgress, Object.entries(uploadsInProgress))

    for (const [key, value] of Object.entries(uploadsInProgress)){
        // console.log("hello: ", [key, value] )
        if(value.progress === 101) numCompleted +=1
        else numOngoing +=1
    }

    // console.log("Total: ", numCompleted + numOngoing )

    
    return (
        <div className={`uploads-container`} style={{display: (numCompleted + numOngoing)?"block":"none"}}>
            {!numOngoing?<img src={close} className={`closePopup-icon`} onClick={clearUploadStatus} alt="close"></img>:null}
            <div className="upload-header">
                {!download?(numOngoing?`${numOngoing} upload${numOngoing==1?'':'s'} in progress`:`${numCompleted} upload${numCompleted==1?'':'s'} completed`):null}
            </div>
            
            {
                Object.entries(uploadsInProgress).map((upload, index)=>{

                    const _fileId = upload[0]
                    const _file = upload[1].file
                    const _progress = upload[1].progress

                return(

                    <div className='progress-row' key={index}>
                        <span className="progress-fileName">{_file.name}</span>
                        <div className="progress-bar-container" >
                            <div className={`progress-bar-fill`} style={{
                                width:`${_progress}%`
                            }}>.</div>
                        </div>
                        {
                            (_progress!==101)?
                            <span className="percentage">
                                {Math.min(_progress, 99)}%
                                
                            </span> 
                            :
                            <img src={tick}  alt="check" className="upload-done-icon"></img>
                        }
                    </div>
                )

            })
                    
                    
            }
            
        </div>
    )
}
export default FileUploadProgress;