import { store } from "../../../redux"
import { ReadingDocument, WritingDocument } from "../../../types/files"
import { uncollapseReader, uncollapseWriter } from "../components/Separator/separatorSlice"
import { addTab, setActiveTabIndex } from "../reader/Reader/readerTabsSlice"
import { setLoadingNewNotebook } from "../writer/notebooks/fetchedNotebooksSlice"
import { createAnnotation, deleteAnnotation, getSingleAnnotation } from "./annotationSaver"
import { fetchNotebookFromID, fetchReadingFromID } from "./cachedDataManager"
import { RenderOnSourceOptions, SourceMetadata, UnrenderOnSourceOptions } from "./metadata"
import { getNotebook } from "./notebookSaver"


export  const initiateScreenshot=  ()=>{
        const activeTab = store.getState().readerTabs.activeTab
        const notebookManager = store.getState().notebookWorkspaceManager.manager
        activeTab?.manager.initiateScreenshot(notebookManager.screenshotCallback)
    }

export const navigateToSource = (sourceMetadata: SourceMetadata, assetType: {name: string, key: string})=>{
        const readerTabs = store.getState().readerTabs
        store.dispatch(uncollapseReader())
        console.log(readerTabs)
        if (readerTabs.activeTab?.readingDocument.objectID === sourceMetadata.objectID){
            readerTabs.activeTab?.manager.navigateToSource(sourceMetadata, assetType)
        }
        else {
            // look for the tab in the list of tabs.
            const exists = readerTabs.tabs.find(x =>x.readingDocument.objectID === sourceMetadata.objectID)
            if (exists){
                exists.manager.setBufferFunction(()=>{
                    exists.manager.navigateToSource(sourceMetadata, assetType)
                })
                store.dispatch(setActiveTabIndex(exists.readingDocument.objectID))
            }
            else{
                // We need to dispatch a new tab, but set the last location to the sourceInfo here.
                // TODO: add fetch objectID logic
                fetchReadingFromID(sourceMetadata.objectID).then((readingDoc: ReadingDocument)=>{
                    store.dispatch(addTab(
                        {
                            ...readingDoc,
                            lastLocation: sourceMetadata.sourceInfo
                        }
                    ))
                })
                
            }
        }
}

export const getLocationFromClick = (callback: (x:SourceMetadata)=>void) =>{
    const activeTab = store.getState().readerTabs.activeTab
    activeTab?.manager.getLocationFromClick(callback)
}


export const renderOnSource = (x: RenderOnSourceOptions)=>{
    // Store x using saver function
    // (this is on the active tab - except if it's on copy paste!)

    //TODO: in case of copy paste from elsewhere, it will not take care of this
    console.log("CALLED RENDER ON SOURCE", x)
    const activeTab = store.getState().readerTabs.activeTab
    if (activeTab){
        const anno = activeTab?.manager.renderOnSource(x)
        createAnnotation(anno)
    }

}

export const unrenderOnSource = async (x: UnrenderOnSourceOptions)=>{
    console.log("CALLED UNRENDER ON SOURCE", x)
    const tabs = store.getState().readerTabs.tabs
    const exists = tabs.find(tab=>tab.readingDocument.objectID === x.sourceMetadata.objectID)
    if (exists){
        exists.manager.unrenderOnSource(x)
    }
    const anno = await getSingleAnnotation(x.id)
    if (anno){
        
        deleteAnnotation(anno)
        
    }
}

export const navigateToNotebook = async (nbData: {"objectID": string, storedLocation: string}, nodeDetails: {annoType: "extract" | "clipping" | "link", id: string}) => {
        store.dispatch(setLoadingNewNotebook(true))
        store.dispatch(uncollapseWriter())
        const notebookManager = store.getState().notebookWorkspaceManager.manager
        const resp = await fetchNotebookFromID(nbData)
        if (resp){
            notebookManager.navigateToNotebook(resp, nodeDetails)
        }
        else{
            // TODO: error here
            store.dispatch(setLoadingNewNotebook(false))
        }
    
}
