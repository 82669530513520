import React, { Component, useState } from 'react'
import './DoublerangeSlider.css'
import { useRef } from "react";
import PropTypes from "prop-types";




function DoublerangeSlider({ min, max, minValue, maxValue, setPageRange }) {
    /**
     * @props
     * min: the minimum possible value, minValue: the starting lower bound of the range
     * max: the maximum possible value, maxValue: the starting upper bound of the range
     * appTheme
     */

    const [minVal, setMinVal] = useState(minValue);
    const [maxVal, setMaxVal] = useState(maxValue);

    const [minInput, setMinInput] = useState(minValue)
    const [maxInput, setMaxInput] = useState(maxValue)

    var minGap = 0;

    const minValRef = useRef(min);
    const maxValRef = useRef(max);


    DoublerangeSlider.propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        setPageRange: PropTypes.func.isRequired
    };

    const setRange = (lo, hi, options = {setInput: true, setSlider: true }) =>{
        const {setInput, setSlider} = options
        console.log("SET RANGE: ", lo, hi)
        const lower = parseInt(lo)
        const upper = parseInt(hi)
        if(setInput){
            setMinInput(lower)
            setMaxInput(upper)
        }
        if(setSlider){
            setMinVal(lower)
            setMaxVal(upper)    
        }
        const pageRange = {minPageNumber: lower, maxPageNumber: upper}
        console.log("pageRange: ", pageRange)
        setPageRange({minPageNumber: lower, maxPageNumber: upper})

    }

    const sliderChange = (e) =>{
        if (e.target.id === 'slider-1' || e.target.id === 'range-1'){
            if(maxVal-e.target.value <= minGap){
                console.log("maxValue is crossing!")
                console.log('maxVal - minGap', maxVal, minGap)
                const lower = parseInt(maxVal) - minGap
                setRange(lower,maxVal)
 
            }
            else{
                setRange(parseInt(e.target.value),maxVal)
            }
        }
        if(e.target.id === 'slider-2' || e.target.id === 'range-2'){

            if(e.target.value-minVal <= minGap){
                console.log("maxValue is crossing!", minGap)
                const upper = parseInt(minVal) + minGap
                setRange(minVal, upper)

            }
            else{
                setRange(minVal,parseInt(e.target.value))

            }
        }

    }

    const inputChange = (e) =>{
        if(e.target.id==="range-1"){
            setMinInput(parseInt(e.target.value))
        }
        else{
            setMaxInput(parseInt(e.target.value))
        }
        // document.addEventListener("click", doubleRangeSubmitInput)
    }

    const doubleRangeSubmitInput = () =>{

        console.log("doubleRangeSubmitInput", minInput, maxInput)

        var lower = parseInt(minInput)
        var upper = parseInt(maxInput)

        console.log("lower, upper" ,lower, upper)

        if(lower>upper){
            lower = upper
        }
        if(upper < lower){
            upper = lower
        }

        setRange(lower, upper)
        document.removeEventListener("click", doubleRangeSubmitInput)

    }

    const onEnterKey = (e) =>{
        if(e.key==="Enter"){
            console.log("ENTER: ", minInput, maxInput)
            doubleRangeSubmitInput()
        }
    }

    return (
       
        <>
        <div className = {`slider-wrapper`}>
            <div className = {`dr-slider-container`}>
                <div className = {`slider-track`}></div>
                <input className="double-slider-range" type = "range" min={min} max={max} value={minVal} ref = {minValRef} onChange = {sliderChange} id="slider-1"></input>
                <input className="double-slider-range" type = "range" min={min} max={max} value={maxVal} ref = {maxValRef} onChange = {sliderChange} id="slider-2"></input>
            </div>
            
        </div>
        {/* <div className = {`slider-value`}> */}
            <input className = {`pagenum-slider-input left`} type='number'id='range-1' value={minInput} onChange={inputChange} onKeyDown = {onEnterKey}></input>
            <input className = {`pagenum-slider-input right`} type='number' id='range-2' value={maxInput} onChange={inputChange} onKeyDown = {onEnterKey}></input>
        {/* </div> */}
        </>
    )
}

export default DoublerangeSlider


