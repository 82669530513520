import React, {useContext, useState} from 'react';
import { openReading, openWriting } from '../../../../../../features/files/openFile';

import { DocumentInfoProps } from '../../../../../../types/files';
// import more_button from '../../../../../../assets/icons/more_button.svg'
import more_button from '../../../../../../assets/legacy_icons/more.png'


import SimpleThumbnail from '../SimpleThumbnail/SimpleThumbnail';

import './ThumbnailLamination.css'

import useFileContextMenu from '../../../../../../features/files/useFileContextMenu';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../types/devices';

const ThumbnailLamination:React.FC<DocumentInfoProps & {onClick: ()=>void, zoom ?: number, screen? :string}> = (props) => {

    const showFileContextMenu = useFileContextMenu()


    // const openDocumentInfoOverlay = (
    //     event: React.MouseEvent<HTMLDivElement>
    // ) => {
    //     event?.preventDefault();
    //     console.log("Need to open overlay");
    //     dispatch(
    //         openOverlay({
    //             heading: {
    //                 icon: "",
    //                 title: "Edit",
    //                 data: props.objectData.name,
    //             },
    //             component: <DocumentInfo {...props} />,
    //         })
    //     );
    // };

    const openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) =>{
    
        showFileContextMenu(props.objectData, event.clientX, event.clientY)
        
    }

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    //   const onDropDocument = (e: any) => {
    //     console.log("Move Document Here", e.target)
    //     console.log(props.objectData.name)
    //   }

    const onDragDocument = (e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer?.setData("text", JSON.stringify(props.objectData));
    };

    const file: any = {...props.objectData}

    const device: Device = useWindowSize();
    
    return (
        <div
            className={`Document ${props.fileType} ${device} ${props.screen}`}
            id={props.objectData.objectID}
            draggable={true}
            onDragStart={(e) => {
                onDragDocument(e);
            }}
            onDragOver={(e) => {
                e.preventDefault();
            }}
            onContextMenu={(e) => {
                e.preventDefault()
                openFileContextMenu(e);
                e.stopPropagation();
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{
                zoom: props.zoom ?? '1' 
            }}
            
        >
            <div className={`more-button ${isHovering ? "hovered" : ""} ${device == "mobile" ? "hovered" : ""}`}>
                <img
                    className="more-icon"
                    src={more_button}
                    alt=""
                    onClick={(e) => {
                        // openDocumentInfoOverlay(e);
                        openFileContextMenu(e)
                        e.stopPropagation();
                    }}
                />
            </div>

            <SimpleThumbnail device={device} file={file} onClick={
                ()=>{
                    // if (props.fileType === "Reading"){
                    //     openReading(props.objectData.objectID)
                    // }
                    // else if(props.fileType==="Writing"){
                    //     openWriting(props.objectData.objectID)
                    // }
                    props.onClick()
                }
            } size="n" />

        </div>
    )
}
export default ThumbnailLamination;