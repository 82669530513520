import { API } from 'aws-amplify';
import React, {useEffect, useRef, useState} from 'react';
import { DocumentInfoProps, ObjectUser } from '../../../../../../../types/files';
import './SearchBox.css'
import SearchInput from './SearchInput/SearchInput';
import SearchResults from './SearchResults/SearchResults';
import fuzzysort from 'fuzzysort'
import { Device } from '../../../../../../../types/devices';
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import { useEffectOnce } from '../../../../../../../hooks/useEffectOnce';

type SearchBoxProps = {
    
};

const getAllQuery = /* GraphQL */ `
query MyQuery {
    getAll{
      parentID
      permission
      name
      objectID
    }
}
`;

export type SearchResult = {
    highlightedName: (string | JSX.Element)[] | null,
    objectUser: ObjectUser
}

const SearchBox:React.FC<SearchBoxProps> = () => {
    
    const [searchString, setSearchString] = useState<string>('')

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const [initLoading, setInitLoading] = useState<boolean>(true)
    const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

    const filesToSearch = useRef()

    const runSearch = (val: string) => {

        setSearchInProgress(true)

        console.log("filesToSearch: ", filesToSearch)
        console.log("searching for: ", val, searchString)

        if(!filesToSearch.current) {
            //files not loaded yet
            console.log("files not loaded yet!")
            return
        }

        const fuzzyResults = fuzzysort.go(val, filesToSearch.current, {key:'name'})

        const searchResults = fuzzyResults.map(res=>{
            return{
                highlightedName: fuzzysort.highlight(res, (m,i) =><span className="bolden" key={i}>{m}</span>),
                // highlightedName: fuzzysort.highlight(res) as string,
                objectUser: res.obj as ObjectUser
            }
        })

        console.log("searchResults: ", searchResults)

        // const fileResults: ObjectUser[] = results.map(res => res.obj as ObjectUser)

        // const test = results.map(result =>fuzzysort.highlight(result))
        // console.log("highlighted: ", test)

        // console.log("results: ", results)
        setSearchResults(searchResults)
        setSearchInProgress(false)
        // return fileResults;
    }

    useEffectOnce(() =>{
        
        // setInitLoading(true)

        (API.graphql({query: getAllQuery}) as any)
        .then((resp:any) =>{
            console.log("got all response: ", resp)
            filesToSearch.current = resp.data.getAll
            console.log("files loaded")
            
        })
        .catch((err:any)=>{
            console.error("couldn't getall: ", err)
        })
        .finally(() =>{
            setInitLoading(false)
        })

    })

    useEffect(() =>{

        if(!initLoading){
            //done with initial loading. Maybe want to run a search?
            console.log("Done with init Loading!")
            runSearch(searchString)
        }

    }, [initLoading])

    const device: Device = useWindowSize();

    return (
        <div className={`searchBox ${device}`}>
            {
                // initLoading?
                // "Loading..."
                // :

                <>
                    <SearchInput setSearchString = {setSearchString} runSearch={runSearch}></SearchInput>

                    <SearchResults initLoading={initLoading} searchInProgress={searchInProgress} searchResults={searchResults}></SearchResults>

                </>

            }
        </div>
    );
}
export default SearchBox;