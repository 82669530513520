import { ReactNode, useRef, useState } from "react";
import { ColorMap } from "../../../../../../../utils/Colors";
import { PDFStickyNoteAnnotation } from "../../PdfManager";
import './PdfStickyNote.css'
import dots from "../../../../../../../assets/legacy_icons/three_dots.png"
import {
    PdfMovableAnnotationComponent,
    PdfMovableAnnotationComponentProps,
    PdfMovableAnnotationProps,
} from "./PdfMovableAnnotation";

export const PdfStickyNote = (props: (PdfMovableAnnotationProps & {fontSize: string})) => {
    const clicked = useRef(0)
    const annotation = props.annotation as PDFStickyNoteAnnotation
    const [actualText, setActualText] = useState(annotation.renderInfo.text)
    const finalProps: PdfMovableAnnotationComponentProps = {
        ...props,
        rndStyle: {},
        children: (
            size: { width: number; height: number },
            topLeft: { x: number; y: number }
        ) => {
            var handleHeight = 15 * props.scale
            handleHeight = handleHeight > size.height ? 0.1 * size.height : handleHeight
            const selectionHandleIconStyle = {
                height: handleHeight*0.7,
                width: handleHeight*0.7,
                top:handleHeight*0.1,
                right: handleHeight*0.3,
                }
            return(
                <div className = "flexContainer shadow" style = {{
                    width:`calc( ${size.width}px)`, height:size.height + "px",
                    backgroundColor: ColorMap[annotation.renderInfo.color]
                    
                }}>
                  
             
                  <div className = "selectionHandle stickyTopBar" onContextMenu = {(event)=>{
                      event.preventDefault()
                      event.stopPropagation()
                      props.showContextMenu(event)
                  }}
                  style = {{
                    height: handleHeight + "px", 
                    width: `calc( ${size.width}px)`,

                    backgroundColor : ColorMap[annotation.renderInfo.color],
                    }}>
                      <img className="selectionhandle-icon" style={selectionHandleIconStyle} src={dots} alt="..." 
                        onClick={
                            (event) =>{
                                event.preventDefault()
                                event.stopPropagation()
                                props.showContextMenu(event)
                            }
                        }
                        ></img>
                  
                  </div>
                  <div style= {{padding: "10px", width: `calc( ${size.width-20}px - 4px)`, height:`calc(100% - ${handleHeight+20}px)`}}>
                  <textarea className = "commentBox" readOnly = {false} id = {annotation.id} value = {actualText} 
                      onContextMenu = {(event)=>{
                          event.preventDefault()
                          event.stopPropagation()
                          props.showContextMenu(event)
                      }}
                      style = {
                        {fontSize: props.fontSize,
                          width: `100%`, height:`100%`, 
                          backgroundColor : ColorMap[annotation.renderInfo.color]}}
                      spellCheck = "false"
                      onChange = {(e)=>
                        {
                            console.log("CHANGING", e)
                            const newAnnotation = {...annotation}
                            newAnnotation.renderInfo.text = e.target.value
                            setActualText(e.target.value)
                            props.updateAnnotation(newAnnotation)
                        }}
                      placeholder = "Comment here..."
                  ></textarea>
                  </div>
                  
  
              </div>)
        },
    };

    return (
        <>
            <PdfMovableAnnotationComponent
                {...finalProps}
            ></PdfMovableAnnotationComponent>
        </>
    );
};
