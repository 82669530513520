import React, { useEffect, useRef, useState } from 'react';
import Topbar from '../../components/DeskTopbar/DeskTopbar';
import DocumentTitle from '../../components/DeskTopbar/DocumentTitle/DocumentTitle';
import search from '../../../../assets/icons/search.svg'
import filter from '../../../../assets/icons/filter.svg'

import './AnnotationsWorkspace.css'
import TopbarTitle from '../../components/DeskTopbar/TopbarTitle/TopbarTitle';
import { useAppSelector } from '../../../../redux';
import { Size } from '../../../../types/sizes';
import Clickout from '../../../../layouts/Clickout/Clickout';
import AnnotationsFilterSelector from './AnnotationsFilterSelector/AnnotationFilterSelector';
import {AnnotationFilterTopic, PageNumberFilterType, DocumentFilterType } from './annotationTypes';
import AnnotationsFilterPreview from './AnnotationsFilterPreview/AnnotationsFilterPreview';

import extract_icon from '../../../../assets/icons/extract.svg'
import highlight_icon from '../../../../assets/icons/reader_toolbar/highlighter.svg'
import strike_icon from '../../../../assets/icons/strike.svg'
import underline_icon from '../../../../assets/icons/underline.svg'
import comment_icon from '../../../../assets/icons/comment.svg'
import sticker_icon from '../../../../assets/icons/stickers/sticker.png'
import emoji_icon from '../../../../assets/icons/stickers/happy.png'
import rectangle_icon from '../../../../assets/icons/reader_toolbar/rectangle.svg'
import AnnotationsList from './AnnotationsList/AnnotationsList';
import { ReadingDocument } from '../../../../types/files';
import { basicColors } from '../../../../utils/Colors';
import { allPDFAnnotations, PDFAnnotation, PDFAnnotationType } from '../../reader/viewers/PDF/PdfManager';

import linkIcon from '../../../../assets/icons/link.svg'
import rectangleIcon from '../../../../assets/icons/reader_toolbar/rectangle_icon.svg'
import commentIcon from '../../../../assets/icons/comment.svg'
import clippingIcon from '../../../../assets/icons/reader_toolbar/clipping.svg'

type AnnotationsWorkspaceProps = {
    totPages: number, 
};

const AnnotationsWorkspace:React.FC<AnnotationsWorkspaceProps> = (props) => {


    const [searchString, setSearchString] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("") //debounced version of searchString

    const searchTimer = useRef<any>(null)

    const [showFilterSelector, setShowFilterSelector] = useState<boolean>(false)
    const [activeFilterTopic, setActiveFilterTopic] = useState<AnnotationFilterTopic>("Annotation Type")

    const [pageNumberFilter, setPageNumberFilter] = useState<PageNumberFilterType>({minPageNumber: 1, maxPageNumber: props.totPages})
    const [selectedColors, _setSelectedColors] = useState<any>(basicColors)

    const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState<PDFAnnotationType[]>(allPDFAnnotations)

    const [documentFilter, setDocumentFilter] = useState<DocumentFilterType>({primFilter: "tabs"})

    const readerWidthPixels = useAppSelector(state=>state.separator.readerWidthPixels)
    const writerWidthPixels = window.innerWidth - readerWidthPixels
    var size: Size = "n"

    const annotations = useAppSelector(state=>state.annotationsPane)
    console.log("annotations: ", annotations)

    if(writerWidthPixels < 350 ){
        size="s"
    }

    useEffect(() =>{
        
    })

    const setSelectedColors = (arr:any) =>{
        console.log("SETTING", arr)
        if (arr.length === 0){
            _setSelectedColors(basicColors)
            return
        }
        
        _setSelectedColors(arr)
    }

    const commonProps = {

        totPages:  props.totPages,
        activeTopic: activeFilterTopic,
        setActiveFilterTopic: setActiveFilterTopic,

        filterText: filterText,

        pageNumberFilter : pageNumberFilter,
        setPageNumberFilter : setPageNumberFilter,

        selectedColors : selectedColors,
        setSelectedColors : setSelectedColors,
        
        selectedAnnotationTypes: selectedAnnotationTypes,
        setSelectedAnnotationTypes: setSelectedAnnotationTypes,
        allAnnotationTypes: allPDFAnnotations, 

        documentFilter: documentFilter,
        setDocumentFilter: setDocumentFilter,
    }

    const onChangeSearchString = (e: any) =>{

        const debounceTime = 500

        console.log("value: ", e.target.value)
        setSearchString(e.target.value)

        if(searchTimer.current!==null){
            clearTimeout(searchTimer.current)
        }
        searchTimer.current = setTimeout(function(){
            console.log("debounced search: ", e.target.value)
            setFilterText(e.target.value)
        }, debounceTime)
        
    }

    const getAnnotationIcon = (annoType: PDFAnnotationType) =>{

        console.log("getAnnotationIcon: ", annoType)

        if (annoType === "extract"){
            return extract_icon
        }
        if(annoType==="highlight") {
            return highlight_icon
        }
        // else if(annoType==="Strikethrough"){
        //     return strike_icon
        // }
        // else if(annoType==="Underline"){
        //     return underline_icon
        // }
        else if(annoType==="sticky"){
            return commentIcon
        }
        // else if(annoType==="Sticker"){
        //     return sticker_icon
        // }
        // else if(annoType==="Emoji"){
        //     return emoji_icon
        // }
        else if(annoType==="rectangle"){
            return rectangle_icon
        }
        else if (annoType==="link"){
            return linkIcon
        }
        else if (annoType==="clipping"){
            return clippingIcon
        }
        else if (annoType==="thread"){
            throw new Error("Threads not supported!")

        }

        return ""
    }
    
    return(
        <>
            <Topbar>
                <div className={`AnnotationsTopbar-container ${size}`}>

                    <TopbarTitle>Annotations</TopbarTitle>

                    <div className="Annotations-searchbox-container">

                        <div className="Annotations-searchbox-div">
                            <img className='search-icon' src={search}></img>
                            <input className='Annotations-input' placeholder='Search annotations' onChange={onChangeSearchString} value={searchString}></input>

                        </div>
                        <div className='filter-container' onClick={() =>{setShowFilterSelector(true)}}>
                            <img className="filter" src={filter}></img>

                        </div>
                       
                    </div>
                </div>
                
            </Topbar>

            {
                    showFilterSelector?
                    <div className='FilterSelector-container'>
                        <Clickout closeFunction={() =>{setShowFilterSelector(false)}}>
                            <AnnotationsFilterSelector
                                {...commonProps}
                            />
                        </Clickout>
                    </div>
                    :null
                }

            <div className='AnnotationsWorkspace'>

                <div className="AnnotationsFilterPreview-container">
                    <AnnotationsFilterPreview 
                        {...commonProps}
                        allAnnotationTypes = {allPDFAnnotations}
                        getAnnotationIcon ={getAnnotationIcon}
                        setShowFilterSelector = {setShowFilterSelector}
                    />

                </div>
                <div className='AnnotationsList-container'>
                    <AnnotationsList getAnnotationIcon={getAnnotationIcon} {...commonProps} />
                </div>

            </div>

        </>
    )
}
export default AnnotationsWorkspace;