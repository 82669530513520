import React, {useState} from 'react';
import { useAppSelector } from '../../../../redux';
import { LabeledMenu } from '../../../../types/menus';
import close from '../../../../assets/icons/close.svg'
import down_arrow from '../../../../assets/icons/.svg'
import up_arrow from '../../../../assets/icons/right_arrow.svg'

import './Toolbar.css'

type ToolbarProps = {
    children?:React.ReactNode, 
};

const Toolbar:React.FC<ToolbarProps> = ({children}) => {

    const {readerWidthPixels} = useAppSelector(state=>state.separator)

    return (
        <div className='toolbar-and-message'>
            <div className={`Toolbar`}>
                {children}
            </div>
            {

            }
            {/* <PdfFinder/> */}
        </div>

       
    )
}
export default Toolbar;