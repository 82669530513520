import React, { useContext,useState } from 'react';

import './Folder.css'
import { EnvironmentContext } from '../../../../../contexts';
import useFolderNavigate from '../../../../../hooks/FileHooks/useFolderNavigate';
import { Folder } from '../../../../../types/files';
import { useAppDispatch, useAppSelector } from '../../../../../redux';
import { openOverlay } from '../../../../../layouts/Overlay';
import folder_icon from '../../../../../assets/icons/folder.svg'
import more_button from '../../../../../assets/icons/more_button.svg'
import { selectFolderPath } from '../folderPathSlice';
import useMoveFile from '../../../../../hooks/FileHooks/useMoveFile';
import { Device } from '../../../../../types/devices';
import useWindowSize from '../../../../../hooks/useWindowSize';
import useFileContextMenu from '../../../../../features/files/useFileContextMenu';


export type FolderProps =Folder

const FolderComponent:React.FC<FolderProps> = (folder) => {

    const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder

    const showFileContextMenu = useFileContextMenu()

    const env = useContext(EnvironmentContext)
    const navigate = useFolderNavigate(env)
    const dispatch = useAppDispatch();
    const folderPath = useAppSelector(selectFolderPath)
    const [currentPath, setCurrentPath] = useState<Folder[]>(folderPath.segments)

    const backendMoveFile = useMoveFile(env)

    // const openFolderDetailsOverlay = (event: React.MouseEvent<HTMLDivElement>,fileType:string) =>{
    //     event?.preventDefault();
    //     console.log("Need to open overlay")
    //     dispatch(openOverlay({
    //         heading: {
    //             icon: folder_icon,
    //             title: "Edit",
    //             data: folder.name,
    //         },
    //         component: <DocumentInfo fileType={"Folder"} objectData={folder}/>,
    //     }))
    // }

    

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };
    
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const onDragFolder = (e: React.DragEvent<HTMLDivElement>) => {
        setIsHovering(false)
        e.dataTransfer?.setData("text", JSON.stringify(folder))
        console.log("I Am dragging!")
    }

    const onDropFolder = (e: React.DragEvent<HTMLDivElement>) => {

        // Move function needs to be written
        var data = e.dataTransfer.getData("text");
        console.log("data in drop", JSON.parse(data) as Folder)
        setIsOnDrop(false);

        //Here, we move the folder

        backendMoveFile({
            file: JSON.parse(data),
            newParentID: folder.objectID
        })

    } 

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) =>{
        console.log("onDragLeave")
        setIsOnDrop(false)
    }

    const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) =>{
        var data = (e.dataTransfer.getData("text"))
        setIsOnDrop(true)
    }

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) =>{
        e.preventDefault()
    }

    const device: Device = useWindowSize()
    const openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) =>{
        showFileContextMenu(folder, event.clientX, event.clientY)

    }

    return(
        <div className={`Folder 
            ${isOnDrop ? "onDropActive" : ""} ${device}`} 
            draggable={true} 
                
                onDragOver={onDragOver}
                onDragStart={onDragFolder} 
                onDragOverCapture={onDragOverCapture} 
                onDragLeave={onDragLeave} 
                onDrop={onDropFolder} 

                onClick={()=>{navigate(folder)}} 
                onContextMenu={
                    (e)=>{
                        // openFolderDetailsOverlay(e,"Folder")
                        e.preventDefault()
                        openFileContextMenu(e)
                    }
                } 
                onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div className={`Folder-title ${device}`}>{folder.name}</div>
            <div 
                className={`folder-more-button ${isHovering || device == "mobile" ? "hovered" : ""}`} 
                onClick={(e)=>{
                    openFileContextMenu(e)
                    e.stopPropagation()
                    setIsHovering(false)
                }}>
                <img className='folder-more-button-icon' src={more_button}></img>
            </div>
        </div>
        
       
    )
}
export default FolderComponent;