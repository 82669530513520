
import React, { useState } from 'react';
import './DocumentTitle.css'

type DocumentTitleProps = {
    title: string, 
    location: string,
    onEdit?: (newTitle: string) =>void,
    topbarType?: "Writing" | "Reading"
};


const DocumentTitle:React.FC<DocumentTitleProps> = ({title, location, onEdit,topbarType}) => {

    const [value, setValue] = useState<string>(title)
    const [isDisabled, setIsDisabled ] = useState<boolean>(onEdit?false: true)

    const onChange = (e: React.ChangeEvent<HTMLInputElement> ) =>{
        setValue(e.target.value)
    }
    
    const submitNewTitle = () =>{

        if(!onEdit) return
        if(!value){
            setValue(title)
            return
        }
        onEdit(value)

    }

    const onTitleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>{
        if(e.key==="Enter"){
            submitNewTitle()
        }
    }

    return(
        <div className={`DocumentTitle ${topbarType === "Writing" ? "writing" : "reading"}`}>
            <input className="DocumentTitle-input" value={value} disabled={isDisabled} onChange={onChange} 
                onKeyPress={onTitleKeyPress} onBlur = {onEdit?submitNewTitle:undefined}
            ></input>
            {/* <div className='DocumentTitle-title'>{title}</div> */}
            {/* <div className='DocumentTitle-location'>{location}</div> */}
        </div>
    )
}
export default DocumentTitle;