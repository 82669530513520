import React, { useEffect } from 'react';
import {closeOverlay, OverlayComponent} from '../overlaySlice'
import close from '../../../assets/icons/close.svg'
import { useAppDispatch } from '../../../redux';
import './Overlay.laptop.css'

type OverlayLaptopProps = {
    overlay: OverlayComponent
};

const OverlayLaptop:React.FC<OverlayLaptopProps> = ({overlay}) => {
    const dispatch = useAppDispatch()
    
    return (
        <div  className={`OverlayLaptop`}>

            <div className='Overlay-container'>
                {
                    overlay?.heading?
                    <div className={`Overlay-heading ${overlay.heading.hideSeperator ? "overlay-heading-no-border" : ""}`}>
                        <div className='Overlay-heading-left-part'>
                            <img src={overlay.heading?.icon} className="Overlay-heading-icon"></img>
                            <div className="Overlay-heading-title">
                                <b>{overlay.heading.title}</b> {overlay.heading.data}
                            </div>
                        </div>
                        <img onClick={() =>{dispatch(closeOverlay())}} className='Overlay-close-icon' src={close}></img>
                    </div>
                    :
                    null
                }
                
                
                
                <div className='Overlay-contents'>
                    {overlay?.component}
                </div>
            </div>

        </div>
    );
}
export default OverlayLaptop;