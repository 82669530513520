
import React from 'react';
import './Tab.css'
import { Draggable } from 'react-beautiful-dnd';
import closeIcon from '../../../../assets/icons/close.svg'
import { closeTab } from '../../../../pages/desk/reader/Reader/readerTabsSlice';
import { Device } from '../../../../types/devices';
import useWindowSize from '../../../../hooks/useWindowSize';

type TabProps = {
    title: string,
    id: string,
    numberOfTabs: number
    index: number,
    active: boolean,
    closeTabHandler?: (index:number, id: string) => void,
    clickTabHandler: React.MouseEventHandler,
};

const Tab:React.FC<TabProps> = ({title, id, index, active, numberOfTabs, closeTabHandler, clickTabHandler}) => {

    const number = 100/(2*numberOfTabs);
    const tabWidth = `${number}%`;

    const device: Device = useWindowSize();
      
    return (
        <Draggable key={id} draggableId={id} index={index}>
            {
                (provided) =>(
                    <div className={`Tab ${active?"active-tab":"inactive-tab"}`} id={id}
                        style={{cursor: "default"}}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        // onMouseDown={clickTabHandler} 
                        onClick={clickTabHandler}
                        // style={{cursor: "default"}}
                    >
                        <div className='tab-title' id={id} style={{cursor: "default"}} >{title}</div>
                        {
                            closeTabHandler?
                            <img className={`closeIcon ${device}`} id="closeIcon" src={closeIcon}
                                onClick={() =>closeTabHandler(index,id)}
                                onTouchEnd={()=>closeTabHandler(index,id)}
                                style={{cursor: "default"}}
                            />
                            :null
                        }
                    </div>
                )
            }

            
        </Draggable> 

        
    )
}
export default Tab;