import React from 'react';
import { useAppSelector, useAppDispatch } from '../../redux';
import { closeFullScreenOverlay } from './FullScreenOverlaySlice';
import backButton from '../../assets/icons/backButton.svg'
import './FullScreenOverlay.css'

type FullScreenOverlayProps = {
    
};

const FullScreenOverlay:React.FC<FullScreenOverlayProps> = () => {

    const FullScreenOverlay = useAppSelector((state) => state.fullscreenOverlay.value)
    
    const dispatch = useAppDispatch();

    if(FullScreenOverlay){
        return(
            <div className='FullScreenOverlay'>
                {FullScreenOverlay.heading == undefined ? null : 
                <div className='heading'>
                    <div className='heading-left-part'>
                        <div className='backButton' onClick={()=>dispatch(closeFullScreenOverlay())}>
                            <img style={{height: "16px"}} src={backButton} alt="" />
                        </div>
                        <div className='headingTitle'>{FullScreenOverlay.heading.title}</div>
                    </div>

                    { FullScreenOverlay.heading?.icon ? 
                        <div className='heading-right-part'>
                        <div className='right-end-icon'>
                            <img src={FullScreenOverlay.heading.icon} alt="" />
                        </div>

                        <div className='right-end-button-text'>{FullScreenOverlay.heading.iconText}</div>
                        </div> :
                        null
                    }
                </div>}



                <div className='fullScreenOverlay-content'>
                    {FullScreenOverlay.component}
                </div>

                <div className='Testing'>Hello Man</div>
            </div>
        )
    }
    else{
        return null
    }
}
export default FullScreenOverlay;