import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../redux";
import {
    selectFolderPath,
    updateFolderPath,
} from "../../features/files/folderPathSlice";
import { EnvironmentContextValue } from "../../../../contexts";
import { Folder, ReadingDocument, WritingDocument } from "../../../../types/files";
import {
    selectCurrentFolderData,
    setCurrentFolderData,
    loadingCurrentFolderData,
} from "../../features/files/folderDataSlice";
import { API } from "aws-amplify";

const getFolderData = /* GraphQL */ `
query MyQuery (
    $parentID: String
){
    getFolderData(parentID: $parentID) {
      folders {
        name
        objectID
        parentID
        description
        permission
      }
      readings {
        name
        objectID
        parentID
        permission
      }
      writings {
        name
        objectID
        parentID
        permission
      }
    }
  }
`

const useFilesData: (env: EnvironmentContextValue) => any = (env) => {
    // This hook fetches all data for the files page.
    // This should be called only once - in the files component. 
    // TODO: Electron support - fetch from some offline store (perhaps, but unlikely)
    const dispatch = useAppDispatch();
    const folderPath = useAppSelector(selectFolderPath);

    const { foldersData, writingData, readingData, loading } = useAppSelector(
        selectCurrentFolderData
    );
    // We must update these whenever we navigate to a new folder.
    useEffect(() => {
        const getData = async (currentPath: [Folder, ...Folder[]]) => {
            const folder = currentPath.at(-1) as Folder;
            // Here, you call the API
            // For now, we go and read the data
            const parentID =  folder.objectID
            try{
                const resp = await API.graphql({query: getFolderData, variables: {parentID: parentID}}) as any
                console.log(parentID, resp)
                const {folders, readings, writings} = resp.data.getFolderData
                dispatch(setCurrentFolderData({ 
                    currentFolder: folder,
                    foldersData: folders as Folder[],
                    readingData: readings as ReadingDocument[],
                    writingData: writings as WritingDocument[]
                }))
            }
            catch(e){
                console.error(e)
            }
            
        };

        if (folderPath.loading) {
            dispatch(loadingCurrentFolderData());
        } else {
            dispatch(loadingCurrentFolderData());
            getData(folderPath.segments);
        }
    }, [folderPath, dispatch]);

    return { foldersData, readingData, writingData, loading };
};

export default useFilesData;
