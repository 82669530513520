
import HomeComponent from "./pages/home";
import './styles/index.css'
import AuthScreen from './pages/auth'
import DeskComponent from './pages/desk'

import awsmobile from './aws-exports'

export const Home = HomeComponent
export const Desk = DeskComponent
export const awsExports = awsmobile
export const Auth = AuthScreen

