import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./FileExplorer.css";

import {
    Folder,
    ReadingDocument,
    WritingDocument,
    DocumentInfoProps,
    myLibrary,
    sharedFiles,
    rootFolder,
    FileType,
} from "../../../../types/files";
import Button from "../../../../ui/buttons/Button/Button";
import MoveWriting from "./items/MoveWriting";
import MoveReading from "./items/MoveReading";
import MoveFolder from "./items/MoveFolder";
import folderIcon from "../../../../assets/icons/folder.svg";
import upload_file from '../../../../assets/icons/upload_file.svg'
import folder_color from "../../../../assets/icons/folder_color.svg";
import close from "../../../../assets/icons/close.svg";
import InputField from "../../../../ui/inputs/TitledInput/TitledInput";
import Clickout from "../../../../layouts/Clickout/Clickout";
import { CurrentFolderData, moveFile } from "../files/folderDataSlice";
import { API } from "aws-amplify";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { closeOverlay, openOverlay } from "../../../../layouts/Overlay";
import { useCreateFolder, useMoveFile } from "../../../../hooks/FileHooks";
import { EnvironmentContext } from "../../../../contexts";
import { selectFolderPath } from "../files/folderPathSlice";
import Breadcrumbs from "../navigate/Breadcrumbs/Breadcrumbs";

import arrow_left_short from "../../../../assets/icons/arrow_left_short.svg";
import tick from "../../../../assets/icons/tick.svg";

import loading_sec from "../../../../assets/icons/gifs/loading_secondary.gif";
import UploadPdf from "../../components/NewButton/laptop/UploadFile/UploadPdf";

const getFolderData = /* GraphQL */ `
    query MyQuery($parentID: String) {
        getFolderData(parentID: $parentID) {
            folders {
                name
                objectID
                parentID
                description
            }
            readings {
                name
                objectID
                parentID
                permission
            }
            writings {
                name
                objectID
                parentID
            }
        }
    }
`;

type FileExplorerProps = (
    | {
          //move for moving files around.
          action: "move";
          object: DocumentInfoProps; //the object that is to be moved.
      }
    | {
          //open for opening a new file
          action: "openReading";
          object?: Folder; //The starting folder. Defaults to the root if not specified.
          onClick: (a: ReadingDocument, completedCallback?:()=>void) => void;
      }
    | {
          //open for opening a new file
          action: "openWriting";
          object?: Folder; //The starting folder. Defaults to the root if not specified.
          onClick: (a: WritingDocument, completedCallback?:()=>void) => void;
      }
) & {
    disabledItems: ("Reading" | "Writing")[];
};

const FileExplorer: React.FC<FileExplorerProps> = (props) => {
    var objectData: Folder | ReadingDocument | WritingDocument;

    if (props.action == "move") {
        var fileType = props.object.fileType;
        objectData = props.object.objectData;
    } else if (
        props.action == "openReading" ||
        props.action === "openWriting"
    ) {
        if (props.object) objectData = props.object;
        else objectData = myLibrary;
    }

    const [disable, setDisable] = useState(true);

    const [createInProgress, setCreateInProgress] = useState<boolean>(false);

    const folderPath = useAppSelector(selectFolderPath);

    const [currentPath, setCurrentPath] = useState<Folder[]>(
        folderPath.segments
    );

    const [newFolderClicked, setNewFolderClicked] = useState(false);
    const [newFolderName, setNewFolderName] = useState<string>("Untitled");

    const env = useContext(EnvironmentContext);

    const backendCreateFolder = useCreateFolder(env);
    const backendMoveFile = useMoveFile(env);

    const [currFolderData, setCurrFolderData] = useState<CurrentFolderData>({
        currentFolder: myLibrary,
        foldersData: [],
        readingData: [],
        writingData: [],
        loading: true,
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        // Set inner folder data

        if (
            currentPath.length == 1 &&
            currentPath[0].objectID === "Folder_root"
        ) {
            //root folder is selected
            setCurrFolderData({
                currentFolder: rootFolder,
                foldersData: [myLibrary, sharedFiles],
                readingData: [],
                writingData: [],
                loading: false,
            });
            return;
        }

        const currentParent = currentPath.at(-1) as Folder;
        if (currentParent.objectID === objectData.parentID) {
            setDisable(true);
        } else {
            setDisable(false);
        }

        setCurrFolderData({
            currentFolder: myLibrary,
            foldersData: [],
            readingData: [],
            writingData: [],
            loading: true,
        });

        // Fetch new data
        const respPromise = API.graphql({
            query: getFolderData,
            variables: { parentID: currentParent.objectID },
        }) as any;

        respPromise.then((resp: any) => {
            const data = resp.data.getFolderData;
            setCurrFolderData({
                currentFolder: currentParent,
                foldersData: data.folders.filter(
                    (x: Folder) => x.objectID !== objectData.objectID
                ),
                readingData: data.readings.filter(
                    (x: ReadingDocument) => x.objectID !== objectData.objectID
                ),
                writingData: data.writings.filter(
                    (x: WritingDocument) => x.objectID !== objectData.objectID
                ),
                loading: false,
            });
        });
    }, [currentPath, props.object]); //objectData was here.

    const onChangeName = (e: any) => {
        const target = e.target as HTMLInputElement;
        console.log("onChangeName: ", target.value);
        setNewFolderName(target.value);
        console.log(newFolderName);
    };

    const createFolder = async () => {
        const name = newFolderName;
        console.log("new Folder name: ", name);
        //Add the code to create folder here
        const currentParent = currentPath.at(-1) as Folder;

        if (name === "" || name == null) {
            console.log("Please enter something");
        } else {
            setCreateInProgress(true);
            try {
                const resp = await backendCreateFolder({
                    name: name,
                    parentID: currentParent.objectID,
                });
                if (resp) {
                    setCurrFolderData({
                        currentFolder:currentParent ,
                        foldersData: currFolderData.foldersData.concat([resp]),
                        readingData: currFolderData.readingData,
                        writingData: currFolderData.writingData,
                        loading: false,
                    });
                }
                // TODO: add error handling
            } catch (e) {
                return;
            } finally {
                setCreateInProgress(false);
            }

            console.log(name, "Folder Created");
            console.log("setNewFolder", false);
            setNewFolderClicked(false);
            setNewFolderName("Untitled");
        }
    };

    const moveFolder = async () => {
        // Edit object move
        const currentParent = currentPath.at(-1) as Folder;
        console.log("currentParent: ", currentParent);

        if (fileType === "Folder") {
            //CODE HERE
            dispatch(closeOverlay());
            try {
                const resp = await backendMoveFile({
                    file: objectData,
                    newParentID: currentParent.objectID,
                });
                if (!resp) {
                    console.error("There was some error!");
                }
            } catch (e) {
                console.error("Error while moving folder: ", e);
            }
        }
    };

    const setRootFolder = () => {
        console.log("we have reached root folder");
        setCurrentPath([rootFolder]);
    };

    const closeInputField = () => {
        setNewFolderClicked(false);
        console.log("closeInputField", false);
        // setNewFolderName("Untitled")
    };

    const openInputField = () => {
        console.log("openInputField", true);

        setNewFolderClicked(true);
    };

    const currentParent = currentPath.at(-1) as Folder;

    const navigationHandler = (segment: Folder) => {
        const tempPath = [...currentPath];
        const index = tempPath.indexOf(segment);
        tempPath.length = index + 1;
        setCurrentPath(tempPath);
    };

    var isAtRoot =
        currentPath.length == 1 && currentPath[0].objectID === "Folder_root";

    const goBack = () => {
        if (isAtRoot) {
            return;
        } else if (
            currentParent !== myLibrary &&
            currentParent !== sharedFiles
        ) {
            console.log(currentPath.slice(0, -1));
            if (Array.isArray(currentPath))
                setCurrentPath(currentPath.slice(0, -1));
        } else {
            //go back root
            setRootFolder();
        }
    };

    return (
        <div className="FileExplorer">
            <div className="navigator">
                <img
                    src={arrow_left_short}
                    className="back-button"
                    onClick={goBack}
                ></img>

                <Breadcrumbs
                    rootHandler={setRootFolder}
                    segments={currentPath as [Folder, ...Folder[]]}
                    navigationHandler={navigationHandler}
                />
            </div>

            <div className="files-list show-scroll">
                {newFolderClicked ? (
                    // <Clickout closeFunction={() => {createFolder(newFolderName)}}>
                    <div className="create-folder">
                        <div className="new-folder-name">
                            <img className="file-icon" src={folder_color}></img>
                            <div className="folder-name-input">
                                <InputField
                                    id="newFolderName"
                                    value={newFolderName}
                                    onChange={onChangeName}
                                    onEnter={createFolder}
                                    edges="sharp"
                                    autoFocus
                                />
                            </div>
                        </div>
                        {!createInProgress ? (
                            <>
                                <img
                                    className="tick-icon"
                                    src={tick}
                                    onClick={createFolder}
                                ></img>
                                <img
                                    className="close-icon"
                                    src={close}
                                    onClick={closeInputField}
                                ></img>
                            </>
                        ) : (
                            <img
                                className="loading-create"
                                src={loading_sec}
                            ></img>
                        )}
                    </div>
                ) : // </Clickout>
                null}
                <div className="folders">
                    {currFolderData.loading ? "Loading..." : null}

                    {currFolderData.foldersData.map((Folder) => (
                        <MoveFolder
                            folder={Folder}
                            setDisable={setDisable}
                            setCurrentParent={(x) => {
                                setCurrentPath(currentPath.concat([x]));
                            }}
                        />
                    ))}
                </div>

                <div className="readings">
                    {currFolderData.readingData.map((Reading) => (
                        <MoveReading
                            onClick={(completedCallback: ()=>void) => {
                                if (
                                    !props.disabledItems.includes("Reading") &&
                                    props.action === "openReading"
                                ) {
                                    props.onClick(Reading, completedCallback);
                                }
                            }}
                            name={Reading.name}
                            disabled={props.disabledItems.includes("Reading")}
                        />
                    ))}
                </div>

                <div className="writings">
                    {currFolderData.writingData.map((Writing) => (
                        <MoveWriting
                            onClick={(completedCallback: ()=>void) => {
                                if (
                                    !props.disabledItems.includes("Writing") &&
                                    props.action === "openWriting"
                                ) {
                                    props.onClick(Writing, completedCallback);
                                }
                            }}
                            name={Writing.name}
                            disabled={props.disabledItems.includes("Writing")}
                        />
                    ))}
                </div>
            </div>
            {props.action==="move"?
            <div className="buttons">
                <div
                    className={`createNewFolder ${isAtRoot ? "disabled" : ""}`}
                    onClick={!isAtRoot ? openInputField : undefined}
                >
                    Create new folder
                </div>
                <Button
                    size="s"
                    icon={folderIcon}
                    rank="primary"
                    fill="solid"
                    edges="round"
                    onClick={moveFolder}
                    isDisabled={disable || isAtRoot}
                >
                    Move Here
                </Button>
            </div>: null}
            {
                props.action==="openReading"?
                <div className="buttons">
                <div
                    className={`createNewFolder ${isAtRoot ? "disabled" : ""}`}
                    onClick={!isAtRoot ? openInputField : undefined}
                >
                    Create new folder
                </div>
                <Button
                    size="s"
                    icon={upload_file}
                    rank="primary"
                    fill="solid"
                    edges="round"
                    onClick={
                        () => {

                            console.log("currentPath is: ", currentPath)
                            
                            console.log("Need to open overlay")
                            dispatch(openOverlay({
                                heading: {
                                    icon: upload_file,
                                    title: "Upload File",
                                    data: "",
                                },
                                component: <UploadPdf currentFolder={currentPath[currentPath.length - 1]}/>
                            }))
                        }
                    }
                    isDisabled={disable || isAtRoot}
                >
                   Upload New
                </Button>
            </div> : null
            }
        </div>
    );
};
export default FileExplorer;
