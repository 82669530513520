import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../../redux/store";
import { ReadingDocument } from "../../../../types/files";

export type ReaderTab = {
    readingDocument: ReadingDocument;
    loading: boolean;
    manager: any; // Replace with type for a generic source manager
};

export type ReaderTabs = ReaderTab[];

type ReaderTabsState = {
    tabs: ReaderTabs;
    activeTabId: string;
    activeTab: ReaderTab | null;
    initialised: boolean;
} 

// const sampleInitialState: ReaderTabsState = {
//     tabs: [
//         {
//             readingDocument: {
//                 url: "https://arxiv.org/pdf/2201.00021.pdf",
//                 name: "Arxiv PDF Astrophysics",
//                 format: "PDF",
//                 parentID: "library",
//                 objectID: "1",
//                 permission: "owner",
//             },
//             loading: true,
//             manager: null,
//         },
//         {
//             readingDocument: {
//                 url: "https://arxiv.org/pdf/2212.08170.pdf",
//                 name: "Bounded Boolean Synthesis",
//                 format: "PDF",
//                 parentID: "library",
//                 objectID: "2",
//                 permission: "owner",
//             },
//             loading: true,
//             manager: null,
//         },
//     ],
//     activeTabId: "1",
//     activeTab: {
//         readingDocument: {
//             url: "https://arxiv.org/pdf/2201.00021.pdf",
//             name: "Arxiv PDF Astrophysics",
//             format: "PDF",
//             parentID: "library",
//             objectID: "1",
//             permission: "owner",
//         },
//         loading: true,
//         manager: null,
//     },
// };

const initialState: ReaderTabsState = {
    tabs: [],
    activeTabId:  '',
    activeTab: null,
    initialised: false
}

export const readerTabsSlice = createSlice({
    name: "readerTabs",
    initialState,
    reducers: {
        initialiseTabs: (state, action: PayloadAction<ReadingDocument[]>) => {
            console.log('READER', action.payload)
            if (action.payload.length === 0){
                state.tabs= []
                state.activeTabId = ''
                state.activeTab = null
                state.initialised = true
                return
            }
            action.payload.forEach((x) => {
                state.tabs.push({
                    readingDocument: { ...x },
                    loading: true,
                    manager: null,
                });
            });
            state.activeTabId = action.payload[0].objectID;
            state.activeTab = {
                readingDocument: { ...action.payload[0] },
                loading: true,
                manager: null,
            };
            state.initialised = true
        },

        addTab: (state, action: PayloadAction<ReadingDocument>) => {
            state.tabs.push({
                readingDocument: { ...action.payload },
                loading: true,
                manager: null,
            });

            state.activeTabId = action.payload.objectID;
            state.activeTab = {
                readingDocument: { ...action.payload },
                loading: true,
                manager: null,
            };
        },
        setActiveTabIndex: (state, action: PayloadAction<string>) => {
            //we have to pass the ID here.
            state.activeTabId = action.payload;
            state.activeTab = state.tabs.find(
                (x) => x.readingDocument.objectID === action.payload
            ) as ReaderTab;
        },
        updateTab: (state, action: PayloadAction<ReaderTab>) => {
            //used to add the manager to the tab once it's been initialised. 
            const update = action.payload;
            state.tabs = state.tabs.map((x) => {
                if (
                    x.readingDocument.objectID !==
                    update.readingDocument.objectID
                ) {
                    return x;
                } else {
                    return update;
                }
            });
            if (state.activeTabId === update.readingDocument.objectID) {
                state.activeTab = update;
            }
        },
        editTab: (state, action: PayloadAction<ReadingDocument>) =>{
            //used to edit the details of this tab
            const updatedDocument = action.payload
            state.tabs = state.tabs.map((x) =>{
                if (
                    x.readingDocument.objectID !== updatedDocument.objectID
                    //objectID stays constant
                ) {
                    return x;
                } else {
                    const temp ={...x}
                    temp.readingDocument = {...updatedDocument}
                    return temp;
                }
            })
        },
        reorderTabs: (state, action: PayloadAction<any>) => {
            // const element = state.tabs.find((tab) => tab.readingDocument.objectID === action.payload.draggableId);
            console.log("PREREODER", state.tabs);

            state.tabs.splice(
                action.payload.destination.index,
                0,
                state.tabs.splice(action.payload.source.index, 1)[0]
            );
            console.log("POST REORDER", state.tabs);
        },

        closeTab: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            // const tabsCopy = [...state.value]
            // tabsCopy.splice(index, 1)

            // const newActiveId = state.tabs[index-1].id

            // console.log("newActiveId: ",newActiveId)

            // state.activeTabId = newActiveId;
            console.log("closeTab")

            const closedTab = state.tabs.splice(index, 1);
            
        },
    },
});

export const { addTab, closeTab, setActiveTabIndex, reorderTabs, updateTab, editTab, initialiseTabs } =
    readerTabsSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectReaderTabs = (state: RootState) => state.readerTabs;
export const selectActiveTab = (state: RootState) => state.readerTabs.activeTab;
export default readerTabsSlice.reducer;
