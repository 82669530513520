import { useState } from "react";
import TitledInput from "../../../../ui/inputs/TitledInput/TitledInput";
import { Subtitle, Title, Caption } from "../../../../ui/text/Text";
import Button from '../../../../ui/buttons/Button/Button'

import './PromoCode.css'

const PromoCode = (props) => {

    const [promocode, setPromocode] = useState("")
    const [showInfo, setShowInfo] = useState(true)

    const onChange = (e) =>{
        setPromocode(e.target.value)
    }

    // const skipPromoCode = () =>{

    //     props.

    // }

    return (  
        <>
            <div>
                <Caption><b>OPTIONAL</b></Caption>
                <Title>Do you have a promo code?</Title>
                <Subtitle>Enter a valid promo code to unlock Desklamp Premium for free.</Subtitle>
                <br/>
                <TitledInput size="l" id="promocode" label={"Promo Code"} placeHolder="e.g. YOLO2022" value={promocode} onChange={onChange} />
            
                <Button position="left half" text={"Skip for now"}  />
                <Button position="right half" style="primary" text={"Confirm Code"}  />
                

                <Subtitle>You can always enter this later.</Subtitle>
                
                <Caption size="s" >This is not the verification code from the previous step.</Caption>
                <br/>
                <Subtitle><a  onClick={props.toggleInfoPanel}>Where can I get a promo code?</a></Subtitle>

                


            
            </div>
        </>
    );
}
 
export default PromoCode;