import React, { useContext, useState } from 'react';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Device } from '../../../../../../types/devices';
import { Folder } from '../../../../../../types/files';

import { useAppSelector } from '../../../../../../redux';
import { editFolderInPath, selectFolderPath } from '../../../../features/files/folderPathSlice';
import './PageTitle.css'
import useFolderNavigate from '../../../../../../hooks/FileHooks/useFolderNavigate';
import { EnvironmentContext } from '../../../../../../contexts';
import Breadcrumbs from '../../../../features/navigate/Breadcrumbs/Breadcrumbs';
import { useAppDispatch } from '../../../../../../redux';
import { openOverlay } from '../../../../../../layouts/Overlay';
import folder_icon from '../../../../../../assets/icons/folder.svg'
// import more_button from '../../../../../../assets/icons/more_button.svg'
import backButton from '../../../../../../assets/icons/backButton.svg'

import more_button from '../../../../../../assets/legacy_icons/more.png'
import edit from '../../../../../../assets/icons/edit.svg'

import EditFileDetails from '../../../../../../features/files/EditFileDetails/EditFileDetails';



const PageTitle:React.FC = () => {

    const dispatch = useAppDispatch();
    const deviceType: Device = useWindowSize()
    const folderPath = useAppSelector(selectFolderPath)
    console.log("folderPath: ", folderPath)
    const currFolder = folderPath.segments.at(-1) as Folder
    const previousFolder = folderPath.segments.at(-2) as Folder

    const env = useContext(EnvironmentContext)
    const navigationHandler = useFolderNavigate(env)
    const navigate = env.navigator();

    // const [nameState, setNameState] = useState<string>(currFolder.name)
    // const [descriptionState, setDescriptionState] = useState<string>(currFolder.description?currFolder.description:"")

    const openFolderDetailsOverlay = (event: React.MouseEvent<HTMLDivElement>, fileType:string) =>{

        event?.preventDefault();
        console.log("Need to open overlay")
        // dispatch(openOverlay({
        //     heading: {
        //         icon: folder_icon,
        //         title: "Edit",
        //         data: currFolder?.name || "",
        //     },
        //     component: <DocumentInfo fileType={"Folder"} objectData={currFolder}/>,
        // }))
    }

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };
    
    const handleMouseOut = () => {
        setIsHovering(false);
    };


    if (folderPath.loading){
        return (
        <div className={`PageTitle ${deviceType}`}>
        {deviceType==="laptop"?
        // <img src={FolderMain} className="PageTitle-icon" />
        <div className='PageTitle-icon' onContextMenu={(e)=>{openFolderDetailsOverlay(e,"Folder")}}>
            
        </div>
        :null}
        <div className={`PageTitle-text`}>
            {
                deviceType==="laptop"? <div className="PageTitle-location">{
                    'Loading ...'
                }</div>:null
            }
            <div className="PageTitle-folder">{'Loading ...'}</div>
            {
                deviceType==="mobile"? <div className="PageTitle-location">{
                    'Loading ...'
                }</div>:null
            }
            <div className="PageTitle-description">{'Loading ...'}</div>
        </div>
        </div>)
    }

    const editDetailsCallback = (file: Folder) =>{
        console.log("editDetailsCallback   !", file)
        const {name, description} = file
        const fileCopy= {...file}
        fileCopy.name = name
        fileCopy.description = description
        // setNameState(name)
        // setDescriptionState(name)

        dispatch(editFolderInPath(fileCopy))
    }

    return(

        <div className={`PageTitle ${deviceType}`}>
            {deviceType==="laptop"?
            // <img src={FolderMain} className="PageTitle-icon" />
            <div className='PageTitle-icon' onContextMenu={(e)=>{openFolderDetailsOverlay(e,"Folder")}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
               
            </div>
            :null}

            {
                (deviceType === "mobile" && previousFolder != undefined) ? <div className='backButton' onClick={()=>navigationHandler(previousFolder)}>
                    <img src={backButton} alt="" />
                </div> : null
            }
            <div className={`PageTitle-text`}>
                {
                    deviceType==="laptop"? <div className="PageTitle-location">{
                        <Breadcrumbs navigationHandler={navigationHandler} segments={folderPath.segments}></Breadcrumbs>
                    }</div>:null
                }
                <div className="PageTitle-folder">
                    {currFolder.name}

                    <img 
                        alt = {"More "}
                        style={{display:(currFolder.objectID==="Folder_library" || currFolder.objectID==="Folder_shared" || deviceType==="mobile" )?"none":"block" }}
                        src={more_button} className="header-more-button"
                        onClick={(e)=>{
                            dispatch(openOverlay({
                                heading: {
                                    icon: edit, 
                                    title: "Edit", 
                                    data: currFolder.name
                                }, 
                                component: <EditFileDetails file={currFolder} editDetailsCallback={editDetailsCallback}  />
                            }))
                            e.stopPropagation()
                        }}
                    ></img>
                
                </div>
                
                {
                    deviceType==="mobile"? <div className="PageTitle-location">{
                        <Breadcrumbs navigationHandler={navigationHandler} segments={folderPath.segments}></Breadcrumbs>
                    }</div>:null
                }
                {
                    currFolder.description  ?
                    <div className="PageTitle-description">{currFolder.description}</div> : <div className={`PageTitle-description null`}>No description</div>
                }
            </div>
        </div>
        
    )
}

export default PageTitle;