import React from 'react';

import './NavbarSection.css'

type NavbarSectionProps = {
    header: string,
    children: React.ReactNode,
};

const NavbarSection:React.FC<NavbarSectionProps> = ({header, children}) => {
    
    return (
        <div className='NavbarSection'>
            <div className='NavbarSection-header'>{header}</div>

            <div className='NavbarSection-contents'>
                {children}
            </div>
            

        </div>
    )
}
export default NavbarSection;