import React, { MouseEventHandler, ReactComponentElement, ReactNode, useState } from 'react'
import {Rnd} from 'react-rnd'
import { PDFAnnotation, PdfMovableAnnotation } from '../../PdfManager'

export interface PdfMovableAnnotationProps {
    annotation: PdfMovableAnnotation
    selector: string
    scale: number
    getViewportPointFromPdfPoint: (pageIndex: number, pdfPoint: [number,number]) => {x: number, y: number}
    getPdfPointFromCoordinates: (pageIndex: number, x:number, y: number ) => [number, number]
    updateAnnotation: (x: PDFAnnotation) => void
    showContextMenu: MouseEventHandler | any
}

export interface PdfMovableAnnotationComponentProps extends PdfMovableAnnotationProps {
    disableResizing?: true
    rndStyle?: any //CSS STYLES BUT typescript is being annoying
    children(size: {width: number, height:number}, topLeft:{x: number, y: number}) : ReactNode
}
export const PdfMovableAnnotationComponent=(props:PdfMovableAnnotationComponentProps)=>{
    const {renderInfo, sourceInfo} = props.annotation
    
    const [topLeft, setTopLeft] = useState(props.getViewportPointFromPdfPoint(sourceInfo.pageIndex, renderInfo.topLeft))
    
    const botRight = props.getViewportPointFromPdfPoint(sourceInfo.pageIndex, renderInfo.botRight)
    const initSize = {width: botRight.x - topLeft.x, height: botRight.y - topLeft.y}
    initSize.width = Math.max(80 * props.scale, initSize.width)
    initSize.height = Math.max(80 * props.scale, initSize.height)
    const [size, setSize] = useState(initSize)
    let rndStyle : any = 
    {
        position: "absolute", 
        mixBlendMode: "normal",
        border: "none",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195)",
        ...props.rndStyle
    }

    const moveAnnotation = (newX: number, newY: number)=>{
        setTopLeft({ x: newX, y: newY }) 
        const newAnnotation = props.annotation
        
        newAnnotation.sourceInfo.pdfPoint = props.getPdfPointFromCoordinates(props.annotation.sourceInfo.pageIndex, newX, newY)
        newAnnotation.renderInfo.topLeft = newAnnotation.sourceInfo.pdfPoint
        newAnnotation.renderInfo.botRight = props.getPdfPointFromCoordinates(props.annotation.sourceInfo.pageIndex, newX + size.width, newY + size.height)
        props.updateAnnotation(newAnnotation)
    }    

    const resizeAnnotation = (width: number, height: number, newTopLeft: {x: number, y: number})=>{
        // console.log("inside run: ", width, typeof _width)

        const pdfTopLeft = props.getPdfPointFromCoordinates(props.annotation.sourceInfo.pageIndex, newTopLeft.x, newTopLeft.y)
        const pdfBotRight = props.getPdfPointFromCoordinates(props.annotation.sourceInfo.pageIndex, newTopLeft.x + width, newTopLeft.y + height)
        const entry = props.annotation
        entry.renderInfo.topLeft = pdfTopLeft
        entry.sourceInfo.pdfPoint = pdfTopLeft
        entry.renderInfo.botRight = pdfBotRight
        props.updateAnnotation(entry)
        setTopLeft(newTopLeft)
        setSize({width, height})
    }

    return <Rnd
        style = {{
            zIndex:1,
            ...rndStyle
        }}
        size={{ width: size.width + "px",  height: size.height + "px" }}
        minHeight = {80 * props.scale + "px"}
        minWidth = {80 * props.scale + "px"}
        lockAspectRatio = {false}
        position = {{x: topLeft.x, y: topLeft.y}}
        enableResizing = {!props.disableResizing}
        // disableDragging = {}
        onDragStart = {
            (e, d)=>{
                
            }
        }
        onDragStop={(e, d) => { 
            moveAnnotation(d.x,d.y)
        }}
        bounds = {props.selector}
        dragHandleClassName = {'selectionHandle'}

        onResize={(e, direction, ref, delta, position) => {
            setSize({
            width: parseInt(ref.style.width.slice(0, -2)),
            height: parseInt(ref.style.height.slice(0, -2))})
            setTopLeft(position)
        }}
        onResizeStop = {(e, direction, ref, delta, position) => {
            //console.log("resize")
            resizeAnnotation(parseInt(ref.style.width.slice(0,-2)), parseInt(ref.style.height.slice(0,-2)), position)
            //console.log("done resize: ", ref.style.width)
        }}
    >
    
    {props.children(size, topLeft)}
    
</Rnd>
}
