
  import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    DelayedPromiseCreator,
    EditorView,
    ErrorConstant,
    extension,
    ExtensionTag,
    getTextSelection,
    invariant,
    isElementDomNode,
    NodeExtension,
    NodeExtensionSpec,
    NodeSpecOverride,
    NodeViewMethod,
    omitExtraAttributes,
    PrimitiveSelection,
    ProsemirrorAttributes,
    ProsemirrorNode,
    mutateTag
  } from "@remirror/core";
  import { MathView } from "./MathView";
import { defaultInlineMathParseRules } from "./PasteRule";
import {makeInlineMathInputRule, REGEX_INLINE_MATH_DOLLARS} from './InputRule'
import { TextSelection, NodeSelection } from '@remirror/pm/state';


mutateTag(tag=>tag.Math = 'math')
  
  @extension({
    defaultOptions: {
      
    },
  })
  export class InlineLaTeX extends NodeExtension {
    get name() {
      return "math-inline";
    }
  
    createTags() {
      return [ExtensionTag.InlineNode, ExtensionTag.Math];
    }
  
    createNodeSpec(extra, override) {
      return {
		    inline: true,
		    atom: true,
        content: "text*",
        ...override,
        attrs: {
          ...extra.defaults()
        },
        parseDOM: [
            
            { tag: "math-inline" },
            ...defaultInlineMathParseRules
			
        ],
        toDOM: (node) => {
        //   extra.metadata = node.attrs.metadata;
        //   const attrs = omitExtraAttributes(node.attrs, extra);
        //   return ["img", { ...extra.dom(node), ...attrs }];
          return ["math-inline", { ...extra.dom(node), class: "math-node" }, 0]
        },
      };
    }
  
  
    createCommands() {
      return {
        insertInlineLatex: (attributes, selection) => {
          return ({ tr, dispatch }) => {
            const { from, to } = getTextSelection(
              selection ?? tr.selection,
              tr.doc
            );
            
            const node = this.type.create(attributes);
            let step1 = tr.replaceRangeWith(from, to, node)
            dispatch?.(
              step1.setSelection(new NodeSelection(step1.doc.resolve(from)))
            );
  
            return true;
          };
        },
      };
    }

    createPlugin(){
      return {
        state: {
          init(config, instance){
            return {
              macros: {},
              activeNodeViews: [],
              prevCursorPos: 0,
            };
          },
          apply(tr, value, oldState, newState){
            // produce updated state field for this plugin
            // console.log("Modifying plugin state", oldState.selection.from, newState.selection instanceof NodeSelection)
            return {
              // these values are left unchanged
              activeNodeViews : value.activeNodeViews,
              macros          : value.macros,
              // update with the second-most recent cursor pos
              prevCursorPos   : oldState.selection.from
            }
          },
          /** @todo (8/21/20) implement serialization for math plugin */
          // toJSON(value) { },
          // fromJSON(config, value, state){ return {}; }
        }
      }
    }

   

    createInputRules(){
      console.log("RULE",makeInlineMathInputRule(REGEX_INLINE_MATH_DOLLARS, this.type))
      return [makeInlineMathInputRule(REGEX_INLINE_MATH_DOLLARS, this.type)]
    }
  
    createNodeViews() {
      let that = this;
      return (node, view, getPos) => {
        /** @todo is this necessary?
        * Docs says that for any function proprs, the current plugin instance
        * will be bound to `this`.  However, the typings don't reflect this.
        */
        let pluginState = that.getPluginState();
        if(!pluginState){ throw new Error("no math plugin!"); }
        let nodeViews = pluginState.activeNodeViews;
        console.log("MATH NODE CREATED")
        // set up NodeView
        let nodeView = new MathView(
          node, view, getPos, 
          { katexOptions : { displayMode: false, macros: pluginState.macros } },
          that.pluginKey,
          ()=>{ nodeViews.splice(nodeViews.indexOf(nodeView)); },
        );
    
        nodeViews.push(nodeView);
        return nodeView;
      }
    }
  }
  