import { createSlice, PayloadAction  } from "@reduxjs/toolkit";
import { SourceAnnotation } from "../../desk-logic/metadata";
import type { RootState } from "../../../../redux/store";


type ObjectAnnotations = {
    [key: number]: SourceAnnotation[]
}

type AnnotationsPaneState = {
    [key: string]: ObjectAnnotations
}

const initialState: AnnotationsPaneState = {
    
}

export const annotationsPaneSlice = createSlice({
    name: "annotationsPane", 
    initialState, 
    reducers: {

        initialiseSourceAnnotations: (state, action: PayloadAction<{objectID: string, annotations: ObjectAnnotations}>) =>{
            //when a new source is opened, we need to add all its annotations
            const {objectID, annotations} = action.payload
            state[objectID] = JSON.parse(JSON.stringify(annotations))

        }, 
        removeSourceAnnotations: (state, action: PayloadAction<string>) =>{
            /**
             * @param objectID: string (the objectID of the item to be removed)
             * when a source is closed, all it's annotions are to be removed from the annotations pane. 
             */

            delete state[action.payload]
        }, 

        addAnnotation: (state, action: PayloadAction<SourceAnnotation>) =>{
            //when a new annotation is added to a particular source

            console.log("received annotation: ", action.payload)
            
            const annotation = JSON.parse(JSON.stringify(action.payload))
            const objectID = annotation.objectID
            const sourceInfo = JSON.parse(JSON.stringify(annotation.sourceInfo))
            const pageIndex = sourceInfo.pageIndex
            console.log("received pageIndex: ", sourceInfo, pageIndex)

            if(!state[objectID]){
                //somehow this document is not in the state. This should never happen
                console.error("received initialisation error!")
                return
            }
            else if (!state[objectID][pageIndex]){
                console.log(" received 2: ", state[objectID][pageIndex])
                state[objectID][pageIndex] = [annotation]
            }
            else{
                console.log(" received 3: ", state[objectID][pageIndex])

                state[objectID][pageIndex].push(annotation)
            }


        }, 
        updateAnnotation: (state, action: PayloadAction<SourceAnnotation>) =>{
            //when an annotation on some particular source is updated

            const annotation = JSON.parse(JSON.stringify(action.payload))
            const objectID = annotation.objectID
            const pageIndex = annotation.sourceInfo.pageIndex
            console.log("received pi: ", pageIndex)

            state[objectID][pageIndex] = state[objectID][pageIndex].map(x=>{
                if(x.id===annotation.id){
                    return annotation
                }
                else{
                    return x
                }
            })
        }, 
        deleteAnnotation: (state, action: PayloadAction<SourceAnnotation>) =>{
            //when an anotation on some particular source is deleted

            const annotation = JSON.parse(JSON.stringify(action.payload))
            const objectID = annotation.objectID
            const pageIndex = annotation.sourceInfo.pageIndex

            state[objectID][pageIndex] = state[objectID][pageIndex].filter((x) => x.id!== annotation.id )




        },
    }
})

export const {initialiseSourceAnnotations, removeSourceAnnotations, addAnnotation, updateAnnotation, deleteAnnotation} = annotationsPaneSlice.actions;
export const selectAnnotationsPane = (state: RootState) => state.annotationsPane;

export default annotationsPaneSlice.reducer;






