import React, { useContext, useState } from "react";
import ProfilePreview from "../../../../../../features/account/ProfilePreview/ProfilePreview";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import Clickout from "../../../../../../layouts/Clickout/Clickout";
import ArrowDropdown from "../../../../../../ui/menus/ArrowDropdown/ArrowDropdown";
import Menu from "../../../../../../ui/menus/Menu/Menu";

import "./ProfileButton.css";

import { DisplayMenu } from "../../../../../../ui/menus/Menu/Menu";
import help from "../../../../../../assets/icons/help.svg";
import exclamation from "../../../../../../assets/icons/exclamation.svg";

import logout from "../../../../../../assets/icons/logout.svg";
import { useAppDispatch, useAppSelector } from "../../../../../../redux";
import { selectUser } from "../../../../../../features/account/userSlice";
import { UserContext } from "../../../../../../contexts";
import { User } from "../../../../../../types/user";
import { Size } from "../../../../../../types/sizes";
import { openOverlay } from "../../../../../../layouts/Overlay";
import LegacyDesklampPopup from "../../../../../../utils/LegacyDesklamp/LegacyDesklampPopup";

type ProfileButtonProps = {
    size?: Size;
    space?: "Reader" | "Writer";
};

const ProfileButton: React.FC<ProfileButtonProps> = ({ size = "n", space }) => {
    const dispatch = useAppDispatch();

    const deviceType = useWindowSize();
    const [showDropdown, setDropdown] = useState<boolean>(false);
    const currentUser = useAppSelector(selectUser) as User;
    const { signOut } = useContext(UserContext);
    // console.log("SIGN OUT CHECK", signOut)
    const profileDropdownMenu: DisplayMenu = {
        menu: [
            {
                label: "Old Desklamp (v1)",
                icon: help,
                handler: () => {
                    setDropdown(false);
                    dispatch(
                        openOverlay({
                            heading: {
                                icon: exclamation,
                                title: "The old Desklamp (v1) has moved",
                            },
                            component: <LegacyDesklampPopup />,
                        })
                    );
                },
            },
            {
                type: "separator",
            },
            {
                label: "Logout",
                icon: logout,
                handler: signOut,
            },
        ],
    };

    return (
        <>
            <img
            style={{
                width: "30px",
                marginRight: "10px",
                cursor: "pointer"
            }}
                src={help}
                onClick={() => {
                    //@ts-expect-error
                    window.clueso.display();
                }}
            ></img>
            <div
                className={`ProfileButton ${size} ${deviceType}`}
                onClick={() => {
                    setDropdown(true);
                }}
            >
                {currentUser?.name[0]}
            </div>

            {showDropdown ? (
                <div className={`profileDropdown ${space}-dropdown `}>
                    <Clickout
                        closeFunction={() => {
                            setDropdown(false);
                        }}
                    >
                        {/* <ArrowDropdown> */}

                        <ProfilePreview user={currentUser} />
                        <Menu
                            {...profileDropdownMenu}
                            // spacing="l"
                        />
                        {/* </ArrowDropdown> */}
                    </Clickout>
                </div>
            ) : null}
        </>
    );
};
export default ProfileButton;
