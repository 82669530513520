import React from "react";
import { useState } from "react";
import right_arrow from "../../../../../assets/icons/right_arrow.svg";
import pdfIcon from "../../../../../assets/icons/pdfIcon.svg";
import { ReadingDocument } from "../../../../../types/files";

type MoveReadingProps = {
    onClick: (completedCallback: () => void) => void;
    name: string;
    disabled?: boolean;
    // setDisable: React.Dispatch<React.SetStateAction<boolean>>
};

const MoveReading: React.FC<MoveReadingProps> = ({
    name,
    onClick,
    disabled,
}) => {
    // const [isHovering, setIsHovering] = useState(false);
    // const [isDisabled, setIsDisabled] = useState(false);

    // const handleMouseOver = () => {
    //   setIsHovering(true);
    // };

    // const handleMouseOut = () => {
    //   setIsHovering(false);
    // };

    // const onSelect = () => {
    //     console.log("Inside move folder for", {name})
    //     setIsDisabled(!isDisabled)
    //     setDisable(!isDisabled);
    // }
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div
            className={`move-file ${disabled || loading ? "disable" : ""}`}
            onClick={() => {
                console.log("LOADING", loading);
                if (!disabled && !loading) {
                    setLoading(true);
                    onClick(() => {
                        console.log("SETTING LOADING FALSE");
                        setLoading(false);
                    });
                }
            }}
            // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
        >
            <div
                className="move-file-name"
                
            >
                <img className="file-icon" src={pdfIcon}></img>
                <div className="file-name">{name}</div>
            </div>
            {/* {isHovering ? <img className='move-right-arrow' src={right_arrow}></img> : null} */}
        </div>
    );
};
export default MoveReading;
