import React, { useRef, useState } from "react";
import boldIcon_light from "../../../../../../assets/legacy_icons/notebook_icons/bold_light.png";
import italicIcon_light from "../../../../../../assets/legacy_icons/notebook_icons/italic_light.png";
import linkIcon_light from "../../../../../../assets/legacy_icons/notebook_icons/link_light.png";
import strikeIcon_light from "../../../../../../assets/legacy_icons/notebook_icons/strikethrough_light.png";
import underlineIcon_light from "../../../../../../assets/legacy_icons/notebook_icons/underline_light.png";
import boldIcon_dark from "../../../../../../assets/legacy_icons/notebook_icons/bold_dark.png";
import italicIcon_dark from "../../../../../../assets/legacy_icons/notebook_icons/italic_dark.png";
import linkIcon_dark from "../../../../../../assets/legacy_icons/notebook_icons/link_dark.png";
import strikeIcon_dark from "../../../../../../assets/legacy_icons/notebook_icons/strikethrough_dark.png";
import underlineIcon_dark from "../../../../../../assets/legacy_icons/notebook_icons/underline_dark.png";
import leftAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/leftAlign_light.png"
import centerAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/centerAlign_light.png"
import rightAlign_light from "../../../../../../assets/legacy_icons/notebook_icons/rightAlign_light.png"
import leftAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/leftAlign_dark.png"
import centerAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/centerAlign_dark.png"
import rightAlign_dark from "../../../../../../assets/legacy_icons/notebook_icons/rightAlign_dark.png"
import attachment_light from "../../../../../../assets/legacy_icons/notebook_icons/attachment_light.png"
import attachment_dark from "../../../../../../assets/legacy_icons/notebook_icons/attachment_dark.png"
import ol_dark from '../../../../../../assets/legacy_icons/notebook_icons/ol_dark.svg'
import ol_light from '../../../../../../assets/legacy_icons/notebook_icons/ol_light.svg'
import ul_dark from '../../../../../../assets/legacy_icons/notebook_icons/ul_dark.svg'
import ul_light from '../../../../../../assets/legacy_icons/notebook_icons/ul_light.svg'
import task_dark from '../../../../../../assets/legacy_icons/notebook_icons/task_dark.png'
import task_light from '../../../../../../assets/legacy_icons/notebook_icons/task_light.png'
import clipping_light from '../../../../../../assets/legacy_icons/toolbar_icons/snip_light_outline.svg'
import clipping_dark from '../../../../../../assets/legacy_icons/toolbar_icons/snip_dark_outline.svg'
import HoverTip from "./HoverTip/HoverTip"

export const ToolbarButton = (props)=>{
    let item = props.item
    let src = ""
    const menuButtonClass = ""
    const [showHover, setShowHover] = useState(false)
    if (props.theme == "light") {
        switch (item.label) {
            case "bold":
                src = boldIcon_light
                break
            case "underline":
                src = underlineIcon_light
                break
            case "italic":
                src = italicIcon_light
                break
            case "strikethrough":
                src = strikeIcon_light
                break
            case "internallink":
                src = linkIcon_light
                break
            case "leftAlign":
                src = leftAlign_light
                break
            case "rightAlign":
                src = rightAlign_light
                break
            case "centerAlign":
                src = centerAlign_light
                break
            case "external":
                src = attachment_light
                break
            case "clipping":
                src = clipping_light
                break
            case "orderedList":
                src = ol_light
                break
            case "bulletList":
                src = ul_light
                break
            case "taskList":
                src = task_light
                break
            default:
        }
    } else {
        switch (item.label) {
            case "bold":
                src = boldIcon_dark
                break
            case "underline":
                src = underlineIcon_dark
                break
            case "italic":
                src = italicIcon_dark
                break
            case "strikethrough":
                src = strikeIcon_dark
                break
            case "internallink":
                src = linkIcon_dark
                break
            case "external":
                src = attachment_dark
                break
            case "clipping":
                src = clipping_dark
                break
            case "leftAlign":
                src = leftAlign_dark
                break
            case "rightAlign":
                src = rightAlign_dark
                break
            case "centerAlign":
                src = centerAlign_dark
                break
            case "orderedList":
                src = ol_dark
                break
            case "bulletList":
                src = ul_dark
                break
            case "taskList":
                src = task_dark
                break
            default:
        }
    } 
    return (
        <span className = "notebook-toolbar-button-container">
        <img 
        alt={item.label}
        className={`notebook-toolbar-button ${props.theme} ${item.active()?"active-nb-button":"inactive-nb-button"} ${menuButtonClass}`}   
        key={props.key} onClick={item.handler} 
        onMouseEnter = {()=>setShowHover(true)}
        onMouseLeave = {()=>setShowHover(false)}
        src={src} />
        {showHover && item.hoverTip? <HoverTip appTheme = {props.theme} text = {item.hoverTip.text} shortcut = {item.hoverTip.shortcut} fineTunePosition = {{top:'30px'}} position = "bottom"></HoverTip>:null}
        </span>
    )
}