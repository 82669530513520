import React, {useState} from 'react';
import { CMenuContext } from '../../../../contexts';
import InfoMessage from '../../../../layouts/MessagePopup/MessagePopup';
import FileUploadProgress from '../../../../features/files/FileUploadProgress/FileUploadProgress';
import { ContextMenuComponent, ContextMenuObject } from '../../../../layouts/ContextMenu/ContextMenu';
import FullScreenOverlay from '../../../../layouts/FullScreenOverlay/FullScreenOverlay';
import Overlay from '../../../../layouts/Overlay/Overlay';
import SectionScroller from '../../../../layouts/SectionScroller/SectionScroller';
import Files from '../../components/Files/Files';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar/';
import NewButton from '../../components/NewButton';

import './Home.mobile.css'

type HomeProps = {
};

const Home:React.FC<HomeProps> = () => {

    
    return (
        <div className='Home mobile'>
                {<FullScreenOverlay/>}

                {<Overlay/>}

                <InfoMessage/>
                <FileUploadProgress />
           

                <div className='Header-row'>
                    <Header />
                </div>
            
                <div className="PageContents-row">
                    <NewButton />
                    <Files/>
                </div>

                <div className='Navbar-row'>
                    <Navbar />
                </div>

            {/* <Files /> */}
            
        </div>
    )
}
export default Home;

