import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    extension,
    ExtensionPriority,
    ExtensionTag,
    isElementDomNode,
    isString,
    joinStyles,
    Mark,
    MarkExtension,
    MarkExtensionSpec,
    MarkSpecOverride,
    getTextSelection,
    omitExtraAttributes,
    PrimitiveSelection,
    Static,
  } from '@remirror/core';
  import { ExtensionTextHighlightMessages as Messages } from '@remirror/messages';
import { TextSelection } from '@remirror/pm/state';
import {v4 as uuid} from 'uuid'

  
  const setTextHighlightOptions = {
    icon: 'markPenLine',
    description: ({ t }) => t(Messages.DESCRIPTION),
    label: ({ t }) => t(Messages.LABEL),
  };
  
  const TEXT_EXTRACT_ATTRIBUTE = 'data-internal-link';
  
  /**
   * Add a highlight color to the selected text (or text within a specified
   * range).
   */
    @extension({
    defaultOptions: {
        sourceInfo: null,
    },
    staticKeys: ['defaultHighlight'],
  })
  export class InternalLinkExtension extends MarkExtension {
    get name() {
      return 'internallink' ;
    }
  
    createTags() {
      return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
    }
  
    createMarkSpec(extra, override) {
      return {
        ...override,
        attrs: {
          ...extra.defaults(),
          

           metadata: {
            id: {default: ''},
            sourceMetadata : { default: null }
          },
        },
        parseDOM: [
          {
            tag: `span[${TEXT_EXTRACT_ATTRIBUTE}]`,
            getAttrs: (dom) => {
              if (!isElementDomNode(dom)) {
                return null;
              }
  
              const highlight = JSON.parse(dom.getAttribute(TEXT_EXTRACT_ATTRIBUTE));
  
              if (!highlight) {
                return null;
              }
  
              return { ...extra.parse(dom), highlight };
            },
          },
          {
            tag: `mark[${TEXT_EXTRACT_ATTRIBUTE}]`,
            getAttrs: (dom) => {
              if (!isElementDomNode(dom)) {
                return null;
              }
  
              const metadata = JSON.parse(dom.getAttribute(TEXT_EXTRACT_ATTRIBUTE));
  
              if (!metadata) {
                return null;
              }
  
              return { ...extra.parse(dom), metadata };
            },
          },
          
          ...(override.parseDOM ?? []),
        ],
        toDOM: (mark) => {
          const { metadata, ...other } = omitExtraAttributes(
            mark.attrs,
            extra,
          );
          const extraAttrs = extra.dom(mark);
          let style = extraAttrs.style;
          
  
          return [
            'mark',
            { ...other, ...extraAttrs, class:"internal-link" ,style, [TEXT_EXTRACT_ATTRIBUTE]: JSON.stringify(metadata)},
            0,
          ];
        },
      };
    }
  
    /**
     * Set the text highlight color value for the selected text.
     */
    createCommands(){
        return {
            createInternalLink: (highlight, options) => {

                return ({tr, state, dispatch}) => {
                    const { from, to } = getTextSelection(
                        tr.selection,
                        tr.doc
                      );
                      highlight.metadata.id = uuid()
                      const mark = this.type.create(highlight);
                      let step1 = tr.addMark(from, to, mark)
                      console.log("TEXTSEL", TextSelection.create(step1.doc, to+1, to+1))
                      let step2 = step1.setSelection(TextSelection.create(step1.doc, to+1, to+1))
                      dispatch?.(step2);
                }
              
            }
        }
    
  
    }
}
  