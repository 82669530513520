import { API } from 'aws-amplify';
import fuzzysort from 'fuzzysort';
import React, { useEffect, useRef, useState } from 'react';
import { getFileType } from '../../../../../features/files/getFileDetails';
import tabs from '../../../../../layouts/HorizontalTabs/TabsData';
import { useAppSelector } from '../../../../../redux';
import { selectAllReadings } from '../../../../../redux/slices/allReadingsSlice';
import { ReadingDocument } from '../../../../../types/files';
import { basicColors } from '../../../../../utils/Colors';
import { SourceAnnotation } from '../../../desk-logic/metadata';
import { PDFAnnotationType } from '../../../reader/viewers/PDF/PdfManager';
import { ColorFilterProps } from '../AnnotationsFilterSelector/filters/ColorFilter';
import { DocumentFilterProps } from '../AnnotationsFilterSelector/filters/DocumentFilter';
import { PageNumberFilterProps } from '../AnnotationsFilterSelector/filters/PageNumberFilter';
import { TypeFilterProps } from '../AnnotationsFilterSelector/filters/TypeFilter';
import AnnotationBoxItem from './AnnotationBoxItem/AnnotationBoxItem';
// import AnnotationBoxItem from './_AnnotationBoxItem';

type AnnotationsListProps = {
    getAnnotationIcon: (annotation: PDFAnnotationType) => string;
    filterText: string,

} & PageNumberFilterProps & ColorFilterProps & TypeFilterProps & DocumentFilterProps  ;

const debug=false

const log = (a:any, b:any, c?:any) =>{
    console.log(a,b, c)
}

const getAllQuery = /* GraphQL */ `
query MyQuery {
    getAll{
      parentID
      permission
      name
      objectID
    }
}
`;

type AnnotationsArray = {text: string, annotation: SourceAnnotation, highlightedText?: (string | JSX.Element)[] | null}[]

const AnnotationsList:React.FC<AnnotationsListProps> = (props) => {

    const annotations = useAppSelector(state => state.annotationsPane)
    const [allDocuments, setAllDocuments] = useState<{[objectID: string]:ReadingDocument}>({})

    const allReadings = (useAppSelector(selectAllReadings))

    var annotationsList: AnnotationsArray  = [] //The array of annotations that will finally be rendered. 

    const [initLoading, setInitLoading] = useState<boolean>(true)

    const activeTab = useAppSelector(state => state.readerTabs.activeTab)
    const openedReadings = useAppSelector(state =>state.readerTabs.tabs).map(tab=>tab.readingDocument.objectID)

    log("Annotations: ", annotations)


    const getReadingDocument: (objectID: string ) => (ReadingDocument | undefined) = (objectID: string) =>{

        return allDocuments[objectID] 

    }

    const runSearch: (searchString: string, annoList: AnnotationsArray) =>AnnotationsArray = (searchString: string, annoList: AnnotationsArray) =>{

        console.log("runSearch: ", annoList)
        const fuzzyResults = fuzzysort.go(searchString, annoList, {key: 'text'})

        console.log(" runSearch fuzzyResults: ", fuzzyResults)

        const searchResults = fuzzyResults.map(res=>{
            console.log("result: ", res)
            return{
                text: res.obj.text,
                highlightedText: fuzzysort.highlight(res, (m,i) =><span className="bolden" key={i}>{m}</span>),
                annotation: res.obj.annotation
            }
        })

        console.log("searchResults: ", searchResults)

        return searchResults

    }

    const annotationPassesFilter: (annotation: SourceAnnotation) =>boolean = (annotation: SourceAnnotation) =>{
        /**
         * Takes in an annotation and returns back true or false depending on if the annotation passes the filters
         */

        //ColorFilter
        if(!annotation.renderInfo.color) {
            //annotation doesn't have a color

            if(props.selectedColors.length<basicColors.length){
                // there is a color filter. 
                return false
            }

            //annotation doesn't have a color and there is no filter . We can pass

        }
        else if(!props.selectedColors.includes(annotation.renderInfo.color)) {
            console.log("skipping annotationsList: ", annotation)
            return false
        }

        //DocumentFilter

        if(props.documentFilter.primFilter==="single"){
            if(!(activeTab?.readingDocument.objectID===annotation.objectID)) return false
        }
        else if(props.documentFilter.primFilter==="tabs"){
            if(!openedReadings.includes(annotation.objectID)) return false
        }
        else{
            //all do nothing
        }


        //Type Filter
        if(!props.selectedAnnotationTypes.includes(annotation.annoType as PDFAnnotationType)) {
            console.log("selected: ", props.selectedAnnotationTypes, annotation.annoType)
            return false
        }

        return true

    }


    for (const [objectID, documentAnnotationsObject] of Object.entries(annotations)){
        log("annotations loop1: ", objectID, documentAnnotationsObject)

        for(var [pageNumber, annotationsArray] of Object.entries(documentAnnotationsObject)){
            log("annotations loop2: ", pageNumber, annotationsArray)

            for (let annotation of annotationsArray){

                if(annotationPassesFilter(annotation)) {
                    annotationsList.push({
                        text: annotation.renderInfo.text, 
                        annotation: annotation
                    })
                    
                }
            }

        }
    }


    if(props.filterText){
        console.log("runSearch: ", props.filterText, annotationsList)
        annotationsList = runSearch(props.filterText, annotationsList )
    } 

    useEffect(() =>{

        //Implement this later

        return

        if(!allReadings){

            (API.graphql({query: getAllQuery}) as any)
            .then((resp:any) =>{
                console.log("got all response: ", resp)
                const _allDocuments: {[objectID: string]:ReadingDocument} = {}
                resp.data.getAll.map((file: any) =>{
                    if(getFileType(file)==="Reading"){
                        _allDocuments[file.objectID] = file
                    }
                })
                console.log("files loaded: ", _allDocuments)
                setAllDocuments(_allDocuments)
                
            })
            .catch((err:any)=>{
                console.error("couldn't getall: ", err)
            })
            .finally(() =>{
                setInitLoading(false)
            })

        }
        else{
            return
        }

        


    }, [])

    log("annotationsList: ", annotationsList)
    
    return (
        <div>
            {annotationsList.map(item=>
                <AnnotationBoxItem 
                    getAnnotationIcon={props.getAnnotationIcon}
                    getReadingDocument={Object.keys(allDocuments)?getReadingDocument: undefined}  
                    annotation={item.annotation} 
                    highlightedText={item.highlightedText || undefined}
                />    
            )}
        </div>
        
    )
}
export default AnnotationsList;