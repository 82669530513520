
/**
 * This page is arrived at when the user signs in in FORCE_CHANGE_PASSWORD state
 */

import React from 'react';
import Form from '../../../layouts/Form/Form';
import Button from '../../../ui/buttons/Button/Button';
import TitledInput from '../../../ui/inputs/TitledInput/TitledInput';
import { Title, Subtitle } from '../../../ui/text/Text';
import { completeNewPassword } from '../auth-functions/SignInFunctions';

const ForceChangePassword = (props) => {
    /**
     * @props
     * password: string
     */

    const onForceChangePassword = {

        run: (formState) =>{

            console.log("formState: ", formState)

            const email = formState.email.value
            const password = props.tempPassword
            const tempUser = props.tempUser
            const newPassword = formState.newPassword.value
            const confirmNewPassword = formState.confirmNewPassword.value

            return completeNewPassword(tempUser, email, newPassword, confirmNewPassword)

        }, 

        onSuccess: (user) =>{
            props.completeSignInStep({payload:user})
        }, 

        onError: () =>{

        }

    }



    return (
        <div>
            <Title>Set new password.</Title>
            <Subtitle>You'll need to reset your password before proceeding</Subtitle>

            <div className='form'>
                
                <Form
                    setFormState={props.setFormState}
                    initFormState={props.initFormState}
                    submit = {onForceChangePassword.run}
                    onSuccess={onForceChangePassword.onSuccess}

                >
                    <TitledInput styleSize="l"  id="email" label={"Email"} type={"email"} placeHolder="Enter your email address" 
                        disabled 
                    />
                    <TitledInput styleSize="l" type="password" id="newPassword" label={"New Password"} placeHolder="Something strong and memorable"  />
                    <TitledInput styleSize="l" type="password" id="confirmNewPassword" label={"Confirm New Password"} placeHolder="Let's make sure we got that right"  />

                    <Button text="Continue" style="primary" submit></Button>
                    <Subtitle size="s">Or <a onClick={() =>{props.setSubScreen("SignInForm")}}>use a different account</a></Subtitle>


                </Form>

            </div>

        </div>


    );
}
 
export default ForceChangePassword;

