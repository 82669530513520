import { Provider } from 'react-redux';
import store from '../../redux/store'


import React from 'react';
import DeskLayout from './main/DeskLayout';

import './index.css'

type DeskProps = {
    
};

const Desk:React.FC<DeskProps> = () => {
    
    return (
        <Provider store={store}>

            <DeskLayout />
            
        </Provider>
        
    )
}
export default Desk;