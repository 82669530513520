import { setStyle } from "@remirror/core";
import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    DelayedPromiseCreator,
    EditorView,
    ErrorConstant,
    extension,
    ExtensionTag,
    getTextSelection,
    invariant,
    isElementDomNode,
    PlainExtension,
    NodeExtension,
    NodeExtensionSpec,
    NodeSpecOverride,
    NodeViewMethod,
    omitExtraAttributes,
    PrimitiveSelection,
    ProsemirrorAttributes,
    ProsemirrorNode,
} from "@remirror/core";

import { fetchSourceRenders } from "../NotebookUtils";
import { ConsoleLogger } from "@aws-amplify/core";
import { TextSelection, NodeSelection } from "@remirror/pm/state";

@extension({
    defaultOptions: {
        renderOnSource: () => {},
        unrenderOnSource: () => {},
        notebookMetadata: {
            objectID: "test-id",
            storedLocation: "online",
        },
        scrollMargin: { top: 400, left: 0, right: 0, bottom: 400 },
        // Important
        imageStore: {
            saveAndInsertImage: () => {},
            fetchImageFromMetadata: () => {},
            deleteImageFromStore: () => {},
        },
    },
})
export class SourceRenderExtension extends PlainExtension {
    get name() {
        return "source-render";
    }

    createPlugin() {
        let that = this;
        return {
            props: {
                scrollMargin: that.options.scrollMargin,
            },
            state: {
                init(config, instance) {
                    // console.log("INIT PLUGIN", instance.doc, fetchSourceRenders(instance.doc, that.options.notebookMetadata))
                    return fetchSourceRenders(
                        instance.doc,
                        that.options.notebookMetadata
                    );
                },
                apply(tr, value, oldState, newState) {
                    let newSourceRenders = fetchSourceRenders(
                        newState.doc,
                        that.options.notebookMetadata
                    );
                    let sourceRenderTypes = ["clipping", "extract", "link"];

                    sourceRenderTypes.forEach((type) => {
                        for (let key in newSourceRenders[type]) {
                            const newArray = newSourceRenders[type][key];
                            const oldArray = value[type][key];
                            newArray?.forEach((entry) => {
                                if (
                                    !oldArray ||
                                    !oldArray.find((x) => x.id == entry.id)
                                ) {
                                    console.log(
                                        "CREATED",
                                        type,
                                        entry,
                                        oldArray,
                                        newArray
                                    );
                                    that.options.renderOnSource({
                                        assetType: type,
                                        sourceMetadata: entry.sourceMetadata,
                                        notebookMetadata: entry.notebookMetadata,
                                        id: entry.id,
                                        creatingNew: true,
                                    });
                                }
                            });
                            oldArray?.forEach((entry) => {
                                if (
                                    !newArray ||
                                    !newArray.find((x) => x.id == entry.id)
                                ) {
                                    console.log("DELETED", type, entry);
                                    // if (type == "clippings"){
                                    //   that.options.imageStore.deleteImageFromStore(entry)
                                    // }
                                    that.options.unrenderOnSource({
                                        assetType: type,
                                        sourceMetadata: entry.sourceMetadata,
                                        notebookMetadata: entry.notebookMetadata,
                                        id: entry.id
                                    });
                                }
                            });
                        }
                        for (let key in value[type]) {
                            if (!newSourceRenders[type][key]) {
                                value[type][key].forEach((entry) => {
                                    // if (type == "clippings"){
                                    //   that.options.imageStore.deleteImageFromStore(entry)
                                    // }
                                    that.options.unrenderOnSource({
                                        assetType: type,
                                        sourceMetadata: entry.sourceMetadata,
                                        notebookMetadata: entry.notebookMetadata,
                                        id: entry.id
                                    });
                                });
                            }
                        }
                    });

                    // that.options.renderOnSource(newSourceRenders)

                    return newSourceRenders;
                },
            },
        };
    }

    createCommands() {
        return {
            setStartSelection: () => {
                return ({ tr, dispatch }) => {
                    const doc = tr.doc;
                    tr = tr.setSelection(TextSelection.create(doc, 1, 1));
                    dispatch?.(tr);
                };
            },
            navigateToNotebook: (type, id) => {
                let that = this;
                return ({ tr, dispatch }) => {
                    const doc = tr.doc;
                    let newTr = null;
                    const recursiveWalk = (node, globalPos) => {
                        //AIM: Calculate global position for child and continue
                        let start = globalPos;
                        node.forEach((childNode, offset, index) => {
                            // console.log("Processing", childNode.type, start)
                            if (
                                type == "clippings" &&
                                childNode.type.name === "image" &&
                                childNode.attrs.metadata.id == id
                            ) {
                                // We must update source.
                                // The net index should be - global
                                // let new = Decoration.node(start+1, start+2, {style:"background: yellow", src:"https://dummyimage.com/600x400/000/fff"})
                                // console.log("Created",newDec)
                                // decorationSet.push(newDec)
                                newTr = tr.setSelection(
                                    TextSelection.create(
                                        tr.doc,
                                        start + childNode.nodeSize + 3,
                                        start + childNode.nodeSize + 3
                                    )
                                );
                                // const elem = that.store.view.domAtPos(start+offset, 1)
                                // console.log("Node", elem.node)
                                // setTimeout(()=>elem.node.scrollIntoView(), 0)
                                // newTr = newTr.scrollIntoView()
                            } else if (
                                childNode.type.name == "text" &&
                                childNode.marks
                            ) {
                                childNode.marks.forEach((mark) => {
                                    if (
                                        ((mark.type.name == "textextract" &&
                                            type == "extracts") ||
                                            (mark.type.name == "internallink" &&
                                                type == "links")) &&
                                        mark.attrs.metadata.id == id
                                    ) {
                                        //replace
                                        newTr = tr.setSelection(
                                            TextSelection.create(
                                                tr.doc,
                                                start + childNode.nodeSize + 4,
                                                start + childNode.nodeSize + 4
                                            )
                                        );
                                        // const elem = that.store.view.domAtPos(start+offset, 1)
                                        // console.log("Node", elem.node)
                                        // setTimeout(()=>elem.node.scrollIntoView(), 0)
                                    }
                                });
                            }
                            start += recursiveWalk(childNode, start);
                        });
                        return node.nodeSize;
                    };
                    recursiveWalk(doc, 0);
                    if (newTr) dispatch?.(newTr);
                };
            },
            deleteMetadata: (type, id, sourceMetadata) => {
                return ({ tr, dispatch }) => {
                    const doc = tr.doc;
                    let decorationSet = [];
                    let newTr = tr;
                    const recursiveWalk = (node, globalPos) => {
                        //AIM: Calculate global position for child and continue
                        let start = globalPos;
                        node.forEach((childNode, offset, index) => {
                            if (
                                type == "clippings" &&
                                childNode.type.name === "image" &&
                                childNode.attrs.metadata.id == id
                            ) {
                                // We must update source.
                                // The net index should be - global
                                newTr = tr.deleteRange(
                                    start,
                                    start + childNode.nodeSize
                                );
                            } else if (
                                childNode.type.name == "text" &&
                                childNode.marks
                            ) {
                                childNode.marks.forEach((mark) => {
                                    if (
                                        ((mark.type.name == "textextract" &&
                                            type == "extracts") ||
                                            (mark.type.name == "internallink" &&
                                                type == "links")) &&
                                        mark.attrs.metadata.id == id
                                    ) {
                                        //replace
                                        newTr = newTr.removeMark(
                                            start,
                                            start + childNode.nodeSize + 1,
                                            mark
                                        );
                                    }
                                });
                            }
                            start += recursiveWalk(childNode, start);
                        });
                        return node.nodeSize;
                    };
                    recursiveWalk(doc, 0);
                    if (newTr) dispatch?.(newTr);
                };
            },
            modifyMetadata: (type, id, sourceMetadata) => {
                return ({ tr, dispatch }) => {
                    const doc = tr.doc;
                    let decorationSet = [];
                    let newTr = null;
                    const recursiveWalk = (node, globalPos) => {
                        //AIM: Calculate global position for child and continue
                        let start = globalPos;
                        node.forEach((childNode, offset, index) => {
                            if (
                                type == "clippings" &&
                                childNode.type.name === "image" &&
                                childNode.attrs.metadata.id == id
                            ) {
                                // We must update source.
                                // The net index should be - global
                            } else if (
                                childNode.type.name == "text" &&
                                childNode.marks
                            ) {
                                childNode.marks.forEach((mark) => {
                                    if (
                                        ((mark.type.name == "textextract" &&
                                            type == "extracts") ||
                                            (mark.type.name == "internallink" &&
                                                type == "links")) &&
                                        mark.attrs.metadata.id == id
                                    ) {
                                        //replace
                                        newTr = tr.removeMark(
                                            start,
                                            start + childNode.nodeSize,
                                            mark
                                        );
                                        mark.attrs = { ...mark.attrs };
                                        mark.attrs.metadata = {
                                            ...mark.attrs.metadata,
                                        };
                                        mark.attrs.metadata.sourceMetadata =
                                            sourceMetadata;
                                        newTr = newTr.addMark(
                                            start,
                                            start + childNode.nodeSize,
                                            mark
                                        );
                                    }
                                });
                            }
                            start += recursiveWalk(childNode, start);
                        });
                        return node.nodeSize;
                    };
                    recursiveWalk(doc, 0);
                    if (newTr) dispatch?.(newTr);
                };
            },
        };
    }
}
