
import React, {useEffect, useState} from 'react';
import  TitledInput  from '../../../../ui/inputs/TitledInput/TitledInput';
import people from '../../../../assets/icons/people.svg'
import arrowIcon from '../../../../assets/icons/right_arrowhead.svg'

import './SharingBox.css'
import Button from '../../../../ui/buttons/Button/Button';
import PeoplePreview from '../../PeoplePreview/PeoplePreview';
import ProfilePreview from '../../../account/ProfilePreview/ProfilePreview';
import Collaborators from '../CollaboratorItem/CollaboratorItem';
import link from '../../../../assets/icons/link.svg';
import { DocumentInfoProps } from '../../../../types/files';
import { Collaborator, Permission, User } from '../../../../types/user';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { selectUser } from '../../../account/userSlice';
import { API } from 'aws-amplify';

import useGetCollaborators from '../../../../hooks/CollaborationHooks/useGetCollaborators';
import useAddCollaborator, {getUpdatedCollaborators} from '../../../../hooks/CollaborationHooks/useAddCollaborator';
import CollaboratorItem from '../CollaboratorItem/CollaboratorItem';
import useRemoveCollaborator from '../../../../hooks/CollaborationHooks/useRemoveCollaborator';
import loading_gif from '../../../../assets/icons/gifs/loading_secondary.gif'
import { showErrorMessagePopup, showMessagePopup } from '../../../../layouts/MessagePopup/messagePopupSlice';

type SharingBoxProps = {
    updateCollaboratorsInParent?: React.Dispatch<React.SetStateAction<Collaborator[] | undefined>>,
    //if the collaborators need to be updated in the parent when changes are made here. 
    file: DocumentInfoProps
}

const SharingBox:React.FC<SharingBoxProps> = (props) => {

    const {file} = props

    const [loadingNewCollaborator, setLoadingNewCollaborator] = useState<boolean>(false) //true when adding a new collaborator is in progress
    const [error, setError] = useState<string>("")

    const[newUserPermission, setNewUserPermission] = useState<Permission>("editor")
    const dispatch = useAppDispatch()

    const backendGetCollaborators = useGetCollaborators()
    const backendAddCollaborator = useAddCollaborator()
    const backendRemoveCollaborator = useRemoveCollaborator()

    const [email,setEmail] = useState<string>("")
    
    const me = useAppSelector(selectUser) as User

    const [initLoading, setInitLoading] = useState<boolean>(true)

    const [users, setUsers] = useState<Collaborator[]>([{
        name: me.name,
        email: me.email as string,
        permission: file.objectData.permission
    }])

    useEffect(() =>{
        //this function runs when users are changed
        console.log("need to update users in parent")
        if(props.updateCollaboratorsInParent){
            props.updateCollaboratorsInParent(users)
        }
    }, [users])

    const onChangeEmail = (e: any) =>{
        setError("")
        const target = e.target as HTMLInputElement
        setEmail(target.value)
    }

    const onKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) =>{
        if(e.key!=="Enter") return
        else onSubmit()
    }

    const updateCollaborators = (email: string, permission: Permission) => {
        
        setLoadingNewCollaborator(true)

        if(permission==="none"){
            //We need to remove a collaborator
            backendRemoveCollaborator({objectID: file.objectData.objectID, email: email})
            .then((removedUser) =>{
                if(removedUser){
                    console.log("user was removed: ", removedUser)
                    const updatedUsers = users.filter(user => user.email !== removedUser.email);
                    setUsers(updatedUsers)
                }
                else{
                    console.log("Something went wrong.")
                }
            })
            .catch(e =>{
                console.error("Error: ", e)
            })
            .finally(() =>{
                setLoadingNewCollaborator(false)
            })

        }
        else{
            backendAddCollaborator({file: file, email: email, permission: permission })
            .then((newUser) =>{
                if(newUser){
                    const updatedUsers = getUpdatedCollaborators(users, newUser)
                    setUsers(updatedUsers)
                    setEmail("")
                }
                else{
                    setError("Couldn't find an account with that email.")
                    console.log("didnt get a new user")
                }
            })
            .catch(e =>{

            })
            .finally(() =>{
                setLoadingNewCollaborator(false)
            })
        }

        

    }
    
    const onSubmit = async( ) =>{

        console.log("submitted!")


        if(email.length==0){
            setError("Enter an email address")
            setLoadingNewCollaborator(false)
            return
        }
        else{
            updateCollaborators(email, newUserPermission)   
        }
    }

    useEffect(()=>{
        // Initialise users!
        // This is done based on the props

        backendGetCollaborators(file).then(res=>{
            console.log("res: ", res)
            if(res) {
                setUsers(res)
                setInitLoading(false)
            }

        })
        
    },[])

    const onChangeNewUserPermission = (e: React.ChangeEvent<HTMLSelectElement> ) =>{
        
        setNewUserPermission(e.target.value as Permission)

    }

    const copyLink = () => {

        var link:string = ""

        if(file.fileType==="Reading"){
            link = '/desk?reading=["' + file.objectData.objectID + '"]'
        }
        else if(file.fileType==="Writing"){
            link = '/desk?writing=' + file.objectData.objectID
        }
        else{
            link = '/folders/' + file.objectData.objectID
        }

        link = location.origin + link

        navigator.clipboard.writeText(link).then(res=>{
            dispatch(showMessagePopup("Copied link to clipboard"))

        })
        .catch(e =>{
            dispatch(showErrorMessagePopup("Couldn't copy link"))
        })

        console.log("link: ", link )

        return location.origin + link

    }
    
    return (
        <div className='SharingBox'>

            

            {/* <h4>People</h4> */}
            <div className="People">


                <div className={`People-input-container ${error?"error-input":"no-error"}`}>
                    <div className='input-element input-main-container'>
                      
                        <img className="people-icon input-icon" src={people}></img>

                        <input className="input-collabs" 
                            placeholder='Enter a registered email address' onChange={onChangeEmail} onKeyDown={onKeyDown} 
                            value={email}
                        
                        ></input>
                        
                        <select className='select-permission' value={newUserPermission} onChange={onChangeNewUserPermission}>
                            <option disabled= {true} value="owner">Owner</option>
                            <option value="editor">Can edit</option>
                            <option value="viewer">Can view</option>
                        </select>
                        {/* <img src={arrowIcon} className="select-arrowIcon" alt="arrow"/> */}
                        



                    </div>
                    <div className='button-add'>
                        <Button fill='solid' onClick={onSubmit} hideTextOnLoading loading={loadingNewCollaborator}
                            // isDisabled={email.length===0}
                        >
                            Add
                        </Button>
                    </div>

                </div>
                
                <div className='error-message'>
                    {error}
                </div>
                {
                    !initLoading?
                    <div className='SharingBox-collaborators show-scroll'>
            
                        <div className="Collaborators">
                            {
                                users.map(user=>
                                    <CollaboratorItem collaborator={user} updateCollaborators={updateCollaborators} />
                                )
                            }
                        </div>

                    </div>
                    :
                    <div className="loading-container-collaborators">
                        <img className="loading-collaborators" src={loading_gif}></img>
                    </div>

                }

                <div className='SharingBox-footer'>
                    <div>Only the people added above can access this file.</div>
                    <Button icon={link} onClick={copyLink}>Copy Link</Button>

                </div>

                

            </div>
            {
                    file.fileType!=="Folder"?
            <div className='SharingBox-message'>
                
                   <b>Tip:</b> {`Share a folder if you would like to share readings along with notebooks.`}
                    
            </div>
            :
            null
            }




            {/* <Collaborator user={{name: "Akash Anand"}} permission="OWNER" /> */}

        </div>
    )
}
export default SharingBox;