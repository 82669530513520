import { PDFDocumentProxy } from "pdfjs-dist";

import {
    PDFViewer,
    PDFLinkService,
    PDFFindController,
    EventBus,
    NullL10n,
} from "pdfjs-dist/web/pdf_viewer.js";
import "pdfjs-dist/web/pdf_viewer.css";
import "./PdfViewer/pdf_overload.css";

import ReactDOM from "react-dom/client";
import highlighterIcon from '../../../../../assets/icons/reader_toolbar/highlighter.svg'

import { CMenuContextValue } from "../../../../../contexts";
import { Comment, ReadingDocument, Thread } from "../../../../../types/files";
import {
    RenderOnSourceOptions,
    SourceAnnotation,
    SourceMetadata,
    UnrenderOnSourceOptions,
} from "../../../desk-logic/metadata";
import { ContextMenuObject } from "../../../../../layouts/ContextMenu/ContextMenu";
import Rectangle from "../helpers/Rectangle";
import navigate_light from "../../../../../../src/assets/legacy_icons/menu_icons/navigate_light.svg";

import { getText } from "../../../../../utils/legacy_utils/getText";
import { fillGap } from "../../../../../utils/legacy_utils/fillGap";
import { store } from "../../../../../redux";
import { v4 as uuidv4 } from "uuid";
import { PdfHighlight } from "./PdfAnnotations/PdfHighlight";
import { ColorAlias, ColorMap } from "../../../../../utils/Colors";
import { ExtractColorMap } from "../../../../../utils/legacy_utils/Colors";
import {
    createAnnotation,
    deleteAnnotation,
    getAnnotations,
    updateAnnotation,
} from "../../../desk-logic/annotationSaver";

import ThreadComponent from "./PdfAnnotations/Comments/Thread";
import { Provider } from "react-redux";
import PdfClip from "./PdfAnnotations/PdfClip";
import {
    inactivateReaderToolbar,
    setActiveTool,
} from "../../components/ReaderToolbar/readerToolbarSlice";
import { ReaderTool } from "../../components/ReaderToolbar/ReaderToolbar";
import { RectangleState } from "../../components/ReaderToolbar/tooltips/RectangleTooltip";
import { PdfRectangle } from "./PdfAnnotations/Movable/PdfRectangle";
import { PdfStickyNote } from "./PdfAnnotations/Movable/PdfStickyNote";
import { PdfLink } from "./PdfAnnotations/Movable/PdfLink";
import { navigateToNotebook } from "../../../desk-logic/sourceFunctions";
import edit from '../../../../../assets/icons/edit.svg';
import copy from '../../../../../assets/icons/edit.svg'
import trash from '../../../../../assets/icons/trash.svg';
import { environment } from "remirror";

// const pdfjsLib = require("pdfjs-dist/webpack"); //See if this is okay: pdfjs-dist/build/pdf.js
// https://softwareengineering.stackexchange.com/questions/378815/whats-the-best-way-to-handle-a-large-multi-file-class-in-javascript
export interface PDFSourceInfo {
    scale: number;
    pageIndex: number;
    pdfPoint: [number, number];
    rotation: number;
}

export interface PDFSourceMetadata extends SourceMetadata {
    sourceInfo: PDFSourceInfo;
    renderInfo: any;
    objectID: string;
    storedLocation: "online" | "offline";
    format: "PDF";
}

export type PDFAnnotationType = "highlight" | "rectangle" | "extract" | "clipping" | "sticky" | "thread" | "link"

export const allPDFAnnotations: PDFAnnotationType[] = ["highlight", "rectangle", "extract", "clipping", "sticky", "link"]; //thread

interface BasePDFAnnotation {
    id: string;
    createdAt: string;
    objectID: string;
    owner: string;
    sourceInfo: PDFSourceInfo;
    annoType: PDFAnnotationType
}

interface PDFHighlightAnnotationDetails {
    renderInfo: {
        coords: [number, number, number, number][];
        color: string;
        text: string;
    };
    annoType: "highlight";
}

interface PDFRectangleAnnotationDetails {
    annoType: "rectangle";
    renderInfo: {
        topLeft: [number, number];
        botRight: [number, number];
        drawingStyle: {
            opacity: number,
            activeColor: ColorAlias
        }
    };
}

interface PDFExtractAnnotationDetails {
    annoType: "extract";
    renderInfo: {
        coords: [number, number, number, number][];
        color: string;
        text: string;
        notebookMetadata: {
            objectID: string;
            storedLocation: string;
        };
    };
}

interface PDFClippingAnnotationDetails {
    annoType: "clipping";
    renderInfo: {
        topLeft: [number, number];
        botRight: [number, number];
        hidden: boolean;
        notebookMetadata: {
            objectID: string;
            storedLocation: string;
        };
    };
}
interface PDFStickyNoteAnnotationDetails {
    annoType: "sticky";
    renderInfo: {
        topLeft: [number, number];
        botRight: [number, number];
        color: ColorAlias,
        text: string
    }
}

interface PDFCommentDetails {
    annoType: "thread";
    renderInfo: {
        comments: Comment[];
    };
}

interface PDFLinkAnnotationDetails {
    annoType: "link"
    renderInfo: {
        topLeft: [number, number];
        botRight: [number, number]; //WASTE
        notebookMetadata: {
            objectID: string;
            storedLocation: string;
        };
    }
}

export type PDFHighlightAnnotation = BasePDFAnnotation & (PDFHighlightAnnotationDetails)
export type PDFRectangleAnnotation = BasePDFAnnotation & (PDFRectangleAnnotationDetails)
export type PDFClippingAnnotation = BasePDFAnnotation & (PDFClippingAnnotationDetails)
export type PDFExtractAnnotation = BasePDFAnnotation & (PDFExtractAnnotationDetails)
export type PDFStickyNoteAnnotation = BasePDFAnnotation & (PDFStickyNoteAnnotationDetails)
export type PDFLinkAnnotation = BasePDFAnnotation & (PDFLinkAnnotationDetails)

export type PdfMovableAnnotation = BasePDFAnnotation & (PDFRectangleAnnotation | PDFStickyNoteAnnotation | PDFLinkAnnotation)

type PDFAnnotationDetails =
    | PDFExtractAnnotationDetails
    | PDFHighlightAnnotationDetails
    | PDFCommentDetails
    | PDFClippingAnnotationDetails
    | PDFRectangleAnnotationDetails
    | PDFStickyNoteAnnotationDetails
    | PDFLinkAnnotationDetails


export type PDFAnnotation = BasePDFAnnotation & PDFAnnotationDetails;

// TODO: We need to feed in env into the manager to maintain this info better

interface PDFClipInfo extends PDFSourceMetadata {
    renderInfo: { topLeft: [number, number]; botRight: [number, number] };
}

class PdfManager {
    readingDocument: ReadingDocument;
    eventBus: EventBus; // PDFjs event bus
    linkService: PDFLinkService; //PDFjs link service
    documentObj: PDFDocumentProxy;
    pdfFindController: PDFFindController;
    viewer: PDFViewer;
    container: HTMLDivElement;
    rectangleContext: {
        pageIndex: number;
        pageView: any; // PDFViewer pageView
    };
    bufferFunction?: () => void;
    active: boolean;
    showContextMenu: (a: false | ContextMenuObject) => void;
    annotations: {
        [key: number]: PDFAnnotation[]; //page number is the key
    };
    highlightRoots: {
        [key: number]: ReactDOM.Root;
    };
    annotationRoots: {
        [key: number]: ReactDOM.Root;
    };
    glowing: Set<String>;
    constructor(
        container: HTMLDivElement,
        pdfDoc: PDFDocumentProxy,
        readingDocument: ReadingDocument,
        active: boolean,
        cMenuContext: CMenuContextValue
    ) {
        console.log("MANAGER CREATED");
        this.readingDocument = readingDocument;
        this.container = container;
        this.documentObj = pdfDoc;
        this.eventBus = new EventBus();

        this.linkService = new PDFLinkService({ eventBus: this.eventBus });
        this.viewer = new PDFViewer({
            container: container,
            // enhanceTextSelection: true,
            // removePageBorders: true,
            linkService: this.linkService,
            eventBus: this.eventBus,
            textLayerMode: 2,
            l10n: NullL10n, //no idea what this is?
        });

        this.viewer.setDocument(pdfDoc);
        this.linkService.setDocument(pdfDoc);
        this.linkService.setViewer(this.viewer);
        this.pdfFindController = new PDFFindController({
            linkService: this.linkService,
            eventBus: this.linkService.eventBus,
        });
        this.viewer.findController = this.pdfFindController 

        


        this.rectangleContext = {
            pageIndex: 0,
            pageView: null,
        };
        this.active = active;
        this.annotations = {};
        this.viewer.eventBus.on("textlayerrendered", (event: any) => {
            this.showAnnotation(event.pageNumber - 1);
        });
        this.viewer.eventBus.on("pagesloaded", async () => {
            // this.enablePinchZoom()

            if (readingDocument.lastLocation) {
                console.log("lastLocation: ", readingDocument.lastLocation);
                const { pageIndex, pdfPoint, scale } =
                    readingDocument.lastLocation;
                this.bufferFunction = () =>
                    this.setReaderConfiguration(
                        this.getReaderConfigurationFromPDFPoint(
                            pageIndex,
                            pdfPoint,
                            scale
                        ),
                        false,
                        true
                    );
            } else {
                try {
                    this.bufferFunction = () =>
                        (this.viewer.currentScaleValue = "page-width");
                } catch (e) {
                    console.error(e);
                }
            }
            if (this.active) {
                this.bufferFunction!();
            }
        });
        this.showContextMenu = cMenuContext.showContextMenu;

        this.container.addEventListener("pointerup", (event) => {
            // if (event.clientX === this.mouseDownPosition.x && event.clientY === this.mouseDownPosition.y){
            //     return
            // }
            console.log("mouse up here!")
            if (window.getSelection()?.toString() !== "") {
                // Check if selected text is in the container or not
                let sel = window.getSelection() as Selection;
                var node = sel.getRangeAt(0)
                    .commonAncestorContainer as HTMLElement;
                let isPDFText = this.checkPDFText(node);
                if (!isPDFText) return;
                let position = sel.anchorNode?.compareDocumentPosition(
                    sel.focusNode as Node
                );
                let backward = false;
                // position == 0 if nodes are the same
                if (
                    (!position && sel.anchorOffset > sel.focusOffset) ||
                    position === Node.DOCUMENT_POSITION_PRECEDING
                )
                    backward = true;

                this.showContextMenu({
                    show: true,
                    menuArray: [
                        {
                            label: "Add to Notes",
                            icon: edit,
                            handler: this.addTextToNotebook,
                        },
                        {
                            icon: highlighterIcon,
                            label: "Annotate:",
                            type: "colors",
                            handler: this.createHighlightFromSelection,
                        },
                    ],
                    X: event.clientX,
                    Y: event.clientY,
                    backward: backward,
                });
            }
            // else{
            //     this.highlightTimer = setTimeout(()=>{this.getHighlightCoords()}, 400)
            // }
        });

        // Network requests for fetching annotations
        getAnnotations(this.readingDocument).then((annotations) => {
            this.annotations = {
                ...(annotations as {
                    [key: number]: PDFAnnotation[];
                }),
            };
            Object.keys(this.annotations).forEach((x) =>
                this.showAnnotation(parseInt(x))
            );
        });

        this.highlightRoots = {};
        this.annotationRoots = {};
        this.glowing = new Set()
    }

    setActive = () => {
        this.active = true;
        if (this.bufferFunction) {
            this.bufferFunction();
        }
        this.bufferFunction = () => {};
    };

    checkPDFText = (node: HTMLElement | null) => {
        while (
            node &&
            node !== this.container &&
            node.className !== "textLayer"
        ) {
            node = node.parentElement;
        }
        if (!node || node.className !== "textLayer") {
            return false;
        }
        return true;
    };



    getPageFromTarget = (node: HTMLElement | null) => {
        while (node && node !== this.container && node.className !== "page") {
            node = node.parentElement;
        }
        if (!node || node.className !== "page") {
            return { pageIndex: null, pageView: null };
        }
        node = node as HTMLElement;
        let pageIndex =
            parseInt(node.getAttribute("data-page-number") as string) - 1;
        return {
            pageIndex: pageIndex,
            pageView: this.viewer.getPageView(pageIndex),
        };
    };

    getScale = () => {
        return this.viewer.currentScale;
    };

    getReaderConfigurationFromPDFPoint = (
        pageIndex: number,
        pdfPoint: [number, number],
        scale: null | number,
        options = { setMargin: true }
    ) => {
        /**
         * @param scale : pass scale when you know the scale and want to create the config object. if scale is null it will get the current scale of the pdf viewer
         * @param setMargin : pass true if you want to offset x and y and false otherwise
         * @param incrementPage : pass true if the pageIndex is the pagenumber shown in the navigator. Pass false if the page index is actually the page index.
         */
        const { setMargin } = options;
        let x = pdfPoint[0];
        let y = pdfPoint[1];
        if (setMargin) {
            x = x - 20;
            y = y + 60;
        }
        let arr = [{ gen: null, key: null }, { name: "XYZ" }, x, y, null];
        return {
            page: pageIndex + 1,
            dest: arr,
            scale: scale || this.getScale(),
        };
    };

    setReaderConfiguration = (
        config: { page: number; dest: any[]; scale?: number },
        calledFromLocMarkContainer = false,
        scaleBack = false
    ) => {
        // console.log("setReaderConfig: ")
        if (config == null) return;
        // this.saveCurrentPosition(calledFromLocMarkContainer)
        if (scaleBack) {
            if (!config.scale) config.scale = 1.0;
            this.viewer.currentScale = config.scale;
        }

        console.log("this.viewer: ", this.viewer)
        this.viewer.scrollPageIntoView({
            pageNumber: config.page,
            destArray: config.dest,
        });
    };

    goToSection = (section: any) => {
        var destArray: any;
        var pageNumber;
        const documentObj = this.documentObj;
        if (typeof section.dest !== "string") {
            destArray = section.dest;
            const refObj = section.dest[0];
            documentObj.getPageIndex(refObj).then((id) => {
                pageNumber = id + 1;
                this.setReaderConfiguration({
                    page: pageNumber,
                    dest: destArray,
                });
            });
        } else {
            documentObj
                .getDestination(section.dest)
                .then((dest) => {
                    destArray = dest;
                    if (!Array.isArray(dest)) return;
                    const refObj = dest[0];
                    documentObj.getPageIndex(refObj).then((id) => {
                        pageNumber = id + 1;
                        this.setReaderConfiguration({
                            page: pageNumber,
                            dest: destArray,
                        });
                    });
                })
                .catch((e) => {
                    console.log(section);
                });
        }
    };

    navigateToSource = (
        sourceMetadata: PDFSourceMetadata,
        assetType: { name: string; key: string }
    ) => {
        const { pageIndex, pdfPoint, scale } = sourceMetadata.sourceInfo;
        if (sourceMetadata.objectID !== this.readingDocument.objectID) {
            return;
        }
        const config = this.getReaderConfigurationFromPDFPoint(
            pageIndex,
            pdfPoint,
            scale,
            { setMargin: true }
        );

        this.setReaderConfiguration(config);
        console.log("ASSET TYPE", assetType);
        if (assetType?.key) {
            this.glowing.add(assetType.key)
            this.showAnnotation(pageIndex)
            setTimeout(()=>{
                this.glowing.delete(assetType.key)
                this.showAnnotation(pageIndex)
            },2500)
        }
        // trackEvent(this.props.user? this.props.user: null, {
        //     category:"NavigateFromNotebook",
        //     action: this.props.user?.sub ?? null,
        //     label: this.props.fileName,
        //     docID: this.props.initInfo.value,
        // })
    };

    getPdfPointFromCoordinates = (pageIndex: number, x:number, y:number) => {
        let page = this.viewer.getPageView(pageIndex)
        var viewport = page.viewport
        // var pageRect = page.canvas.getClientRects()[0]; not needed
        return viewport.convertToPdfPoint(x, y)
        
    }

    getViewportPointFromPdfPoint = (pageIndex: number, pdfPoint: [number, number])=>{
        let page = this.viewer.getPageView(pageIndex)
        var viewport = page.viewport
        const [x,y] = viewport.convertToViewportPoint(
            pdfPoint[0],
            pdfPoint[1]
        )
        return {x, y}
    }

    getPdfPointFromEvent = (event: MouseEvent) => {
        let { clientX, clientY } = event;
        let { pageView, pageIndex } = this.getPageFromTarget(
            event.target as HTMLElement
        );
        if (!pageView) {
            return { page: null, pageIndex: null, pdfPoint: null };
        }
        var pageRect = pageView.canvas.getClientRects()[0];
        return {
            pageView: pageView,
            pageIndex: pageIndex,
            pdfPoint: pageView.viewport.convertToPdfPoint(
                clientX - pageRect.x,
                clientY - pageRect.y
            ),
        };
    };

    addAnnotation = (
        inc: PDFAnnotationDetails,
        id: string | false,
        sourceInfo: PDFSourceInfo,
        noSave?: boolean
    ) => {
        const user = store.getState().user.user;
        const createdAt = new Date();
        const annotation = {
            ...inc,
            createdAt: createdAt.toISOString(),
            owner: user?.userID as string,
            id: id ? id : uuidv4(),
            objectID: this.readingDocument.objectID,
            sourceInfo: sourceInfo,
        };
        console.log("Creating", annotation);
        if (this.annotations[sourceInfo.pageIndex]) {
            this.annotations[sourceInfo.pageIndex].push(annotation);
        } else {
            this.annotations[annotation.sourceInfo.pageIndex] = [annotation];
        }
        if (!noSave) createAnnotation(annotation);
        this.showAnnotation(annotation.sourceInfo.pageIndex);
        return annotation;
    };

    createHighlightFromSelection = (color: string) => {
        let { sourceMetadata } = this.getSelectedText()!;
        sourceMetadata.renderInfo.color = color;

        const newHighlight: PDFHighlightAnnotationDetails = {
            annoType: "highlight",
            renderInfo: sourceMetadata.renderInfo,
        };
        window.getSelection()?.removeAllRanges();
        this.addAnnotation(newHighlight, false, sourceMetadata.sourceInfo);
    };

    getLocationFromClick = (
        callback: (x: PDFSourceMetadata) => void,
        callingTool?: ReaderTool
    ) => {
        if (callingTool) {
            setTimeout(()=>store.dispatch(
                setActiveTool({
                    tool: callingTool,
                })
            ), 0)
        }
        const getLocationListener = (event: MouseEvent) => {
            store.dispatch(inactivateReaderToolbar())
            document.removeEventListener("click", getLocationListener);
            // this.setPopupMessage(false)
            console.log("Listening for click! In listener");
            let { pdfPoint, pageIndex, pageView } = this.getPdfPointFromEvent(event);
            if (pdfPoint == null) {
                return null
            };
            callback({
                sourceInfo: {
                    pdfPoint,
                    pageIndex: pageIndex!,
                    scale: this.getScale(),
                    rotation:
                        (pageView.rotation + pageView.pdfPageRotate) % 360,
                },
                renderInfo: null,
                objectID: this.readingDocument.objectID,
                storedLocation: "online",
                format: "PDF",
            });
        };
        // this.setPopupMessage("Click anywhere on the source document to add link")
        setTimeout(() => {
            document.addEventListener("click", getLocationListener);
        }, 0);
    };

    setBufferFunction = (callback: () => void) => {
        this.bufferFunction = callback;
    };

    useRectangle = (successCallback: (sourceInfo: PDFClipInfo) => void, options?: RectangleState) => {
        // This function initiates a rectangle from the PDF
        // The success callback needs to do with that rectangle whatever it needs to
        // (add as annotation or make screenshot)
        new Rectangle({
            drawingStyle: options,
            checkDragStart: (evt: MouseEvent) => {
                const { pageIndex, pageView } = this.getPageFromTarget(
                    evt.target as HTMLElement
                );
                if (!pageView) {
                    // Clicked somewhere that is not a page!
                    store.dispatch(inactivateReaderToolbar());
                    return false;
                } else {
                    this.rectangleContext = {
                        pageIndex: pageIndex as number,
                        pageView,
                    };
                    return true;
                }
            },
            getBounds: () => {
                const { pageView } = this.rectangleContext;
                const canvas = pageView.canvas;
                const pageRect = canvas.getClientRects()[0];
                return {
                    minX: pageRect.x,
                    minY: pageRect.y,
                    maxX: pageRect.x + pageRect.width,
                    maxY: pageRect.y + pageRect.height,
                };
            },
            finishDrawing: (finalRect) => {
                console.log("finishDrawin!");
                const { pageView, pageIndex } = this.rectangleContext;
                const { x, y, width, height } = finalRect;
                const viewport = pageView.viewport;
                const canvas = pageView.canvas;
                const pageRect = canvas.getClientRects()[0];
                let point1 = viewport.convertToPdfPoint(
                    x - pageRect.x,
                    y - pageRect.y
                );
                let point2 = viewport.convertToPdfPoint(
                    x + width - pageRect.x,
                    y - pageRect.y
                );
                let point3 = viewport.convertToPdfPoint(
                    x - pageRect.x,
                    y + height - pageRect.y
                );
                let point4 = viewport.convertToPdfPoint(
                    x + width - pageRect.x,
                    y + height - pageRect.y
                );
                var points = [point1, point2, point3, point4];
                points = points.sort((a, b) => {
                    if (a[0] < b[0]) return -1;
                    if (a[0] === b[0] && a[1] > b[1]) return -1;
                    return 1;
                });
                const topLeft = points[0];
                const botRight = points[3];
                const netRotation =
                    (pageView.rotation + pageView.pdfPageRotate) % 360;
                const scale = this.viewer.currentScale;
                let sourceMetadata: PDFClipInfo = {
                    sourceInfo: {
                        scale,
                        pageIndex,
                        pdfPoint: topLeft,
                        rotation: netRotation,
                    },
                    renderInfo: { topLeft, botRight },
                    objectID: this.readingDocument.objectID,
                    storedLocation: "online",
                    // TODO: We need to feed in env into the manager to maintain this info better
                    format: "PDF",
                };
                // We should now call the success callback
                successCallback(sourceMetadata);
            },
        });
    };

    initiateRectangle = (options: RectangleState) => {
        setTimeout(()=>store.dispatch(
            setActiveTool({
                tool: "rectangle",
            })
        ), 0)
        this.useRectangle((clipInfo: PDFClipInfo)=>{
            const rectAnno : PDFRectangleAnnotationDetails = {
                annoType: "rectangle",
                renderInfo: 
                {...clipInfo.renderInfo, drawingStyle: options}
            }
            this.addAnnotation(rectAnno, false, clipInfo.sourceInfo)
            // INACTIVATING
            console.log("Running the inactivate call")
            store.dispatch(inactivateReaderToolbar())
        }, options)
    };

    initiateScreenshot = (
        callback: (url: any, sourceMetadata: PDFClipInfo) => void
    ) => {
        console.log("clipping started")
        setTimeout(()=>store.dispatch(
            setActiveTool({
                tool: "clipping",
            })
        ), 0)


        this.useRectangle((sourceMetadata: PDFClipInfo) => {
            // Remember, rectangleContext is still available.
            const { pageView } = this.rectangleContext;
            const canvas = pageView.canvas;
            let { scale, rotation } = sourceMetadata.sourceInfo;
            let { topLeft, botRight } = sourceMetadata.renderInfo;
            if (scale === null || isNaN(scale)) {
                scale = 5.0;
            }



            let width = canvas.width;

            pageView.pdfPage.pdfPageRotate = rotation;

            let viewportScale =
                width /
                pageView.pdfPage.getViewport({ scale: 1.0, rotation: rotation })
                    .width;
            const newViewport = pageView.pdfPage.getViewport({
                scale: viewportScale,
                rotation: rotation,
            });

            const newCanvas = document.createElement("canvas");

            topLeft = newViewport.convertToViewportPoint(
                topLeft[0],
                topLeft[1]
            );
            botRight = newViewport.convertToViewportPoint(
                botRight[0],
                botRight[1]
            );
            const newCanvasContext = newCanvas.getContext(
                "2d"
            ) as CanvasRenderingContext2D;
            let context = canvas.getContext("2d");

            try {
                var imageData = context.getImageData(
                    topLeft[0],
                    topLeft[1],
                    botRight[0] - topLeft[0],
                    botRight[1] - topLeft[1]
                );
                if (rotation === 0) {
                    newCanvas.height = botRight[1] - topLeft[1];
                    newCanvas.width = botRight[0] - topLeft[0];
                } else if (rotation === 90) {
                    newCanvas.width = botRight[1] - topLeft[1];
                    newCanvas.height = botRight[0] - topLeft[0];
                }
                newCanvas.height =
                    botRight[1] - topLeft[1] > 0
                        ? botRight[1] - topLeft[1]
                        : -(botRight[1] - topLeft[1]);
                newCanvas.width =
                    botRight[0] - topLeft[0] > 0
                        ? botRight[0] - topLeft[0]
                        : -(botRight[0] - topLeft[0]);

                newCanvasContext.putImageData(imageData, 0, 0);
                //this.tempRef.current.appendChild(canvas)
                var url = newCanvas.toDataURL("image/png");
                //document.getElementById("root").appendChild(newCanvas)
            newCanvas.remove();
                callback(url, sourceMetadata);
            } catch (e) {
                console.error(e);
            } finally {
                store.dispatch(inactivateReaderToolbar());
            }
        });
    };

    getSelectedText = () => {
        let sel = window.getSelection() as Selection;
        var selectionRects = sel.getRangeAt(0).getClientRects();
        let boundingClientRect = sel.getRangeAt(0).getBoundingClientRect();
        var node = sel.getRangeAt(0).commonAncestorContainer as HTMLElement;
        var text = getText();

        //window.getSelection().removeAllRanges()
        let { pageView, pageIndex } = this.getPageFromTarget(node);
        if (!pageView) return null;
        var pageRect = pageView.canvas.getClientRects()[0];

        var viewport = pageView.viewport;
        var coords: any[] = [];
        var i = 0;
        let cleanedList = Array.from(selectionRects);
        let finalRects = fillGap(cleanedList);
        for (i = 0; i < finalRects.length; i++) {
            var r = finalRects[i];
            let topY = r.top;
            let botY = r.bottom;

            var temp = [
                viewport
                    .convertToPdfPoint(r.left - pageRect.x, topY - pageRect.y)
                    .concat(
                        viewport.convertToPdfPoint(
                            r.right - pageRect.x,
                            botY - pageRect.y
                        )
                    ),
            ];
            coords = coords.concat(temp);
        }

        let pdfPoint = pageView.viewport.convertToPdfPoint(
            boundingClientRect.x - pageRect.x,
            boundingClientRect.y - pageRect.y
        );
        return {
            text: text,
            sourceMetadata: {
                format: "PDF",
                storedLocation: "online",
                objectID: this.readingDocument.objectID,
                sourceInfo: {
                    scale: this.getScale(),
                    rotation:
                        (pageView.rotation + pageView.pdfPageRotate) % 360,
                    pageIndex: pageIndex as number,
                    pdfPoint,
                },
                renderInfo: { coords, text, color: "default" },
            } as PDFSourceMetadata,
        };
    };

    addTextToNotebook = () => {
        // Use the notebook manager
        const { text, sourceMetadata } = this.getSelectedText()!;
        this.addSpecificTextToNotebook(text, sourceMetadata)
    };

    addSpecificTextToNotebook = (text: string, sourceMetadata: PDFSourceMetadata) => {
        const manager = store.getState().notebookWorkspaceManager.manager;
        manager.addTextBlock(text, sourceMetadata as SourceMetadata);
    }

    createComment = (x: PDFSourceMetadata, color: ColorAlias) => {
        // const vtopLeft = this.getViewportPointFromPdfPoint(x.sourceInfo.pageIndex, x.sourceInfo.pdfPoint)
        // const vBotRight = {x: vtopLeft.x + 100, y: vtopLeft.y + 80 }
        const commentInfo: PDFStickyNoteAnnotationDetails = {
            annoType: "sticky",
            renderInfo: {
                topLeft: x.sourceInfo.pdfPoint,
                botRight: [x.sourceInfo.pdfPoint[0]+80, x.sourceInfo.pdfPoint[1] - 40],
                color: color,
                text: ''
            },
        };

        this.addAnnotation(commentInfo, false, x.sourceInfo);
        // store.dispatch(inactivateReaderToolbar());
    };

    renderOnSource = (x: RenderOnSourceOptions) => {
        if (x.sourceMetadata.objectID === this.readingDocument.objectID) {
            // We have to create a new annotation
            x.assetType = x.assetType as "extract" | "clipping" | "link";

            const newAnnotation: PDFExtractAnnotationDetails | PDFClippingAnnotationDetails | PDFLinkAnnotationDetails =
                {
                    renderInfo: {
                        ...x.sourceMetadata.renderInfo,
                        notebookMetadata: x.notebookMetadata,
                    },
                    annoType: x.assetType as "extract" | "clipping" | "link",
                };
            if (newAnnotation.annoType === "link"){
                newAnnotation.renderInfo.topLeft = x.sourceMetadata.sourceInfo.pdfPoint
                newAnnotation.renderInfo.botRight = newAnnotation.renderInfo.topLeft
            }
            const final = this.addAnnotation(
                newAnnotation,
                x.id,
                x.sourceMetadata.sourceInfo,
                true
            );
            return final;
        } else {
            console.error(x);
        }

        return;
    };

    unrenderOnSource = (x: UnrenderOnSourceOptions) => {
        if (x.sourceMetadata.objectID === this.readingDocument.objectID) {
            const sourceMetadata = x.sourceMetadata as PDFSourceMetadata;
            this.annotations[sourceMetadata.sourceInfo.pageIndex] =
                this.annotations[sourceMetadata.sourceInfo.pageIndex].filter(
                    (anno) => anno.id !== x.id
                );
            this.showAnnotation(sourceMetadata.sourceInfo.pageIndex);
        } else {
            console.error(x);
        }

        return;
    };

    updateAnnotation = (annotation: PDFAnnotation) =>{
        this.annotations[annotation.sourceInfo.pageIndex] = this.annotations[annotation.sourceInfo.pageIndex].map((x)=>{
            if (x.id === annotation.id){
                return annotation
            }
            else{
                return x
            }
        }) 
        this.showAnnotation(annotation.sourceInfo.pageIndex)
        updateAnnotation(annotation)
    }

    deleteAnnotation = (annotation: PDFAnnotation)=>{
        this.annotations[annotation.sourceInfo.pageIndex] = this.annotations[annotation.sourceInfo.pageIndex].filter((x)=>{
            return x.id !== annotation.id
            
        }) 
        this.showAnnotation(annotation.sourceInfo.pageIndex)
        deleteAnnotation(annotation)
    }

    showAnnotation = (num: number) => {
        console.log(this.annotations);
        let pageAnnotations = this.annotations[num];
        if (!pageAnnotations) return;

        var pageView = this.viewer.getPageView(num);
        if (!pageView?.textLayer) return;

        var pageElement = pageView.textLayer.textLayerDiv;
        var viewport = pageView.viewport;
        var layer = pageElement.getElementsByClassName("CustomHighlightLayer");
        if (layer.length === 0) {
            if (this.highlightRoots[num]) {
                this.highlightRoots[num].unmount();
            }
            layer = document.createElement("div");
            layer.setAttribute("class", "CustomHighlightLayer");
            pageElement.appendChild(layer);
            let root = ReactDOM.createRoot(layer);
            this.highlightRoots[num] = root;
        }
        let all = [];

        // HIGHLIGHTS SECTION
        var pageHighlights = pageAnnotations.filter(
            (x) => x.annoType === "highlight" || x.annoType === "extract"
        );
        if (pageHighlights) {
            for (var j = 0; j < pageHighlights.length; j++) {
                let elem = pageHighlights[j] as
                     PDFHighlightAnnotation
                    | PDFExtractAnnotation;
                var rectangles = [];
                for (var i = 0; i < elem.renderInfo.coords.length; i++) {
                    var rect = elem.renderInfo.coords[i];
                    var bounds = viewport.convertToViewportRectangle(rect);
                    var left = Math.min(bounds[0], bounds[2]);
                    var top = Math.min(bounds[1], bounds[3]);
                    var width = Math.abs(bounds[0] - bounds[2]);
                    var height = Math.abs(bounds[1] - bounds[3]);
                    var CSSrect = {
                        left: left,
                        top: top,
                        width: width,
                        height: height,
                    };
                    rectangles.push(CSSrect);
                }
                const color =
                    elem.annoType === "highlight"
                        ? ColorMap[elem.renderInfo.color as ColorAlias]
                        : (ExtractColorMap as any)[elem.renderInfo.color];
                let el = (
                    <PdfHighlight
                        id={elem.id}
                        key={elem.id}
                        rectangles={rectangles}
                        color={color}
                        sourceInfo={elem.sourceInfo}
                        borderBottom={elem.annoType === "extract"}
                        glow = {this.glowing.has(elem.id)}
                        showContextMenu= {
                            (e:any)=>{
                                e.stopPropagation()
                                e.preventDefault()
                                this.showContextMenu({
                                    menuArray: elem.annoType === "highlight" ?[
                                        {
                                            handler: (color: string)=>{
                                                const newAnnotation = {...elem}
                                                newAnnotation.renderInfo.color = color
                                                this.updateAnnotation(newAnnotation)
                                            },
                                            type: "colors"
                                        },
                                        {
                                            label: 'Add to Notes',
                                            icon: edit,
                                            handler: ()=>{
                                                this.addSpecificTextToNotebook(elem.renderInfo.text, {
                                                    objectID: elem.objectID,
                                                    renderInfo: elem.renderInfo,
                                                    sourceInfo: elem.sourceInfo,
                                                    format: "PDF",
                                                    storedLocation: "online"
                                                })
                                            }
                                        },
                                        {
                                            label: 'Copy',
                                            icon: copy,
                                            handler: async()=>{
                                                try {
                                                    await navigator.clipboard.writeText(elem.renderInfo.text);
                                                    console.log('Page URL copied to clipboard');
                                                  } catch (err) {
                                                    console.error('Failed to copy: ', err);
                                                  }
                                            }
                                        },

                                        {
                                            type: 'separator'
                                        },
                                        {
                                            type: 'danger',
                                            label: 'Delete',
                                            icon: trash,
                                            handler: ()=>{
                                                this.deleteAnnotation(elem)
                                            }
                                        }
                                    ]: [
                                        
                                        {
                                            label: 'Navigate to Notes',
                                            icon: navigate_light,
                                            handler: ()=>{
                                                navigateToNotebook((elem as PDFExtractAnnotation).renderInfo.notebookMetadata, {
                                                    annoType: "extract",
                                                    id: elem.id
                                                })
                                            }
                                        },
                                        {
                                            label: 'Copy',
                                            icon: copy,
                                            handler: async()=>{
                                                try {
                                                    await navigator.clipboard.writeText(elem.renderInfo.text);
                                                    console.log('Page URL copied to clipboard');
                                                  } catch (err) {
                                                    console.error('Failed to copy: ', err);
                                                  }
                                            }
                                        }
                                    ] ,
                                    show: true,
                                    X: e.clientX,
                                    Y: e.clientY
                                })
                            }
                        }
                    ></PdfHighlight>
                );
                all.push(el);
            }
        }

        var pageClippings = pageAnnotations.filter(
            (x) => x.annoType === "clipping"
        ) as (PDFClippingAnnotation)[];
        pageClippings.forEach((x) => {
            let el = (
                <PdfClip
                    key = {x.id}
                    topLeft={viewport.convertToViewportPoint(
                        x.renderInfo.topLeft[0],
                        x.renderInfo.topLeft[1]
                    )}
                    botRight={viewport.convertToViewportPoint(
                        x.renderInfo.botRight[0],
                        x.renderInfo.botRight[1]
                    )}
                    scale={this.getScale()}
                    hidden={x.renderInfo.hidden}
                    setHidden={(bool) => {
                        this.annotations[num].map((anno) => {
                            if (
                                x.id === anno.id &&
                                anno.annoType === "clipping"
                            ) {
                                anno.renderInfo.hidden = bool;
                                updateAnnotation(anno);
                                return anno;
                            }
                            return anno;
                        });
                        // this.showAnnotation(num);
                    }}
                    showContextMenu={this.showContextMenu}
                    navigateToNotebook={()=>{
                        navigateToNotebook(x.renderInfo.notebookMetadata, {annoType: x.annoType, id: x.id})
                    }}
                    glow = {this.glowing.has(x.id)}
                ></PdfClip>
            );
            all.push(el);
        });

        const final = <Provider store={store}>{all}</Provider>;
        this.highlightRoots[num].render(final);

        // END HIGHLIGHTS SECTION

        const selector = ".PageViewer[data='" + this.readingDocument.objectID + "'] > [data-page-number='"+ (num + 1)+ "']"
        const allAnnotations = [];

        // Begin Comments Section
        pageElement = pageView.div;
        layer = pageElement.getElementsByClassName("CustomAnnotationLayer");
        if (layer.length === 0) {
            if (this.annotationRoots[num]) {
                this.annotationRoots[num].unmount();
            }
            layer = document.createElement("div");
            layer.setAttribute("class", "CustomAnnotationLayer");
            pageElement.appendChild(layer);
            let root = ReactDOM.createRoot(layer);
            this.annotationRoots[num] = root;
        }

        // var pageComments = pageAnnotations.filter(
        //     (x) => x.annoType === "thread"
        // ) as (PDFComment)[];

        // for (j = 0; j < pageComments.length; j++) {
        //     let comment = pageComments[j];
        //     let el = (
        //         <ThreadComponent
        //             thread={{
        //                 id: comment.id,
        //                 comments: comment.renderInfo.comments,
        //             }}
        //             updateThread={(comments: Comment[]) => {
        //                 this.annotations[num].map((x) => {
        //                     if (
        //                         x.id === comment.id &&
        //                         x.annoType === "thread"
        //                     ) {
        //                         x.renderInfo.comments = comments;
        //                         updateAnnotation(x);
        //                         return x;
        //                     }
        //                     return x;
        //                 });
        //                 this.showAnnotation(num);
        //             }}
        //         ></ThreadComponent>
        //     );
        //     allAnnotations.push(el);
        // }

        

        let pageRectangles = pageAnnotations.filter(
            (x) => x.annoType === "rectangle"
        ) as (PDFRectangleAnnotation)[]

        for (j = 0; j < pageRectangles.length; j++){
            let rectangle = pageRectangles[j]
            let el = <PdfRectangle
                key = {rectangle.id}
                annotation={rectangle}
                selector={selector}
                getViewportPointFromPdfPoint = {this.getViewportPointFromPdfPoint}
                getPdfPointFromCoordinates = {this.getPdfPointFromCoordinates}
                updateAnnotation = {
                    this.updateAnnotation
                }
                showContextMenu = {
                    (e:any)=>{
                        e.stopPropagation()
                        e.preventDefault()
                        this.showContextMenu({
                            menuArray: [
                                {
                                    handler: (color: string)=>{
                                        const newAnnotation = {...rectangle}
                                        newAnnotation.renderInfo.drawingStyle.activeColor = color as ColorAlias
                                        this.updateAnnotation(newAnnotation)
                                    },
                                    type: "colors"
                                },
                                {
                                    label: 'Opacity',
                                    currentValue: rectangle.renderInfo.drawingStyle.opacity,
                                    handler: (newOpacity: number)=>{
                                        const newAnnotation = {...rectangle}
                                        newAnnotation.renderInfo.drawingStyle.opacity = newOpacity
                                        this.updateAnnotation(newAnnotation)
                                    },
                                    type: "slider"
                                },
                                {
                                    type: 'separator'
                                },
                                {
                                    type: 'danger',
                                    label: 'Delete',
                                    icon: trash,
                                    handler: ()=>{
                                        this.deleteAnnotation(rectangle)
                                    }
                                }
                            ],
                            show: true,
                            X: e.clientX,
                            Y: e.clientY
                        })
                    }
                }
                scale = {this.getScale()}
                
            ></PdfRectangle>
            allAnnotations.push(el);
        }


        let pageLinks = pageAnnotations.filter(
            (x) => x.annoType === "link"
        ) as (PDFLinkAnnotation)[]

        for (j = 0; j < pageLinks.length; j++){
            let link = pageLinks[j]
            let el = <PdfLink
                key={link.id}
                annotation={link}
                selector={selector}
                getViewportPointFromPdfPoint = {this.getViewportPointFromPdfPoint}
                getPdfPointFromCoordinates = {this.getPdfPointFromCoordinates}
                updateAnnotation = {
                    this.updateAnnotation
                }
                scale = {this.getScale()}
                glow = {this.glowing.has(link.id)}
                showContextMenu = {
                    (e:any)=>{
                        e.stopPropagation()
                        e.preventDefault()
                        this.showContextMenu({
                            menuArray: [
                                {
                                    label: "Navigate to Note",
                                    icon: navigate_light,
                                    handler: ()=>{
                                        navigateToNotebook((link as PDFLinkAnnotation).renderInfo.notebookMetadata, {
                                            annoType: "link",
                                            id: link.id
                                        })
                                    },
                                    type: "none"
                                },
                                
                            ],
                            show: true,
                            X: e.clientX,
                            Y: e.clientY
                        })
                    }
                }
            ></PdfLink>
            allAnnotations.push(el);
        }

        var pageComments = pageAnnotations.filter(
            (x) => x.annoType === "sticky"
        ) as (PDFStickyNoteAnnotation)[];

        for (j = 0; j < pageComments.length; j++) {
            let comment = pageComments[j];
            let el = (
                // @ts-expect-error
                <PdfStickyNote
                    key={comment.id}
                    fontSize={12*this.getScale()+'px'}
                    annotation={comment}
                    selector ={selector}
                    scale ={this.getScale()}
                    getViewportPointFromPdfPoint = {this.getViewportPointFromPdfPoint} 
                    getPdfPointFromCoordinates = {this.getPdfPointFromCoordinates}
                    updateAnnotation = {this.updateAnnotation}
                    showContextMenu = {
                        (e:any)=>{
                            e.stopPropagation()
                            e.preventDefault()
                            this.showContextMenu({
                                menuArray: [
                                    {
                                        handler: (color: string)=>{
                                            const newAnnotation = {...comment}
                                            newAnnotation.renderInfo.color = color as ColorAlias
                                            this.updateAnnotation(newAnnotation)
                                        },
                                        type: "colors"
                                    },
                                    {
                                        label: 'Copy',
                                        icon: copy,
                                        handler: async()=>{
                                            try {
                                                await navigator.clipboard.writeText(comment.renderInfo.text);
                                                console.log('Page URL copied to clipboard');
                                              } catch (err) {
                                                console.error('Failed to copy: ', err);
                                              }
                                        }
                                    },
                                    {
                                        type: 'separator'
                                    },
                                    {
                                        type: 'danger',
                                        label: 'Delete',
                                        icon: trash,
                                        handler: ()=>{
                                            this.deleteAnnotation(comment)
                                        }
                                    }
                                ],
                                show: true,
                                X: e.clientX,
                                Y: e.clientY
                            })
                        }
                    }
                    >

                </PdfStickyNote>)
            allAnnotations.push(el);
        }
        const finalAnnotations = (
            <Provider store={store}>{allAnnotations}</Provider>
        );
        this.annotationRoots[num].render(finalAnnotations);
    };

    getRotation: () => number = () => {
        return this.viewer.pagesRotation;
    };

    setRotation: (value: number) => void = (value) => {
        this.viewer.pagesRotation = value;
    };

    navigateToPage: (pageNumber: number) => void = (pageNumber) => {};


    findOptions = {
        highlightAll: true,
        entireWordCheckbox: true, 
        caseSensitiveCheckbox: false,
    }

    find = (search: string)=>{
        this.eventBus.dispatch("find", {
            ...this.findOptions,
            query: search,
            findPrevious: false
          });
    }

    findNext = (search:string)=>{
        this.eventBus.dispatch("find", {
            ...this.findOptions,
            type: "again",
            query: search,
            findPrevious: false
          });
    }

    findPrev = (search:string)=>{
        this.eventBus.dispatch("find", {
            ...this.findOptions,
            type: "again",
            query: search,
            findPrevious: true
          });
    }

   
}

export { PdfManager };
