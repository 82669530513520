import React, {useRef, useEffect} from 'react';
import { Device } from '../../types/devices';
import useWindowSize from '../../hooks/useWindowSize';

import {useAppSelector, useAppDispatch} from '../../redux'
import {closeOverlay, openOverlay} from '.'
import close from '../../assets/icons/close.svg'
import OverlayLaptop from './Overlay.laptop/Overlay.laptop';
import OverlayMobile from './Overlay.mobile/Overlay.mobile';

type OverlayProps = {
    
};

const Overlay:React.FC<OverlayProps> = () => {

    const overlay = useAppSelector((state) => state.overlay.value)

    const device: Device = useWindowSize()

    const dispatch = useAppDispatch()

    const clickOutsideOverlay = (e: any) =>{
        console.log("clickoutSide function working")
        if(e.target.classList.contains("OverlayLaptop") || e.target.classList.contains("OverlayMobile")){
            //clicked on the transluscent background
            console.log("I need to close overlay here. ")
            
            dispatch(closeOverlay())            
        }
    }

    useEffect(() =>{

        console.log("mounting overlay")
        setTimeout(() =>{
            document.addEventListener('click', clickOutsideOverlay)
        }, 300)

        return () =>{
            console.log("removing useOverlayListener")
            document.removeEventListener('click', clickOutsideOverlay)
        }

    }, [])

    // console.log("overlay is: ", overlay)
    
    if(overlay){
        return (
            <div>
                {device=="laptop" ? <OverlayLaptop overlay={overlay}/> : <OverlayMobile overlay={overlay} />}
            </div>
        )   
    }
    else{
        return null
    }
}
export default Overlay;