import {
    ResizableNodeView,
    ResizableRatioType,
} from "prosemirror-resizable-view";
import { setStyle } from "@remirror/core";
// import loading from "../../../../../../../assets/legacy_icons/gifs/loading_auth.gif";
import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    DelayedPromiseCreator,
    EditorView,
    ErrorConstant,
    extension,
    ExtensionTag,
    getTextSelection,
    invariant,
    isElementDomNode,
    NodeExtension,
    NodeExtensionSpec,
    NodeSpecOverride,
    NodeViewMethod,
    omitExtraAttributes,
    PrimitiveSelection,
    ProsemirrorAttributes,
    ProsemirrorNode,
} from "@remirror/core";
import navigate_light from "../../../../../../../assets/legacy_icons/menu_icons/navigate_light.svg";
import navigate_dark from "../../../../../../../assets/legacy_icons/menu_icons/navigate_dark.svg";
import bin_dark from "../../../../../../../assets/legacy_icons/menu_icons/bin_dark.svg";
import bin_light from "../../../../../../../assets/legacy_icons/menu_icons/bin_light.svg";
import copy_light from "../../../../../../../assets/legacy_icons/copy_light.png";
import copy_dark from "../../../../../../../assets/legacy_icons//copy_dark.png";
import { TextSelection, NodeSelection } from "@remirror/pm/state";
import { fetchSourceRenders, getSourceInfo } from "../../NotebookUtils";
import { navigateToSource } from "../../../../../desk-logic/sourceFunctions";
import loading  from '../../../../../../../assets/icons/gifs/loading_secondary.gif'


function getDataURL(img) {
    // Create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/jpeg");
}

function getDimensions(element) {
    let { width, height } = element.style;
    width = width || element.getAttribute("width") || "";
    height = height || element.getAttribute("height") || "";

    return { width, height };
}

/**
 * ResizableImageView is a NodeView for image. You can resize the image by
 * dragging the handle over the image.
 */
export class ResizableImageView extends ResizableNodeView {
    constructor(node, view, getPos, options) {
        super({
            node,
            view,
            getPos,
            aspectRatio: ResizableRatioType.Fixed,
            options,
        });

        /**
     * 
     * imageStore provides the following functionality:
     * 1. fetchImageFromMetadata - a function that takes the node's metadata attribute and
     *      returns a promise. On resolving the promise we get the actual image source.
     * It is responsible for caching and saving the image in the backend as well. 
    
    */
    }

    createElement({ node, view, options, getPos }) {
        this.inner = document.createElement("img");

        this.inner.setAttribute("src", loading);
        this.inner.setAttribute(
            IMAGE_METADATA_ATTRIBUTE,
            JSON.stringify(node.attrs.metadata)
        );
        this.imageStore = options.imageStore;

        this.imageStore.fetchImageFromMetadata(node.attrs).then((newSrc) => {
            this.inner.setAttribute("src", newSrc);
            console.log("SRC", newSrc, node.attrs.src);

            if (newSrc != node.attrs.src) {
                let currTr = view.state.tr;
                const newAttrs = { ...node.attrs };
                newAttrs.src = newSrc;
                view.dispatch(currTr.setNodeMarkup(getPos(), null, newAttrs));
            }
        });

        console.log("to dom nodeview", node.attrs);

        setStyle(this.inner, {
            width: "100%",
            minWidth: "50px",
            objectFit: "contain", // maintain image's aspect ratio
        });
        this.inner.addEventListener("click", (event) => {
            console.log("DRAFT clicked", node);
            setTimeout(
                () =>
                    options.showContextMenu({
                        show: true,
                        menuArray: [
                            node.attrs.metadata.sourceMetadata
                                ? {
                                      icon:
                                          options.getAppTheme() == "light"
                                              ? navigate_light
                                              : navigate_dark,
                                      label:
                                          "Navigate to " +
                                          getSourceInfo(
                                              node.attrs.metadata.sourceMetadata
                                          ),
                                      handler: () =>
                                          navigateToSource(
                                              node.attrs.metadata.sourceMetadata, {assetType: "clipping", key: node.attrs.metadata.id}
                                          ),
                                  }
                                : {},
                            {
                                icon:
                                    options.getAppTheme() == "light"
                                        ? bin_light
                                        : bin_dark,
                                label: "Delete Image",
                                handler: () => {
                                    let outerTr = view.state.tr;
                                    view.dispatch(
                                        outerTr.deleteRange(
                                            getPos(),
                                            getPos() + 1
                                        )
                                    );
                                },
                            },
                            {
                                icon:
                                    options.getAppTheme() == "light"
                                        ? copy_light
                                        : copy_dark,
                                label: "Copy Image",
                                handler: async () => {
                                    let outerTr = view.state.tr;
                                    view.dispatch(
                                        outerTr.setSelection(
                                            new NodeSelection(
                                                view.state.doc.resolve(getPos())
                                            )
                                        )
                                    );
                                    document.execCommand("copy");
                                    const clipboardContents =
                                        await navigator.clipboard.read();
                                    for (const item of clipboardContents) {
                                        // if (!item.types.includes('image/png')) {
                                        //   throw new Error('Clipboard contains non-image data.');
                                        // }
                                        const blobText = await (
                                            await item.getType("text/html")
                                        ).text();
                                        console.log("copied", blobText);
                                    }
                                    //view.dispatch(outerTr.setSelection(view.state.selection))
                                },
                            },
                        ],
                        X: event.clientX,
                        Y: event.clientY,
                    }),
                0
            );
        });

        return this.inner;
    }
}

const IMAGE_METADATA_ATTRIBUTE = "data-custom-image";

@extension({
    defaultOptions: {
        imageStore: {
            fetchImageFromMetadata: () =>
                new Promise((resolve) =>
                    setTimeout(
                        () => resolve("https://dummyimage.com/600x400/000/fff"),
                        1000
                    )
                ),
        },
        navigateToSource: () => {},
        showContextMenu: () => {},
        getAppTheme: () => {return 'light'},
    },
})
export class UpdatedImageExtension extends NodeExtension {
    get name() {
        return "image";
    }

    createTags() {
        return [ExtensionTag.InlineNode, ExtensionTag.Media];
    }

    createNodeSpec(extra, override) {
        return {
            inline: true,
            draggable: true,
            selectable: false,
            ...override,
            attrs: {
                ...extra.defaults(),
                alt: { default: "" },
                crop: { default: null },
                height: { default: null },
                width: { default: null },
                rotate: { default: null },
                src: { default: null },
                metadata: {
                    default: {
                        id: null,
                        imageFormat: "image/png",
                        type: null,
                        filePath: null,
                        s3Object: null,
                        sourceMetadata: null,
                        sourceURL: null,
                    },
                },
                // Contains:
                // type: online or offline
                // filePath: (where asset is stored, offline only)
                // s3Object: (where asset is stored, online only)
                // sourceMetadata:
                title: { default: "" },
                resizable: { default: false },
            },
            parseDOM: [
                {
                    tag: "img[src]",
                    getAttrs: (element) =>
                        isElementDomNode(element)
                            ? this.getImageAttributes({
                                  element,
                                  parse: extra.parse,
                              })
                            : {},
                },
                {
                    tag: `img[${IMAGE_METADATA_ATTRIBUTE}]`,
                    getAttrs: (element) =>
                        isElementDomNode(element)
                            ? this.getImageAttributes({
                                  element,
                                  parse: extra.parse,
                              })
                            : {},
                },
                ...(override.parseDOM ?? []),
            ],
            toDOM: (node) => {
                // extra.metadata = node.attrs.metadata;
                console.log("TO DOM", node.attrs.src);
                const attrs = omitExtraAttributes(node.attrs, extra);
                return [
                    "img",
                    {
                        ...extra.dom(node),
                        ...attrs,
                        alt: "Image",
                        src: node.attrs.src,
                        [IMAGE_METADATA_ATTRIBUTE]: JSON.stringify(
                            node.attrs.metadata
                        ),
                    },
                ];
            },
        };
    }

    /**
     * Retrieve attributes from the dom for the image extension.
     */
    getImageAttributes = ({ element, parse }) => {
        const { width, height } = getDimensions(element);
        const src = element.getAttribute("src") ?? null;
        return {
            ...parse(element),
            alt: element.getAttribute("alt") ?? "",
            height: Number.parseInt(height || "0", 10) || null,
            title: element.getAttribute("title") ?? "",
            width: Number.parseInt(width || "0", 10) || null,
            src: src,
            metadata:
                JSON.parse(element.getAttribute(IMAGE_METADATA_ATTRIBUTE)) ??
                this.options.imageStore.saveAndInsertImage({ url: src })
                    .metadata,
        };
    };

    createCommands() {
        return {
            insertImageAtStart: (attributes, selection) => {
                return ({ tr, dispatch }) => {
                    const from = 1;
                    const to = 1;
                    const node = this.type.create(attributes);
                    let step1 = tr.deleteRange(from, to);
                    console.log("FIRST POS", from);
                    let step3 = step1.insert(
                        from,
                        step1.doc.type.schema.nodes.paragraph.createAndFill(
                            { nodeTextAlignment: "center" },
                            node
                        )
                    );
                    let step5 = step3.insert(
                        from + 3,
                        step3.doc.type.schema.nodes.paragraph.create()
                    );
                    console.log("STEP 5", step5);
                    let step6 = step5.setSelection(
                        TextSelection.create(step5.doc, from + 4, from + 4)
                    );

                    dispatch?.(step6);

                    return true;
                };
            },
            insertImage: (attributes, selection) => {
                return ({ tr, dispatch }) => {
                    const { from, to } = getTextSelection(
                        selection ?? tr.selection,
                        tr.doc
                    );
                    const node = this.type.create(attributes);
                    let step1 = tr.deleteRange(from, to);
                    console.log("FIRST POS", from);
                    let step3 = step1.insert(
                        from,
                        step1.doc.type.schema.nodes.paragraph.createAndFill(
                            { nodeTextAlignment: "center" },
                            node
                        )
                    );
                    let step5 = step3.insert(
                        from + 3,
                        step3.doc.type.schema.nodes.paragraph.create()
                    );
                    console.log("STEP 5", step5);
                    let step6 = step5.setSelection(
                        TextSelection.create(step5.doc, from + 4, from + 4)
                    );

                    dispatch?.(step6);

                    return true;
                };
            },
        };
    }

    createNodeViews() {
        let that = this;
        return (node, view, getPos) => {
            return new ResizableImageView(node, view, getPos, that.options);
        };
    }
}
