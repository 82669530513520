import React, {useContext, useState} from 'react';
import useMoveFile from '../../../../../hooks/FileHooks/useMoveFile';
import { EnvironmentContext } from '../../../../../contexts';
import { Folder } from '../../../../../types/files';


type BreadcrumbSectionProps = {
    segment: Folder, 
    navigationHandler: (segment: Folder) => void | null
};

const BreadcrumbSection:React.FC<BreadcrumbSectionProps> = (props) => {

    const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder


    const env = useContext(EnvironmentContext)
    const backendMoveFile = useMoveFile(env)    

    const onDropFolder = (e: React.DragEvent<HTMLDivElement>) => {

        // Move function needs to be written
        var data = e.dataTransfer.getData("text");
        console.log("data in drop", JSON.parse(data) as Folder)
        setIsOnDrop(false);

        //Here, we move the folder

        backendMoveFile({
            file: JSON.parse(data) as Folder,
            newParentID: props.segment.objectID
        })

    }

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) =>{
        console.log("onDragLeave")
        setIsOnDrop(false)
    }


    const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) =>{

        var data = (e.dataTransfer.getData("text"))
        setIsOnDrop(true)
    }

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) =>{
        e.preventDefault()
    }

    
    return (
        <span className = {`PageTitle-pathSegment ${isOnDrop?"onDrop":""}`} onClick={()=>props.navigationHandler(props.segment)}
            onDragOver={onDragOver}
            onDragOverCapture={onDragOverCapture} 
            onDragLeave={onDragLeave} 
            onDrop={onDropFolder} 
        >
            {props.segment.name}
        </span>
    )
}
export default BreadcrumbSection;