import React, {useEffect, useState, useRef} from 'react';
import arrow from '../../../../../assets/icons/right_arrow.svg'
import close from '../../../../../assets/icons/close.svg'
import './ReaderFindbar.css'
import { addShortcutListeners } from '../../../../../utils/shortcuts/shortcuts';
import { ReaderTab } from '../../Reader/readerTabsSlice';
import { useEffectOnce } from '../../../../../hooks/useEffectOnce';

type ReaderFindbarProps = {
    findbarVisible: boolean,
    setFindbarVisible: React.Dispatch<React.SetStateAction<boolean>>,
    tab: ReaderTab
};

const ReaderFindbar:React.FC<ReaderFindbarProps> = (props) => {
    
    const [numResults, setNumResults] = useState<string>('')
    const [findValue, setFindValue] = useState<string>('');
    const [findMessage, setFindMessage] = useState<string>('')
    const findFieldRef = useRef<HTMLInputElement>(null)
    // const toggleButtonRef = useRef(null)
    // const matchCaseButtonRef = useRef(null)
    // const wholeWordsButtonRef = useRef(null)
    // const barRef = useRef(null)
    const findPreviousButtonRef = useRef(null)
    const findNextButtonRef = useRef(null)
    const findMsgRef = useRef(null)
    const findResultsCountRef = useRef(null)

    const find = (text:string)=>{
        props.tab.manager.find(text)
    }

    const findPrev = ()=>{
        props.tab.manager.findPrev(findValue)
    }

    const findNext = ()=>{
        props.tab.manager.findNext(findValue)
    }

    useEffect(()=>{
        // console.error("HALLELUJAH REGISTERINTG")
        props.tab.manager?.eventBus.on("updatefindmatchescount", (e:any)=>{

            if (e.source._pdfDocument === props.tab.manager.documentObj){
                if (e.matchesCount){
                    if (e.matchesCount.current !==0 && e.matchesCount.total !== 0){

                        setFindMessage(e.matchesCount.current + " of " + e.matchesCount.total)
                    }
                    else{
                        setFindMessage("No matches!")
                    }
                }

            }
        })
        props.tab.manager?.eventBus.on("updatefindcontrolstate", (e:any)=>{
            if (e.source._pdfDocument === props.tab.manager.documentObj){

                if (e.source._pdfDocument === props.tab.manager.documentObj){
                    if (e.matchesCount){
                        if (e.matchesCount.current !==0 && e.matchesCount.total !== 0){

                            setFindMessage(e.matchesCount.current + " of " + e.matchesCount.total)
                        }else{
                            setFindMessage("No matches!")
                        }
                    }
                    
                }
            }
        })

        console.log("Mounted!")

    }, [props.tab])

    useEffect(() =>{
        if(props.findbarVisible){
            console.log("findbar visible!")
            setFindValue("")
            if(findFieldRef.current) findFieldRef.current.focus()
            
        }

    }, [props.findbarVisible])

    const closeFindBar = () => {
        props.tab.manager?.eventBus.dispatch("findbarclose", { source: this });
        props.setFindbarVisible(false)
    }

    const _handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {
            findNext()
        }
      }
   

    
    return (
        <div className = {`ReaderFindbar ${props.findbarVisible?"show":"hide"}`}>
            <button className="non-button err-msg" ref ={findMsgRef}></button>
            <input className="searchInput" ref={findFieldRef}  placeholder="Find in PDF..." value = {findValue} onChange={(e)=>{
                setFindValue(e.target.value)
                find(e.target.value)
            }} onKeyDown={_handleKeyDown}></input>
            <img className={`findButtonImage findNext `} ref={findNextButtonRef} alt="next" src={arrow} onClick={findNext}></img>
            <img className={`findButtonImage findPrev `} ref={findPreviousButtonRef} alt="prev" src={arrow} onClick={findPrev} ></img>
            <button className="find-message" ref={findResultsCountRef}>{findMessage}</button>
            <img className={`closeFind findButtonImage`} src={close} alt='close' onClick={closeFindBar}></img>
            {/* Match {this.state.currentMatch} of {this.state.totalMatches} */}
        </div>
    );

    // <div ref={barRef} className = {`ReaderFindbar ${props.findbarVisible?"show":"hide"}`}>
    //         {/* <button className="non-button err-msg"></button> */}

    //         <input ref={findFieldRef} className="searchInput"  placeholder="Find in document..."></input>
    //         <img className={`findButtonImage findNext `} alt="next" src={arrow} ></img>
    //         <img className={`findButtonImage findPrev `} alt="prev" src={arrow} ></img>
    //         {/* <button className="find-message"></button> */}
    //         <img className={`closeFind findButtonImage`} src={close} alt='close' onClick={closeFindBar}></img>
    //         {/* Match {this.state.currentMatch} of {this.state.totalMatches} */}
    //     </div>
}
export default ReaderFindbar;