import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Device} from '../types/devices'
import { resizeWindow } from '../pages/desk/components/Separator/separatorSlice';

type windowSizeType = {
    width: number, 
    height: number
}

const useWindowSize: () => Device = () => {

    const dispatch = useDispatch()

    const [windowSize, setWindowSize] = useState<windowSizeType>(
        {width: window.innerWidth, height: window.innerHeight}
    )

    const changeWindowSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        dispatch(resizeWindow())
    }

    useEffect(() =>{
        window.addEventListener('resize', changeWindowSize)

        return () =>{
            window.removeEventListener("resize", changeWindowSize)
        }

    }, [])
    

    return windowSize.width>500?"laptop":"mobile"

}

export default useWindowSize